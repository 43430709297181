// // // Custom DashBoardHome component
// // // Created by Shamiel Speelman
// // // Version: 0.1
// import React, { useState, useEffect } from 'react';
// import { Box, Card, CardContent, Typography, Button, Modal, Grid, Divider, Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
// import SubscribeStart from './SubscribeStart';
// import { Client, Databases, Query } from 'appwrite';
// import authService from '../appwrite/auth';
// import conf from '../conf/conf';
// import WarningIcon from '@mui/icons-material/Warning';
// import BlockIcon from '@mui/icons-material/Block';
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   ResponsiveContainer,
//   Legend,
// } from 'recharts';
// import MatchCard from './MatchCard';
// import MyCalendar from './Calendar';

// const client = new Client()
//   .setEndpoint(conf.appwriteUrl)
//   .setProject(conf.appwriteProjectId);

// const databases = new Databases(client);

// const DashboardHome = () => {
//   const [open, setOpen] = useState(false);
//   const [cardsData, setCardsData] = useState({ redCards: 0, yellowCards: 0, goals: 0, wins: 0, losses: 0, topScorer: '', topScorerImage: '' });
//   const [performanceData, setPerformanceData] = useState([]);
//   const [topScorers, setTopScorers] = useState([]);
//   const [nextMatch, setNextMatch] = useState(null);

//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const currentUser = await authService.getCurrentUser();
//         if (!currentUser) {
//           throw new Error('No user is currently logged in.');
//         }

//         const cardsResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.cardsCollectionId, [
//           Query.equal('userID', currentUser.$id)
//         ]);

//         const goalsResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.goalsCollectionId, [
//           Query.equal('userID', currentUser.$id)
//         ]);

//         const resultsResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.finalResultsCollectionId, [
//           Query.equal('userID', currentUser.$id)
//         ]);

//         const redCards = cardsResponse.documents.filter(card => card.cardType === 'red').length;
//         const yellowCards = cardsResponse.documents.filter(card => card.cardType === 'yellow').length;

//         const wins = resultsResponse.documents.filter(result => result.finalScoreHome > result.finalScoreOpposition).length;
//         const losses = resultsResponse.documents.filter(result => result.finalScoreHome < result.finalScoreOpposition).length;

//         const goals = goalsResponse.documents.length;

//         const topScorersData = goalsResponse.documents.reduce((acc, doc) => {
//           acc[doc.teamPlayer] = acc[doc.teamPlayer] || { goals: 0, assists: 0 };
//           acc[doc.teamPlayer].goals += 1;
//           if (doc.assistPlayer) {
//             acc[doc.assistPlayer] = acc[doc.assistPlayer] || { goals: 0, assists: 0 };
//             acc[doc.assistPlayer].assists += 1;
//           }
//           return acc;
//         }, {});

//         const topScorers = Object.entries(topScorersData)
//           .map(([player, stats]) => ({ player, ...stats }))
//           .sort((a, b) => b.goals - a.goals)
//           .slice(0, 3);

//         setTopScorers(topScorers);

//         const topScorer = topScorers.length > 0 ? topScorers[0].player : 'No data';

//         let topScorerImage = '';

//         if (topScorer !== 'No data') {
//           const topScorerResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteCollectionId, [
//             Query.equal('fullName', topScorer)
//           ]);

//           if (topScorerResponse.total > 0) {
//             topScorerImage = topScorerResponse.documents[0].image;
//           }
//         }

//         setCardsData({
//           redCards,
//           yellowCards,
//           goals,
//           wins,
//           losses,
//           topScorer,
//           topScorerImage
//         });

//         const performanceResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.rpeCaptureCollectionId, [
//           Query.equal('playerName', topScorer),
//           Query.equal('userID', currentUser.$id)
//         ]);

//         const performanceData = performanceResponse.documents.map(doc => ({
//           date: doc.matchDate || doc.sessionDate,
//           rpeValue: doc.rpeValue,
//           hrBPM: doc.hrBPM,
//         }));

//         setPerformanceData(performanceData);

//         const matchResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.createSquadCollectionId, [
//           Query.equal('userID', currentUser.$id),
//         ]);

//         const sortedMatches = matchResponse.documents
//           .map(match => ({ ...match, matchDate: new Date(match.matchDate) }))
//           .filter(match => match.matchDate > new Date())
//           .sort((a, b) => a.matchDate - b.matchDate);

//         const nextMatch = sortedMatches.length > 0 ? sortedMatches[0] : null;

//         if (nextMatch) {
//           const clubResponse = await databases.getDocument(conf.appwriteDatabaseId, conf.createClubCollectionId, nextMatch.defaultClub);
//           nextMatch.club = clubResponse;
//         }

//         setNextMatch(nextMatch);

//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <Box>
//       <Card sx={{
//         width: '100%', height: '110px', backgroundColor: '#f5f5f5', transition: 'box-shadow 0.3s ease-in-out',
//         '&:hover': {
//           boxShadow: '0 8px 16px rgba(0, 0, 0, 0.9)',
//         }
//       }}>
//         <CardContent>
//           <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px' }}>
//             Welcome to FitSport | RPE
//           </Typography>
//           <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '5px' }}>
//             You can try FitSport for the next 7 days at no cost. All data captured or created during your 7 day test run will be deleted automatically, should you decide FitSport | RPE does not meet your needs.
//           </Typography>
//           <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '5px' }}>
//             If you like what you see click here to&nbsp;
//             <Button variant="contained" size="small" onClick={handleOpen} sx={{
//               padding: '0px 10px', marginLeft: '5px', backgroundColor: '#ffffff', color: '#356596',
//               '&:hover': {
//                 backgroundColor: '#356596',
//                 color: '#ffffff'
//               }
//             }}>
//               subscribe
//             </Button>
//           </Typography>
//         </CardContent>
//       </Card>

//       <Modal open={open} onClose={handleClose}>
//         <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//           <SubscribeStart handleClose={handleClose} />
//         </Box>
//       </Modal>

//       <Grid container spacing={2} sx={{ mt: 2 }}>
//         <Grid item xs={12} sm={6} md={3}>
//           <Card sx={{ backgroundColor: '#FFF8E1', height: 'auto', transition: 'box-shadow 0.3s ease-in-out',
//             '&:hover': {
//               boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
//             }}}>
//             <CardContent sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
//               <Typography variant="h6" align="center" sx={{ mb: { xs: 1, sm: 0 } }}>Cards This Season</Typography>
//               <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                 <BlockIcon sx={{ color: 'red' }} />
//                 <Typography variant="h4" sx={{ ml: 1 }}>{cardsData.redCards}</Typography>
//                 <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
//                 <WarningIcon sx={{ color: 'yellow' }} />
//                 <Typography variant="h4" sx={{ ml: 1 }}>{cardsData.yellowCards}</Typography>
//               </Box>
//             </CardContent>
//           </Card>
//         </Grid>
//         <Grid item xs={12} sm={6} md={3}>
//           <Card sx={{ backgroundColor: '#E8F5E9', height: 'auto', transition: 'box-shadow 0.3s ease-in-out',
//             '&:hover': {
//               boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
//             }}}>
//             <CardContent sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
//               <Typography variant="h6" align="center" sx={{ mb: { xs: 1, sm: 0 } }}>Goals This Season</Typography>
//               <Typography variant="h4" align="center">{cardsData.goals}</Typography>
//             </CardContent>
//           </Card>
//         </Grid>
//         <Grid item xs={12} sm={6} md={3}>
//           <Card sx={{ backgroundColor: '#E3F2FD', height: 'auto', transition: 'box-shadow 0.3s ease-in-out',
//             '&:hover': {
//               boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
//             }}}>
//             <CardContent sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
//               <Typography variant="h6" align="center" sx={{ mb: { xs: 1, sm: 0 } }}>Wins | Losses</Typography>
//               <Typography variant="h4" align="center">{cardsData.wins} | {cardsData.losses}</Typography>
//             </CardContent>
//           </Card>
//         </Grid>
//         <Grid item xs={12} sm={6} md={3}>
//           <Card sx={{ backgroundColor: '#FFEBEE', height: '83px', transition: 'box-shadow 0.3s ease-in-out',
//             '&:hover': {
//               boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
//             }}}>
//             <CardContent sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
//               <Box sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
//                 <Typography variant="h6">Top Scorer This Season</Typography>
//                 <Typography variant="body1">{cardsData.topScorer}</Typography>
//               </Box>
//               {cardsData.topScorerImage && (
//                 <Avatar
//                   src={`https://cloud.appwrite.io/v1/storage/buckets/${conf.appwriteBucketId}/files/${cardsData.topScorerImage}/view?project=${conf.appwriteProjectId}`}
//                   alt={cardsData.topScorer}
//                   sx={{ width: 56, height: 56, ml: 2 }}
//                 />
//               )}
//             </CardContent>
//           </Card>
//         </Grid>
//       </Grid>

//       <Box sx={{ mt: 4 }}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} md={6}>
//             <Typography variant="h6">Player Performance Over Time: {cardsData.topScorer}</Typography>
//             <Box sx={{ height: '200px' }}>
//               <ResponsiveContainer width="100%" height="100%">
//                 <LineChart data={performanceData}>
//                   <CartesianGrid strokeDasharray="3 3" />
//                   <XAxis dataKey="date" />
//                   <YAxis />
//                   <Tooltip />
//                   <Legend />
//                   <Line type="monotone" dataKey="rpeValue" stroke="#8884d8" />
//                   {performanceData.some(entry => entry.hrBPM) && (
//                     <Line type="monotone" dataKey="hrBPM" stroke="#82ca9d" />
//                   )}
//                 </LineChart>
//               </ResponsiveContainer>
//             </Box>
//             <Box sx={{ mt: 4 }}>
//               <Typography variant="h6">Calendar</Typography>
//               <Box sx={{ height: '600px' }}>
//                 <MyCalendar />
//               </Box>
//             </Box>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography variant="h6">Top Scorers</Typography>
//             <TableContainer component={Paper}>
//               <Table size="small" aria-label="top scorers table" sx={{ height: '200px' }}>
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>Position</TableCell>
//                     <TableCell>Player Name</TableCell>
//                     <TableCell>Goals</TableCell>
//                     <TableCell>Assists</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {topScorers.map((scorer, index) => (
//                     <TableRow key={scorer.player}>
//                       <TableCell>{index + 1}</TableCell>
//                       <TableCell>{scorer.player}</TableCell>
//                       <TableCell>{scorer.goals}</TableCell>
//                       <TableCell>{scorer.assists}</TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//             {nextMatch && (
//               <Box sx={{ mt: 4 }}>
//                 <Typography variant="h6">Next Match</Typography>
//                 <MatchCard
//                   team1={nextMatch.club.teamname}
//                   team2={nextMatch.opposition}
//                   date={new Date(nextMatch.matchDate).toLocaleDateString()}
//                   time={new Date(nextMatch.matchDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
//                   matchType={nextMatch.matchName}
//                   team1Logo={nextMatch.club.emblem}
//                   team2Logo="OT"
//                   disabled={false}
//                 />
//               </Box>
//             )}
//           </Grid>
//         </Grid>
//       </Box>
//     </Box>
//   );
// };

// export default DashboardHome;
import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Button, Modal, Grid, Divider, Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import SubscribeStart from './SubscribeStart';
import { Client, Databases, Query } from 'appwrite';
import authService from '../appwrite/auth';
import conf from '../conf/conf';
import WarningIcon from '@mui/icons-material/Warning';
import BlockIcon from '@mui/icons-material/Block';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import MatchCard from './MatchCard';
import MyCalendar from './Calendar';

const client = new Client()
  .setEndpoint(conf.appwriteUrl)
  .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const DashboardHome = () => {
  const [open, setOpen] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [cardsData, setCardsData] = useState({ redCards: 0, yellowCards: 0, goals: 0, wins: 0, losses: 0, topScorer: '', topScorerImage: '' });
  const [performanceData, setPerformanceData] = useState([]);
  const [topScorers, setTopScorers] = useState([]);
  const [nextMatch, setNextMatch] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleVideoClose = () => {
    setShowVideoModal(false);
  };

  const handleDontShowAgain = () => {
    Cookies.set('dontShowVideo', 'true', { expires: 365 });
    setShowVideoModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = await authService.getCurrentUser();
        if (!currentUser) {
          throw new Error('No user is currently logged in.');
        }

        const cardsResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.cardsCollectionId, [
          Query.equal('userID', currentUser.$id)
        ]);

        const goalsResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.goalsCollectionId, [
          Query.equal('userID', currentUser.$id)
        ]);

        const resultsResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.finalResultsCollectionId, [
          Query.equal('userID', currentUser.$id)
        ]);

        const redCards = cardsResponse.documents.filter(card => card.cardType === 'red').length;
        const yellowCards = cardsResponse.documents.filter(card => card.cardType === 'yellow').length;

        const wins = resultsResponse.documents.filter(result => result.finalScoreHome > result.finalScoreOpposition).length;
        const losses = resultsResponse.documents.filter(result => result.finalScoreHome < result.finalScoreOpposition).length;

        const goals = goalsResponse.documents.length;

        const topScorersData = goalsResponse.documents.reduce((acc, doc) => {
          acc[doc.teamPlayer] = acc[doc.teamPlayer] || { goals: 0, assists: 0 };
          acc[doc.teamPlayer].goals += 1;
          if (doc.assistPlayer) {
            acc[doc.assistPlayer] = acc[doc.assistPlayer] || { goals: 0, assists: 0 };
            acc[doc.assistPlayer].assists += 1;
          }
          return acc;
        }, {});

        const topScorers = Object.entries(topScorersData)
          .map(([player, stats]) => ({ player, ...stats }))
          .sort((a, b) => b.goals - a.goals)
          .slice(0, 3);

        setTopScorers(topScorers);

        const topScorer = topScorers.length > 0 ? topScorers[0].player : 'No data';

        let topScorerImage = '';

        if (topScorer !== 'No data') {
          const topScorerResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteCollectionId, [
            Query.equal('fullName', topScorer)
          ]);

          if (topScorerResponse.total > 0) {
            topScorerImage = topScorerResponse.documents[0].image;
          }
        }

        setCardsData({
          redCards,
          yellowCards,
          goals,
          wins,
          losses,
          topScorer,
          topScorerImage
        });

        const performanceResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.rpeCaptureCollectionId, [
          Query.equal('playerName', topScorer),
          Query.equal('userID', currentUser.$id)
        ]);

        const performanceData = performanceResponse.documents.map(doc => ({
          date: doc.matchDate || doc.sessionDate,
          rpeValue: doc.rpeValue,
          hrBPM: doc.hrBPM,
        }));

        setPerformanceData(performanceData);

        const matchResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.createSquadCollectionId, [
          Query.equal('userID', currentUser.$id),
        ]);

        const sortedMatches = matchResponse.documents
          .map(match => ({ ...match, matchDate: new Date(match.matchDate) }))
          .filter(match => match.matchDate > new Date())
          .sort((a, b) => a.matchDate - b.matchDate);

        const nextMatch = sortedMatches.length > 0 ? sortedMatches[0] : null;

        if (nextMatch) {
          const clubResponse = await databases.getDocument(conf.appwriteDatabaseId, conf.createClubCollectionId, nextMatch.defaultClub);
          nextMatch.club = clubResponse;
        }

        setNextMatch(nextMatch);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const dontShowVideo = Cookies.get('dontShowVideo');
    if (!dontShowVideo) {
      setShowVideoModal(true);
    }

    fetchData();
  }, []);

  return (
    <Box>
      <Card sx={{
        width: '100%', height: '110px', backgroundColor: '#f5f5f5', transition: 'box-shadow 0.3s ease-in-out',
        '&:hover': {
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.9)',
        }
      }}>
        <CardContent>
          <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px' }}>
            Welcome to FitSport | RPE
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '5px' }}>
            You can try FitSport for the next 7 days at no cost. All data captured or created during your 7 day test run will be deleted automatically, should you decide FitSport | RPE does not meet your needs.
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '5px' }}>
            If you like what you see click here to&nbsp;
            <Button variant="contained" size="small" onClick={handleOpen} sx={{
              padding: '0px 10px', marginLeft: '5px', backgroundColor: '#ffffff', color: '#356596',
              '&:hover': {
                backgroundColor: '#356596',
                color: '#ffffff'
              }
            }}>
              subscribe
            </Button>
          </Typography>
        </CardContent>
      </Card>

      <Modal open={showVideoModal} onClose={handleVideoClose}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '102vh', backgroundColor: 'rgba(0,0,0,0.8)' }}>
          <Box sx={{ width: '80%', height: '85%', position: 'relative', backgroundColor: '#fff', borderRadius: '8px', p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={handleDontShowAgain} sx={{ mr: 2 }}>
                Don't show again
              </Button>
              <IconButton onClick={handleVideoClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <iframe
              width="100%"
              height="95%"
              src="https://www.youtube.com/embed/TqPxJ__B77w?autoplay=1&loop=1&playlist=TqPxJ__B77w"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Box>
        </Box>
      </Modal>

      <Modal open={open} onClose={handleClose}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <SubscribeStart handleClose={handleClose} />
        </Box>
      </Modal>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ backgroundColor: '#FFF8E1', height: 'auto', transition: 'box-shadow 0.3s ease-in-out',
            '&:hover': {
              boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
            }}}>
            <CardContent sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" align="center" sx={{ mb: { xs: 1, sm: 0 } }}>Cards This Season</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <BlockIcon sx={{ color: 'red' }} />
                <Typography variant="h4" sx={{ ml: 1 }}>{cardsData.redCards}</Typography>
                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                <WarningIcon sx={{ color: 'yellow' }} />
                <Typography variant="h4" sx={{ ml: 1 }}>{cardsData.yellowCards}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ backgroundColor: '#E8F5E9', height: 'auto', transition: 'box-shadow 0.3s ease-in-out',
            '&:hover': {
              boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
            }}}>
            <CardContent sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" align="center" sx={{ mb: { xs: 1, sm: 0 } }}>Goals This Season</Typography>
              <Typography variant="h4" align="center">{cardsData.goals}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ backgroundColor: '#E3F2FD', height: 'auto', transition: 'box-shadow 0.3s ease-in-out',
            '&:hover': {
              boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
            }}}>
            <CardContent sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" align="center" sx={{ mb: { xs: 1, sm: 0 } }}>Wins | Losses</Typography>
              <Typography variant="h4" align="center">{cardsData.wins} | {cardsData.losses}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ backgroundColor: '#FFEBEE', height: '83px', transition: 'box-shadow 0.3s ease-in-out',
            '&:hover': {
              boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
            }}}>
            <CardContent sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                <Typography variant="h6">Top Scorer This Season</Typography>
                <Typography variant="body1">{cardsData.topScorer}</Typography>
              </Box>
              {cardsData.topScorerImage && (
                <Avatar
                  src={`https://cloud.appwrite.io/v1/storage/buckets/${conf.appwriteBucketId}/files/${cardsData.topScorerImage}/view?project=${conf.appwriteProjectId}`}
                  alt={cardsData.topScorer}
                  sx={{ width: 56, height: 56, ml: 2 }}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Player Performance Over Time: {cardsData.topScorer}</Typography>
            <Box sx={{ height: '200px' }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={performanceData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="rpeValue" stroke="#8884d8" />
                  {performanceData.some(entry => entry.hrBPM) && (
                    <Line type="monotone" dataKey="hrBPM" stroke="#82ca9d" />
                  )}
                </LineChart>
              </ResponsiveContainer>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6">Calendar</Typography>
              <Box sx={{ height: '600px' }}>
                <MyCalendar />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Top Scorers</Typography>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="top scorers table" sx={{ height: '200px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Position</TableCell>
                    <TableCell>Player Name</TableCell>
                    <TableCell>Goals</TableCell>
                    <TableCell>Assists</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topScorers.map((scorer, index) => (
                    <TableRow key={scorer.player}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{scorer.player}</TableCell>
                      <TableCell>{scorer.goals}</TableCell>
                      <TableCell>{scorer.assists}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {nextMatch && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6">Next Match</Typography>
                <MatchCard
                  team1={nextMatch.club.teamname}
                  team2={nextMatch.opposition}
                  date={new Date(nextMatch.matchDate).toLocaleDateString()}
                  time={new Date(nextMatch.matchDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  matchType={nextMatch.matchName}
                  team1Logo={nextMatch.club.emblem}
                  team2Logo="OT"
                  disabled={false}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DashboardHome;

