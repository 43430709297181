import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, ListSubheader } from '@mui/material';

export default function PlayingFootSelector({ selectedFoot, setSelectedFoot }) {
    const handleFootChange = (event) => {
        setSelectedFoot(event.target.value); // Update parent component state
    };

    return (
        <FormControl
            fullWidth
            size="small"
            sx={{
                marginBottom: '1px',
                '& .MuiInputBase-input': {
                    fontSize: '0.65rem',
                    padding: '16px 12px',
                    fontFamily: 'Roboto, sans-serif'
                },
                '& .MuiInputBase-root': {
                    marginTop: '8px',
                    marginBottom: '8px'
                }
            }}
        >
            <InputLabel id="foot-select-label" sx={{ fontSize: '0.65rem', fontFamily: 'Roboto, sans-serif' }}>
                Playing Foot
            </InputLabel>
            <Select
                labelId="foot-select-label"
                id="foot-select"
                value={selectedFoot} // Use value from parent component
                label="Playing Foot"
                onChange={handleFootChange}
                sx={{ fontSize: '0.65rem', fontFamily: 'Roboto, sans-serif' }}
            >
                <MenuItem value=""><em>None</em></MenuItem>
                <MenuItem value="Left-footed">Left-footed</MenuItem>
                <MenuItem value="Right-footed">Right-footed</MenuItem>
                <MenuItem value="Ambidextrous">Ambidextrous</MenuItem>
            </Select>
        </FormControl>
    );
}
