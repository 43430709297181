// // export default SoccerPitch;
// import React, { useState, useEffect, useMemo, useCallback } from 'react';
// import SoccerLineUp from 'react-soccer-lineup';
// import { Select, MenuItem, FormControl, InputLabel, Box, IconButton } from '@mui/material';
// import { styled } from '@mui/system';
// import SettingsIcon from '@mui/icons-material/Settings';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import StopIcon from '@mui/icons-material/Stop';
// import CameraAltIcon from '@mui/icons-material/CameraAlt';
// import jerseyIcon from './jerseyIcon.png';

// const SoccerPitch = ({ selectedPlayers = [], formation }) => {
//     const [currentFormation, setCurrentFormation] = useState(formation || "4-4-2");
//     const [counterFormation, setCounterFormation] = useState("");
//     const [counterFormations, setCounterFormations] = useState([]);

//     // Wrap the formationMapping in useMemo to avoid re-creating it on every render
//     const formationMapping = useMemo(() => ({
//         "1-3-5-2": ["gk", "df", "df", "df", "cm", "cm", "cm", "cm", "cm", "fw", "fw"],
//         "4-4-2": ["gk", "df", "df", "df", "df", "cm", "cm", "cm", "cm", "fw", "fw"],
//         "4-3-3": ["gk", "df", "df", "df", "df", "cm", "cm", "cm", "fw", "fw", "fw"],
//         "3-5-2": ["gk", "df", "df", "df", "cm", "cm", "cm", "cm", "cm", "fw", "fw"],
//         "5-3-2": ["gk", "df", "df", "df", "df", "df", "cm", "cm", "cm", "fw", "fw"],
//         "4-2-3-1": ["gk", "df", "df", "df", "df", "cdm", "cdm", "cam", "cam", "cam", "fw"],
//         "4-1-4-1": ["gk", "df", "df", "df", "df", "cdm", "cm", "cm", "cm", "cm", "fw"],
//         "3-4-3": ["gk", "df", "df", "df", "cm", "cm", "cm", "cm", "fw", "fw", "fw"],
//         "3-4-1-2": ["gk", "df", "df", "df", "cm", "cm", "cm", "cm", "cam", "fw", "fw"],
//         "4-5-1": ["gk", "df", "df", "df", "df", "cm", "cm", "cm", "cm", "cm", "fw"],
//         "5-4-1": ["gk", "df", "df", "df", "df", "df", "cm", "cm", "cm", "cm", "fw"],
//         "5-2-3": ["gk", "df", "df", "df", "df", "df", "cm", "cm", "fw", "fw", "fw"],
//         "5-2-2-1": ["gk", "df", "df", "df", "df", "df", "cm", "cm", "cam", "cam", "fw"],
//         "5-2-1-2": ["gk", "df", "df", "df", "df", "df", "cm", "cm", "cam", "fw", "fw"],
//         "4-4-1-1": ["gk", "df", "df", "df", "df", "cm", "cm", "cm", "cm", "cam", "fw"],
//         "4-3-2-1": ["gk", "df", "df", "df", "df", "cm", "cm", "cm", "cam", "cam", "fw"],
//         "4-3-1-2": ["gk", "df", "df", "df", "df", "cm", "cm", "cm", "cam", "fw", "fw"],
//         "7-2-1": ["gk", "df", "df", "df", "df", "df", "df", "df", "cm", "cm", "fw", "fw", "fw", "fw"],
//         "4-2-2-2": ["gk", "df", "df", "df", "df", "cdm", "cdm", "cam", "cam", "fw", "fw"],
//         "4-2-1-3": ["gk", "df", "df", "df", "df", "cdm", "cdm", "cam", "fw", "fw", "fw"],
//         "4-1-3-2": ["gk", "df", "df", "df", "df", "cdm", "cm", "cm", "cm", "fw", "fw"],
//         "4-1-2-1-2": ["gk", "df", "df", "df", "df", "cdm", "cm", "cm", "cam", "fw", "fw"],
//         "4-1-2-3": ["gk", "df", "df", "df", "df", "cdm", "cm", "cm", "fw", "fw", "fw"],
//         "3-5-1-1": ["gk", "df", "df", "df", "cm", "cm", "cm", "cm", "cm", "cam", "fw"],
//         "3-4-2-1": ["gk", "df", "df", "df", "cm", "cm", "cm", "cm", "cam", "cam", "fw"],
//         "3-1-4-2": ["gk", "df", "df", "df", "cdm", "cm", "cm", "cm", "cm", "fw", "fw"]
//     }), []);

//     const counterFormationMapping = useMemo(() => ({
//         "1-3-5-2": ["4-3-3", "4-4-2", "4-2-3-1", "3-5-2", "4-1-4-1"],
//         "4-4-2": ["4-3-3", "4-2-3-1", "3-5-2", "4-1-4-1"],
//         "4-3-3": ["4-4-2", "3-5-2", "4-2-3-1", "4-1-4-1"],
//         "3-5-2": ["4-3-3", "4-4-2", "4-2-3-1", "4-1-4-1"],
//         "5-3-2": ["4-3-3", "4-4-2", "4-2-3-1", "4-1-4-1"],
//         "4-2-3-1": ["4-3-3", "4-4-2", "3-5-2", "4-1-4-1"],
//         "4-1-4-1": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
//         "3-4-3": ["4-3-3", "4-4-2", "4-2-3-1", "4-1-4-1"],
//         "3-4-1-2": ["4-3-3", "4-4-2", "4-2-3-1", "4-1-4-1"],
//         "4-5-1": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
//         "5-4-1": ["4-3-3", "4-4-2", "4-2-3-1", "4-1-4-1"],
//         "5-2-3": ["4-3-3", "4-4-2", "4-2-3-1", "4-1-4-1"],
//         "5-2-2-1": ["4-3-3", "4-4-2", "4-2-3-1", "4-1-4-1"],
//         "5-2-1-2": ["4-3-3", "4-4-2", "4-2-3-1", "4-1-4-1"],
//         "4-4-1-1": ["4-3-3", "4-4-2", "4-2-3-1", "3-5-2"],
//         "4-3-2-1": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
//         "4-3-1-2": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
//         "7-2-1": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
//         "4-2-2-2": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
//         "4-2-1-3": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
//         "4-1-3-2": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
//         "4-1-2-1-2": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
//         "4-1-2-3": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
//         "3-5-1-1": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
//         "3-4-2-1": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
//         "3-1-4-2": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"]
//     }), []);

//     // Function to map players to formation
//     const mapPlayersToFormation = useCallback((players, formation, showNames = true) => {
//         const positions = formationMapping[formation] || [];
//         let formationObj = {};

//         positions.forEach((pos, index) => {
//             if (!formationObj[pos]) {
//                 formationObj[pos] = [];
//             }
//             const player = players[index];
//             if (player && showNames) {
//                 formationObj[pos].push({
//                     name: player.fullName,
//                     number: Number(player.jerseyNumber), // Ensure number is an integer
//                     position: pos
//                 });
//             } else {
//                 // Create a dummy player if not enough players are provided or if showNames is false
//                 formationObj[pos].push({
//                     name: showNames ? `Player ${index + 1}` : pos.toUpperCase(),
//                     number: index + 1,
//                     position: pos
//                 });
//             }
//         });

//         return formationObj;
//     }, [formationMapping]);

//     const teamFormation = useMemo(() => mapPlayersToFormation(selectedPlayers, currentFormation), [selectedPlayers, currentFormation, mapPlayersToFormation]);
//     const counterTeamFormation = useMemo(() => mapPlayersToFormation(selectedPlayers, counterFormation, false), [selectedPlayers, counterFormation, mapPlayersToFormation]);

//     useEffect(() => {
//         if (currentFormation) {
//             setCounterFormations(counterFormationMapping[currentFormation] || []);
//         }
//     }, [currentFormation, counterFormationMapping]);

//     const formations = Object.keys(formationMapping);

//     const MenuBar = styled(Box)(({ theme }) => ({
//         width: '600px',
//         height: '50px',
//         border: '1px solid grey',
//         borderRadius: '5px',
//         backgroundColor: 'white',
//         display: 'flex',
//         justifyContent: 'space-around',
//         alignItems: 'center',
//         marginTop: '20px', // Added margin to create space between pitch and menu bar
//         transition: 'box-shadow 0.3s ease',
//         '&:hover': {
//             boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
//         },
//         [theme.breakpoints.down('sm')]: {
//             width: '100%',
//             height: '50px'
//         }
//     }));

//     return (
//         <Box display="flex" flexDirection="column" alignItems="center">
//             <FormControl fullWidth size="small" sx={{
//                 marginBottom: '10px',
//                 '& .MuiInputBase-input': {
//                     fontSize: '0.75rem',
//                     padding: '6px 12px'
//                 },
//                 '& .MuiInputBase-root': {
//                     marginTop: '8px',
//                     marginBottom: '8px'
//                 }
//             }}>
//                 <InputLabel>Formation</InputLabel>
//                 <Select
//                     value={currentFormation}
//                     onChange={(e) => setCurrentFormation(e.target.value)}
//                     label="Formation"
//                 >
//                     {formations.map(formation => (
//                         <MenuItem key={formation} value={formation}>{formation}</MenuItem>
//                     ))}
//                 </Select>
//             </FormControl>

//             <FormControl fullWidth size="small" sx={{
//                 marginBottom: '10px',
//                 '& .MuiInputBase-input': {
//                     fontSize: '0.75rem',
//                     padding: '6px 12px'
//                 },
//                 '& .MuiInputBase-root': {
//                     marginTop: '8px',
//                     marginBottom: '8px'
//                 }
//             }}>
//                 <InputLabel>Counter Formation</InputLabel>
//                 <Select
//                     value={counterFormation}
//                     onChange={(e) => setCounterFormation(e.target.value)}
//                     label="Counter Formation"
//                 >
//                     {counterFormations.map(formation => (
//                         <MenuItem key={formation} value={formation}>{formation}</MenuItem>
//                     ))}
//                 </Select>
//             </FormControl>

//             <SoccerLineUp
//                 size={"normal"}
//                 color={"lightseagreen"}
//                 pattern={"lines"}
//                 homeTeam={{
//                     squad: teamFormation,
//                     style: {
//                         color: '#f08080',
//                         numberColor: '#ffffff'
//                     }
//                 }}
//                 awayTeam={{
//                     squad: counterTeamFormation,
//                     style: {
//                         color: 'blue',
//                         numberColor: '#ffffff'
//                     }
//                 }}
//             />
//             <MenuBar>
//                 <IconButton>
//                     <SettingsIcon />
//                 </IconButton>
//                 <IconButton>
//                     <PlayArrowIcon />
//                 </IconButton>
//                 <IconButton>
//                     <StopIcon />
//                 </IconButton>
//                 <IconButton>
//                     <CameraAltIcon />
//                 </IconButton>
//             </MenuBar>
//         </Box>
//     );
// };

// export default SoccerPitch;
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import SoccerLineUp from 'react-soccer-lineup';
import { Select, MenuItem, FormControl, InputLabel, Box, IconButton, Drawer, Typography } from '@mui/material';
import { styled } from '@mui/system';
import SettingsIcon from '@mui/icons-material/Settings';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import TacticalBoard from './TacticalBoard';
import jerseyIcon from './jerseyIcon.png';

const SoccerPitch = ({ selectedPlayers = [], formation }) => {
    const [currentFormation, setCurrentFormation] = useState(formation || "4-4-2");
    const [counterFormation, setCounterFormation] = useState("");
    const [counterFormations, setCounterFormations] = useState([]);
    const [showTacticalBoard, setShowTacticalBoard] = useState(false);
    const [tacticalBoardLocked, setTacticalBoardLocked] = useState(false);

    const handleToggleTacticalBoard = () => {
        setShowTacticalBoard(prev => !prev);
    };

    const handleLockTacticalBoard = () => {
        setTacticalBoardLocked(prev => !prev);
    };

    // Wrap the formationMapping in useMemo to avoid re-creating it on every render
    const formationMapping = useMemo(() => ({
        "1-3-5-2": ["gk", "df", "df", "df", "cm", "cm", "cm", "cm", "cm", "fw", "fw"],
        "4-4-2": ["gk", "df", "df", "df", "df", "cm", "cm", "cm", "cm", "fw", "fw"],
        "4-3-3": ["gk", "df", "df", "df", "df", "cm", "cm", "cm", "fw", "fw", "fw"],
        "3-5-2": ["gk", "df", "df", "df", "cm", "cm", "cm", "cm", "cm", "fw", "fw"],
        "5-3-2": ["gk", "df", "df", "df", "df", "df", "cm", "cm", "cm", "fw", "fw"],
        "4-2-3-1": ["gk", "df", "df", "df", "df", "cdm", "cdm", "cam", "cam", "cam", "fw"],
        "4-1-4-1": ["gk", "df", "df", "df", "df", "cdm", "cm", "cm", "cm", "cm", "fw"],
        "3-4-3": ["gk", "df", "df", "df", "cm", "cm", "cm", "cm", "fw", "fw", "fw"],
        "3-4-1-2": ["gk", "df", "df", "df", "cm", "cm", "cm", "cm", "cam", "fw", "fw"],
        "4-5-1": ["gk", "df", "df", "df", "df", "cm", "cm", "cm", "cm", "cm", "fw"],
        "5-4-1": ["gk", "df", "df", "df", "df", "df", "cm", "cm", "cm", "cm", "fw"],
        "5-2-3": ["gk", "df", "df", "df", "df", "df", "cm", "cm", "fw", "fw", "fw"],
        "5-2-2-1": ["gk", "df", "df", "df", "df", "df", "cm", "cm", "cam", "cam", "fw"],
        "5-2-1-2": ["gk", "df", "df", "df", "df", "df", "cm", "cm", "cam", "fw", "fw"],
        "4-4-1-1": ["gk", "df", "df", "df", "df", "cm", "cm", "cm", "cm", "cam", "fw"],
        "4-3-2-1": ["gk", "df", "df", "df", "df", "cm", "cm", "cm", "cam", "cam", "fw"],
        "4-3-1-2": ["gk", "df", "df", "df", "df", "cm", "cm", "cm", "cam", "fw", "fw"],
        "7-2-1": ["gk", "df", "df", "df", "df", "df", "df", "df", "cm", "cm", "fw", "fw", "fw", "fw"],
        "4-2-2-2": ["gk", "df", "df", "df", "df", "cdm", "cdm", "cam", "cam", "fw", "fw"],
        "4-2-1-3": ["gk", "df", "df", "df", "df", "cdm", "cdm", "cam", "fw", "fw", "fw"],
        "4-1-3-2": ["gk", "df", "df", "df", "df", "cdm", "cm", "cm", "cm", "fw", "fw"],
        "4-1-2-1-2": ["gk", "df", "df", "df", "df", "cdm", "cm", "cm", "cam", "fw", "fw"],
        "4-1-2-3": ["gk", "df", "df", "df", "df", "cdm", "cm", "cm", "fw", "fw", "fw"],
        "3-5-1-1": ["gk", "df", "df", "df", "cm", "cm", "cm", "cm", "cm", "cam", "fw"],
        "3-4-2-1": ["gk", "df", "df", "df", "cm", "cm", "cm", "cm", "cam", "cam", "fw"],
        "3-1-4-2": ["gk", "df", "df", "df", "cdm", "cm", "cm", "cm", "cm", "fw", "fw"]
    }), []);

    const counterFormationMapping = useMemo(() => ({
        "1-3-5-2": ["4-3-3", "4-4-2", "4-2-3-1", "3-5-2", "4-1-4-1"],
        "4-4-2": ["4-3-3", "4-2-3-1", "3-5-2", "4-1-4-1"],
        "4-3-3": ["4-4-2", "3-5-2", "4-2-3-1", "4-1-4-1"],
        "3-5-2": ["4-3-3", "4-4-2", "4-2-3-1", "4-1-4-1"],
        "5-3-2": ["4-3-3", "4-4-2", "4-2-3-1", "4-1-4-1"],
        "4-2-3-1": ["4-3-3", "4-4-2", "3-5-2", "4-1-4-1"],
        "4-1-4-1": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
        "3-4-3": ["4-3-3", "4-4-2", "4-2-3-1", "4-1-4-1"],
        "3-4-1-2": ["4-3-3", "4-4-2", "4-2-3-1", "4-1-4-1"],
        "4-5-1": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
        "5-4-1": ["4-3-3", "4-4-2", "4-2-3-1", "4-1-4-1"],
        "5-2-3": ["4-3-3", "4-4-2", "4-2-3-1", "4-1-4-1"],
        "5-2-2-1": ["4-3-3", "4-4-2", "4-2-3-1", "4-1-4-1"],
        "5-2-1-2": ["4-3-3", "4-4-2", "4-2-3-1", "4-1-4-1"],
        "4-4-1-1": ["4-3-3", "4-4-2", "4-2-3-1", "3-5-2"],
        "4-3-2-1": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
        "4-3-1-2": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
        "7-2-1": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
        "4-2-2-2": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
        "4-2-1-3": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
        "4-1-3-2": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
        "4-1-2-1-2": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
        "4-1-2-3": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
        "3-5-1-1": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
        "3-4-2-1": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"],
        "3-1-4-2": ["4-3-3", "4-4-2", "3-5-2", "4-2-3-1"]
    }), []);

    // Function to map players to formation
    const mapPlayersToFormation = useCallback((players, formation, showNames = true) => {
        const positions = formationMapping[formation] || [];
        let formationObj = {};

        positions.forEach((pos, index) => {
            if (!formationObj[pos]) {
                formationObj[pos] = [];
            }
            const player = players[index];
            if (player && showNames) {
                formationObj[pos].push({
                    name: player.fullName,
                    number: Number(player.jerseyNumber), // Ensure number is an integer
                    position: pos
                });
            } else {
                // Create a dummy player if not enough players are provided or if showNames is false
                formationObj[pos].push({
                    name: showNames ? `Player ${index + 1}` : pos.toUpperCase(),
                    number: index + 1,
                    position: pos
                });
            }
        });

        return formationObj;
    }, [formationMapping]);

    const teamFormation = useMemo(() => mapPlayersToFormation(selectedPlayers, currentFormation), [selectedPlayers, currentFormation, mapPlayersToFormation]);
    const counterTeamFormation = useMemo(() => mapPlayersToFormation(selectedPlayers, counterFormation, false), [selectedPlayers, counterFormation, mapPlayersToFormation]);

    useEffect(() => {
        if (currentFormation) {
            setCounterFormations(counterFormationMapping[currentFormation] || []);
        }
    }, [currentFormation, counterFormationMapping]);

    const formations = Object.keys(formationMapping);

    const MenuBar = styled(Box)(({ theme }) => ({
        width: '600px',
        height: '50px',
        border: '1px solid grey',
        borderRadius: '5px',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginTop: '20px', // Added margin to create space between pitch and menu bar
        transition: 'box-shadow 0.3s ease',
        '&:hover': {
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '50px'
        }
    }));

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <FormControl fullWidth size="small" sx={{
                marginBottom: '10px',
                '& .MuiInputBase-input': {
                    fontSize: '0.75rem',
                    padding: '6px 12px'
                },
                '& .MuiInputBase-root': {
                    marginTop: '8px',
                    marginBottom: '8px'
                }
            }}>
                <InputLabel>Formation</InputLabel>
                <Select
                    value={currentFormation}
                    onChange={(e) => setCurrentFormation(e.target.value)}
                    label="Formation"
                >
                    {formations.map(formation => (
                        <MenuItem key={formation} value={formation}>{formation}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl fullWidth size="small" sx={{
                marginBottom: '10px',
                '& .MuiInputBase-input': {
                    fontSize: '0.75rem',
                    padding: '6px 12px'
                },
                '& .MuiInputBase-root': {
                    marginTop: '8px',
                    marginBottom: '8px'
                }
            }}>
                <InputLabel>Counter Formation</InputLabel>
                <Select
                    value={counterFormation}
                    onChange={(e) => setCounterFormation(e.target.value)}
                    label="Counter Formation"
                >
                    {counterFormations.map(formation => (
                        <MenuItem key={formation} value={formation}>{formation}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <SoccerLineUp
                size={"normal"}
                color={"lightseagreen"}
                pattern={"lines"}
                homeTeam={{
                    squad: teamFormation,
                    style: {
                        color: '#f08080',
                        numberColor: '#ffffff'
                    }
                }}
                awayTeam={{
                    squad: counterTeamFormation,
                    style: {
                        color: 'blue',
                        numberColor: '#ffffff'
                    }
                }}
            />
            <MenuBar>
                <IconButton>
                    <SettingsIcon />
                </IconButton>
                <IconButton>
                    <PlayArrowIcon />
                </IconButton>
                <IconButton>
                    <StopIcon />
                </IconButton>
                <IconButton>
                    <CameraAltIcon />
                </IconButton>
                <IconButton onClick={handleToggleTacticalBoard}>
                    <DisplaySettingsIcon />
                </IconButton>
            </MenuBar>

            <Drawer
                anchor="right"
                open={showTacticalBoard}
                onClose={() => setShowTacticalBoard(false)}
                variant="persistent"
                sx={{ zIndex: 1300 }}
            >
                <Box sx={{
                    width: 1000,
                    padding: 2,
                    backgroundColor: '#f5f5f5',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end'
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
    <IconButton
        onClick={() => !tacticalBoardLocked && setShowTacticalBoard(false)}
        disabled={tacticalBoardLocked}
    >
        <Typography>X</Typography>
    </IconButton>
    <IconButton onClick={handleLockTacticalBoard}>
        {tacticalBoardLocked ? (
            <Typography sx={{ color: 'green' }}>🔓</Typography>
        ) : (
            <Typography sx={{ color: 'red' }}>🔒</Typography>
        )}
    </IconButton>
</Box>
<TacticalBoard open={showTacticalBoard} onClose={() => setShowTacticalBoard(false)} />

                </Box>
            </Drawer>
        </Box>
    );
};

export default SoccerPitch;
