// // // Custom Suscribe component
// // // Created by Shamiel Speelman
// // // Version: 0.1
// import React, { useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import { Box, Card, CardContent, Typography, Button, Grid } from '@mui/material';
// import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
// import { styled } from '@mui/system';
// import qrCodeImage from './qr_test_dR63f74WT3mZ8gMcMM.png';

// const CustomCard = styled(Card)({
//   width: '460px',
//   display: 'flex',
//   backgroundColor: '#ffffff',
//   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//   borderRadius: '10px',
//   overflow: 'hidden',
//   transition: 'box-shadow 0.7s ease-in-out',
//   '&:hover': {
//     boxShadow: '0 10px 16px rgba(0, 0, 0, 0.9)'
//   },
//   margin: '20px',
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   flexDirection: 'column',
//   alignItems: 'center',
// });

// const StyledButton = styled(Button)({
//   backgroundColor: '#356596',
//   color: '#ffffff',
//   '&:hover': {
//     backgroundColor: '#ffffff',
//     color: '#356596',
//   }
// });

// const FeatureItem = styled(Typography)({
//   display: 'flex',
//   alignItems: 'center',
//   '& svg': {
//     color: '#356596',
//     marginRight: '8px',
//   }
// });

// const QRCard = styled(Card)({
//   width: '460px',
//   backgroundColor: '#ffffff',
//   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//   borderRadius: '10px',
//   overflow: 'hidden',
//   margin: '20px',
//   transform: 'translate(-5%, 23%)',
//   top: '50%',
//   transition: 'box-shadow 0.7s ease-in-out',
//   '&:hover': {
//     boxShadow: '0 10px 16px rgba(0, 0, 0, 0.9)'
//   },
// });

// const TrialCheckout = ({ handleOpenPlans }) => {
//   const location = useLocation();
//   const { plan, cost, billingCycle, billedToday, totalBilled, billingDate } = location.state || { plan: '', cost: '', billingCycle: '', billedToday: '', totalBilled: '', billingDate: '' };

//   const taxRate = 0.20; // 20% tax for Europe
//   const billedTodayAmount = parseFloat(billedToday.replace('€', ''));
//   const taxAmount = (billedTodayAmount * taxRate).toFixed(2);
//   const totalAmount = (billedTodayAmount + parseFloat(taxAmount)).toFixed(2);

//   const handleStripeCheckout = () => {
//     window.location.href = 'https://buy.stripe.com/test_dR63f74WT3mZ8gMcMM';
//   };

//   return (
//     <>
//       <CustomCard>
//         <CardContent sx={{ width: '100%', padding: 4 }}>
//           <Typography variant="h5" sx={{ mb: 2 }}>Selected Plan: {plan}</Typography>
//           <Typography variant="h6" sx={{ mb: 2 }}>Cost: {cost}</Typography>
//           <Typography variant="h7" sx={{ mb: 2, mt: 7 }}>Billing Cycle: {billingCycle}</Typography>

//           {/* Features */}
//           <FeatureItem><CheckCircleIcon /> Personalized Route Suggestions</FeatureItem>
//           <FeatureItem><CheckCircleIcon /> Advanced Analysis Tools</FeatureItem>
//           <FeatureItem><CheckCircleIcon /> Segment Leaderboards</FeatureItem>
//           <FeatureItem><CheckCircleIcon /> Recover Athletics NEW</FeatureItem>
//           <FeatureItem><CheckCircleIcon /> FATMAP NEW</FeatureItem>

//           <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 4 }}>
//             <Grid item xs={6}>
//               <Typography variant="h7">Billed today:</Typography>
//             </Grid>
//             <Grid item xs={6} textAlign="right">
//               <Typography variant="h7">{billedToday}</Typography>
//             </Grid>
//           </Grid>

//           <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
//             <Grid item xs={6}>
//               <Typography variant="h7">Tax:</Typography>
//             </Grid>
//             <Grid item xs={6} textAlign="right">
//               <Typography variant="h7">€{taxAmount}</Typography>
//             </Grid>
//           </Grid>

//           <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
//             <Grid item xs={6}>
//               <Typography variant="h7">{billingDate}</Typography>
//             </Grid>
//             <Grid item xs={6} textAlign="right">
//               <Typography variant="h7">€{totalAmount}</Typography>
//             </Grid>
//           </Grid>

//           <Box container spacing={2} sx={{ mt: 4 }}>
//             <Button variant="contained" onClick={handleOpenPlans} sx={{
//               backgroundColor: '#ffffff',
//               borderRadius: '1px',
//               color: '#356596',
//               '&:hover': {
//                 backgroundColor: '#356596',
//                 color: '#ffffff',
//               }
//             }}>Select Another Plan</Button>
//             <Button variant="contained" sx={{
//               backgroundColor: '#ffffff',
//               borderRadius: '1px',
//               color: '#356596',
//               '&:hover': {
//                 backgroundColor: '#356596',
//                 color: '#ffffff',
//               }
//             }}>Redeem Promo Code</Button>
//           </Box>
//           <Box container spacing={2} sx={{ mt: 2, width: '96%', pl: -3 }}>
//             <Button
//               variant="contained"
//               fullWidth
//               sx={{
//                 mt: '3',
//                 backgroundColor: '#356596',
//                 color: '#ffffff',
//                 '&:hover': {
//                   backgroundColor: '#ffffff',
//                   color: '#356596',
//                 },
//               }}
//               onClick={handleStripeCheckout}
//             >
//               Proceed to Payment
//             </Button>
//           </Box>
//         </CardContent>
//       </CustomCard>
//       <QRCard>
//         <CardContent sx={{ width: '100%', padding: 1, textAlign: 'center', mb: 2 }}>
//           <Typography variant="h6" sx={{ mb: 2 }}>Scan to Pay</Typography>
//           <img src={qrCodeImage} alt="Payment QR Code" style={{ maxWidth: '70%', height: 'auto' }} />
//         </CardContent>
//       </QRCard>
//     </>
//   );
// };

// export default TrialCheckout;
// // Custom Suscribe component
// // Created by Shamiel Speelman
// // Version: 0.1
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Button, Grid } from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { styled } from '@mui/system';
import qrCodeImage from './qr_test_dR63f74WT3mZ8gMcMM.png';

const CustomCard = styled(Card)({
  width: '460px',
  backgroundColor: '#ffffff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '10px',
  overflow: 'hidden',
  transition: 'box-shadow 0.7s ease-in-out',
  '&:hover': {
    boxShadow: '0 10px 16px rgba(0, 0, 0, 0.9)'
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
});

const StyledButton = styled(Button)({
  backgroundColor: '#356596',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#ffffff',
    color: '#356596',
  }
});

const FeatureItem = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    color: '#356596',
    marginRight: '8px',
  }
});

const QRCard = styled(Card)({
  width: '460px',
  backgroundColor: '#ffffff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '10px',
  overflow: 'hidden',
  transition: 'box-shadow 0.7s ease-in-out',
  '&:hover': {
    boxShadow: '0 10px 16px rgba(0, 0, 0, 0.9)'
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
});

const TrialCheckout = ({ handleOpenPlans }) => {
  const location = useLocation();
  const { plan, cost, billingCycle, billedToday, totalBilled, billingDate } = location.state || { plan: '', cost: '', billingCycle: '', billedToday: '', totalBilled: '', billingDate: '' };

  const taxRate = 0.20; // 20% tax for Europe
  const billedTodayAmount = parseFloat(billedToday.replace('€', ''));
  const taxAmount = (billedTodayAmount * taxRate).toFixed(2);
  const totalAmount = (billedTodayAmount + parseFloat(taxAmount)).toFixed(2);

  const handleStripeCheckout = () => {
    window.location.href = 'https://buy.stripe.com/test_dR63f74WT3mZ8gMcMM';
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2} style={{ height: '100vh' }}>
      <Grid item>
        <CustomCard>
          <CardContent sx={{ width: '100%', padding: 4 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>Selected Plan: {plan}</Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>Cost: {cost}</Typography>
            <Typography variant="h7" sx={{ mb: 2, mt: 7 }}>Billing Cycle: {billingCycle}</Typography>

            {/* Features */}
            <FeatureItem><CheckCircleIcon /> Personalized Route Suggestions</FeatureItem>
            <FeatureItem><CheckCircleIcon /> Advanced Analysis Tools</FeatureItem>
            <FeatureItem><CheckCircleIcon /> Segment Leaderboards</FeatureItem>
            <FeatureItem><CheckCircleIcon /> Recover Athletics NEW</FeatureItem>
            <FeatureItem><CheckCircleIcon /> FATMAP NEW</FeatureItem>

            <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 4 }}>
              <Grid item xs={6}>
                <Typography variant="h7">Billed today:</Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Typography variant="h7">{billedToday}</Typography>
              </Grid>
            </Grid>

            <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
              <Grid item xs={6}>
                <Typography variant="h7">Tax:</Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Typography variant="h7">€{taxAmount}</Typography>
              </Grid>
            </Grid>

            <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
              <Grid item xs={6}>
                <Typography variant="h7">{billingDate}</Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Typography variant="h7">€{totalAmount}</Typography>
              </Grid>
            </Grid>

            <Box container spacing={2} sx={{ mt: 4 }}>
              <Button variant="contained" onClick={handleOpenPlans} sx={{
                backgroundColor: '#ffffff',
                borderRadius: '1px',
                color: '#356596',
                '&:hover': {
                  backgroundColor: '#356596',
                  color: '#ffffff',
                }
              }}>Select Another Plan</Button>
              <Button variant="contained" sx={{
                backgroundColor: '#ffffff',
                borderRadius: '1px',
                color: '#356596',
                '&:hover': {
                  backgroundColor: '#356596',
                  color: '#ffffff',
                }
              }}>Redeem Promo Code</Button>
            </Box>
            <Box container spacing={2} sx={{ mt: 2, width: '96%', pl: -3 }}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  mt: '3',
                  backgroundColor: '#356596',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#ffffff',
                    color: '#356596',
                  },
                }}
                onClick={handleStripeCheckout}
              >
                Proceed to Payment
              </Button>
            </Box>
          </CardContent>
        </CustomCard>
      </Grid>
      <Grid item>
        <QRCard>
          <CardContent sx={{ width: '100%', padding: 1, textAlign: 'center', mb: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Scan to Pay</Typography>
            <img src={qrCodeImage} alt="Payment QR Code" style={{ maxWidth: '70%', height: 'auto' }} />
          </CardContent>
        </QRCard>
      </Grid>
    </Grid>
  );
};

export default TrialCheckout;
