// // Custom GoalDetails component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState, useEffect } from 'react';
import { Box, Card, Typography, TextField, Button, MenuItem, Switch, FormControlLabel, Chip } from '@mui/material';
import { Client, Databases, Query, ID } from 'appwrite';
import conf from '../conf/conf';
import authService from '../appwrite/auth';
import { useSnackbar } from 'notistack';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const GoalDetails = ({ time, period, matchID, details, setDetails, onClose }) => {
    const [teamPlayers, setTeamPlayers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredPlayers, setFilteredPlayers] = useState([]);
    const [assistSearchQuery, setAssistSearchQuery] = useState('');
    const [filteredAssistPlayers, setFilteredAssistPlayers] = useState([]);

    const actionOptions = ["Center", "Counter-Attack", "Corner", "Direct Shot", "Indirect Shot", "Penalty"];
    const bodyPartOptions = ["Right Foot", "Left Foot", "Head", "Other"];
    const groundAreaOptions = ["Right", "Center", "Left", "Exterior surface", "6 meters", "Interior surface"];

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            height: '30px'
        },
        '& .MuiSelect-select': {
            height: '30px',
            display: 'flex',
            alignItems: 'center'
        }
    };

    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        const fetchPlayers = async () => {
            try {
                const currentUser = await authService.getCurrentUser();
                if (!currentUser) {
                    throw new Error('No user is currently logged in.');
                }
                const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.createSquadCollectionId, [
                    Query.equal('userID', currentUser.$id),
                ]);
                const players = response.documents.flatMap(doc => {
                    const startingEleven = doc.startingEleven.map(player => JSON.parse(player));
                    const substitutes = doc.substitutes.map(player => JSON.parse(player));
                    return [...startingEleven, ...substitutes];
                });
                setTeamPlayers(players);
            } catch (error) {
                console.error('Error fetching players:', error);
            }
        };

        fetchPlayers();
    }, []);

    useEffect(() => {
        if (searchQuery.length >= 3) {
            setFilteredPlayers(
                teamPlayers.filter(player => player.fullName && player.fullName.toLowerCase().includes(searchQuery.toLowerCase()))
            );
        } else {
            setFilteredPlayers([]);
        }
    }, [searchQuery, teamPlayers]);

    useEffect(() => {
        if (assistSearchQuery.length >= 3) {
            setFilteredAssistPlayers(
                teamPlayers.filter(player => player.fullName && player.fullName.toLowerCase().includes(assistSearchQuery.toLowerCase()))
            );
        } else {
            setFilteredAssistPlayers([]);
        }
    }, [assistSearchQuery, teamPlayers]);

    const handlePlayerSelect = (player) => {
        setDetails(prevDetails => ({ ...prevDetails, selectedPlayer: player }));
        setSearchQuery('');
        setFilteredPlayers([]);
    };

    const handleAssistPlayerSelect = (player) => {
        setDetails(prevDetails => ({ ...prevDetails, assistPlayer: player }));
        setAssistSearchQuery('');
        setFilteredAssistPlayers([]);
    };

    const handleSave = async () => {
        try {
            const currentUser = await authService.getCurrentUser();
            const goalData = {
                matchID,
                userID: currentUser.$id,
                userEmail: currentUser.email,
                teamPlayer: details.selectedPlayer?.fullName || '',
                assistPlayer: details.assistPlayer?.fullName || '',
                oppositionPlayer: details.oppositionPlayer || '',
                action: details.action,
                bodyPart: details.bodyPart,
                groundSurface: details.groundArea,
                ownGoal: details.ownGoal,
                period,
                minute: time,
                comment: details.comment
            };
            await databases.createDocument(conf.appwriteDatabaseId, conf.goalsCollectionId, ID.unique(), goalData);
            enqueueSnackbar('Goal saved successfully', { variant: 'success' });
            setDetails({ selectedPlayer: null, assistPlayer: null, action: '', bodyPart: '', groundArea: '', oppositionPlayer: '', ownGoal: false, comment: '' });
            onClose();
        } catch (error) {
            console.error('Error saving goal data:', error);
        }
    };

    return (
        <Card sx={{ padding: 2, width: '100%', boxSizing: 'border-box' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">Capture Goal Details</Typography>
                <Typography variant="body1">| Period: <strong>{period}</strong> | Minute: <strong>{time}</strong></Typography>
            </Box>
            <Typography variant="subtitle1">Team Player</Typography>
            <TextField
                fullWidth
                placeholder="Search Team Player"
                margin="normal"
                sx={textFieldStyle}
                InputProps={{ style: { height: '30px' } }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                autoComplete="off"
            />
            {filteredPlayers.length > 0 && (
                <Box sx={{ maxHeight: '150px', overflowY: 'auto', border: '1px solid #ccc', marginTop: '5px' }}>
                    {filteredPlayers.map((player, index) => (
                        <Box key={index} sx={{ padding: '5px', cursor: 'pointer' }} onClick={() => handlePlayerSelect(player)}>
                            {`${player.fullName} | No ${player.jerseyNumber} | ${player.pposition}`}
                        </Box>
                    ))}
                </Box>
            )}
            {details.selectedPlayer && (
                <Box sx={{ mt: 2 }}>
                    <Chip label={`${details.selectedPlayer.fullName} | No ${details.selectedPlayer.jerseyNumber} | ${details.selectedPlayer.pposition}`} />
                </Box>
            )}

            <Typography variant="subtitle1">Opposition Player</Typography>
            <TextField
                fullWidth
                placeholder="Enter Opposition Player"
                margin="normal"
                sx={textFieldStyle}
                InputProps={{ style: { height: '30px' } }}
                value={details.oppositionPlayer}
                onChange={(e) => setDetails(prevDetails => ({ ...prevDetails, oppositionPlayer: e.target.value }))}
            />

            <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ flex: 1 }}>
                    <Typography variant="subtitle1">Action</Typography>
                    <TextField
                        select
                        fullWidth
                        margin="normal"
                        sx={textFieldStyle}
                        SelectProps={{ style: { height: '30px' } }}
                        value={details.action}
                        onChange={(e) => setDetails(prevDetails => ({ ...prevDetails, action: e.target.value }))}
                    >
                        {actionOptions.map(option => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>

                <Box sx={{ flex: 1 }}>
                    <Typography variant="subtitle1">Assist</Typography>
                    <TextField
                        fullWidth
                        placeholder="Search Assist Player"
                        margin="normal"
                        sx={textFieldStyle}
                        InputProps={{ style: { height: '30px' } }}
                        value={assistSearchQuery}
                        onChange={(e) => setAssistSearchQuery(e.target.value)}
                        autoComplete="off"
                    />
                    {filteredAssistPlayers.length > 0 && (
                        <Box sx={{ maxHeight: '150px', overflowY: 'auto', border: '1px solid #ccc', marginTop: '5px' }}>
                            {filteredAssistPlayers.map((player, index) => (
                                <Box key={index} sx={{ padding: '5px', cursor: 'pointer' }} onClick={() => handleAssistPlayerSelect(player)}>
                                    {`${player.fullName} | No ${player.jerseyNumber} | ${player.pposition}`}
                                </Box>
                            ))}
                        </Box>
                    )}
                    {details.assistPlayer && (
                        <Box sx={{ mt: 2 }}>
                            <Chip label={`${details.assistPlayer.fullName} | No ${details.assistPlayer.jerseyNumber} | ${details.assistPlayer.pposition}`} />
                        </Box>
                    )}
                </Box>
            </Box>

            <Typography variant="subtitle1">Body Part</Typography>
            <TextField
                select
                fullWidth
                margin="normal"
                sx={textFieldStyle}
                SelectProps={{ style: { height: '30px' } }}
                value={details.bodyPart}
                onChange={(e) => setDetails(prevDetails => ({ ...prevDetails, bodyPart: e.target.value }))}
            >
                {bodyPartOptions.map(option => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
            <Typography variant="subtitle1">Ground Area</Typography>
            <TextField
                select
                fullWidth
                margin="normal"
                sx={textFieldStyle}
                SelectProps={{ style: { height: '30px' } }}
                value={details.groundArea}
                onChange={(e) => setDetails(prevDetails => ({ ...prevDetails, groundArea: e.target.value }))}
            >
                {groundAreaOptions.map(option => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
            <FormControlLabel
                control={
                    <Switch
                        checked={details.ownGoal}
                        onChange={(e) => setDetails(prevDetails => ({ ...prevDetails, ownGoal: e.target.checked }))}
                    />
                }
                label="Own Goal"
            />
            <TextField
                fullWidth
                label="Comment"
                multiline
                rows={3}
                margin="normal"
                sx={{ '& .MuiInputBase-root': { height: 'auto' } }}
                value={details.comment}
                onChange={(e) => setDetails(prevDetails => ({ ...prevDetails, comment: e.target.value }))}
            />
            <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
        </Card>
    );
};

export default GoalDetails;
