import { useState } from 'react';

const useFormState = (initialState) => {
    const [formState, setFormState] = useState(initialState);

    const updateFormState = (field, value) => {
        setFormState((prev) => ({ ...prev, [field]: value }));
    };

    const resetForm = () => {
        setFormState(initialState);
    };

    return [formState, updateFormState, resetForm];
};

export default useFormState;
