import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import Groups2Icon from '@mui/icons-material/Groups2';
import DeleteIcon from '@mui/icons-material/Delete';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import ball from './ball.png';
import balls from './balls.png';

const BottomMenu = ({ onPlayerDragStart, availablePlayers, onClearPitch }) => {
    const [isBlueExpanded, setIsBlueExpanded] = useState(false);
    const [isRedExpanded, setIsRedExpanded] = useState(false);
    const [isBallExpanded, setIsBallExpanded] = useState(false);

    const handleBlueClick = () => {
        setIsBlueExpanded(!isBlueExpanded);
        if (isRedExpanded) setIsRedExpanded(false);
        if (isBallExpanded) setIsBallExpanded(false);
    };

    const handleRedClick = () => {
        setIsRedExpanded(!isRedExpanded);
        if (isBlueExpanded) setIsBlueExpanded(false);
        if (isBallExpanded) setIsBallExpanded(false);
    };

    const handleBallClick = () => {
        setIsBallExpanded(!isBallExpanded);
        if (isBlueExpanded) setIsBlueExpanded(false);
        if (isRedExpanded) setIsRedExpanded(false);
    };

    const renderCircleIcons = (color) => (
        availablePlayers
            .filter((player) => player.color === color)
            .map((player) => (
                <Box
                    key={player.number}
                    draggable
                    onDragStart={(event) => onPlayerDragStart(event, player)}
                    className="circle-icon"
                    style={{ backgroundColor: color }}
                >
                    {player.number}
                </Box>
            ))
    );

    const renderBallIcons = () => (
        <>
            <Box
                draggable
                onDragStart={(event) => onPlayerDragStart(event, { type: 'ball', image: ball })}
                className="ball-icon"
                style={{ backgroundImage: `url(${ball})` }}
            />
            <Box
                draggable
                onDragStart={(event) => onPlayerDragStart(event, { type: 'ball', image: balls })}
                className="ball-icon"
                style={{ backgroundImage: `url(${balls})` }}
            />
        </>
    );

    return (
        <Box className="bottom-menu">
            <Box className="menu-group">
                <IconButton onClick={handleBlueClick}>
                    <Groups2Icon sx={{ color: '#a0bede' }} />
                </IconButton>
                {isBlueExpanded && (
                    <Box className="menu-items">
                        {renderCircleIcons('#a0bede')}
                    </Box>
                )}
            </Box>
            <Box className="menu-group">
                <IconButton onClick={handleRedClick}>
                    <Groups2Icon sx={{ color: '#eb6e89' }} />
                </IconButton>
                {isRedExpanded && (
                    <Box className="menu-items">
                        {renderCircleIcons('#eb6e89')}
                    </Box>
                )}
            </Box>
            <Box className="menu-group">
                <IconButton onClick={handleBallClick}>
                    <SportsSoccerIcon sx={{ color: 'black' }} />
                </IconButton>
                {isBallExpanded && (
                    <Box className="menu-items">
                        {renderBallIcons()}
                    </Box>
                )}
            </Box>
            <Box className="clear-button">
                <IconButton onClick={onClearPitch}>
                    <DeleteIcon sx={{ color: 'black' }} />
                </IconButton>
            </Box>
        </Box>
    );
};

export default BottomMenu;
