// // Custom RPEStatistics component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Drawer, Pagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Client, Databases, Query } from 'appwrite';
import conf from '../conf/conf';
import authService from '../appwrite/auth';
import PlayerCard from '../components/PlayerCard';
import RPEAnalytics from './RPEAnalytics';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const CardContainer = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '1rem',
    padding: '20px',
});

const CombinedPanel = styled(Drawer)({
    '& .MuiDrawer-paper': {
        width: '50%',
        boxSizing: 'border-box',
        padding: '20px',
    },
});

const FullScreenDrawer = styled(Drawer)({
    '& .MuiDrawer-paper': {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        padding: '20px',
    },
});

const DataRow = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    fontSize: '0.875rem',
}));

const DataColumn = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
});

export default function RPEStatistics() {
    const [rpeData, setRpeData] = useState([]);
    const [readinessRpeData, setReadinessRpeData] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [analyticsOpen, setAnalyticsOpen] = useState(false);
    const rowsPerPage = 9;

    useEffect(() => {
        const fetchRPEData = async () => {
            try {
                const currentUser = await authService.getCurrentUser();
                const userID = currentUser.$id;

                const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.rpeCaptureCollectionId, [
                    Query.equal('userID', userID)
                ]);

                const data = response.documents.map((doc) => ({
                    id: doc.$id,
                    matchName: doc.matchName,
                    matchDate: doc.matchDate,
                    opposition: doc.opposition,
                    sessionTitle: doc.sessionTitle,
                    sessionDate: doc.sessionDate,
                    location: doc.location,
                    playerName: doc.playerName,
                    jerseyNumber: doc.jerseyNumber,
                    pposition: doc.pposition,
                    rpeValue: doc.rpeValue,
                    duration: doc.duration,
                    trainingLoad: doc.trainingLoad,
                    hrBPM: doc.hrBPM,
                }));
                setRpeData(data);
            } catch (error) {
                console.error('Error fetching RPE data:', error);
            }
        };

        const fetchReadinessData = async () => {
            try {
                const currentUser = await authService.getCurrentUser();
                const userID = currentUser.$id;

                const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.rpeReadinessCollectionId, [
                    Query.equal('userID', userID)
                ]);

                const readinessData = response.documents.map((doc) => ({
                    id: doc.$id,
                    matchName: doc.matchName,
                    matchDate: doc.matchDate,
                    opposition: doc.opposition,
                    trainingID: doc.trainingID,
                    sessionTitle: doc.sessionTitle,
                    sessionDate: doc.sessionDate,
                    location: doc.location,
                    playerName: doc.playerName,
                    jerseyNumber: doc.jerseyNumber,
                    pposition: doc.pposition,
                    fatigue: doc.fatigue,
                    sleepQuality: doc.sleepQuality,
                    muscleSoreness: doc.muscleSoreness,
                    stressLevel: doc.stressLevel,
                    mood: doc.mood,
                    unweightedAverage: doc.unweightedAverage,
                    hrBPM: doc.hrBPM,
                }));
                setReadinessRpeData(readinessData);
            } catch (error) {
                console.error('Error fetching readiness RPE data:', error);
            }
        };

        fetchRPEData();
        fetchReadinessData();
    }, []);

    const handleCardClick = (playerName) => {
        const playerRPEData = rpeData.filter(data => data.playerName === playerName);
        const playerReadinessData = readinessRpeData.filter(data => data.playerName === playerName);
        setSelectedPlayer({
            rpe: playerRPEData,
            readiness: playerReadinessData,
        });
    };

    const handleClosePanel = () => {
        setSelectedPlayer(null);
    };

    const handleAnalyticsOpen = () => {
        setAnalyticsOpen(true);
    };

    const handleAnalyticsClose = () => {
        setAnalyticsOpen(false);
    };

    const indexOfLastData = currentPage * rowsPerPage;
    const indexOfFirstData = indexOfLastData - rowsPerPage;
    const currentData = rpeData.slice(indexOfFirstData, indexOfLastData);

    const uniquePlayers = Array.from(new Set([...rpeData, ...readinessRpeData].map(data => data.playerName)));

    return (
        <Box sx={{ padding: '20px' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h4" sx={{ marginBottom: '20px' }}>RPE Statistics</Typography>
                <Button variant="contained" color="primary" onClick={handleAnalyticsOpen}>Analytics</Button>
            </Box>
            <CardContainer>
                {uniquePlayers.map((playerName) => {
                    const playerData = rpeData.find(data => data.playerName === playerName) || readinessRpeData.find(data => data.playerName === playerName);
                    return (
                        <Box key={playerName}>
                            <PlayerCard
                                player={{
                                    fullName: playerData.playerName,
                                    jerseyNumber: playerData.jerseyNumber,
                                    pPosition: playerData.pposition,
                                    image: '' 
                                }}
                                isSelected={selectedPlayer && selectedPlayer.rpe && selectedPlayer.rpe[0].playerName === playerName}
                                isLocked={false}
                                onSelect={() => handleCardClick(playerName)}
                                clickable={true}
                            />
                        </Box>
                    );
                })}
            </CardContainer>
            {rpeData.length > rowsPerPage && (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <Pagination
                        count={Math.ceil(rpeData.length / rowsPerPage)}
                        page={currentPage}
                        onChange={(event, page) => setCurrentPage(page)}
                    />
                </Box>
            )}
            <CombinedPanel anchor="right" open={Boolean(selectedPlayer)} onClose={handleClosePanel}>
                {selectedPlayer && (
                    <Box>
                        <Typography variant="h5" gutterBottom>
                            {selectedPlayer.rpe.length > 0 ? selectedPlayer.rpe[0].playerName : selectedPlayer.readiness[0].playerName}
                        </Typography>
                        {selectedPlayer.rpe.map((data) => (
                            <Box key={data.id} sx={{ marginBottom: '20px' }}>
                                {data.matchName ? (
                                    <>
                                        <Typography variant="h6" gutterBottom>
                                            Match: {data.matchName} | {new Date(data.matchDate).toLocaleDateString()}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            {data.opposition}
                                        </Typography>
                                        <DataRow>
                                            <DataColumn>
                                                <Typography variant="body2" color="textSecondary">RPE Value</Typography>
                                                <Typography variant="body2">{data.rpeValue}</Typography>
                                            </DataColumn>
                                            <DataColumn>
                                                <Typography variant="body2" color="textSecondary">Duration</Typography>
                                                <Typography variant="body2">{data.duration}</Typography>
                                            </DataColumn>
                                            <DataColumn>
                                                <Typography variant="body2" color="textSecondary">Training Load</Typography>
                                                <Typography variant="body2">{data.trainingLoad}</Typography>
                                            </DataColumn>
                                            {data.hrBPM !== undefined && (
                                                <DataColumn>
                                                    <Typography variant="body2" color="textSecondary">BPM</Typography>
                                                    <Typography variant="body2">{data.hrBPM}</Typography>
                                                </DataColumn>
                                            )}
                                        </DataRow>
                                        <hr />
                                    </>
                                ) : data.sessionTitle ? (
                                    <>
                                        <Typography variant="h6" gutterBottom>
                                            Training: {data.sessionTitle} | {new Date(data.sessionDate).toLocaleDateString()}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            {data.location}
                                        </Typography>
                                        <DataRow>
                                            <DataColumn>
                                                <Typography variant="body2" color="textSecondary">RPE Value</Typography>
                                                <Typography variant="body2">{data.rpeValue}</Typography>
                                            </DataColumn>
                                            <DataColumn>
                                                <Typography variant="body2" color="textSecondary">Duration</Typography>
                                                <Typography variant="body2">{data.duration}</Typography>
                                            </DataColumn>
                                            <DataColumn>
                                                <Typography variant="body2" color="textSecondary">Training Load</Typography>
                                                <Typography variant="body2">{data.trainingLoad}</Typography>
                                            </DataColumn>
                                            {data.hrBPM !== undefined && (
                                                <DataColumn>
                                                    <Typography variant="body2" color="textSecondary">BPM</Typography>
                                                    <Typography variant="body2">{data.hrBPM}</Typography>
                                                </DataColumn>
                                            )}
                                        </DataRow>
                                        <hr />
                                    </>
                                ) : null}
                            </Box>
                        ))}
                        {selectedPlayer.readiness.map((data) => (
                            <Box key={data.id} sx={{ marginBottom: '20px' }}>
                                <Typography variant="h6" gutterBottom>
                                    Readiness Data
                                </Typography>
                                <DataRow>
                                    <DataColumn>
                                        <Typography variant="body2" color="textSecondary">Fatigue</Typography>
                                        <Typography variant="body2">{data.fatigue}</Typography>
                                    </DataColumn>
                                    <DataColumn>
                                        <Typography variant="body2" color="textSecondary">Mood</Typography>
                                        <Typography variant="body2">{data.mood}</Typography>
                                    </DataColumn>
                                    <DataColumn>
                                        <Typography variant="body2" color="textSecondary">Muscle Soreness</Typography>
                                        <Typography variant="body2">{data.muscleSoreness}</Typography>
                                    </DataColumn>
                                    <DataColumn>
                                        <Typography variant="body2" color="textSecondary">Sleep Quality</Typography>
                                        <Typography variant="body2">{data.sleepQuality}</Typography>
                                    </DataColumn>
                                    <DataColumn>
                                        <Typography variant="body2" color="textSecondary">Stress Level</Typography>
                                        <Typography variant="body2">{data.stressLevel}</Typography>
                                    </DataColumn>
                                    <DataColumn>
                                        <Typography variant="body2" color="textSecondary">Unweighted Average</Typography>
                                        <Typography variant="body2">{data.unweightedAverage}</Typography>
                                    </DataColumn>
                                    {data.hrBPM !== undefined && (
                                        <DataColumn>
                                            <Typography variant="body2" color="textSecondary">BPM</Typography>
                                            <Typography variant="body2">{data.hrBPM}</Typography>
                                        </DataColumn>
                                    )}
                                </DataRow>
                                <hr />
                            </Box>
                        ))}
                    </Box>
                )}
            </CombinedPanel>
            <FullScreenDrawer anchor="right" open={analyticsOpen} onClose={handleAnalyticsClose}>
                <RPEAnalytics onClose={handleAnalyticsClose} rpeData={rpeData} />
            </FullScreenDrawer>
        </Box>
    );
}
