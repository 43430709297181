// import React from 'react';
// import { Drawer, Box, IconButton, Typography, Button } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import SoccerPitch from './SoccerPitch'; // Import your existing SoccerPitch component

// const SoccerPitchDrawer = ({
//     showPitch,
//     setShowPitch,
//     drawerCollapsed,
//     toggleDrawerCollapse,
//     playersStriped,
//     lineup,
//     handleClose
// }) => (
//     <Drawer
//         anchor="right"
//         open={showPitch}
//         onClose={() => setShowPitch(false)}
//         variant="persistent"
//     >
//         <Box sx={{
//             width: drawerCollapsed ? 60 : '90vw',
//             padding: 2,
//             backgroundColor: '#f5f5f5',
//             overflow: 'hidden',
//             display: 'flex',
//             flexDirection: 'column'
//         }}>
//             {!drawerCollapsed && (
//                 <>
//                     <IconButton onClick={() => setShowPitch(false)} sx={{ position: 'absolute', top: 0, right: 0 }}>
//                         <CloseIcon />
//                     </IconButton>
//                     <Typography variant="h6">Soccer Pitch</Typography>
//                 </>
//             )}
//             {drawerCollapsed && (
//                 <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center', backgroundColor: 'lightgrey' }}>
//                     <IconButton onClick={toggleDrawerCollapse}>
//                         <ChevronLeftIcon />
//                     </IconButton>
//                 </Box>
//             )}
//         </Box>
//         <Box sx={{
//             flexGrow: 1,
//             overflow: 'hidden',
//             display: drawerCollapsed ? 'none' : 'flex',
//             justifyContent: 'center',
//             alignItems: 'center'
//         }}>
//             {!drawerCollapsed && <SoccerPitch
//                 selectedPlayers={playersStriped.filter(player => player.selected)}
//                 formation={lineup}
//             />}
//             <Button onClick={toggleDrawerCollapse} sx={{ position: 'absolute', bottom: 10, right: 5 }}>Hide</Button>
//         </Box>
//     </Drawer>
// );

// export default SoccerPitchDrawer;
import React, { useEffect, useState } from 'react';
import { Drawer, Box, IconButton, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SoccerPitch from './SoccerPitch'; // Import your existing SoccerPitch component
import authService from './appwrite/auth';
import { Client, Databases } from 'appwrite';
import conf from './conf/conf';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const SoccerPitchDrawer = ({
    showPitch,
    setShowPitch,
    drawerCollapsed,
    toggleDrawerCollapse,
    playersStriped,
    lineup,
    lastDocumentId,
    handleClose
}) => {
    const [fetchedSquad, setFetchedSquad] = useState(null);
    const [isLocked, setIsLocked] = useState(true); // Initial state is locked

    useEffect(() => {
        const fetchSquad = async () => {
            try {
                const currentUser = await authService.getCurrentUser();
                if (!currentUser) {
                    throw new Error('No user is currently logged in.');
                }

                console.log('Fetching squad with document ID:', lastDocumentId);
                const response = await databases.getDocument(conf.appwriteDatabaseId, conf.createSquadCollectionId, lastDocumentId);
                console.log('Fetched squad:', response);
                setFetchedSquad(response);
            } catch (error) {
                console.error('Error fetching squad:', error);
            }
        };

        if (lastDocumentId) {
            fetchSquad();
        }
    }, [lastDocumentId]);

    const handleLockToggle = () => {
        setIsLocked(!isLocked);
    };

    const handleDrawerClose = () => {
        if (!isLocked) {
            setShowPitch(false);
        }
    };

    return (
        <Drawer
            anchor="right"
            open={showPitch}
            onClose={handleDrawerClose}
            variant="persistent"
        >
            <Box sx={{
                width: drawerCollapsed ? 60 : '90vw',
                padding: 2,
                backgroundColor: '#f5f5f5',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column'
            }}>
                {!drawerCollapsed && (
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6">Soccer Pitch</Typography>
                            <Box>
                                <IconButton onClick={handleLockToggle}>
                                    {isLocked ? <LockIcon sx={{ color: 'red' }} /> : <LockOpenIcon sx={{ color: 'green' }} />}
                                </IconButton>
                                <IconButton onClick={handleDrawerClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </>
                )}
                {drawerCollapsed && (
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center', backgroundColor: 'lightgrey' }}>
                        <IconButton onClick={toggleDrawerCollapse}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Box>
                )}
            </Box>
            <Box sx={{
                flexGrow: 1,
                overflow: 'hidden',
                display: drawerCollapsed ? 'none' : 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {!drawerCollapsed && fetchedSquad && (
                    <SoccerPitch
                        selectedPlayers={fetchedSquad.startingEleven.map(player => JSON.parse(player))}
                        formation={fetchedSquad.lineup}
                    />
                )}
                <Button onClick={toggleDrawerCollapse} sx={{ position: 'absolute', bottom: 10, right: 5 }}>Hide</Button>
            </Box>
        </Drawer>
    );
};

export default SoccerPitchDrawer;
