// import React, { useState, useEffect } from 'react';
// import { Card, CardContent, Typography, IconButton, Button, Grid, Divider, Avatar, Menu, MenuItem, List, ListItem, ListItemText, Collapse, Paper, Checkbox, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
// import { Close, ExpandMore, ExpandLess, Phone, Email, Edit, Save } from '@mui/icons-material';
// import { Client, Databases, ID, Storage, Query, Account } from 'appwrite';
// import conf from '../conf/conf';
// import authService from '../appwrite/auth';

// const client = new Client()
//     .setEndpoint(conf.appwriteUrl)
//     .setProject(conf.appwriteProjectId);

// const databases = new Databases(client);
// const storage = new Storage(client);
// const account = new Account(client);

// const ClubDetails = ({ clubs, passwordDialogRef }) => {
//     const [anchorEl, setAnchorEl] = useState(null);
//     const [openClubs, setOpenClubs] = useState(false);
//     const [openPlayers, setOpenPlayers] = useState(false);
//     const [openTraining, setOpenTraining] = useState(false);
//     const [openGames, setOpenGames] = useState(false);
//     const [openSquads, setOpenSquads] = useState(false);
//     const [playerList, setPlayerList] = useState([]);
//     const [trainingList, setTrainingList] = useState([]);
//     const [image, setImage] = useState('');
//     const [buttonText, setButtonText] = useState('Upload Image');
//     const [defaultClub, setDefaultClub] = useState(null);
//     const [user, setUser] = useState(null);
//     const [userDocId, setUserDocId] = useState(null);
//     const [editMode, setEditMode] = useState(false);
//     const [phoneNumber, setPhoneNumber] = useState('');
//     const [originalPhoneNumber, setOriginalPhoneNumber] = useState('');
//     const [password, setPassword] = useState('');
//     const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const currentUser = await authService.getCurrentUser();
//                 if (!currentUser) {
//                     window.location.href = '/login';
//                     return;
//                 }

//                 setUser(currentUser);

//                 const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteUsersId, [
//                     Query.equal('userid', currentUser.$id)
//                 ]);

//                 let userDoc;
//                 if (response.total > 0) {
//                     userDoc = response.documents[0];
//                     setUserDocId(userDoc.$id);
//                     setPhoneNumber(currentUser.phone || '');
//                     setOriginalPhoneNumber(currentUser.phone || '');
//                 } else {
//                     userDoc = await databases.createDocument(conf.appwriteDatabaseId, conf.appwriteUsersId, ID.unique(), {
//                         userid: currentUser.$id,
//                         username: currentUser.name,
//                         userEmail: currentUser.email,
//                         defaultClub: null,
//                     });
//                     setUserDocId(userDoc.$id);
//                 }

//                 if (userDoc.clubImageId) {
//                     const imageUrl = `${client.config.endpoint}/storage/buckets/${conf.appwriteBucketId}/files/${userDoc.clubImageId}/view?project=${client.config.project}`;
//                     setImage(imageUrl);
//                     setButtonText('Update Image');
//                 }

//                 if (userDoc.defaultClub) {
//                     const defaultClubName = clubs.find(club => club.id === userDoc.defaultClub)?.teamname || 'Unknown Club';
//                     setDefaultClub({ id: userDoc.defaultClub, name: defaultClubName });
//                 }

//                 const playerResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteCollectionId, [
//                     Query.equal('userID', currentUser.$id)
//                 ]);
//                 setPlayerList(playerResponse.documents);

//                 const trainingResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.createTrainingCollectionId, [
//                     Query.equal('userID', currentUser.$id)
//                 ]);
//                 setTrainingList(trainingResponse.documents);
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         fetchData();
//     }, [clubs]);

//     const handleMenuClick = (event) => {
//         setAnchorEl(event.currentTarget);
//     };

//     const handleMenuClose = () => {
//         setAnchorEl(null);
//     };

//     const logUserActivity = async (originalRecord, newRecord, activityType) => {
//         try {
//             await databases.createDocument(conf.appwriteDatabaseId, conf.appwrite_user_audit, ID.unique(), {
//                 userId: user.$id,
//                 userName: user.name,
//                 originalRecord,
//                 newRecord,
//                 dateTime: new Date().toISOString(),
//                 activityType
//             });
//         } catch (error) {
//             console.error('Error logging user activity:', error);
//         }
//     };

//     const handleImageUpload = async (event) => {
//         const file = event.target.files[0];
//         if (file) {
//             try {
//                 const response = await storage.createFile(conf.appwriteBucketId, ID.unique(), file);
//                 const fileId = response.$id;
//                 const originalImageId = userDocId ? userDocId.clubImageId : '';

//                 await databases.updateDocument(conf.appwriteDatabaseId, conf.appwriteUsersId, userDocId, { clubImageId: fileId });
//                 const fileUrl = `${client.config.endpoint}/storage/buckets/${conf.appwriteBucketId}/files/${fileId}/view?project=${client.config.project}`;
//                 setImage(fileUrl);
//                 setButtonText('Update Image');
//                 logUserActivity(originalImageId, fileId, 'Image Upload/Update');
//             } catch (error) {
//                 console.error('Error uploading file:', error);
//             }
//         }
//     };

//     const handleDefaultClubChange = async (clubId) => {
//         try {
//             const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteUsersId, [
//                 Query.equal('userid', user.$id)
//             ]);

//             if (response.total > 0) {
//                 const userDoc = response.documents[0];
//                 await databases.updateDocument(conf.appwriteDatabaseId, conf.appwriteUsersId, userDoc.$id, {
//                     defaultClub: clubId
//                 });
//             } else {
//                 await databases.createDocument(conf.appwriteDatabaseId, conf.appwriteUsersId, ID.unique(), {
//                     userid: user.$id,
//                     username: user.name,
//                     userEmail: user.email,
//                     defaultClub: clubId,
//                 });
//             }

//             const defaultClubName = clubs.find(club => club.id === clubId)?.teamname || 'Unknown Club';
//             setDefaultClub({ id: clubId, name: defaultClubName });
//         } catch (error) {
//             console.error('Error setting default club:', error);
//         }
//     };

//     const handleEditClick = () => {
//         setEditMode(true);
//     };

//     const handleSaveClick = () => {
//         setPasswordDialogOpen(true);
//     };

//     const handlePasswordDialogClose = () => {
//         setPasswordDialogOpen(false);
//         setPassword('');
//     };

//     const handlePasswordSubmit = async () => {
//         try {
//             const originalPhone = originalPhoneNumber;
//             await account.updatePhone(phoneNumber, password);
//             setEditMode(false);
//             setPasswordDialogOpen(false);
//             setPassword('');
//             logUserActivity(originalPhone, phoneNumber, 'Phone Update');
//         } catch (error) {
//             console.error('Error updating phone number:', error);
//         }
//     };

//     if (!user) {
//         return null;
//     }

//     return (
//         <Card sx={{ width: 400, height: '100vh', overflowY: 'auto', m: 2, position: 'relative', bgcolor: '#fff', transition: 'box-shadow 0.3s ease-in-out', 
//         '&:hover': {
//           boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)' 
//         } }}>
//             <CardContent>
//                 <IconButton aria-label="close" sx={{ position: 'absolute', right: 0, top: 0 }} onClick={handleMenuClose}>
//                     <Close />
//                 </IconButton>
//                 <Grid container spacing={2} alignItems="center">
//                     <Grid item>
//                         <Avatar src={image} sx={{ width: 56, height: 56, borderRadius: '10%', border: '1px solid grey' }} />
//                         <input
//                             accept="image/*"
//                             style={{ display: 'none' }}
//                             id="upload-image"
//                             type="file"
//                             onChange={handleImageUpload}
//                         />
//                         <label htmlFor="upload-image">
//                             <Button variant="contained" component="span" size="small" sx={{ mt: 1, backgroundColor: '#356596', color: '#ffffff', '&:hover': { backgroundColor: '#ffffff', color: '#356596' }}}>
//                                 {buttonText}
//                             </Button>
//                         </label>
//                     </Grid>
//                     <Grid item xs>
//                         <Typography variant="h6">{user.name}</Typography>
//                         <Typography variant="body2" sx={{ color: 'green', display: 'flex', alignItems: "center" }}>
//                             <Typography variant="caption" sx={{ bgcolor: 'green', borderRadius: '50%', width: 8, height: 8, mr: 0.5, display: 'inline-block' }}></Typography> Active
//                         </Typography>
//                         <Typography variant="body2">Club Admin</Typography>
//                         {defaultClub && (
//                             <Typography variant="body2" sx={{ mt: 1 }}>
//                                 Contracted: {defaultClub.name}
//                             </Typography>
//                         )}
//                     </Grid>
//                 </Grid>
//                 <Divider sx={{ my: 1 }} />
//                 <Grid container alignItems="center" sx={{ mb: 1 }}>
//                     <Phone sx={{ mr: 1 }} />
//                     {editMode ? (
//                         <>
//                             <TextField 
//                                 value={phoneNumber} 
//                                 onChange={(e) => setPhoneNumber(e.target.value)} 
//                                 variant="outlined" 
//                                 size="small" 
//                                 sx={{ mr: 1 }}
//                             />
//                             <IconButton onClick={handleSaveClick} color="primary">
//                                 <Save />
//                             </IconButton>
//                         </>
//                     ) : (
//                         <>
//                             <Typography variant="body2">{phoneNumber || 'N/A'}</Typography>
//                             <IconButton onClick={handleEditClick} sx={{ ml: 'auto' }}>
//                                 <Edit />
//                             </IconButton>
//                         </>
//                     )}
//                 </Grid>
//                 <Grid container alignItems="center" sx={{ mb: 1 }}>
//                     <Email sx={{ mr: 1 }} />
//                     <Typography variant="body2">{user.email || 'N/A'}</Typography>
//                 </Grid>
//                 <Divider sx={{ my: 1 }} />
//                 <Button aria-controls="action-menu" aria-haspopup="true" onClick={handleMenuClick} sx={{ bgcolor: '#fff', color: '#356596', '&:hover': { bgcolor: '#356596', color: '#fff' } }}>
//                     See Actions
//                 </Button>
//                 <Menu
//                     id="action-menu"
//                     anchorEl={anchorEl}
//                     keepMounted
//                     open={Boolean(anchorEl)}
//                     onClose={handleMenuClose}
//                 >
//                     <MenuItem onClick={handleMenuClose}>Call</MenuItem>
//                     <MenuItem onClick={handleMenuClose}>Send Email</MenuItem>
//                     <MenuItem onClick={handleMenuClose}>Export Data</MenuItem>
//                     <MenuItem onClick={handleMenuClose}>Purge Data</MenuItem>
//                     <MenuItem onClick={handleMenuClose}>Documents</MenuItem>
//                 </Menu>
//                 <Divider sx={{ my: 1 }} />
//                 <ListItem button onClick={() => setOpenClubs(!openClubs)} sx={{ '&:hover': { bgcolor: 'action.hover' }, bgcolor: openClubs ? 'action.hover' : '#fff' }}>
//                     <ListItemText primary="Clubs" />
//                     {openClubs ? <ExpandLess /> : <ExpandMore />}
//                 </ListItem>
//                 <Collapse in={openClubs} timeout="auto" unmountOnExit>
//                     <List component="div" disablePadding>
//                         {clubs.map((club) => (
//                             <Paper key={club.id} variant="outlined" sx={{ m: 1, p: 1 }}>
//                                 <Typography variant="body2">{club.teamname}</Typography>
//                                 {club.emblem && (
//                                     <img src={club.emblem} alt={club.teamname} style={{ width: '30%', maxWidth: '200px', marginTop: '10px' }} />
//                                 )}
//                                 <Checkbox
//                                     checked={defaultClub && defaultClub.id === club.id}
//                                     onChange={() => handleDefaultClubChange(club.id)}
//                                     color="primary"
//                                 />
//                                 Default Club
//                             </Paper>
//                         ))}
//                     </List>
//                 </Collapse>
//                 <ListItem button onClick={() => setOpenPlayers(!openPlayers)} sx={{ '&:hover': { bgcolor: 'action.hover' }, bgcolor: openPlayers ? 'action.hover' : '#fff' }}>
//                     <ListItemText primary="Players" />
//                     {openPlayers ? <ExpandLess /> : <ExpandMore />}
//                 </ListItem>
//                 <Collapse in={openPlayers} timeout="auto" unmountOnExit>
//                     <List component="div" disablePadding>
//                         {playerList.map((player) => (
//                             <Paper key={player.$id} variant="outlined" sx={{ m: 1, p: 1 }}>
//                                 <Grid container alignItems="center" spacing={1}>
//                                     <Grid item>
//                                         <Avatar src={player.image ? `${conf.appwriteUrl}/storage/buckets/${conf.appwriteBucketId}/files/${player.image}/view?project=${conf.appwriteProjectId}` : ''} sx={{ width: 56, height: 56 }} />
//                                     </Grid>
//                                     <Grid item xs>
//                                         <Typography variant="body2">{player.fullName}</Typography>
//                                     </Grid>
//                                 </Grid>
//                             </Paper>
//                         ))}
//                     </List>
//                 </Collapse>
//                 <ListItem button onClick={() => setOpenTraining(!openTraining)} sx={{ '&:hover': { bgcolor: 'action.hover' }, bgcolor: openTraining ? 'action.hover' : '#fff' }}>
//                     <ListItemText primary="Training" />
//                     {openTraining ? <ExpandLess /> : <ExpandMore />}
//                 </ListItem>
//                 <Collapse in={openTraining} timeout="auto" unmountOnExit>
//                     <List component="div" disablePadding>
//                         {trainingList.map((training) => (
//                             <Paper key={training.$id} variant="outlined" sx={{ m: 1, p: 1 }}>
//                                 <Grid container alignItems="center" spacing={1}>
//                                     <Grid item xs>
//                                         <Typography variant="body2">{training.sessionTitle}</Typography>
//                                         <Typography variant="body2">{training.sessionDate}</Typography>
//                                     </Grid>
//                                 </Grid>
//                             </Paper>
//                         ))}
//                     </List>
//                 </Collapse>
//                 <ListItem button onClick={() => setOpenGames(!openGames)} sx={{ '&:hover': { bgcolor: 'action.hover' }, bgcolor: openGames ? 'action.hover' : '#fff' }}>
//                     <ListItemText primary="Games" />
//                     {openGames ? <ExpandLess /> : <ExpandMore />}
//                 </ListItem>
//                 <Collapse in={openGames} timeout="auto" unmountOnExit>
//                     <List component="div" disablePadding>
//                         <Paper variant="outlined" sx={{ m: 1, p: 1 }}>
//                             <Typography variant="body2">No data available</Typography>
//                         </Paper>
//                     </List>
//                 </Collapse>
//                 <ListItem button onClick={() => setOpenSquads(!openSquads)} sx={{ '&:hover': { bgcolor: 'action.hover' }, bgcolor: openSquads ? 'action.hover' : '#fff' }}>
//                     <ListItemText primary="Squads" />
//                     {openSquads ? <ExpandLess /> : <ExpandMore />}
//                 </ListItem>
//                 <Collapse in={openSquads} timeout="auto" unmountOnExit>
//                     <List component="div" disablePadding>
//                         <Paper variant="outlined" sx={{ m: 1, p: 1 }}>
//                             <Typography variant="body2">No data available</Typography>
//                         </Paper>
//                     </List>
//                 </Collapse>
//             </CardContent>

//             <Dialog open={passwordDialogOpen} onClose={handlePasswordDialogClose} ref={passwordDialogRef}>
//                 <DialogTitle>Enter Password</DialogTitle>
//                 <DialogContent>
//                     <DialogContentText>
//                         To update your phone number, please enter your password.
//                     </DialogContentText>
//                     <TextField
//                         autoFocus
//                         margin="dense"
//                         label="Password"
//                         type="password"
//                         fullWidth
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                     />
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={handlePasswordDialogClose} color="primary">
//                         Cancel
//                     </Button>
//                     <Button onClick={handlePasswordSubmit} color="primary">
//                         Submit
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//         </Card>
//     );
// };

// export default ClubDetails;
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, IconButton, Button, Grid, Divider, Avatar, Menu, MenuItem, List, ListItem, ListItemText, Collapse, Paper, Checkbox, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Close, ExpandMore, ExpandLess, Phone, Email, Edit, Save } from '@mui/icons-material';
import { Client, Databases, ID, Storage, Query, Account } from 'appwrite';
import conf from '../conf/conf';
import authService from '../appwrite/auth';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);
const storage = new Storage(client);
const account = new Account(client);

const ClubDetails = ({ clubs, passwordDialogRef }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openClubs, setOpenClubs] = useState(false);
    const [openPlayers, setOpenPlayers] = useState(false);
    const [openTraining, setOpenTraining] = useState(false);
    const [openGames, setOpenGames] = useState(false);
    const [openSquads, setOpenSquads] = useState(false);
    const [playerList, setPlayerList] = useState([]);
    const [trainingList, setTrainingList] = useState([]);
    const [image, setImage] = useState('');
    const [buttonText, setButtonText] = useState('Upload Image');
    const [defaultClub, setDefaultClub] = useState(null);
    const [user, setUser] = useState(null);
    const [userDocId, setUserDocId] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [originalPhoneNumber, setOriginalPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const currentUser = await authService.getCurrentUser();
                if (!currentUser) {
                    window.location.href = '/login';
                    return;
                }

                setUser(currentUser);

                const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteUsersId, [
                    Query.equal('userid', currentUser.$id)
                ]);

                let userDoc;
                if (response.total > 0) {
                    userDoc = response.documents[0];
                    setUserDocId(userDoc.$id);
                    setPhoneNumber(currentUser.phone || '');
                    setOriginalPhoneNumber(currentUser.phone || '');
                } else {
                    userDoc = await databases.createDocument(conf.appwriteDatabaseId, conf.appwriteUsersId, ID.unique(), {
                        userid: currentUser.$id,
                        username: currentUser.name,
                        userEmail: currentUser.email,
                        defaultClub: null,
                    });
                    setUserDocId(userDoc.$id);
                }

                if (userDoc.clubImageId) {
                    const imageUrl = `${client.config.endpoint}/storage/buckets/${conf.appwriteBucketId}/files/${userDoc.clubImageId}/view?project=${client.config.project}`;
                    setImage(imageUrl);
                    setButtonText('Update Image');
                }

                if (userDoc.defaultClub) {
                    const defaultClubName = clubs.find(club => club.id === userDoc.defaultClub)?.teamname || 'Unknown Club';
                    setDefaultClub({ id: userDoc.defaultClub, name: defaultClubName });
                }

                const playerResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteCollectionId, [
                    Query.equal('userID', currentUser.$id)
                ]);
                setPlayerList(playerResponse.documents);

                const trainingResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.createTrainingCollectionId, [
                    Query.equal('userID', currentUser.$id)
                ]);
                setTrainingList(trainingResponse.documents);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [clubs]);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const logUserActivity = async (originalRecord, newRecord) => {
        try {
            await databases.createDocument(conf.appwriteDatabaseId, conf.appwrite_user_audit, ID.unique(), {
                userId: user.$id,
                userName: user.name,
                originalRecord,
                newRecord,
                dateTime: new Date().toISOString()
            });
        } catch (error) {
            console.error('Error logging user activity:', error);
        }
    };

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const response = await storage.createFile(conf.appwriteBucketId, ID.unique(), file);
                const fileId = response.$id;
                const originalImageId = image;

                await databases.updateDocument(conf.appwriteDatabaseId, conf.appwriteUsersId, userDocId, { clubImageId: fileId });
                const fileUrl = `${client.config.endpoint}/storage/buckets/${conf.appwriteBucketId}/files/${fileId}/view?project=${client.config.project}`;
                setImage(fileUrl);
                setButtonText('Update Image');
                logUserActivity(originalImageId, fileId, 'Image Upload/Update');
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    const handleDefaultClubChange = async (clubId) => {
        try {
            const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteUsersId, [
                Query.equal('userid', user.$id)
            ]);

            if (response.total > 0) {
                const userDoc = response.documents[0];
                await databases.updateDocument(conf.appwriteDatabaseId, conf.appwriteUsersId, userDoc.$id, {
                    defaultClub: clubId
                });
            } else {
                await databases.createDocument(conf.appwriteDatabaseId, conf.appwriteUsersId, ID.unique(), {
                    userid: user.$id,
                    username: user.name,
                    userEmail: user.email,
                    defaultClub: clubId,
                });
            }

            const defaultClubName = clubs.find(club => club.id === clubId)?.teamname || 'Unknown Club';
            setDefaultClub({ id: clubId, name: defaultClubName });
        } catch (error) {
            console.error('Error setting default club:', error);
        }
    };

    const handleEditClick = () => {
        setEditMode(true);
    };

    const handleSaveClick = () => {
        setPasswordDialogOpen(true);
    };

    const handlePasswordDialogClose = () => {
        setPasswordDialogOpen(false);
        setPassword('');
    };

    const handlePasswordSubmit = async () => {
        try {
            const originalPhone = originalPhoneNumber;
            await account.updatePhone(phoneNumber, password);
            setEditMode(false);
            setPasswordDialogOpen(false);
            setPassword('');
            logUserActivity(originalPhone, phoneNumber, 'Phone Update');
        } catch (error) {
            console.error('Error updating phone number:', error);
        }
    };

    if (!user) {
        return null;
    }

    return (
        <Card sx={{ width: 400, height: '100vh', overflowY: 'auto', m: 2, position: 'relative', bgcolor: '#fff', transition: 'box-shadow 0.3s ease-in-out', 
        '&:hover': {
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)' 
        } }}>
            <CardContent>
                <IconButton aria-label="close" sx={{ position: 'absolute', right: 0, top: 0 }} onClick={handleMenuClose}>
                    <Close />
                </IconButton>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Avatar src={image} sx={{ width: 56, height: 56, borderRadius: '10%', border: '1px solid grey' }} />
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="upload-image"
                            type="file"
                            onChange={handleImageUpload}
                        />
                        <label htmlFor="upload-image">
                            <Button variant="contained" component="span" size="small" sx={{ mt: 1, backgroundColor: '#356596', color: '#ffffff', '&:hover': { backgroundColor: '#ffffff', color: '#356596' }}}>
                                {buttonText}
                            </Button>
                        </label>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h6">{user.name}</Typography>
                        <Typography variant="body2" sx={{ color: 'green', display: 'flex', alignItems: "center" }}>
                            <Typography variant="caption" sx={{ bgcolor: 'green', borderRadius: '50%', width: 8, height: 8, mr: 0.5, display: 'inline-block' }}></Typography> Active
                        </Typography>
                        <Typography variant="body2">Club Admin</Typography>
                        {defaultClub && (
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                Contracted: {defaultClub.name}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container alignItems="center" sx={{ mb: 1 }}>
                    <Phone sx={{ mr: 1 }} />
                    {editMode ? (
                        <>
                            <TextField 
                                value={phoneNumber} 
                                onChange={(e) => setPhoneNumber(e.target.value)} 
                                variant="outlined" 
                                size="small" 
                                sx={{ mr: 1 }}
                            />
                            <IconButton onClick={handleSaveClick} color="primary">
                                <Save />
                            </IconButton>
                        </>
                    ) : (
                        <>
                            <Typography variant="body2">{phoneNumber || 'N/A'}</Typography>
                            <IconButton onClick={handleEditClick} sx={{ ml: 'auto' }}>
                                <Edit />
                            </IconButton>
                        </>
                    )}
                </Grid>
                <Grid container alignItems="center" sx={{ mb: 1 }}>
                    <Email sx={{ mr: 1 }} />
                    <Typography variant="body2">{user.email || 'N/A'}</Typography>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Button aria-controls="action-menu" aria-haspopup="true" onClick={handleMenuClick} sx={{ bgcolor: '#fff', color: '#356596', '&:hover': { bgcolor: '#356596', color: '#fff' } }}>
                    See Actions
                </Button>
                <Menu
                    id="action-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleMenuClose}>Call</MenuItem>
                    <MenuItem onClick={handleMenuClose}>Send Email</MenuItem>
                    <MenuItem onClick={handleMenuClose}>Export Data</MenuItem>
                    <MenuItem onClick={handleMenuClose}>Purge Data</MenuItem>
                    <MenuItem onClick={handleMenuClose}>Documents</MenuItem>
                </Menu>
                <Divider sx={{ my: 1 }} />
                <ListItem button onClick={() => setOpenClubs(!openClubs)} sx={{ '&:hover': { bgcolor: 'action.hover' }, bgcolor: openClubs ? 'action.hover' : '#fff' }}>
                    <ListItemText primary="Clubs" />
                    {openClubs ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openClubs} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {clubs.map((club) => (
                            <Paper key={club.id} variant="outlined" sx={{ m: 1, p: 1 }}>
                                <Typography variant="body2">{club.teamname}</Typography>
                                {club.emblem && (
                                    <img src={club.emblem} alt={club.teamname} style={{ width: '30%', maxWidth: '200px', marginTop: '10px' }} />
                                )}
                                <Checkbox
                                    checked={defaultClub && defaultClub.id === club.id}
                                    onChange={() => handleDefaultClubChange(club.id)}
                                    color="primary"
                                />
                                Default Club
                            </Paper>
                        ))}
                    </List>
                </Collapse>
                <ListItem button onClick={() => setOpenPlayers(!openPlayers)} sx={{ '&:hover': { bgcolor: 'action.hover' }, bgcolor: openPlayers ? 'action.hover' : '#fff' }}>
                    <ListItemText primary="Players" />
                    {openPlayers ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openPlayers} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {playerList.map((player) => (
                            <Paper key={player.$id} variant="outlined" sx={{ m: 1, p: 1 }}>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <Avatar src={player.image ? `${conf.appwriteUrl}/storage/buckets/${conf.appwriteBucketId}/files/${player.image}/view?project=${conf.appwriteProjectId}` : ''} sx={{ width: 56, height: 56 }} />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="body2">{player.fullName}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        ))}
                    </List>
                </Collapse>
                <ListItem button onClick={() => setOpenTraining(!openTraining)} sx={{ '&:hover': { bgcolor: 'action.hover' }, bgcolor: openTraining ? 'action.hover' : '#fff' }}>
                    <ListItemText primary="Training" />
                    {openTraining ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openTraining} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {trainingList.map((training) => (
                            <Paper key={training.$id} variant="outlined" sx={{ m: 1, p: 1 }}>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs>
                                        <Typography variant="body2">{training.sessionTitle}</Typography>
                                        <Typography variant="body2">{training.sessionDate}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        ))}
                    </List>
                </Collapse>
                <ListItem button onClick={() => setOpenGames(!openGames)} sx={{ '&:hover': { bgcolor: 'action.hover' }, bgcolor: openGames ? 'action.hover' : '#fff' }}>
                    <ListItemText primary="Games" />
                    {openGames ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openGames} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Paper variant="outlined" sx={{ m: 1, p: 1 }}>
                            <Typography variant="body2">No data available</Typography>
                        </Paper>
                    </List>
                </Collapse>
                <ListItem button onClick={() => setOpenSquads(!openSquads)} sx={{ '&:hover': { bgcolor: 'action.hover' }, bgcolor: openSquads ? 'action.hover' : '#fff' }}>
                    <ListItemText primary="Squads" />
                    {openSquads ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openSquads} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Paper variant="outlined" sx={{ m: 1, p: 1 }}>
                            <Typography variant="body2">No data available</Typography>
                        </Paper>
                    </List>
                </Collapse>
            </CardContent>

            <Dialog open={passwordDialogOpen} onClose={handlePasswordDialogClose} ref={passwordDialogRef}>
                <DialogTitle>Enter Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To update your phone number, please enter your password.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Password"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePasswordDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handlePasswordSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

export default ClubDetails;

