import React, { useState, useEffect } from 'react';
import {
  Drawer,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  IconButton,
  Typography,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const allExercises = {
  warmUp: [
    { id: 'dynamicStretching', name: 'Dynamic Stretching', type: 'warmUp' },
    { id: 'lightJogging', name: 'Light Jogging', type: 'warmUp' },
    { id: 'rondoPassingCircle', name: 'Rondo (Passing Circle)', type: 'warmUp' },
    { id: 'miniHurdles', name: 'Mini Hurdles', type: 'warmUp' },
  ],
  coolDown: [
    { id: 'slowJogging', name: 'Slow Jogging', type: 'coolDown' },
    { id: 'staticStretching', name: 'Static Stretching', type: 'coolDown' },
    { id: 'foamRolling', name: 'Foam Rolling', type: 'coolDown' },
    { id: 'breathingExercises', name: 'Breathing Exercises', type: 'coolDown' },
  ],
};

const formatPropertyName = (name) => {
  return name
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export default function ExerciseSelectionPanel({
  isOpen,
  selectedExercises,
  setSelectedExercises,
  setIsPanelOpen,
}) {
  const [localExercises, setLocalExercises] = useState([]);
  const theme = useTheme();
  
  useEffect(() => {
    setLocalExercises(Object.values(allExercises).flat());
  }, []);

  const handleSelect = (exerciseId) => {
    const currentIndex = selectedExercises.findIndex((item) => item.id === exerciseId);
    const newSelectedExercises = [...selectedExercises];

    if (currentIndex === -1) {
      const newExercise = localExercises.find((item) => item.id === exerciseId);
      newSelectedExercises.push(newExercise);
    } else {
      newSelectedExercises.splice(currentIndex, 1);
    }

    setSelectedExercises(newSelectedExercises);
  };

  const selectAll = () => {
    setSelectedExercises(localExercises);
  };

  const removeAll = () => {
    setSelectedExercises([]);
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => setIsPanelOpen(false)}
      variant="persistent"
      ModalProps={{ keepMounted: true }}
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: '#fff',
          color: '#fff',
          borderRadius: '8px 0 0 8px',
          boxShadow: theme.shadows[5],
          transition: 'width 0.3s',
        }
      }}
    >
      <Box sx={{ width: 400, padding: 2 }}>
        <IconButton
          onClick={() => setIsPanelOpen(false)}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            zIndex: 1300,
            backgroundColor: 'white',
            borderRadius: '50%',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" style={{ padding: '16px' }}>
          Exercise List
        </Typography>
        {Object.keys(allExercises).map((category) => (
          <Accordion key={category} sx={{
            backgroundColor: '#356596',
            color: '#fff',
            '& .MuiAccordionSummary-root': {
              backgroundColor: '#3e3e3e',
            }
          }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}>
              <Typography>{formatPropertyName(category)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List style={{ maxHeight: '70vh', overflow: 'auto' }}>
                {allExercises[category].map((exercise) => (
                  <ListItem key={exercise.id} sx={{
                    '&:hover': {
                      backgroundColor: '#3e3e3e',
                    }
                  }}>
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon sx={{ color: '#fff' }} />}
                      checkedIcon={<CheckCircleIcon sx={{ color: '#ffffff' }} />}
                      checked={selectedExercises.some((e) => e.id === exercise.id)}
                      onChange={() => handleSelect(exercise.id)}
                      sx={{
                        color: '#356596',
                        '&.Mui-checked': {
                          color: '#ffffff',
                        }
                      }}
                    />
                    <ListItemText primary={exercise.name} />
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
        <Box display="flex" justifyContent="space-between" p={1}>
          <Button onClick={selectAll} style={{
            transition: 'background-color 0.3s, color 0.3s',
            color: '#356596',
            borderColor: '#ffffff'
          }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#356596';
              e.target.style.color = '#fff';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = 'transparent';
              e.target.style.color = '#356596';
            }}>
            Select All
          </Button>
          <Button onClick={removeAll} style={{
            transition: 'background-color 0.3s, color 0.3s',
            color: '#f44336',
            borderColor: '#ffffff'
          }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#f44336';
              e.target.style.color = '#fff';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = 'transparent';
              e.target.style.color = '#f44336';
            }}>
            Remove All
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
