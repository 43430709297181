// // Custom TrainingList component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Typography, Box, Chip, IconButton, Collapse, TextField, Button
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import BuildIcon from '@mui/icons-material/Build';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { Client, Databases, ID, Query } from 'appwrite';
import conf from '../conf/conf';
import authService from '../appwrite/auth';
import { useSnackbar } from 'notistack';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const ViewTraining = () => {
    const { enqueueSnackbar } = useSnackbar(); 
    const [trainingSessions, setTrainingSessions] = useState([]);
    const [openId, setOpenId] = useState(-1);
    const [selectedChip, setSelectedChip] = useState(null);
    const [noteText, setNoteText] = useState('');
    const [openSections, setOpenSections] = useState({});
    const [chipNotes, setChipNotes] = useState({}); // Store notes for chips

    useEffect(() => {
        const fetchTrainingSessions = async () => {
            try {
                const user = await authService.getCurrentUser();
                if (user) {
                    const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.createTrainingCollectionId, [
                        Query.equal('userID', user.$id)
                    ]);
                    console.log("Fetched Data: ", response.documents);
                    setTrainingSessions(response.documents);
                }
            } catch (error) {
                console.error("Error fetching training sessions:", error);
            }
        };
        fetchTrainingSessions();
    }, []);

    useEffect(() => {
        const fetchChipNotes = async () => {
            try {
                const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteSessionsNotesId);
                const notes = response.documents.reduce((acc, note) => {
                    acc[note.chipID] = note;
                    return acc;
                }, {});
                setChipNotes(notes);
            } catch (error) {
                console.error("Error fetching chip notes:", error);
            }
        };
        fetchChipNotes();
    }, []);

    const handleOpen = (id) => {
        setOpenId(openId === id ? -1 : id);
        setSelectedChip(null);
    };

    const handleChipClick = (chipId, session) => {
        setSelectedChip(selectedChip === chipId ? null : chipId);
        setNoteText(chipNotes[chipId]?.noteText || '');
    };

    const toggleSection = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    const SectionHeader = ({ icon, title, section }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
            {icon}
            <Typography variant="h6" sx={{ marginLeft: 1, flex: 1 }}>
                {title}
            </Typography>
            <IconButton size="small" onClick={() => toggleSection(section)}>
                {openSections[section] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
        </Box>
    );

    const safeParseJSON = (jsonString, defaultValue = []) => {
        if (!jsonString) return defaultValue;
        try {
            return JSON.parse(jsonString);
        } catch (e) {
            console.error("Error parsing JSON:", e, jsonString);
            return defaultValue;
        }
    };

    const renderSessionDetails = (session) => {
        const exercises = safeParseJSON(session.selectedExercises);
        const skills = safeParseJSON(session.selectedSkills);
        const conditioning = safeParseJSON(session.selectedConditioning);
        const specializedTraining = safeParseJSON(session.selectedSpecialized);
        const equipment = safeParseJSON(session.selectedEquip);

        const renderChip = (type, items) => (
            items.length > 0 ? (
                items.map((item, index) => (
                    <Chip
                        key={`${type}-${session.$id}-${index}`}
                        label={item.name}
                        variant="outlined"
                        onClick={() => handleChipClick(`${session.$id}-${type}-${index}`, session)}
                        sx={{
                            backgroundColor: chipNotes[`${session.$id}-${type}-${index}`] ? '#356596' : 'default',
                            color: chipNotes[`${session.$id}-${type}-${index}`] ? '#ffffff' : 'default'
                        }}
                    />
                ))
            ) : (
                <Typography variant="body2" color="textSecondary">No {type} selected</Typography>
            )
        );

        return (
            <Box sx={{ padding: '40px', margin: 2  }}>
                <SectionHeader icon={<EventNoteIcon />} title="General Information" section="generalInfo" />
                <Collapse in={openSections.generalInfo} timeout="auto" unmountOnExit>
                    <Typography variant="body1"><strong>Title:</strong> {session.sessionTitle}</Typography>
                    <Typography variant="body1"><strong>Date:</strong> {new Date(session.sessionDate).toLocaleString()}</Typography>
                    <Typography variant="body1"><strong>Location:</strong> {session.location}</Typography>
                    <Typography variant="body1"><strong>Assistant Coach:</strong> {session.assistingCoach}</Typography>
                </Collapse>

                <SectionHeader icon={<SportsSoccerIcon />} title="Selected Exercises" section="exercises" />
                <Collapse in={openSections.exercises} timeout="auto" unmountOnExit>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {renderChip('exercise', exercises)}
                    </Box>
                </Collapse>

                <SectionHeader icon={<FitnessCenterIcon />} title="Selected Skills" section="skills" />
                <Collapse in={openSections.skills} timeout="auto" unmountOnExit>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {renderChip('skill', skills)}
                    </Box>
                </Collapse>

                <SectionHeader icon={<EmojiEventsIcon />} title="Selected Conditioning" section="conditioning" />
                <Collapse in={openSections.conditioning} timeout="auto" unmountOnExit>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {renderChip('conditioning', conditioning)}
                    </Box>
                </Collapse>

                <SectionHeader icon={<BuildIcon />} title="Selected Specialized Training" section="specializedTraining" />
                <Collapse in={openSections.specializedTraining} timeout="auto" unmountOnExit>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {renderChip('specialized', specializedTraining)}
                    </Box>
                </Collapse>

                <SectionHeader icon={<BuildIcon />} title="Selected Equipment" section="equipment" />
                <Collapse in={openSections.equipment} timeout="auto" unmountOnExit>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {renderChip('equipment', equipment)}
                    </Box>
                </Collapse>

                {selectedChip && renderChipExpander(session, selectedChip)}
            </Box>
        );
    };

    const renderChipExpander = (session, chipId) => {
        const [sessionId, chipType, chipIndex] = chipId.split('-');
        let chipData;

        const exercises = safeParseJSON(session.selectedExercises);
        const skills = safeParseJSON(session.selectedSkills);
        const conditioning = safeParseJSON(session.selectedConditioning);
        const specializedTraining = safeParseJSON(session.selectedSpecialized);
        const equipment = safeParseJSON(session.selectedEquip);

        switch (chipType) {
            case 'exercise':
                chipData = exercises[chipIndex];
                break;
            case 'skill':
                chipData = skills[chipIndex];
                break;
            case 'conditioning':
                chipData = conditioning[chipIndex];
                break;
            case 'specialized':
                chipData = specializedTraining[chipIndex];
                break;
            case 'equipment':
                chipData = equipment[chipIndex];
                break;
            default:
                chipData = {};
        }

        const chipName = chipData ? chipData.name : 'Unknown';

        const closePanel = () => setSelectedChip(null);

        const saveNote = async () => {
            const user = await authService.getCurrentUser();
            const noteData = {
                userID: user.$id,
                userEmail: user.email,
                sessionID: session.$id,
                sessionTitle: session.sessionTitle,
                chipID: chipId,
                chipName: chipName,
                noteText: noteText
            };

            try {
                await databases.createDocument(conf.appwriteDatabaseId, conf.appwriteSessionsNotesId, ID.unique(), noteData);
                setChipNotes(prevNotes => ({
                    ...prevNotes,
                    [chipId]: noteData
                }));
                enqueueSnackbar('Note saved', { variant: 'success' });
            } catch (error) {
                console.error('Error saving note', error);
                enqueueSnackbar('Failed to save note. Please try again.', { variant: 'error' });
            }
        };

        return (
            <div className={`notesPanel ${selectedChip ? 'open' : ''}`}>
                <div className="notesPanelHeader">
                    <span>Notes for {chipName}</span>
                    <span className="notesPanelClose" onClick={closePanel}>X</span>
                </div>
                <Box sx={{ padding: 2 }}>
                    <TextField
                        value={noteText}
                        onChange={(e) => setNoteText(e.target.value)}
                        fullWidth
                        label={`Notes for ${chipName}`}
                        multiline
                        rows={4}
                        variant="outlined"
                    />
                    <Button
                        variant="contained"
                        sx={{ marginTop: 1 }}
                        onClick={saveNote}
                    >
                        Save Notes
                    </Button>
                </Box>
            </div>
        );
    };

    return (
        <Box>
            <Typography variant="h4" sx={{ marginBottom: '20px' }}>Training Sessions</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead style={{ backgroundColor: 'black' }}>
                        <TableRow>
                            <TableCell />
                            <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Session Title</TableCell>
                            <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Session Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Location</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {trainingSessions.map((session, index) => (
                            <React.Fragment key={session.$id}>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                    <TableCell>
                                        <IconButton size="small" onClick={() => handleOpen(index)}>
                                            {openId === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {session.sessionTitle}
                                    </TableCell>
                                    <TableCell>{new Date(session.sessionDate).toLocaleString()}</TableCell>
                                    <TableCell>{session.location}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                        <Collapse in={openId === index} timeout="auto" unmountOnExit>
                                            {renderSessionDetails(session)}
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ViewTraining;
