
// export default PlayerCard;
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme, isSelected, isLocked, isSaved }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: isSaved ? '2px solid red' : (isSelected ? '2px solid green' : '1px solid lightgrey'),
    backgroundColor: isSaved ? 'lightcoral' : (isSelected ? 'lightgreen' : '#fff'),
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const StyledAvatar = styled(Avatar)({
    width: '60px',
    height: '60px',
});

const SelectCircle = styled(CircleIcon)(({ isSelected, isLocked }) => ({
    color: isLocked ? 'red' : (isSelected ? 'green' : 'lightgrey'),
}));

const PlayerInfo = styled(Typography)(({ theme }) => ({
    fontSize: '0.7rem',
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const PlayerName = styled(Typography)(({ theme }) => ({
    fontSize: '0.7rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
    },
}));

function PlayerCard({ player, isSelected, isLocked, onSelect, clickable }) {
    const handleClick = () => {
        if (clickable) {
            onSelect();
        }
    };

    return (
        <StyledCard
            isSelected={isSelected}
            isLocked={isLocked}
            onClick={handleClick}
        >
            <StyledAvatar src={player.image} alt={player.fullName} />
            <CardContent>
                <PlayerName>{player.fullName}</PlayerName>
                <PlayerInfo>{`#${player.jerseyNumber} - ${player.pPosition}`}</PlayerInfo>
            </CardContent>
            <IconButton>
                <SelectCircle isSelected={isSelected} isLocked={isLocked} />
            </IconButton>
        </StyledCard>
    );
}

export default PlayerCard;
