// // MatchDetailsForm.jsx
// import React from 'react';
// import { Box, TextField, Typography, Select, MenuItem, Button } from '@mui/material';

// const MatchDetailsForm = ({
//     matchName, opposition, stadium, duration, session, startingElevenCount,
//     substitutesCount, lineup, notes, selectedDate, formations, handleInputChange,
//     handleDateChange, handleDurationChange, handleContinueClick
// }) => {
//     return (
//         <Box sx={{ width: ['80%', '35%'], p: 2 }}>
//             <Typography variant="h6" sx={{ marginBottom: '5px' }}>Match Details</Typography>
//             <TextField
//                 label="Match Name"
//                 variant="outlined"
//                 fullWidth
//                 size="small" sx={{ marginBottom: '5px',
//                 '& .MuiInputBase-input': {
//                   fontSize: '0.75rem',
//                   padding: '6px 12px'
//                 },
//                 '& .MuiInputBase-root': {
//                   marginTop: '8px',
//                   marginBottom: '8px'
//                 }
//               }}
//                 value={matchName}
//                 onChange={(e) => handleInputChange('matchName', e.target.value)}
//             />
//             <TextField
//                 label="Date and Time"
//                 type="datetime-local"
//                 value={selectedDate}
//                 onChange={(e) => handleDateChange(e.target.value)}
//                 fullWidth
//                 size="small" sx={{ marginBottom: '5px',
//                 '& .MuiInputBase-input': {
//                   fontSize: '0.75rem',
//                   padding: '6px 12px'
//                 },
//                 '& .MuiInputBase-root': {
//                   marginTop: '8px',
//                   marginBottom: '8px'
//                 }
//               }}
//                 InputLabelProps={{
//                     shrink: true,
//                 }}
//             />
//             <TextField
//                 label="Opposition"
//                 variant="outlined"
//                 fullWidth
//                 size="small" sx={{ marginBottom: '5px',
//                 '& .MuiInputBase-input': {
//                   fontSize: '0.75rem',
//                   padding: '6px 12px'
//                 },
//                 '& .MuiInputBase-root': {
//                   marginTop: '8px',
//                   marginBottom: '8px'
//                 }
//               }}
//                 value={opposition}
//                 onChange={(e) => handleInputChange('opposition', e.target.value)}
//             />
//             <TextField
//                 label="Stadium"
//                 variant="outlined"
//                 fullWidth
//                 size="small" sx={{ marginBottom: '5px',
//                 '& .MuiInputBase-input': {
//                   fontSize: '0.75rem',
//                   padding: '6px 12px'
//                 },
//                 '& .MuiInputBase-root': {
//                   marginTop: '8px',
//                   marginBottom: '8px'
//                 }
//               }}
//                 value={stadium}
//                 onChange={(e) => handleInputChange('stadium', e.target.value)}
//             />
//             <TextField
//                 label="Duration (in minutes)"
//                 type="number"
//                 variant="outlined"
//                 fullWidth
//                 size="small" sx={{ marginBottom: '5px',
//                 '& .MuiInputBase-input': {
//                   fontSize: '0.75rem',
//                   padding: '6px 12px'
//                 },
//                 '& .MuiInputBase-root': {
//                   marginTop: '8px',
//                   marginBottom: '8px'
//                 }
//               }}
//                 value={duration}
//                 onChange={handleDurationChange}
//             />
//             <TextField
//                 label="Session"
//                 variant="outlined"
//                 fullWidth
//                 size="small" sx={{ marginBottom: '5px',
//                 '& .MuiInputBase-input': {
//                   fontSize: '0.75rem',
//                   padding: '6px 12px'
//                 },
//                 '& .MuiInputBase-root': {
//                   marginTop: '8px',
//                   marginBottom: '8px'
//                 }
//               }}
//                 value={session}
//                 disabled
//             />
//             <TextField
//                 label="Starting 11"
//                 variant="outlined"
//                 fullWidth
//                 size="small" sx={{ marginBottom: '5px',
//                 '& .MuiInputBase-input': {
//                   fontSize: '0.75rem',
//                   padding: '6px 12px'
//                 },
//                 '& .MuiInputBase-root': {
//                   marginTop: '8px',
//                   marginBottom: '8px'
//                 }
//               }}
//                 value={startingElevenCount}
//                 disabled
//             />
//             <TextField
//                 label="Substitutes"
//                 variant="outlined"
//                 fullWidth
//                 size="small" sx={{ marginBottom: '5px',
//                 '& .MuiInputBase-input': {
//                   fontSize: '0.75rem',
//                   padding: '6px 12px'
//                 },
//                 '& .MuiInputBase-root': {
//                   marginTop: '8px',
//                   marginBottom: '8px'
//                 }
//               }}
//                 value={substitutesCount}
//                 disabled
//             />
//             <Select
//                 label="Lineup"
//                 value={lineup}
//                 onChange={(e) => handleInputChange('lineup', e.target.value)}
//                 fullWidth
//                 size="small" sx={{ marginBottom: '5px',
//                 '& .MuiInputBase-input': {
//                   fontSize: '0.75rem',
//                   padding: '6px 12px'
//                 },
//                 '& .MuiInputBase-root': {
//                   marginTop: '8px',
//                   marginBottom: '8px'
//                 }
//               }}
//                 disabled={startingElevenCount < 11}
//             >
//                 {formations.map((formation, index) => (
//                     <MenuItem key={index} value={formation}>{formation}</MenuItem>
//                 ))}
//             </Select>
//             <TextField
//                 label="Notes"
//                 variant="outlined"
//                 multiline
//                 rows={1}
//                 fullWidth
//                 size="small" sx={{ marginBottom: '5px',
//                 '& .MuiInputBase-input': {
//                   fontSize: '0.75rem',
//                   padding: '6px 12px'
//                 },
//                 '& .MuiInputBase-root': {
//                   marginTop: '8px',
//                   marginBottom: '8px'
//                 }
//               }}
//                 value={notes}
//                 onChange={(e) => handleInputChange('notes', e.target.value)}
//             />
//             <Button variant="contained" style={{ width: '100%' }} size="small" sx={{

// '& .MuiInputBase-input': {
//   fontSize: '0.75rem',
//   padding: '6px 12px'
// },
// '& .MuiInputBase-root': {
//   marginTop: '8px',
//   marginBottom: '8px'
// },
// backgroundColor: '#356596',
// color: '#ffffff',
// '&:hover': {
// backgroundColor: '#ffffff',
// color: '#356596'}
// }} onClick={handleContinueClick}>
//                 Continue
//             </Button>
//         </Box>
//     );
// };

// export default MatchDetailsForm;
// MatchDetailsForm.jsx
import React from 'react';
import { Box, TextField, Typography, Select, MenuItem, Button } from '@mui/material';

const MatchDetailsForm = ({
    matchName, opposition, stadium, duration, session, startingElevenCount,
    substitutesCount, lineup, notes, selectedDate, formations, handleInputChange,
    handleDateChange, handleDurationChange, handleContinueClick
}) => {

  const formatDateTimeLocal = (date) => {
    const d = new Date(date);
    const pad = (n) => n < 10 ? '0' + n : n;
    return `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}T${pad(d.getHours())}:${pad(d.getMinutes())}`;
};
return (
  <Box sx={{ width: ['80%', '35%'], p: 2 }}>
      <Typography variant="h6" sx={{ marginBottom: '5px' }}>Match Details</Typography>
      <TextField
          label="Match Name"
          variant="outlined"
          fullWidth
          size="small" sx={{ marginBottom: '5px',
          '& .MuiInputBase-input': {
            fontSize: '0.75rem',
            padding: '6px 12px'
          },
          '& .MuiInputBase-root': {
            marginTop: '8px',
            marginBottom: '8px'
          }
        }}
          value={matchName}
          onChange={(e) => handleInputChange('matchName', e.target.value)}
      />
      <TextField
          label="Date and Time"
          type="datetime-local"
          value={formatDateTimeLocal(selectedDate)}
          onChange={(e) => handleDateChange(new Date(e.target.value).toISOString())}
          fullWidth
          size="small" sx={{ marginBottom: '5px',
          '& .MuiInputBase-input': {
            fontSize: '0.75rem',
            padding: '6px 12px'
          },
          '& .MuiInputBase-root': {
            marginTop: '8px',
            marginBottom: '8px'
          }
        }}
          InputLabelProps={{
              shrink: true,
          }}
      />
      <TextField
          label="Opposition"
          variant="outlined"
          fullWidth
          size="small" sx={{ marginBottom: '5px',
          '& .MuiInputBase-input': {
            fontSize: '0.75rem',
            padding: '6px 12px'
          },
          '& .MuiInputBase-root': {
            marginTop: '8px',
            marginBottom: '8px'
          }
        }}
          value={opposition}
          onChange={(e) => handleInputChange('opposition', e.target.value)}
      />
      <TextField
          label="Stadium"
          variant="outlined"
          fullWidth
          size="small" sx={{ marginBottom: '5px',
          '& .MuiInputBase-input': {
            fontSize: '0.75rem',
            padding: '6px 12px'
          },
          '& .MuiInputBase-root': {
            marginTop: '8px',
            marginBottom: '8px'
          }
        }}
          value={stadium}
          onChange={(e) => handleInputChange('stadium', e.target.value)}
      />
      <TextField
          label="Duration (in minutes)"
          type="number"
          variant="outlined"
          fullWidth
          size="small" sx={{ marginBottom: '5px',
          '& .MuiInputBase-input': {
            fontSize: '0.75rem',
            padding: '6px 12px'
          },
          '& .MuiInputBase-root': {
            marginTop: '8px',
            marginBottom: '8px'
          }
        }}
          value={duration}
          onChange={handleDurationChange}
      />
      <TextField
          label="Session"
          variant="outlined"
          fullWidth
          size="small" sx={{ marginBottom: '5px',
          '& .MuiInputBase-input': {
            fontSize: '0.75rem',
            padding: '6px 12px'
          },
          '& .MuiInputBase-root': {
            marginTop: '8px',
            marginBottom: '8px'
          }
        }}
          value={session}
          disabled
      />
      <TextField
          label="Starting 11"
          variant="outlined"
          fullWidth
          size="small" sx={{ marginBottom: '5px',
          '& .MuiInputBase-input': {
            fontSize: '0.75rem',
            padding: '6px 12px'
          },
          '& .MuiInputBase-root': {
            marginTop: '8px',
            marginBottom: '8px'
          }
        }}
          value={startingElevenCount}
          disabled
      />
      <TextField
          label="Substitutes"
          variant="outlined"
          fullWidth
          size="small" sx={{ marginBottom: '5px',
          '& .MuiInputBase-input': {
            fontSize: '0.75rem',
            padding: '6px 12px'
          },
          '& .MuiInputBase-root': {
            marginTop: '8px',
            marginBottom: '8px'
          }
        }}
          value={substitutesCount}
          disabled
      />
      <Select
          label="Lineup"
          value={lineup}
          onChange={(e) => handleInputChange('lineup', e.target.value)}
          fullWidth
          size="small" sx={{ marginBottom: '5px',
          '& .MuiInputBase-input': {
            fontSize: '0.75rem',
            padding: '6px 12px'
          },
          '& .MuiInputBase-root': {
            marginTop: '8px',
            marginBottom: '8px'
          }
        }}
          disabled={startingElevenCount < 11}
      >
          {formations.map((formation, index) => (
              <MenuItem key={index} value={formation}>{formation}</MenuItem>
          ))}
      </Select>
      <TextField
          label="Notes"
          variant="outlined"
          multiline
          rows={1}
          fullWidth
          size="small" sx={{ marginBottom: '5px',
          '& .MuiInputBase-input': {
            fontSize: '0.75rem',
            padding: '6px 12px'
          },
          '& .MuiInputBase-root': {
            marginTop: '8px',
            marginBottom: '8px'
          }
        }}
          value={notes}
          onChange={(e) => handleInputChange('notes', e.target.value)}
      />
      <Button variant="contained" style={{ width: '100%' }} size="small" sx={{

'& .MuiInputBase-input': {
fontSize: '0.75rem',
padding: '6px 12px'
},
'& .MuiInputBase-root': {
marginTop: '8px',
marginBottom: '8px'
},
backgroundColor: '#356596',
color: '#ffffff',
'&:hover': {
backgroundColor: '#ffffff',
color: '#356596'}
}} onClick={handleContinueClick}>
          Continue
      </Button>
  </Box>
);
};

export default MatchDetailsForm;