// // Custom ClubList component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState, useEffect } from 'react';
import {
    TextField, Box, Collapse, IconButton, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Typography, Grid, Divider, Pagination,
    Button, Drawer
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Client, Databases, Query } from 'appwrite';
import conf from '../conf/conf';
import ClubDetailCard from '../components/ClubDetailCard';
import authService from '../appwrite/auth';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

export default function ListClubs() {
    const [clubs, setClubs] = useState([]);
    const [open, setOpen] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [user, setUser] = useState(null);
    const rowsPerPage = 9;

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const currentUser = await authService.getCurrentUser();
                if (currentUser) {
                    setUser(currentUser);
                    fetchClubs(currentUser.$id);
                } else {
                    window.location.href = '/login';
                }
            } catch (error) {
                console.error('Error fetching user:', error);
                window.location.href = '/login';
            }
        };

        const fetchClubs = async (userId) => {
            try {
                const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.createClubCollectionId, [
                    Query.equal('userID', userId)
                ]);
                const data = response.documents.map((doc) => ({
                    id: doc.$id,
                    teamname: doc.teamname,
                    nickname: doc.nickname,
                    homestadium: doc.homestadium,
                    capacity: doc.capacity,
                    country: doc.country,
                    league: doc.league,
                    foundedyear: doc.foundedyear,
                    owner: doc.owner,
                    manager: doc.manager,
                    headcoach: doc.headcoach,
                    primaryColor: doc.primaryColor,
                    secondaryColor: doc.secondaryColor,
                    tertiaryColor: doc.tertiaryColor,
                    emblem: doc.emblem ? doc.emblem : '',
                    addressline1: doc.addressline1,
                    addressline2: doc.addressline2,
                    phone: doc.phone,
                    email: doc.email,
                    officialweb: doc.officialweb,
                    userEmail: doc.userEmail
                }));
                setClubs(data);
                console.log('Fetched clubs:', data);
            } catch (error) {
                console.error('Error fetching clubs:', error);
            }
        };

        fetchUser();
    }, []);

    const handleOpen = (id) => {
        setOpen(prev => ({ ...prev, [id]: !prev[id] }));
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const indexOfLastClub = currentPage * rowsPerPage;
    const indexOfFirstClub = indexOfLastClub - rowsPerPage;
    const currentClubs = clubs.slice(indexOfFirstClub, indexOfLastClub);

    if (!user) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Box sx={{ padding: '20px' }}>
            <Typography variant="h4" sx={{ marginBottom: '20px' }}>Team List</Typography>
            <Button 
                variant="contained" 
                color="primary" 
                onClick={handleDrawerOpen}
                sx={{ marginBottom: '20px', backgroundColor: '#356596', color: '#ffffff', '&:hover': { backgroundColor: '#ffffff', color: '#356596' }}}
            >
                Details
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead style={{ backgroundColor: 'black' }}>
                        <TableRow>
                            <TableCell />
                            <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Team Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Nickname</TableCell>
                            <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Home Stadium</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentClubs.map((club) => (
                            <React.Fragment key={club.id}>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                    <TableCell>
                                        <IconButton size="small" onClick={() => handleOpen(club.id)}>
                                            {open[club.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{club.teamname}</TableCell>
                                    <TableCell>{club.nickname}</TableCell>
                                    <TableCell>{club.homestadium}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                        <Collapse in={open[club.id]} timeout="auto" unmountOnExit sx={{ backgroundColor: '#f2f2f2', padding: '10px' }}>
                                            <Box margin={1}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12} md={2}>
                                                        {club.emblem ? (
                                                            <img src={club.emblem} alt={club.teamname} style={{ width: '100%', maxWidth: '200px', marginRight: '10px'}} onError={(e) => console.error('Image Load Error:', e)} />
                                                        ) : (
                                                            <Typography variant="body2" color="textSecondary">No Emblem</Typography>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} md={10}>
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Email"
                                                                    value={club.email}
                                                                    InputProps={{ readOnly: true }}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Phone"
                                                                    value={club.phone}
                                                                    InputProps={{ readOnly: true }}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Founded Year"
                                                                    value={club.foundedyear}
                                                                    InputProps={{ readOnly: true }}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Divider style={{ margin: '10px 0' }} />

                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="League"
                                                                    value={club.league}
                                                                    InputProps={{ readOnly: true }}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Manager"
                                                                    value={club.manager}
                                                                    InputProps={{ readOnly: true }}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Owner"
                                                                    value={club.owner}
                                                                    InputProps={{ readOnly: true }}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Divider style={{ margin: '10px 0' }} />

                                                        <TextField
                                                            label="Address"
                                                            value={`${club.addressline1}, ${club.addressline2}`}
                                                            InputProps={{ readOnly: true }}
                                                            margin="normal"
                                                            fullWidth
                                                            multiline
                                                            rows={2}
                                                            variant="outlined"
                                                        />
                                                        <Divider style={{ margin: '10px 0' }} />

                                                        <TextField
                                                            label="Official Website"
                                                            value={club.officialweb}
                                                            InputProps={{ readOnly: true }}
                                                            margin="normal"
                                                            fullWidth
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination
                    count={Math.ceil(clubs.length / rowsPerPage)}
                    page={currentPage}
                    onChange={(event, page) => setCurrentPage(page)}
                />
            </Box>
            <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
                {user && <ClubDetailCard user={user} clubs={clubs} />}
            </Drawer>
        </Box>
    );
}
