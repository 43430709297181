import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import authService from '../appwrite/auth';
import { useSnackbar } from 'notistack';

const Login = ({ onBack }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar()

    const handleLogin = async () => {
        try {
            await authService.login({email, password});
            enqueueSnackbar('Login successful', { variant: 'success' });
            navigate('/dashboard');
        } catch (err) {
            setError('Login failed: ' + err.message);
            enqueueSnackbar('Login failed: ' + err.message, { variant: 'error' });
        }
    };

    
    return (
        <Box
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 3,
                borderRadius: 2,
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                transition: 'box-shadow 0.3s ease-in-out',
      '&:hover': {
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.9)'
      }
            }}
        >
            <TextField label="Email" variant="outlined" fullWidth value={email}
                onChange={(e) => setEmail(e.target.value)} size="small" sx={{ marginBottom: '10px',
                '& .MuiInputBase-input': {
                  fontSize: '0.75rem',
                  padding: '6px 12px'
                },
                '& .MuiInputBase-root': {
                  marginTop: '8px',
                  marginBottom: '8px'
                }
              }}/>
            <TextField label="Password" type="password" variant="outlined" fullWidth value={password}
                onChange={(e) => setPassword(e.target.value)} size="small" sx={{ marginBottom: '10px',
                '& .MuiInputBase-input': {
                  fontSize: '0.75rem',
                  padding: '6px 12px'
                },
                '& .MuiInputBase-root': {
                  marginTop: '8px',
                  marginBottom: '8px'
                }
              }}/>
            <Button variant="contained" style={{ width: '100%' }} size="small" sx={{

'& .MuiInputBase-input': {
  fontSize: '0.75rem',
  padding: '6px 12px'
},
'& .MuiInputBase-root': {
  marginTop: '8px',
  marginBottom: '8px'
},
backgroundColor: '#356596',
color: '#ffffff',
'&:hover': {
backgroundColor: '#ffffff',
color: '#356596'}
}}
                onClick={handleLogin}
            >Login</Button>
            {error && (
                <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
                    {error}
                </Typography>
            )}
            <Typography sx={{ mt: 2, textAlign: 'center' }}>
                Don't have an account? <span style={{ color: 'primary.main', cursor: 'pointer' }} onClick={onBack}>Click here to Register</span>
            </Typography>
        </Box>
    );
}

export default Login;


