// // Custom MatchEventDetails component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState, useEffect } from 'react';
import { Box, Card, Typography, TextField, Button, MenuItem, RadioGroup, FormControlLabel, Radio, Chip } from '@mui/material';
import { Client, Databases, Query } from 'appwrite';
import conf from '../conf/conf';
import authService from '../appwrite/auth';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const MatchEventDetails = ({ time, period, matchID, details, setDetails, onClose }) => {
    const [teamPlayers, setTeamPlayers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredPlayers, setFilteredPlayers] = useState([]);
    const reasonOptions = ["Chance for", "Chance against", "Fault for", "Fault against", "Corner for", "Corner against"];

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            height: '30px'
        },
        '& .MuiSelect-select': {
            height: '30px',
            display: 'flex',
            alignItems: 'center'
        }
    };

    useEffect(() => {
        const fetchPlayers = async () => {
            try {
                const currentUser = await authService.getCurrentUser();
                if (!currentUser) {
                    throw new Error('No user is currently logged in.');
                }
                const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.createSquadCollectionId, [
                    Query.equal('userID', currentUser.$id),
                ]);
                const players = response.documents.flatMap(doc => [...doc.startingEleven.map(player => JSON.parse(player)), ...doc.substitutes.map(player => JSON.parse(player))]);
                setTeamPlayers(players);
            } catch (error) {
                console.error('Error fetching players:', error);
            }
        };

        fetchPlayers();
    }, []);

    useEffect(() => {
        if (searchQuery.length >= 3) {
            setFilteredPlayers(
                teamPlayers.filter(player => player.fullName && player.fullName.toLowerCase().includes(searchQuery.toLowerCase()))
            );
        } else {
            setFilteredPlayers([]);
        }
    }, [searchQuery, teamPlayers]);

    const handlePlayerSelect = (player) => {
        setDetails(prevDetails => ({ ...prevDetails, selectedPlayer: player }));
        setSearchQuery('');
        setFilteredPlayers([]);
    };

    const handleSave = async () => {
        try {
            const currentUser = await authService.getCurrentUser();
            const data = {
                matchID,
                userID: currentUser.$id,
                userEmail: currentUser.email,
                eventType: details.eventType,
                selectedPlayer: details.selectedPlayer ? JSON.stringify(details.selectedPlayer) : null,
                oppositionPlayer: details.oppositionPlayer,
                reason: details.reason,
                period,
                minute: time,
                comment: details.comment,
            };
            await databases.createDocument(conf.appwriteDatabaseId, conf.matchEventsCollectionId, 'unique()', data);
            onClose();
        } catch (error) {
            console.error('Error saving match event:', error);
        }
    };

    return (
        <Card sx={{ padding: 2, width: '100%', boxSizing: 'border-box' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">Capture Match Event</Typography>
                <Typography variant="body1">| Period: <strong>{period}</strong> | Minute: <strong>{time}</strong></Typography>
            </Box>
            <RadioGroup row value={details.eventType} onChange={(e) => setDetails(prevDetails => ({ ...prevDetails, eventType: e.target.value }))}>
                <FormControlLabel value="decisive_save" control={<Radio />} label="Decisive Save" />
                <FormControlLabel value="match_event" control={<Radio />} label="Match Event" />
            </RadioGroup>
            {details.eventType === 'decisive_save' ? (
                <>
                    <Typography variant="subtitle1">Opposition Player</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Opposition Player"
                        margin="normal"
                        sx={textFieldStyle}
                        InputProps={{ style: { height: '30px' } }}
                        value={details.oppositionPlayer}
                        onChange={(e) => setDetails(prevDetails => ({ ...prevDetails, oppositionPlayer: e.target.value }))}
                    />
                    <Typography variant="subtitle1">Team Player</Typography>
                    <TextField
                        fullWidth
                        placeholder="Search Team Player"
                        margin="normal"
                        sx={textFieldStyle}
                        InputProps={{ style: { height: '30px' } }}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        autoComplete="off"
                    />
                    {filteredPlayers.length > 0 && (
                        <Box sx={{ maxHeight: '150px', overflowY: 'auto', border: '1px solid #ccc', marginTop: '5px' }}>
                            {filteredPlayers.map((player, index) => (
                                <Box key={index} sx={{ padding: '5px', cursor: 'pointer' }} onClick={() => handlePlayerSelect(player)}>
                                    {`${player.fullName} | No ${player.jerseyNumber} | ${player.pposition}`}
                                </Box>
                            ))}
                        </Box>
                    )}
                    {details.selectedPlayer && (
                        <Box sx={{ mt: 2 }}>
                            <Chip label={`${details.selectedPlayer.fullName} | No ${details.selectedPlayer.jerseyNumber} | ${details.selectedPlayer.pposition}`} />
                        </Box>
                    )}
                </>
            ) : (
                <>
                    <Typography variant="subtitle1">Reason</Typography>
                    <TextField
                        select
                        fullWidth
                        margin="normal"
                        sx={textFieldStyle}
                        SelectProps={{ style: { height: '30px' } }}
                        value={details.reason || ''}
                        onChange={(e) => setDetails(prevDetails => ({ ...prevDetails, reason: e.target.value }))}
                    >
                        {reasonOptions.map(option => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </>
            )}
            <TextField
                fullWidth
                label="Comment"
                multiline
                rows={3}
                margin="normal"
                sx={{ '& .MuiInputBase-root': { height: 'auto' } }}
                value={details.comment}
                onChange={(e) => setDetails(prevDetails => ({ ...prevDetails, comment: e.target.value }))}
            />
            <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
        </Card>
    );
};

export default MatchEventDetails;


