import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Client, Databases, ID } from 'appwrite';
import conf from '../conf/conf';
import authService from '../appwrite/auth';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const Register = ({ onBack }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [firstName, setFirstName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [role, setRole] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const saveUserData = async (userId, name, email, role) => {
        try {
            const response = await databases.createDocument(
                conf.appwriteDatabaseId, // Database ID
                conf.appwriteUsersId, // Collection ID
                ID.unique(), // Document ID
                {
                    userid: userId,
                    username: name,
                    userEmail: email,
                    defaultClub: '', // Assuming this will be set elsewhere
                    roleType: role
                }
            );
            console.log('User data saved successfully:', response);
        } catch (error) {
            console.error('Failed to save user data:', error);
            throw new Error('Failed to save user data');
        }
    };

    const handleRegister = async () => {
        if (!termsAccepted) {
            setError("You must accept the terms of use.");
            enqueueSnackbar('You must accept the terms of use.', { variant: 'warning' });
            return;
        }
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            enqueueSnackbar('Passwords do not match.', { variant: 'error' });
            return;
        }
        try {
            setLoading(true);
            setError('');
            const response = await authService.createAccount({ email, password, name: firstName, surname });
            console.log('Registration successful', response);
            
            // Fetch the user data after successful registration
            const user = await authService.getCurrentUser();
            
            // Save the additional user data to the users collection
            await saveUserData(user.$id, `${firstName} ${surname}`, email, role);
            
            enqueueSnackbar('Registration successful!', { variant: 'success' });
            navigate('/dashboard');
        } catch (error) {
            console.error("Registration failed: ", error);
            setError(error.message);
            enqueueSnackbar('Registration failed: ' + error.message, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 3,
                borderRadius: 2,
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                transition: 'box-shadow 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.9)'
                }
            }}
        >
            <Typography variant="h6">Register</Typography>
            <TextField label="First Name" variant="outlined" fullWidth value={firstName} onChange={(e) => setFirstName(e.target.value)} size="small" sx={{ marginBottom: '10px',
                '& .MuiInputBase-input': {
                  fontSize: '0.75rem',
                  padding: '6px 12px'
                },
                '& .MuiInputBase-root': {
                  marginTop: '8px',
                  marginBottom: '8px'
                }
              }}/>
            <TextField label="Surname" variant="outlined" fullWidth value={surname} onChange={(e) => setSurname(e.target.value)} size="small" sx={{ marginBottom: '10px',
                '& .MuiInputBase-input': {
                  fontSize: '0.75rem',
                  padding: '6px 12px'
                },
                '& .MuiInputBase-root': {
                  marginTop: '8px',
                  marginBottom: '8px'
                }
              }}/>
            <TextField label="Email" variant="outlined" fullWidth value={email} onChange={(e) => setEmail(e.target.value)} size="small" sx={{ marginBottom: '10px',
                '& .MuiInputBase-input': {
                  fontSize: '0.75rem',
                  padding: '6px 12px'
                },
                '& .MuiInputBase-root': {
                  marginTop: '8px',
                  marginBottom: '8px'
                }
              }}/>
            <TextField label="Password" variant="outlined" fullWidth type="password" value={password} onChange={(e) => setPassword(e.target.value)} size="small" sx={{ marginBottom: '10px',
                '& .MuiInputBase-input': {
                  fontSize: '0.75rem',
                  padding: '6px 12px'
                },
                '& .MuiInputBase-root': {
                  marginTop: '8px',
                  marginBottom: '8px'
                }
              }}/>
            <TextField label="Password (confirm)" variant="outlined" fullWidth type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} size="small" sx={{ marginBottom: '10px',
                '& .MuiInputBase-input': {
                  fontSize: '0.75rem',
                  padding: '6px 12px'
                },
                '& .MuiInputBase-root': {
                  marginTop: '8px',
                  marginBottom: '8px'
                }
              }}/>
            <FormControl fullWidth>
                <InputLabel>Role</InputLabel>
                <Select
                    value={role}
                    label="Role"
                    onChange={(e) => setRole(e.target.value)}
                    size="small" sx={{ marginBottom: '10px',
                '& .MuiInputBase-input': {
                  fontSize: '0.75rem',
                  padding: '6px 8px'
                },
                '& .MuiInputBase-root': {
                  marginTop: '4px',
                  marginBottom: '12px'
                }
              }}
                >
                    <MenuItem value="Coach">Coach</MenuItem>
                    <MenuItem value="Player">Player</MenuItem>
                    <MenuItem value="ClubAdmin">Club Admin</MenuItem>
                    <MenuItem value="ClubManager">Club Manager</MenuItem>
                    <MenuItem value="ClubDr">Club Doctor</MenuItem>
                </Select>
            </FormControl>
            <FormControlLabel
                control={<Checkbox checked={termsAccepted} onChange={(e) => setTermsAccepted(e.target.checked)} />}
                label="I accept terms of use"
            />
            {error && (
                <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
                    {error}
                </Typography>
            )}
            <Button
                variant="contained"
                style={{ width: '100%' }} size="small" sx={{

                    '& .MuiInputBase-input': {
                      fontSize: '0.75rem',
                      padding: '6px 12px'
                    },
                    '& .MuiInputBase-root': {
                      marginTop: '8px',
                      marginBottom: '8px'
                    },
                    backgroundColor: '#356596',
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: '#ffffff',
                  color: '#356596'}
                  }}
                onClick={handleRegister}
                disabled={loading}
            >
                {loading ? "Registering..." : "Register"}
            </Button>
            <Typography sx={{ mt: 2, textAlign: 'center' }}>
                Already have an account? <span style={{ color: 'primary.main', cursor: 'pointer' }} onClick={onBack}>Log in</span>
            </Typography>
        </Box>
    );
};

export default Register;
