// // Custom CreateEvent component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState, useEffect } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Client, Databases, ID } from 'appwrite';
import conf from '../conf/conf';
import authService from '../appwrite/auth';

const client = new Client()
  .setEndpoint(conf.appwriteUrl)
  .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const CreateEvent = ({ selectedDay, onClose, addEventToCalendar }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formState, setFormState] = useState({
    emailTo: '',
    emailCC: '',
    eventTitle: '',
    eventDetails: '',
  });
  const [clubID, setClubID] = useState('');

  useEffect(() => {
    const fetchClubID = async () => {
      try {
        const user = await authService.getCurrentUser();
        if (user) {
          const userDoc = await databases.getDocument(conf.appwriteDatabaseId, conf.appwriteUsersCollectionId, user.$id);
          setClubID(userDoc.clubID);
        }
      } catch (error) {
        console.error('Failed to fetch club ID:', error);
      }
    };

    fetchClubID();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = async (sendEmail) => {
    try {
      const user = await authService.getCurrentUser();
      if (!user) {
        throw new Error('User not authenticated');
      }

      const eventData = {
        clubID: clubID,
        eventDetails: formState.eventDetails,
        eventDate: selectedDay.toISOString(),
        userId: user.$id,
        userEmail: user.email,
        eventTitle: formState.eventTitle,
        emailTo: formState.emailTo,
        emailCC: formState.emailCC,
      };

      const document = await databases.createDocument(conf.appwriteDatabaseId, conf.appwriteClubEventsCollectionId, ID.unique(), eventData);

      if (sendEmail && (formState.emailTo || formState.emailCC)) {
      }

      enqueueSnackbar('Event saved successfully!', { variant: 'success' });
      addEventToCalendar({
        id: document.$id,
        title: formState.eventTitle,
        start: new Date(selectedDay),
        end: new Date(selectedDay),
        type: 'event',
        eventDetails: formState.eventDetails,
        eventDate: selectedDay.toISOString(),
        emailTo: formState.emailTo,
        emailCC: formState.emailCC
      });
      onClose();
    } catch (error) {
      console.error('Failed to save event:', error);
      enqueueSnackbar('Failed to save event', { variant: 'error' });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <TextField
        label="Email To"
        name="emailTo"
        value={formState.emailTo}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Email CC"
        name="emailCC"
        value={formState.emailCC}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Event Title"
        name="eventTitle"
        value={formState.eventTitle}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Event Details"
        name="eventDetails"
        value={formState.eventDetails}
        onChange={handleInputChange}
        multiline
        rows={4}
        fullWidth
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Button variant="contained" onClick={() => handleSave(false)}>
          Save
        </Button>
        <Button variant="contained" color="primary" onClick={() => handleSave(true)}>
          Save and Send
        </Button>
      </Box>
    </Box>
  );
};

export default CreateEvent;
