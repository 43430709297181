import React, { useState, useEffect } from 'react';
import {
    Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Typography, Box, Chip, IconButton, Collapse, TextField, Button, Drawer
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowDown';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import BuildIcon from '@mui/icons-material/Build';
import EventNoteIcon from '@mui/icons-material/EventNote';

const mockTrainingSessions = [
    {
        TrainingSessionID: 1,
        sessionTitle: "Morning Training",
        sessionDate: "2024-05-20T08:00:00",
        location: "Field A",
        assistingCoach: "John Doe",
        exercises: JSON.stringify([{ id: '1', name: 'Warm Up' }, { id: '2', name: 'Dribbling' }]),
        skills: JSON.stringify([{ id: '1', name: 'Passing' }, { id: '2', name: 'Shooting' }]),
        conditioning: JSON.stringify([{ id: '1', name: 'Endurance Running' }, { id: '2', name: 'Interval Sprints' }]),
        specializedTraining: JSON.stringify([{ id: '1', name: 'Goalkeeping' }, { id: '2', name: 'Defensive Skills' }]),
        equipment: JSON.stringify([{ id: '1', name: 'Cones' }, { id: '2', name: 'Balls' }])
    },
    // ...more sessions
];

const ViewTraining = () => {
    const [trainingSessions, setTrainingSessions] = useState([]);
    const [openId, setOpenId] = useState(-1); // For expandable rows
    const [selectedChip, setSelectedChip] = useState(null); // For chip expanders
    const [noteText, setNoteText] = useState('');

    useEffect(() => {
        // Mock data for training sessions
        setTrainingSessions(mockTrainingSessions);
    }, []);

    const handleOpen = (id) => {
        setOpenId(openId === id ? -1 : id);
        setSelectedChip(null); // Reset chip selection when opening a different session
    };

    const handleChipClick = (chipId) => {
        setSelectedChip(selectedChip === chipId ? null : chipId);
    };

    const SectionHeader = ({ icon, title }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
            {icon}
            <Typography variant="h6" sx={{ marginLeft: 1 }}>
                {title}
            </Typography>
        </Box>
    );

    const renderSessionDetails = (session) => {
        const safeParseJSON = (jsonString) => {
            try {
                return JSON.parse(jsonString);
            } catch (e) {
                console.error("Error parsing JSON:", e);
                return []; // Return an empty array in case of invalid JSON
            }
        };

        // Parse the JSON strings
        const exercises = safeParseJSON(session.exercises);
        const skills = safeParseJSON(session.skills);
        const conditioning = safeParseJSON(session.conditioning);
        const specializedTraining = safeParseJSON(session.specializedTraining);
        const equipment = safeParseJSON(session.equipment);

        return (
            <Box sx={{ margin: 2 }}>
                <Card sx={{ marginBottom: 2 }}>
                    <CardContent>
                        <SectionHeader icon={<EventNoteIcon />} title="General Information" />
                        <Typography variant="body1"><strong>Title:</strong> {session.sessionTitle}</Typography>
                        <Typography variant="body1"><strong>Date:</strong> {new Date(session.sessionDate).toLocaleString()}</Typography>
                        <Typography variant="body1"><strong>Location:</strong> {session.location}</Typography>
                        <Typography variant="body1"><strong>Assistant Coach:</strong> {session.assistingCoach}</Typography>
                    </CardContent>
                </Card>

                <Card sx={{ marginBottom: 2 }}>
                    <CardContent>
                        <SectionHeader icon={<SportsSoccerIcon />} title="Selected Exercises" />
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1, marginBottom: 1 }}>
                            {exercises.map((exercise, index) => (
                                <Chip key={`exercise-${session.TrainingSessionID}-${index}`} label={exercise.name} variant="outlined" onClick={() => handleChipClick(`exercise-${index}`)} />
                            ))}
                        </Box>
                    </CardContent>
                </Card>

                <Card sx={{ marginBottom: 2 }}>
                    <CardContent>
                        <SectionHeader icon={<FitnessCenterIcon />} title="Selected Skills" />
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1, marginBottom: 1 }}>
                            {skills.map((skill, index) => (
                                <Chip key={`skill-${session.TrainingSessionID}-${index}`} label={skill.name} variant="outlined" onClick={() => handleChipClick(`skill-${index}`)} />
                            ))}
                        </Box>
                    </CardContent>
                </Card>

                <Card sx={{ marginBottom: 2 }}>
                    <CardContent>
                        <SectionHeader icon={<EmojiEventsIcon />} title="Selected Conditioning" />
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1, marginBottom: 1 }}>
                            {conditioning.map((conditioningItem, index) => (
                                <Chip key={`conditioning-${session.TrainingSessionID}-${index}`} label={conditioningItem.name} variant="outlined" onClick={() => handleChipClick(`conditioning-${index}`)} />
                            ))}
                        </Box>
                    </CardContent>
                </Card>

                <Card sx={{ marginBottom: 2 }}>
                    <CardContent>
                        <SectionHeader icon={<BuildIcon />} title="Selected Specialized Training" />
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1, marginBottom: 1 }}>
                            {specializedTraining.map((specialized, index) => (
                                <Chip key={`specialized-${session.TrainingSessionID}-${index}`} label={specialized.name} variant="outlined" onClick={() => handleChipClick(`specialized-${index}`)} />
                            ))}
                        </Box>
                    </CardContent>
                </Card>

                <Card sx={{ marginBottom: 2 }}>
                    <CardContent>
                        <SectionHeader icon={<BuildIcon />} title="Selected Equipment" />
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1, marginBottom: 1 }}>
                            {equipment.map((equip, index) => (
                                <Chip key={`equipment-${session.TrainingSessionID}-${index}`} label={equip.name} variant="outlined" onClick={() => handleChipClick(`equipment-${index}`)} />
                            ))}
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        );
    };

    const renderChipExpander = (session, chipId) => {
        const [chipType, chipIndex] = chipId.split('-');
        let chipData;

        const safeParseJSON = (jsonString) => {
            try {
                return JSON.parse(jsonString);
            } catch (e) {
                return []; // In case of invalid JSON, return empty array
            }
        };

        const exercises = safeParseJSON(session.exercises);
        const skills = safeParseJSON(session.skills);
        const conditioning = safeParseJSON(session.conditioning);
        const specializedTraining = safeParseJSON(session.specializedTraining);
        const equipment = safeParseJSON(session.equipment);

        switch (chipType) {
            case 'exercise':
                chipData = exercises[chipIndex];
                break;
            case 'skill':
                chipData = skills[chipIndex];
                break;
            case 'conditioning':
                chipData = conditioning[chipIndex];
                break;
            case 'specialized':
                chipData = specializedTraining[chipIndex];
                break;
            case 'equipment':
                chipData = equipment[chipIndex];
                break;
            default:
                chipData = {};
        }

        const chipName = chipData ? chipData.name : 'Unknown';
        const closePanel = () => setSelectedChip(null);

        return (
            <Drawer
                anchor="right"
                open={Boolean(selectedChip)}
                onClose={closePanel}
                variant="persistent"
                PaperProps={{ style: { width: 300 } }}
            >
                <Box sx={{ padding: 2 }}>
                    <Typography variant="h6">Notes for {chipName}</Typography>
                    <IconButton
                        onClick={closePanel}
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            backgroundColor: 'white',
                            borderRadius: '50%'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <TextField
                        value={noteText}
                        onChange={(e) => setNoteText(e.target.value)}
                        fullWidth
                        label={`Notes for ${chipData.name}`}
                        multiline
                        rows={4}
                        variant="outlined"
                        sx={{
                            '& .MuiInputBase-input': {
                              fontSize: '0.75rem',
                              padding: '6px 12px',
                              fontFamily: 'Roboto'
                            }
                        }}
                    />
                    <Button
                        variant="contained"
                        sx={{ marginTop: 1 }}
                        onClick={() => alert("Save Note functionality here.")}
                    >
                        Save Notes
                    </Button>
                </Box>
            </Drawer>
        );
    };

    return (
        <Box sx={{ width: 600 }}>
            <Typography variant="h4" sx={{ marginBottom: '20px' }}>Training Sessions</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead style={{ backgroundColor: '#356596' }}>
                        <TableRow>
                            <TableCell />
                            <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Session Title</TableCell>
                            <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Session Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Location</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {trainingSessions.map((session, index) => (
                            <React.Fragment key={session.TrainingSessionID}>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                    <TableCell>
                                        <IconButton size="small" onClick={() => handleOpen(index)}>
                                            {openId === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {session.sessionTitle}
                                    </TableCell>
                                    <TableCell>{new Date(session.sessionDate).toLocaleString()}</TableCell>
                                    <TableCell>{session.location}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                                        <Collapse in={openId === index} timeout="auto" unmountOnExit>
                                            {renderSessionDetails(session)}
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedChip && renderChipExpander(trainingSessions[openId], selectedChip)}
        </Box>
    );
};

export default ViewTraining;
