// // // Custom DashBoard component
// // // Created by Shamiel Speelman
// // // Version: 0.1
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import SidebarMenu from '../components/SidebarMenu';
import TopNav from '../components/TopNav';
import CookiesCard from './AcceptCookies';
import authService from '../appwrite/auth';
import { Client, Databases, Query } from 'appwrite';
import conf from '../conf/conf';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const Dashboard = () => {
  const [isLocked, setIsLocked] = useState(false);
  const [isHovered] = useState(false);
  const [showCookies, setShowCookies] = useState(false);
  const [user, setUser] = useState(null);
  const [clubs, setClubs] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await authService.getCurrentUser();
        if (!currentUser) {
          window.location.href = '/login';
        } else {
          setUser(currentUser);
          fetchClubs(currentUser.$id);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
        window.location.href = '/login';
      }
    };

    const fetchClubs = async (userId) => {
      try {
        const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.createClubCollectionId, [
          Query.equal('userID', userId)
        ]);
        const data = response.documents.map((doc) => ({
          id: doc.$id,
          teamname: doc.teamname,
          nickname: doc.nickname,
          homestadium: doc.homestadium,
          capacity: doc.capacity,
          country: doc.country,
          league: doc.league,
          foundedyear: doc.foundedyear,
          owner: doc.owner,
          manager: doc.manager,
          headcoach: doc.headcoach,
          primaryColor: doc.primaryColor,
          secondaryColor: doc.secondaryColor,
          tertiaryColor: doc.tertiaryColor,
          emblem: doc.emblem ? doc.emblem : '',
          addressline1: doc.addressline1,
          addressline2: doc.addressline2,
          phone: doc.phone,
          email: doc.email,
          officialweb: doc.officialweb,
          userEmail: doc.userEmail
        }));
        setClubs(data);
      } catch (error) {
        console.error('Error fetching clubs:', error);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted !== 'true') {
      setShowCookies(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowCookies(false);
  };

  const handleReject = () => {
    setShowCookies(false);
  };

  const mainContentStyle = isLocked ? { marginLeft: '200px', padding: '10px' } : { marginLeft: '0px', padding: '10px' };

  const handleLogout = async () => {
    try {
      await authService.logout();
      window.location.href = '/';
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div style={{ minHeight: '90vh', display: 'flex', flexDirection: 'column', overflowX: 'hidden', overflowY: 'hidden' }}>
      <TopNav user={user} clubs={clubs} handleLogout={handleLogout} />
      <div style={{ display: 'flex', flexGrow: 1 }}>
        <SidebarMenu isLocked={isLocked} setIsLocked={setIsLocked} isHovered={isHovered} handleLogout={handleLogout} />
        <Box sx={{ flexGrow: 1, ...mainContentStyle }}>
          <Outlet />
        </Box>
      </div>
      {showCookies && <CookiesCard onAccept={handleAccept} onReject={handleReject} />}
    </div>
  );
};

export default Dashboard;

