// // Custom CreateSquad component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import PlayerSelection from '../components/custome_components/PlayerSelection';
import MatchDetailsForm from '../components/custome_components/MatchDetailsForm';
import SoccerPitchDrawer from '../components/custome_components/SoccerPitchDrawer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import authService from '../appwrite/auth';
import { Client, Databases, Query, ID } from 'appwrite';
import conf from '../conf/conf';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const createInitialPlayers = (jerseyType, playersData) => {
    return playersData.map(player => ({
        ...player,
        selected: false,
        jerseyType: jerseyType
    }));
};

const CreateSquad = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [currentJerseyType, setCurrentJerseyType] = useState('striped');
    const [duration, setDuration] = useState('');
    const [session, setSession] = useState('');
    const [playersStriped, setPlayersStriped] = useState([]);
    const [playersPlain, setPlayersPlain] = useState([]);
    const [lineup, setLineup] = useState('');
    const [lineupFullDialogOpen, setLineupFullDialogOpen] = useState(false);
    const [startingElevenCount, setStartingElevenCount] = useState(0);
    const [substitutesCount, setSubstitutesCount] = useState(0);
    const [drawerCollapsed, setDrawerCollapsed] = useState(false);
    const [showPitch, setShowPitch] = useState(false);
    const [matchName, setMatchName] = useState('');
    const [opposition, setOpposition] = useState('');
    const [stadium, setStadium] = useState('');
    const [notes, setNotes] = useState('');
    const [lastDocumentId, setLastDocumentId] = useState('');

    const formations = [
        "1-3-5-2", "4-4-2", "4-3-3", "3-5-2", "5-3-2",
        "4-2-3-1", "4-1-4-1", "3-4-3", "3-4-1-2", "4-5-1",
        "5-4-1", "5-2-3", "5-2-2-1", "5-2-1-2", "4-4-1-1",
        "4-3-2-1", "4-3-1-2", "7-2-4", "4-2-2-2", "4-2-1-3",
        "4-1-3-2", "4-1-2-1-2", "4-1-2-3", "3-5-1-1", "3-4-2-1",
        "3-1-4-2"
    ];

    useEffect(() => {
        const fetchPlayers = async () => {
            try {
                const currentUser = await authService.getCurrentUser();
                if (!currentUser) {
                    throw new Error('No user is currently logged in.');
                }
                const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteCollectionId, [
                    Query.equal('userID', currentUser.$id),
                ]);
                console.log('Fetched players:', response.documents);
                
                const stripedPlayers = createInitialPlayers('striped', response.documents);
                const plainPlayers = createInitialPlayers('plain', response.documents);
                
                setPlayersStriped(stripedPlayers);
                setPlayersPlain(plainPlayers);
            } catch (error) {
                console.error('Error fetching players:', error);
            }
        };
        fetchPlayers();
    }, []);

    const handleJerseyClick = (jerseyType) => {
        setCurrentJerseyType(jerseyType);
    };

    const handlePlayerSelected = (selectedPlayer) => {
        const updateSelection = (players) => players.map(player =>
            player.$id === selectedPlayer.$id ? { ...player, selected: !player.selected } : player
        );

        let updatedPlayers;
        if (currentJerseyType === 'striped') {
            updatedPlayers = updateSelection(playersStriped);
            setPlayersStriped(updatedPlayers);
            setStartingElevenCount(updatedPlayers.filter(player => player.selected).length);
        } else {
            updatedPlayers = updateSelection(playersPlain);
            setPlayersPlain(updatedPlayers);
            setSubstitutesCount(updatedPlayers.filter(player => player.selected).length);
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleInputChange = (field, value) => {
        switch (field) {
            case 'matchName':
                setMatchName(value);
                break;
            case 'opposition':
                setOpposition(value);
                break;
            case 'stadium':
                setStadium(value);
                break;
            case 'notes':
                setNotes(value);
                break;
            case 'lineup':
                setLineup(value);
                break;
            default:
                break;
        }
    };

    const handleDurationChange = (event) => {
        const durationValue = event.target.value;
        setDuration(durationValue);

        const numericDuration = parseInt(durationValue, 10);
        if (numericDuration === 90) {
            setSession(2);
        } else if (numericDuration === 45) {
            setSession(1);
        } else {
            setSession('');
        }
    };

    const handleContinueClick = async () => {
        if (!selectedDate || !lineup || startingElevenCount !== 11 || substitutesCount < 1) {
            alert("Please ensure all fields are filled and the squad is correctly selected.");
            return;
        }

        try {
            const currentUser = await authService.getCurrentUser();
            if (!currentUser) {
                throw new Error('No user is currently logged in.');
            }

            const userDocument = await databases.getDocument(conf.appwriteDatabaseId, conf.appwriteUsersId, currentUser.$id);
            const defaultClub = userDocument.defaultClub;

            const startingEleven = playersStriped.filter(player => player.selected).map(player => JSON.stringify({
                jerseyNumber: player.jerseyNumber,
                fullName: player.fullName,
                pposition: player.pposition
            }));

            const substitutes = playersPlain.filter(player => player.selected).map(player => JSON.stringify({
                jerseyNumber: player.jerseyNumber,
                fullName: player.fullName,
                pposition: player.pposition
            }));

            const squadData = {
                matchName,
                matchDate: selectedDate,
                opposition,
                stadium,
                duration: parseInt(duration),
                session: parseInt(session),
                startingEleven,
                substitutes,
                lineup,
                userID: currentUser.$id,
                userEmail: currentUser.email,
                defaultClub: defaultClub,
                notes
            };

            console.log('Squad data to be saved:', squadData);

            const createdDocument = await databases.createDocument(conf.appwriteDatabaseId, conf.createSquadCollectionId, ID.unique(), squadData);
            setLastDocumentId(createdDocument.$id);
            alert("Squad created successfully!");

            setShowPitch(true);

        } catch (error) {
            console.error('Error saving squad:', error);
            alert("Failed to create squad. Please try again.");
        }
    };

    const toggleDrawerCollapse = () => {
        setDrawerCollapsed(!drawerCollapsed);
    };

    return (
        <DndProvider backend={HTML5Backend}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '85vh' }}>
            <Box sx={{
                display: 'flex',
                flexGrow: 1,
                padding: '10px',
                overflowY: 'auto',
            }}>
                <PlayerSelection
                    players={currentJerseyType === 'striped' ? playersStriped : playersPlain}
                    currentJerseyType={currentJerseyType}
                    handlePlayerSelected={handlePlayerSelected}
                />
                <MatchDetailsForm
                    matchName={matchName}
                    opposition={opposition}
                    stadium={stadium}
                    duration={duration}
                    session={session}
                    startingElevenCount={startingElevenCount}
                    substitutesCount={substitutesCount}
                    lineup={lineup}
                    notes={notes}
                    selectedDate={selectedDate}
                    formations={formations}
                    handleInputChange={handleInputChange}
                    handleDateChange={handleDateChange}
                    handleDurationChange={handleDurationChange}
                    handleContinueClick={handleContinueClick}
                />
            </Box>
            <Box sx={{
                width: '30%',
                height: '60px',
                position: 'fixed',
                bottom: 0,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'left',
                justifyContent: 'left',
                backgroundColor: '#f5f5f5',
                padding: '10px',
                transition: 'box-shadow 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.9)'
                }
            }}>
                <IconButton onClick={() => handleJerseyClick('striped')}>
                    <img src='/icons/football-jersey.png' alt="Striped Jersey" style={{ width: 40, height: 40 }} />
                </IconButton>
                <IconButton onClick={() => handleJerseyClick('plain')}>
                    <img src='/icons/football-plain.png' alt="Plain Jersey" style={{ width: 40, height: 40 }} />
                </IconButton>
                <Typography variant="h6" sx={{
                    whiteSpace: 'nowrap',
                    marginLeft: '20px',
                }}>Squad Selection</Typography>
            </Box>
            <SoccerPitchDrawer
                showPitch={showPitch}
                setShowPitch={setShowPitch}
                drawerCollapsed={drawerCollapsed}
                toggleDrawerCollapse={toggleDrawerCollapse}
                lineupFullDialogOpen={lineupFullDialogOpen}
                setLineupFullDialogOpen={setLineupFullDialogOpen}
                playersStriped={playersStriped}
                playersPlain={playersPlain}
                lineup={lineup}
                lastDocumentId={lastDocumentId}
            />
        </Box>
        </DndProvider>
    );
};

export default CreateSquad;
