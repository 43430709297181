import React from 'react';
import { Box } from '@mui/material';

const SideMenu = () => {
    return (
        <Box className="side-menu" />
    );
};

export default SideMenu;
