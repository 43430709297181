// // Custom StepTwo component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState, useEffect } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, Typography, Switch, FormControlLabel, Tooltip } from '@mui/material';
import PlayingFoot from './PlayingFoot';
import '../App.css';
import CustomTextField from './CustomTextField';

const formatPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    if (phoneRegex.test(phoneNumber)) {
        return phoneNumber.replace(/^(\+\d{1,3})(\d{1,4})(\d{1,4})$/, '$1 $2 $3');
    }
    return phoneNumber;
};

const StepTwo = ({ formState, updateFormState }) => {
    const [errors, setErrors] = useState({
        fifaplayerID: '',
        jerseyNumber: '',
        weight: '',
        height: '',
        foot: '',
        previousClubs: '',
        emergencyName: '',
        emergencyRelationship: '',
        emergencyPhone: ''
    });

    useEffect(() => {
        validateAllFields();
    }, [formState]);

    const validateField = (field, value) => {
        let error = '';
        switch (field) {
            case 'fifaplayerID':
                if (!value) error = 'FIFA Player ID is required';
                break;
            case 'jerseyNumber':
                if (!value) error = 'Jersey Number is required';
                else if (!/^\d{1,2}$/.test(value) || value < 1 || value > 99) error = 'Jersey Number must be between 1 and 99';
                break;
            case 'weight':
                if (!value) error = 'Weight is required';
                else if (!/^\d{2,3}$/.test(value) || value < 10 || value > 200) error = 'Weight must be between 10 and 200 kg';
                break;
            case 'height':
                if (!value) error = 'Height is required';
                else if (!/^\d{2,3}$/.test(value) || value < 10 || value > 300) error = 'Height must be between 10 and 300 cm';
                break;
            case 'previousClubs':
                if (formState.transferred && !value) error = 'Previous Clubs is required';
                break;
            case 'emergencyName':
                if (!value) error = 'Emergency Contact Name is required';
                break;
            case 'emergencyRelationship':
                if (!value) error = 'Emergency Relationship is required';
                break;
            case 'emergencyPhone':
                const phoneRegex = /^\+?[1-9]\d{1,14}$/;
                if (!value) error = 'Emergency Phone Number is required';
                else if (!phoneRegex.test(value)) error = 'Invalid phone number format';
                break;
            default:
                break;
        }
        setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
    };

    const validateAllFields = () => {
        Object.keys(formState).forEach((field) => {
            validateField(field, formState[field]);
        });
    };

    const renderCustomTextFieldWithTooltip = (label, field, type = "text", placeholder = "") => (
        <FormControl fullWidth>
            <Tooltip
                title={errors[field] || ''}
                open={!!errors[field]}
                placement="top-end"
                classes={{ tooltip: 'custom-tooltip' }}
                PopperProps={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -21],
                            },
                        },
                    ],
                }}
                sx={{
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: 'rgba(173, 216, 230, 0.8)', 
                        color: '#000',
                    },
                }}
            >
                <div>
                    <CustomTextField
                        label={label}
                        value={formState[field] || ''}
                        onChange={(e) => updateFormState(field, e.target.value)}
                        onBlur={(e) => validateField(field, e.target.value)}
                        error={!!errors[field]}
                        helperText=""
                        required
                        type={type}
                        InputProps={{
                            style: {
                                borderColor: errors[field] ? '#ced4da' : undefined,
                                color: errors[field] ? '#000' : undefined,
                            },
                        }}
                    />
                </div>
            </Tooltip>
        </FormControl>
    );

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
                {renderCustomTextFieldWithTooltip("FIFA Player ID", "fifaplayerID")}
            </Grid>
            <Grid item xs={12} sm={3}>
                {renderCustomTextFieldWithTooltip("Jersey Number", "jerseyNumber")}
            </Grid>
            <Grid item xs={12} sm={3}>
                {renderCustomTextFieldWithTooltip("Weight (kg)", "weight")}
            </Grid>
            <Grid item xs={12} sm={3}>
                {renderCustomTextFieldWithTooltip("Height (cm)", "height")}
            </Grid>
            <Grid item xs={12} sm={6} container alignItems="center">
                <FormControlLabel
                    control={
                        <Switch
                            checked={formState.transferred}
                            onChange={(e) => {
                                updateFormState('transferred', e.target.checked);
                                validateField('previousClubs', formState.previousClubs);
                            }}
                            name="transferred"
                            color="primary"
                        />
                    }
                    label="Transferred"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <PlayingFoot
                    selectedFoot={formState.foot}
                    setSelectedFoot={(newValue) => updateFormState('foot', newValue)}
                />
            </Grid>
            <Grid item xs={12}>
                {renderCustomTextFieldWithTooltip("Previous Clubs", "previousClubs")}
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Emergency Contact Details:
                </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
                {renderCustomTextFieldWithTooltip("Name", "emergencyName")}
            </Grid>
            <Grid item xs={12} sm={4}>
                {renderCustomTextFieldWithTooltip("Relationship", "emergencyRelationship")}
            </Grid>
            <Grid item xs={12} sm={4}>
                {renderCustomTextFieldWithTooltip("Phone Number", "emergencyPhone")}
            </Grid>
        </Grid>
    );
};

export default StepTwo;
