
// // Custom StepOne component
// // Created by Shamiel Speelman
// // Version: 0.1

import React, { useEffect, useState } from 'react';
import { Grid, Tooltip, TextField, Select, MenuItem, InputAdornment, Button } from '@mui/material';
import CustomTextField from './CustomTextField';
import CountrySelector from './CountrySelector';

import PositionSelector from './PositionSelector';
import PPositionSelector from './PPositionSelector';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import 'flag-icon-css/css/flag-icons.min.css';
import '../App.css';


const StepOne = ({ formState, updateFormState, handleImageUpload }) => {
    const [errors, setErrors] = useState({
        fullName: '',
        email: '',
        phone: '',
        dateOfBirth: '',
        joinedClub: '',
        position: '',
        pposition: '',
        nationality: '',
        addressLine1: ''
    });
    const [selectedCountry, setSelectedCountry] = useState('US'); 
    const countries = getCountries();

    useEffect(() => {
        validateAllFields();
    }, [formState]);

    const validateField = (field, value) => {
        let error = '';
        switch (field) {
            case 'fullName':
                if (!value) error = 'Full Name is required';
                break;
            case 'email':
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(value)) error = 'Invalid email format';
                break;
            case 'phone':
                const phoneRegex = /^\+?[1-9]\d{1,14}$/;
                if (!value) error = 'Phone Number is required';
                else if (!phoneRegex.test(value)) error = 'Invalid phone number format';
                break;
            case 'dateOfBirth':
                if (!value) error = 'Date of Birth is required';
                break;
            case 'joinedClub':
                if (!value) error = 'Joined Club is required';
                break;
            case 'pposition':
                if (!value) error = 'Preferred Position is required';
                break;
            case 'nationality':
                if (!value) error = 'Nationality is required';
                break;
            case 'addressLine1':
                if (!value) error = 'Address Line 1 is required';
                break;
            default:
                break;
        }
        setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
    };

    const validateAllFields = () => {
        Object.keys(formState).forEach((field) => {
            validateField(field, formState[field]);
        });
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        updateFormState('phone', value);
        validateField('phone', value);
    };

    const handleCountryChange = (e) => {
        const newCountry = e.target.value;
        setSelectedCountry(newCountry);
        updateFormState('nationality', newCountry);
        validateField('nationality', newCountry);
    };

    const renderCustomTextFieldWithTooltip = (label, field, type = "text", multiline = false, rows = 1, tooltipStyle = {}) => (
        <Tooltip
            title={errors[field] || ''}
            open={!!errors[field]}
            placement="top-end"
            arrow={false}
            classes={{ tooltip: 'custom-tooltip' }}
            PopperProps={{
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 0],
                            ...tooltipStyle,
                        },
                    },
                ],
            }}
            sx={{
                '& .MuiTooltip-tooltip': {
                    backgroundColor: 'rgba(216, 172, 178)',
                    color: '#000',
                },
            }}
        >
            <div>
                <CustomTextField
                    label={label}
                    value={formState[field] || ''}
                    onChange={(e) => updateFormState(field, e.target.value)}
                    onBlur={(e) => validateField(field, e.target.value)}
                    error={!!errors[field]}
                    helperText=""
                    required
                    type={type}
                    multiline={multiline}
                    rows={rows}
                    InputProps={{
                        style: {
                            borderColor: errors[field] ? '#ced4da' : undefined, 
                            color: errors[field] ? '#000' : undefined, 
                            height: '56px' 
                        },
                    }}
                    fullWidth 
                />
            </div>
        </Tooltip>
    );

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
                {renderCustomTextFieldWithTooltip("Full Name", "fullName", "text", false, 1, { offset: [0, -20] })}
            </Grid>
            <Grid item xs={12} md={4}>
                {renderCustomTextFieldWithTooltip("Email", "email", "text", false, 1, { offset: [0, -20] })}
            </Grid>
            <Grid item xs={12} md={4}>
                <Tooltip
                    title={errors.phone || ''}
                    open={!!errors.phone}
                    placement="top-end"
                    arrow={false}
                    classes={{ tooltip: 'custom-tooltip' }}
                    PopperProps={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -21],
                                },
                            },
                        ],
                    }}
                    sx={{
                        '& .MuiTooltip-tooltip': {
                            backgroundColor: 'rgba(216, 172, 178, 0.8)',
                            color: '#000', 
                        },
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Select
                            variant="standard"
                            value={selectedCountry}
                            onChange={handleCountryChange}
                            displayEmpty
                            sx={{
                                marginBottom: '33px',
                                marginRight: '10px',
                                marginTop: '8px',
                                '& .MuiInputBase-input': {
                                    fontSize: '0.65rem',
                                    padding: '8px 12px',
                                    fontFamily: 'Roboto, sans-serif'
                                },
                                '& .MuiInputBase-root': {
                                    marginTop: '8px',
                                    marginBottom: '8px',
                                }
                            }}
                        >
                            {countries.map((code) => (
                                <MenuItem key={code} value={code}>
                                    <span className={`flag-icon flag-icon-${code.toLowerCase()}`}></span>
                                    {code}
                                </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            label="Phone Number"
                            value={formState.phone || ''}
                            onChange={handlePhoneChange}
                            onBlur={(e) => validateField('phone', e.target.value)}
                            error={!!errors.phone}
                            helperText=""  
                            required
                            sx={{
                                height: '44px',
                                marginTop: '-16px',
                                marginBottom: '-5px',
                                '& .MuiInputBase-input': {
                                    fontSize: '0.65rem',
                                    padding: '12px 16px',
                                    fontFamily: 'Roboto, sans-serif'
                                },
                                '& .MuiInputBase-root': {
                                    marginTop: '0px',
                                    marginBottom: '0px',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#ced4da',  
                                    },
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        +{getCountryCallingCode(selectedCountry)}
                                    </InputAdornment>
                                ),
                                style: {
                                    height: '48px', 
                                },
                            }}
                            fullWidth 
                        />
                    </div>
                </Tooltip>
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: '2px' }}>
                {renderCustomTextFieldWithTooltip("Date of Birth", "dateOfBirth", "date", false, 1, { offset: [0, -20] })}
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: '2px' }}>
                {renderCustomTextFieldWithTooltip("Joined Club", "joinedClub", "date", false, 1, { offset: [0, -20] })}
            </Grid>
            <Grid item xs={12} md={3}>
                <Tooltip
                    title={errors.pposition || ''}
                    open={!!errors.pposition}
                    placement="top-end"
                    arrow={false}
                    classes={{ tooltip: 'custom-tooltip' }}
                    PopperProps={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -20], 
                                },
                            },
                        ],
                    }}
                    sx={{
                        '& .MuiTooltip-tooltip': {
                            backgroundColor: 'rgba(173, 216, 230, 0.8)', 
                            color: '#000', 
                        },
                    }}
                >
                    <div>
                        <PPositionSelector
                            selectedPPosition={formState.pposition}
                            setSelectedPPosition={(newValue) => {
                                updateFormState('pposition', newValue);
                                validateField('pposition', newValue);
                            }}
                        />
                    </div>
                </Tooltip>
            </Grid>
            <Grid item xs={12} md={3}>
                <Tooltip
                    title={errors.position || ''}
                    open={!!errors.position}
                    placement="top-end"
                    arrow={false}
                    classes={{ tooltip: 'custom-tooltip' }}
                    PopperProps={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -20],
                                },
                            },
                        ],
                    }}
                    sx={{
                        '& .MuiTooltip-tooltip': {
                            backgroundColor: 'rgba(173, 216, 230, 0.8)', 
                            color: '#000', 
                        },
                    }}
                >
                    <div>
                        <PositionSelector
                            selectedPosition={formState.position}
                            setSelectedPosition={(newValue) => {
                                updateFormState('position', newValue);
                                validateField('position', newValue);
                            }}
                        />
                    </div>
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                <Tooltip
                    title={errors.nationality || ''}
                    open={!!errors.nationality}
                    placement="top-end"
                    arrow={false}
                    classes={{ tooltip: 'custom-tooltip' }}
                    PopperProps={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -20],
                                },
                            },
                        ],
                    }}
                    sx={{
                        '& .MuiTooltip-tooltip': {
                            backgroundColor: 'rgba(173, 216, 230, 0.8)',
                            color: '#000', 
                        },
                    }}
                >
                    <div>
                        <CountrySelector
                            selectedCountry={formState.nationality}
                            setSelectedCountry={(newValue) => {
                                updateFormState('nationality', newValue);
                                validateField('nationality', newValue);
                            }}
                        />
                    </div>
                </Tooltip>
            </Grid>
            <Grid item xs={12} md={6}>
                {renderCustomTextFieldWithTooltip("Address Line 1", "addressLine1", "text", true, 2, { offset: [0, -20] })}
            </Grid>
            <Grid item xs={12} md={6}>
                {renderCustomTextFieldWithTooltip("Address Line 2", "addressLine2", "text", true, 2, { offset: [0, -20] })}
            </Grid>
            <Grid item xs={12}>
                <div style={{ textAlign: 'center' }}>
                    {formState.photo && (
                        <img src={formState.photo.preview} alt="Uploaded" style={{ maxHeight: '100px', display: 'block', margin: '0 auto 10px auto' }} />
                    )}
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={handleImageUpload}
                    />
                    <label htmlFor="raised-button-file">
                        <Button variant="contained" component="span" size="small" sx={{
                            '& .MuiInputBase-input': {
                                fontSize: '0.75rem',
                                padding: '6px 12px'
                            },
                            '& .MuiInputBase-root': {
                                marginTop: '8px',
                                marginBottom: '8px'
                            },
                            backgroundColor: '#356596',
                            color: '#ffffff',
                            '&:hover': {
                                backgroundColor: '#ffffff',
                                color: '#356596'
                            }
                        }}>
                            Upload Photo
                        </Button>
                    </label>
                </div>
            </Grid>
        </Grid>
    );
};

export default StepOne;
