// import React, { useState } from 'react';
// import { Box, IconButton, Drawer } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import LockIcon from '@mui/icons-material/Lock';
// import LockOpenIcon from '@mui/icons-material/LockOpen';
// import Groups2Icon from '@mui/icons-material/Groups2';
// import DeleteIcon from '@mui/icons-material/Delete';
// import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
// import ball from './ball.png';
// import balls from './balls.png';

// const SoccerPitch = ({ players, onPlayerDrop, onPlayerRemove, onPlayerDragStart }) => {
//     const handleDrop = (event) => {
//         event.preventDefault();
//         const playerData = event.dataTransfer.getData('text/plain');
//         if (!playerData) return;
//         const player = JSON.parse(playerData);
//         const rect = event.currentTarget.getBoundingClientRect();
//         const x = event.clientX - rect.left;
//         const y = event.clientY - rect.top;

//         onPlayerDrop({ ...player, x, y });
//     };

//     const handleDragOver = (event) => {
//         event.preventDefault();
//     };

//     return (
//         <Box
//             sx={{
//                 width: '800px',
//                 height: '500px',
//                 backgroundColor: '#fff',
//                 position: 'relative',
//                 margin: '100',
//                 border: '1px solid #001400',
//             }}
//             onDrop={handleDrop}
//             onDragOver={handleDragOver}
//         >
//             {players.map((player, index) => (
//                 <Box
//                     key={index}
//                     draggable
//                     onDragStart={(event) => onPlayerDragStart(event, player)}
//                     onDoubleClick={() => onPlayerRemove(player)}
//                     sx={{
//                         width: '30px',
//                         height: '30px',
//                         borderRadius: '50%',
//                         backgroundColor: player.color,
//                         color: '#fff',
//                         fontWeight: 'bold',
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         position: 'absolute',
//                         top: player.y,
//                         left: player.x,
//                         transform: 'translate(-50%, -50%)',
//                         cursor: 'move',
//                         zIndex: 2,
//                         backgroundImage: player.image ? `url(${player.image})` : 'none',
//                         backgroundSize: 'cover',
//                     }}
//                 >
//                     {player.type === 'player' && player.number}
//                 </Box>
//             ))}
//             {/* Halfway Line */}
//             <Box
//                 sx={{
//                     position: 'absolute',
//                     top: '0',
//                     bottom: '0',
//                     left: '50%',
//                     width: '2px',
//                     backgroundColor: '#001400',
//                     transform: 'translateX(-50%)',
//                 }}
//             />
//             {/* Center Circle */}
//             <Box
//                 sx={{
//                     position: 'absolute',
//                     width: '100px',
//                     height: '100px',
//                     borderRadius: '50%',
//                     top: '50%',
//                     left: '50%',
//                     border: '2px solid #001400',
//                     transform: 'translate(-50%, -50%)',
//                     zIndex: 1,
//                 }}
//             />
//             {/* Center Spot */}
//             <Box
//                 sx={{
//                     position: 'absolute',
//                     width: '6px',
//                     height: '6px',
//                     borderRadius: '50%',
//                     top: '50%',
//                     left: '50%',
//                     backgroundColor: '#001400',
//                     transform: 'translate(-50%, -50%)',
//                 }}
//             />
//             {/* Penalty Boxes */}
//             <Box
//                 sx={{
//                     position: 'absolute',
//                     width: '120px',
//                     height: '200px',
//                     top: '150px',
//                     left: '0',
//                     border: '1px solid #001400',
//                     zIndex: 1,
//                 }}
//             />
//             <Box
//                 sx={{
//                     position: 'absolute',
//                     width: '120px',
//                     height: '200px',
//                     top: '150px',
//                     right: '0',
//                     border: '1px solid #001400',
//                     zIndex: 1,
//                 }}
//             />
//             {/* Goal Boxes */}
//             <Box
//                 sx={{
//                     position: 'absolute',
//                     width: '40px',
//                     height: '100px',
//                     top: '200px',
//                     left: '0',
//                     border: '1px solid #001400',
//                     zIndex: 1,
//                 }}
//             />
//             <Box
//                 sx={{
//                     position: 'absolute',
//                     width: '40px',
//                     height: '100px',
//                     top: '200px',
//                     right: '0',
//                     border: '1px solid #001400',
//                     zIndex: 1,
//                 }}
//             />
//             {/* Penalty Spots */}
//             <Box
//                 sx={{
//                     position: 'absolute',
//                     width: '6px',
//                     height: '6px',
//                     borderRadius: '50%',
//                     top: '50%',
//                     left: '80px',
//                     backgroundColor: '#001400',
//                     transform: 'translateY(-50%)',
//                 }}
//             />
//             <Box
//                 sx={{
//                     position: 'absolute',
//                     width: '6px',
//                     height: '6px',
//                     borderRadius: '50%',
//                     top: '50%',
//                     right: '80px',
//                     backgroundColor: '#001400',
//                     transform: 'translateY(-50%)',
//                 }}
//             />
//             {/* Penalty Arcs */}
//             <Box
//                 sx={{
//                     position: 'absolute',
//                     width: '60px',
//                     height: '60px',
//                     borderRadius: '50%',
//                     top: '50%',
//                     left: '120px',
//                     border: '2px solid #001400',
//                     borderColor: '#001400 transparent transparent transparent',
//                     transform: 'translate(-50%, -50%) rotate(90deg)',
//                     zIndex: 1,
//                 }}
//             />
//             <Box
//                 sx={{
//                     position: 'absolute',
//                     width: '60px',
//                     height: '60px',
//                     borderRadius: '50%',
//                     top: '50%',
//                     right: '120px',
//                     border: '2px solid #001400',
//                     borderColor: '#001400 transparent transparent transparent',
//                     transform: 'translate(50%, -50%) rotate(-90deg)',
//                     zIndex: 1,
//                 }}
//             />
//         </Box>
//     );
// };

// const SideMenu = () => {
//     return (
//         <Box
//             sx={{
//                 width: '50px',
//                 height: '500px',
//                 position: 'absolute',
//                 top: 120,
//                 right: '245px',
//                 backgroundColor: '#fff',
//                 border: '1px solid #000',
//                 borderRadius: '5px',
//                 transition: 'box-shadow 0.3s ease',
//                 '&:hover': {
//                     boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.9)',
//                 },
//             }}
//         />
//     );
// };

// const BottomMenu = ({ onPlayerDragStart, availablePlayers, onClearPitch }) => {
//     const [isBlueExpanded, setIsBlueExpanded] = useState(false);
//     const [isRedExpanded, setIsRedExpanded] = useState(false);
//     const [isBallExpanded, setIsBallExpanded] = useState(false);

//     const handleBlueClick = () => {
//         setIsBlueExpanded(!isBlueExpanded);
//         if (isRedExpanded) setIsRedExpanded(false); // Collapse red expander if blue is clicked
//         if (isBallExpanded) setIsBallExpanded(false); // Collapse ball expander if blue is clicked
//     };

//     const handleRedClick = () => {
//         setIsRedExpanded(!isRedExpanded);
//         if (isBlueExpanded) setIsBlueExpanded(false); // Collapse blue expander if red is clicked
//         if (isBallExpanded) setIsBallExpanded(false); // Collapse ball expander if red is clicked
//     };

//     const handleBallClick = () => {
//         setIsBallExpanded(!isBallExpanded);
//         if (isBlueExpanded) setIsBlueExpanded(false); // Collapse blue expander if ball is clicked
//         if (isRedExpanded) setIsRedExpanded(false); // Collapse red expander if ball is clicked
//     };

//     const renderCircleIcons = (color) => (
//         availablePlayers
//             .filter((player) => player.color === color)
//             .map((player) => (
//                 <Box
//                     key={player.number}
//                     draggable
//                     onDragStart={(event) => onPlayerDragStart(event, player)}
//                     sx={{
//                         width: '30px',
//                         height: '30px',
//                         borderRadius: '50%',
//                         backgroundColor: color,
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         margin: '0 5px',
//                         color: '#fff',
//                         fontWeight: 'bold',
//                         cursor: 'grab',
//                     }}
//                 >
//                     {player.number}
//                 </Box>
//             ))
//     );

//     const renderBallIcons = () => (
//         <>
//             <Box
//                 draggable
//                 onDragStart={(event) => onPlayerDragStart(event, { type: 'ball', image: ball })}
//                 sx={{
//                     width: '30px',
//                     height: '30px',
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     margin: '0 5px',
//                     cursor: 'grab',
//                     backgroundImage: `url(${ball})`,
//                     backgroundSize: 'cover',
//                 }}
//             />
//             <Box
//                 draggable
//                 onDragStart={(event) => onPlayerDragStart(event, { type: 'ball', image: balls })}
//                 sx={{
//                     width: '30px',
//                     height: '30px',
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     margin: '0 5px',
//                     cursor: 'grab',
//                     backgroundImage: `url(${balls})`,
//                     backgroundSize: 'cover',
//                 }}
//             />
//         </>
//     );

//     return (
//         <Box
//             sx={{
//                 width: '800px',
//                 height: '50px',
//                 position: 'absolute',
//                 bottom: '50px',
//                 left: '90',
//                 backgroundColor: '#fff',
//                 border: '1px solid #000',
//                 borderRadius: '5px',
//                 transition: 'box-shadow 0.3s ease',
//                 display: 'flex',
//                 alignItems: 'center',
//                 padding: '0 10px',
//                 '&:hover': {
//                     boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.9)',
//                 },
//             }}
//         >
//             <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                 <IconButton onClick={handleBlueClick}>
//                     <Groups2Icon sx={{ color: '#a0bede' }} />
//                 </IconButton>
//                 {isBlueExpanded && (
//                     <Box sx={{ display: 'flex', marginLeft: '10px' }}>
//                         {renderCircleIcons('#a0bede')}
//                     </Box>
//                 )}
//             </Box>
//             <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
//                 <IconButton onClick={handleRedClick}>
//                     <Groups2Icon sx={{ color: '#eb6e89' }} />
//                 </IconButton>
//                 {isRedExpanded && (
//                     <Box sx={{ display: 'flex', marginLeft: '10px' }}>
//                         {renderCircleIcons('#eb6e89')}
//                     </Box>
//                 )}
//             </Box>
//             <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
//                 <IconButton onClick={handleBallClick}>
//                     <SportsSoccerIcon sx={{ color: 'black' }} />
//                 </IconButton>
//                 {isBallExpanded && (
//                     <Box sx={{ display: 'flex', marginLeft: '10px' }}>
//                         {renderBallIcons()}
//                     </Box>
//                 )}
//             </Box>
//             <Box sx={{ marginLeft: 'auto' }}>
//                 <IconButton onClick={onClearPitch}>
//                     <DeleteIcon sx={{ color: 'black' }} />
//                 </IconButton>
//             </Box>
//         </Box>
//     );
// };

// const TacticalBoard = ({ open, onClose }) => {
//     const [locked, setLocked] = useState(false);
//     const [playersOnPitch, setPlayersOnPitch] = useState([]);
//     const [availablePlayers, setAvailablePlayers] = useState([
//         ...Array.from({ length: 11 }, (_, i) => ({ color: '#a0bede', number: i + 1, type: 'player' })),
//         ...Array.from({ length: 11 }, (_, i) => ({ color: '#eb6e89', number: i + 1, type: 'player' })),
//     ]);

//     const handlePlayerDrop = (player) => {
//         setPlayersOnPitch((prevPlayers) => {
//             const existingPlayerIndex = prevPlayers.findIndex(
//                 (p) =>
//                     (p.color === player.color &&
//                     p.number === player.number &&
//                     p.type === 'player') || (p.type === 'ball' && p.image === player.image && p.x === player.x && p.y === player.y)
//             );
//             if (existingPlayerIndex !== -1) {
//                 // Move existing player
//                 const updatedPlayers = [...prevPlayers];
//                 updatedPlayers[existingPlayerIndex] = player;
//                 return updatedPlayers;
//             } else {
//                 // Add new player
//                 if (player.type === 'player') {
//                     setAvailablePlayers((prevAvailable) =>
//                         prevAvailable.filter(
//                             (p) => p.color !== player.color || p.number !== player.number
//                         )
//                     );
//                 }
//                 return [...prevPlayers, player];
//             }
//         });
//     };

//     const handlePlayerRemove = (playerToRemove) => {
//         setPlayersOnPitch((prevPlayers) =>
//             prevPlayers.filter(
//                 (player) =>
//                     player.color !== playerToRemove.color ||
//                     player.number !== playerToRemove.number ||
//                     player.type !== playerToRemove.type ||
//                     player.image !== playerToRemove.image
//             )
//         );
//         if (playerToRemove.type === 'player') {
//             setAvailablePlayers((prevAvailable) => [...prevAvailable, playerToRemove]);
//         }
//     };

//     const handlePlayerDragStart = (event, player) => {
//         event.dataTransfer.setData('text/plain', JSON.stringify(player));
//     };

//     const handleClearPitch = () => {
//         setPlayersOnPitch([]);
//         setAvailablePlayers([
//             ...Array.from({ length: 11 }, (_, i) => ({ color: '#a0bede', number: i + 1, type: 'player' })),
//             ...Array.from({ length: 11 }, (_, i) => ({ color: '#eb6e89', number: i + 1, type: 'player' })),
//         ]);
//     };

//     const handleLockToggle = () => {
//         setLocked(!locked);
//     };

//     return (
//         <Drawer anchor="right" open={open} onClose={locked ? null : onClose}>
//             <Box sx={{ width: '100vw', height: '100vh', padding: 2, backgroundColor: '#f5f5f5' }}>
//                 <Box display="flex" justifyContent="space-between" alignItems="center">
//                     <IconButton onClick={locked ? null : onClose} disabled={locked}>
//                         <CloseIcon />
//                     </IconButton>
//                     <IconButton onClick={handleLockToggle}>
//                         {locked ? <LockOpenIcon color="success" /> : <LockIcon color="error" />}
//                     </IconButton>
//                 </Box>
//                 <Box
//                     sx={{
//                         width: '100%',
//                         height: '100%',
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         position: 'relative',
//                     }}
//                 >
//                     <SoccerPitch
//                         players={playersOnPitch}
//                         onPlayerDrop={handlePlayerDrop}
//                         onPlayerRemove={handlePlayerRemove}
//                         onPlayerDragStart={handlePlayerDragStart}
//                     />
//                     <SideMenu />
//                     <BottomMenu
//                         onPlayerDragStart={handlePlayerDragStart}
//                         availablePlayers={availablePlayers}
//                         onClearPitch={handleClearPitch}
//                     />
//                 </Box>
//             </Box>
//         </Drawer>
//     );
// };

// export default TacticalBoard;
import React, { useState } from 'react';
import { Box, IconButton, Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Groups2Icon from '@mui/icons-material/Groups2';
import DeleteIcon from '@mui/icons-material/Delete';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import ball from './ball.png';
import balls from './balls.png';
import './tactical.css';

import SoccerPitch from './TacticalPitch';
import SideMenu from './TacticalSideMenu';
import BottomMenu from './TacticalBottomMenu';

const TacticalBoard = ({ open, onClose }) => {
    const [locked, setLocked] = useState(false);
    const [playersOnPitch, setPlayersOnPitch] = useState([]);
    const [availablePlayers, setAvailablePlayers] = useState([
        ...Array.from({ length: 11 }, (_, i) => ({ color: '#a0bede', number: i + 1, type: 'player' })),
        ...Array.from({ length: 11 }, (_, i) => ({ color: '#eb6e89', number: i + 1, type: 'player' })),
    ]);

    const handlePlayerDrop = (player) => {
        setPlayersOnPitch((prevPlayers) => {
            const existingPlayerIndex = prevPlayers.findIndex(
                (p) =>
                    (p.color === player.color &&
                    p.number === player.number &&
                    p.type === 'player') || (p.type === 'ball' && p.image === player.image && p.x === player.x && p.y === player.y)
            );
            if (existingPlayerIndex !== -1) {
                // Move existing player
                const updatedPlayers = [...prevPlayers];
                updatedPlayers[existingPlayerIndex] = player;
                return updatedPlayers;
            } else {
                // Add new player
                if (player.type === 'player') {
                    setAvailablePlayers((prevAvailable) =>
                        prevAvailable.filter(
                            (p) => p.color !== player.color || p.number !== player.number
                        )
                    );
                }
                return [...prevPlayers, player];
            }
        });
    };

    const handlePlayerRemove = (playerToRemove) => {
        setPlayersOnPitch((prevPlayers) =>
            prevPlayers.filter(
                (player) =>
                    player.color !== playerToRemove.color ||
                    player.number !== playerToRemove.number ||
                    player.type !== playerToRemove.type ||
                    player.image !== playerToRemove.image
            )
        );
        if (playerToRemove.type === 'player') {
            setAvailablePlayers((prevAvailable) => [...prevAvailable, playerToRemove]);
        }
    };

    const handlePlayerDragStart = (event, player) => {
        event.dataTransfer.setData('text/plain', JSON.stringify(player));
    };

    const handleClearPitch = () => {
        setPlayersOnPitch([]);
        setAvailablePlayers([
            ...Array.from({ length: 11 }, (_, i) => ({ color: '#a0bede', number: i + 1, type: 'player' })),
            ...Array.from({ length: 11 }, (_, i) => ({ color: '#eb6e89', number: i + 1, type: 'player' })),
        ]);
    };

    const handleLockToggle = () => {
        setLocked(!locked);
    };

    return (
        <Drawer anchor="left" open={open} onClose={locked ? null : onClose}>
            <Box className="tactical-board">
                <Box className="header">
                    <IconButton onClick={locked ? null : onClose} disabled={locked}>
                        <CloseIcon />
                    </IconButton>
                    <IconButton onClick={handleLockToggle}>
                        {locked ? <LockOpenIcon color="success" /> : <LockIcon color="error" />}
                    </IconButton>
                </Box>
                <Box className="content">
                    <Box className="pitch-container">
                        <SoccerPitch
                            players={playersOnPitch}
                            onPlayerDrop={handlePlayerDrop}
                            onPlayerRemove={handlePlayerRemove}
                            onPlayerDragStart={handlePlayerDragStart}
                        />
                        <SideMenu />
                    </Box>
                    <BottomMenu
                        onPlayerDragStart={handlePlayerDragStart}
                        availablePlayers={availablePlayers}
                        onClearPitch={handleClearPitch}
                    />
                </Box>
            </Box>
        </Drawer>
    );
};

export default TacticalBoard;
