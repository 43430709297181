// // Custom CreatePlayer component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState } from 'react';
import { Container, Typography, Box, Button, Card, Stepper, Step, StepLabel } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import StepOne from '../components/StepOne';
import StepTwo from '../components/StepTwo';
import StepThree from '../components/StepThree';
import useFormState from '../components/useFormState';
import { Client, Databases, ID, Storage } from 'appwrite';
import conf from '../conf/conf';
import authService from '../appwrite/auth';

const steps = ['Player Info', 'Additional Details', 'Confirmation'];

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);
const storage = new Storage(client);

const CreatePlayer = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [formState, updateFormState, resetForm] = useFormState({
        fullName: '',
        email: '',
        phone: '',
        dateOfBirth: '',
        joinedClub: null,
        pposition: '',
        position: '',
        nationality: '',
        addressLine1: '',
        addressLine2: '',
        photo: null,
        jerseyNumber: '',
        fifaplayerID: '',
        foot: '',
        height: '',
        weight: '',
        previousClubs: '',
        emergencyName: '',
        emergencyRelationship: '',
        emergencyPhone: '',
        transferred: false 
    });

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                updateFormState('photo', { file, preview: reader.result });
            };
            reader.readAsDataURL(file);
        }
    };

    const uploadPhoto = async (file) => {
        try {
            const response = await storage.createFile(conf.appwriteBucketId, ID.unique(), file);
            return response.$id;
        } catch (error) {
            console.error('Failed to upload photo:', error);
            enqueueSnackbar('Failed to upload photo', { variant: 'error' });
            throw error;
        }
    };

    const isStepComplete = () => {
        if (activeStep === 0) {
            return formState.fullName.trim() !== '' &&
                formState.email.trim() !== '' &&
                formState.dateOfBirth &&
                formState.pposition.trim() !== '' &&
                formState.nationality.trim() !== '' &&
                formState.addressLine1.trim() !== '';
        }
        return true;
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCancel = () => {
        const isFormEmpty = Object.values(formState).every(value => value === '' || value === null);

        if (isFormEmpty) {
            navigate('/dashboard');
        } else {
            enqueueSnackbar('Do you want to continue? You will lose any data that has not been saved.', {
                variant: 'warning',
                action: (key) => (
                    <>
                        <Button
                            onClick={() => {
                                navigate('/dashboard');
                                closeSnackbar(key);
                            }}
                            sx={{ color: '#eb5e34' }}
                        >
                            Yes
                        </Button>
                        <Button
                            onClick={() => {
                                closeSnackbar(key);
                            }}
                            sx={{ color: '#eb5e34' }}
                        >
                            No
                        </Button>
                    </>
                ),
            });
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const user = await authService.getCurrentUser();
            if (user) {
                let photoId = null;
                if (formState.photo && formState.photo.file) {
                    photoId = await uploadPhoto(formState.photo.file);
                }
                const documentData = {
                    ...formState,
                    image: photoId, 
                    userID: user.$id, 
                    userEmail: user.email 
                };
                delete documentData.photo; 
                delete documentData.transferred; 

                const document = await databases.createDocument(
                    conf.appwriteDatabaseId,
                    conf.appwriteCollectionId,
                    ID.unique(),
                    documentData
                );
                console.log('Player data saved:', document);
                enqueueSnackbar('Player data saved successfully!', { variant: 'success' });
                resetForm();
                setActiveStep(0);
            } else {
                console.log('User not authenticated');
                enqueueSnackbar('User not authenticated', { variant: 'error' });
            }
        } catch (error) {
            console.error('Failed to save player data:', error);
            enqueueSnackbar('Failed to save player data', { variant: 'error' });
        }
    };

    return (
        <Container>
            <Typography variant="h4" sx={{ margin: '20px 0' }}>Create Player</Typography>
            <Stepper activeStep={activeStep} sx={{ marginBottom: 3 }}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Card sx={{
                padding: 2,
                marginBottom: 3,
                transition: 'box-shadow 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.9)'
                }
            }}>
                {activeStep === 0 && (
                    <StepOne formState={formState} updateFormState={updateFormState} handleImageUpload={handleImageUpload} />
                )}
                {activeStep === 1 && (
                    <StepTwo formState={formState} updateFormState={updateFormState} />
                )}
                {activeStep === 2 && <StepThree />}
            </Card>
            <Box display="flex" justifyContent="space-between" mt={2}>
                <Button variant="outlined" onClick={handleBack} disabled={activeStep === 0} sx={{
                    transition: 'background-color 0.3s, color 0.3s',
                    '&:hover': {
                        backgroundColor: '#f0f0f0',
                        color: '#000',
                    },
                }}>Back</Button>
                <Box>
                    <Button
                        variant="contained"
                        onClick={handleCancel}
                        sx={{
                            backgroundColor: '#eb5e34',
                            color: '#ffffff',
                            '&:hover': {
                                backgroundColor: '#ffffff',
                                color: '#eb5e34'
                            },
                            mr: 2
                        }}
                    >
                        Cancel
                    </Button>
                    {activeStep < steps.length - 1 && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            disabled={!isStepComplete()}
                            size="small" sx={{

                                '& .MuiInputBase-input': {
                                    fontSize: '0.75rem',
                                    padding: '6px 12px'
                                },
                                '& .MuiInputBase-root': {
                                    marginTop: '8px',
                                    marginBottom: '8px'
                                },
                                backgroundColor: '#356596',
                                color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#ffffff',
                                    color: '#356596'
                                },
                            }}>
                            Next
                        </Button>
                    )}
                    {activeStep === steps.length - 1 && (
                        <>
                            <Button variant="contained" color="primary" onClick={handleFormSubmit} sx={{
                                backgroundColor: '#356596',
                                color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#ffffff',
                                    color: '#356596',
                                },
                                marginLeft: '10px'
                            }}>Submit</Button>
                            <Button variant="contained" color="secondary" onClick={resetForm} size="small" sx={{
                                marginLeft: 1,
                                backgroundColor: '#356596',
                                color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#ffffff',
                                    color: '#356596'
                                }
                            }}>
                                Create Next Player
                            </Button>
                        </>
                    )}
                </Box>
            </Box>
        </Container>
    );
};

export default CreatePlayer;
