/* // start with appwrite auth service
import conf from "../conf/conf.js"
import { Client, Account, ID } from "appwrite";

export class AuthService {
    client = new Client();
    account;

    constructor(){
        this.client
        .setEndpoint(conf.appwriteUrl)
        .setProject(conf.appwriteProjectId)
        this.account = new Account(this.client)
    }

    async createAccount({email, password, name, surname, role}){
        try {
            const userAccount = await this.account.create(ID.unique(), email, password, name, surname, role)
            if (userAccount) {
                return this.login({email, password})
            } else {
                return userAccount
            }
        } catch (error) {
            throw error
        }
    }

    async login({email, password}) {
        try {
            // Create session and retrieve JWT for session management
            await this.account.createEmailPasswordSession(email, password);
            const jwtResponse = await this.account.createJWT();
    
            // Retrieve user data
            const user = await this.account.get();
            if (!user) {
                throw new Error('User data not available after login.');
            }
    
            // Prepare user data for storage
            const userData = {
                jwt: jwtResponse.jwt, // Store the JWT
                name: user.name || '', // Handle possible undefined values
                email: user.email || '',
                startTime: new Date().toISOString(),
            };
    
            // Store user data and JWT in localStorage
            localStorage.setItem('userData', JSON.stringify(userData)); // Store all user data
            localStorage.setItem('jwt', jwtResponse.jwt); // Store JWT separately if needed
    
            // Optionally, if you need to send data to other parts of your application
            window.dispatchEvent(new CustomEvent('auth-change', { detail: userData }));
    
            return userData;
        } catch (error) {
            console.error('Login failed:', error);
            throw error; // Re-throw the error for further handling by caller
        }
    }

    async getCurrentUser(){
        try {
            return await this.account.get()
        } catch (error) {
            console.log("Appwrite service :: getCurrentUser() :: ", error);
        }
        return null
    }

    async logout() {
        try {
            await this.account.deleteSessions();
            localStorage.removeItem('jwt'); // Clear JWT from local storage
            window.dispatchEvent(new CustomEvent('auth-change', { detail: null }));
        } catch (error) {
            console.error("Appwrite service :: logout() :: ", error);
        }
    }

    async validateSession() {
        const jwt = localStorage.getItem('jwt');
        if (!jwt) {
            return null;
        }
        try {
            // Here you might need to implement a method to verify JWT validity
            // This is an assumed method, check Appwrite SDK for actual implementation
            const session = await this.account.get(); // Get session using stored JWT
            if (session) {
                return jwt;
            }
        } catch (error) {
            console.log("No valid session:", error);
            localStorage.removeItem('jwt');
            return null;
        }
    }

    isUserLoggedIn() {
        return !!localStorage.getItem('jwt');
    }
}



const authService = new AuthService()

export default authService */
// start with appwrite auth service
import conf from "../conf/conf.js"
import { Client, Account, ID } from "appwrite";

export class AuthService {
    client = new Client();
    account;

    constructor(){
        this.client
        .setEndpoint(conf.appwriteUrl)
        .setProject(conf.appwriteProjectId)
        this.account = new Account(this.client)
    }

    async createAccount({email, password, name, surname, role}){
        try {
            const userAccount = await this.account.create(ID.unique(), email, password, name, surname, role)
            if (userAccount) {
                return this.login({email, password})
            } else {
                return userAccount
            }
        } catch (error) {
            throw error
        }
    }

    async login({email, password}) {
        try {
            // Create session and retrieve JWT for session management
            await this.account.createEmailPasswordSession(email, password);
            const jwtResponse = await this.account.createJWT();
    
            // Retrieve user data
            const user = await this.account.get();
            if (!user) {
                throw new Error('User data not available after login.');
            }
    
            // Prepare user data for storage
            const userData = {
                jwt: jwtResponse.jwt, // Store the JWT
                name: user.name  || '',
                startTime: new Date().toISOString(),
            };
    
            // Store user data and JWT in localStorage
            localStorage.setItem('userData', JSON.stringify(userData)); // Store all user data
            localStorage.setItem('jwt', jwtResponse.jwt); // Store JWT separately if needed
    
            // Optionally, if you need to send data to other parts of your application
            window.dispatchEvent(new CustomEvent('auth-change', { detail: userData }));
    
            return userData;
        } catch (error) {
            console.error('Login failed:', error);
            throw error; // Re-throw the error for further handling by caller
        }
    }

    async getCurrentUser(){
        try {
            return await this.account.get()
        } catch (error) {
            console.log("Appwrite service :: getCurrentUser() :: ", error);
        }
        return null
    }

    async logout() {
        try {
            await this.account.deleteSessions();
            localStorage.removeItem('jwt'); // Clear JWT from local storage
            window.dispatchEvent(new CustomEvent('auth-change', { detail: null }));
        } catch (error) {
            console.error("Appwrite service :: logout() :: ", error);
        }
    }

    async validateSession() {
        const jwt = localStorage.getItem('jwt');
        if (!jwt) {
            return null;
        }
        try {
            // Here you might need to implement a method to verify JWT validity
            // This is an assumed method, check Appwrite SDK for actual implementation
            const session = await this.account.get(); // Get session using stored JWT
            if (session) {
                return jwt;
            }
        } catch (error) {
            console.log("No valid session:", error);
            localStorage.removeItem('jwt');
            return null;
        }
    }

    isUserLoggedIn() {
        return !!localStorage.getItem('jwt');
    }
}



const authService = new AuthService()

export default authService