// // Custom MatchCard component
// // Created by Shamiel Speelman
// // Version: 0.1
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const MatchCard = ({ team1, team2, date, time, matchType, team1Logo, team2Logo, disabled }) => {
  const navigate = useNavigate();

  const handleMatchCentreClick = () => {
    navigate('/dashboard/matchday');
  };

  return (
    <Box sx={{
      backgroundColor: '#ffffff',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
      width: '100%',
      maxWidth: '700px',
      margin: '20px auto',
      height: '140px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      border: '1px solid #ddd',
      opacity: disabled ? 0.5 : 1,
      pointerEvents: disabled ? 'none' : 'auto',
      transition: 'box-shadow 0.3s ease-in-out',
      '&:hover': {
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.9)'
      }
    }}>
      <Box sx={{ padding: '10px 0', flexGrow: 1 }}>
        <Typography variant="subtitle2" color="textSecondary" sx={{ fontSize: '12px' }}>{date}</Typography>
        <Typography variant="subtitle1" color="textSecondary" sx={{ fontSize: '12px', marginBottom: '5px' }}>{matchType}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
            <Box sx={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              backgroundColor: '#eee',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <img src={team1Logo} alt={`${team1} logo`} style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
            </Box>
            <Typography variant="h6" sx={{ fontSize: '14px' }}>{team1}</Typography>
          </Box>
          <Typography variant="h4" sx={{ flex: 1, fontSize: '20px', mx: 1 }}>{time}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
            <Box sx={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              backgroundColor: '#eee',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Typography variant="h6" sx={{ fontSize: '14px' }}>OT</Typography>
            </Box>
            <Typography variant="h6" sx={{ fontSize: '14px' }}>{team2}</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{
        backgroundColor: '#356596',
        color: '#ffffff',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}>
        <Button variant="text" sx={{ color: '#ffffff', '&:hover': { backgroundColor: '#ffffff', color: '#356596' } }} onClick={handleMatchCentreClick}>Match Centre</Button>
      </Box>
    </Box>
  );
};

export default MatchCard;
