import React, { useState, useEffect } from 'react';
import {
  Drawer,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  IconButton,
  Typography,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const allSpecialized = {
  goalkeepingSkills: [
    { id: 'divingTechniques', name: 'Diving Techniques', type: 'goalkeepingSkills' },
    { id: 'shotStopperDrills', name: 'Shot-Stopper Drills', type: 'goalkeepingSkills' },
    { id: 'goalKicksandDistribution', name: 'Goal Kicks and Distribution', type: 'goalkeepingSkills' },
    { id: 'reactionandReflexTraining', name: 'Reaction and Reflex Training', type: 'goalkeepingSkills' },
  ],
  defensiveSkills: [
    { id: 'tacklingDrills', name: 'Tackling Drills', type: 'defensiveSkills' },
    { id: 'positionalAwareness', name: 'Positional Awareness', type: 'defensiveSkills' },
    { id: 'markingandIntercepting', name: 'Marking and Intercepting', type: 'defensiveSkills' },
    { id: 'defensiveHeading', name: 'Defensive Heading', type: 'defensiveSkills' },
  ],
  midfieldPlay: [
    { id: 'ballDistributionSkills', name: 'Ball Distribution Skills', type: 'midfieldPlay' },
    { id: 'creatingSpace', name: 'Creating Space', type: 'midfieldPlay' },
    { id: 'supportPlay', name: 'Support Play', type: 'midfieldPlay' },
    { id: 'transitionDrills', name: 'Transition Drills', type: 'midfieldPlay' },
  ],
  attackingSkills: [
    { id: 'finishingDrills', name: 'Finishing Drills', type: 'attackingSkills' },
    { id: 'crossingandWidePlay', name: 'Crossing and Wide Play', type: 'attackingSkills' },
    { id: 'one2oneDribbling', name: '1-on-1 Dribbling', type: 'attackingSkills' },
    { id: 'attackingHeadings', name: 'Attacking Headings', type: 'attackingSkills' },
  ],
  setPieces: [
    { id: 'cornerKicks', name: 'Corner Kicks', type: 'setPieces' },
    { id: 'freeKickPractice', name: 'Free Kick Practice', type: 'setPieces' },
    { id: 'penaltyKicks', name: 'Penalty Kicks', type: 'setPieces' },
    { id: 'tacticalSetPiecePlays', name: 'Tactical Set Piece Plays', type: 'setPieces' },
  ],
  teamCohesionDrills: [
    { id: 'teamBuildingActivities', name: 'Team Building Activities', type: 'teamCohesionDrills' },
    { id: 'communicationDrills', name: 'Communication Drills', type: 'teamCohesionDrills' },
    { id: 'positionalSwapGames', name: 'Positional Swap Games', type: 'teamCohesionDrills' },
    { id: 'matchSimulation', name: 'Match Simulation', type: 'teamCohesionDrills' },
  ]
};

const formatPropertyName = (name) => {
  return name
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export default function SpecializedTrainingPanel({
  isOpen,
  selectedSpecialized,
  setSelectedSpecialized,
  setIsPanelOpen,
}) {
  const [localSpecialized, setLocalSpecialized] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    setLocalSpecialized(Object.values(allSpecialized).flat());
  }, []);

  const handleSelect = (specializedId) => {
    const currentIndex = selectedSpecialized.findIndex((item) => item.id === specializedId);
    const newSelectedSpecialized = [...selectedSpecialized];

    if (currentIndex === -1) {
      const newSpecialized = localSpecialized.find((item) => item.id === specializedId);
      newSelectedSpecialized.push(newSpecialized);
    } else {
      newSelectedSpecialized.splice(currentIndex, 1);
    }

    setSelectedSpecialized(newSelectedSpecialized);
  };

  const selectAll = () => {
    setSelectedSpecialized(localSpecialized);
  };

  const removeAll = () => {
    setSelectedSpecialized([]);
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => setIsPanelOpen(false)}
      variant="persistent"
      ModalProps={{ keepMounted: true }}
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: '#ffffff',
          color: '#fff',
          borderRadius: '8px 0 0 8px',
          boxShadow: theme.shadows[5],
          transition: 'width 0.3s',
        }
      }}
    >
      <Box sx={{ width: 400, padding: 2 }}>
        <IconButton
          onClick={() => setIsPanelOpen(false)}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            zIndex: 1300,
            backgroundColor: 'white',
            borderRadius: '50%',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" style={{ padding: '16px' }}>
          Specialized Training List
        </Typography>
        {Object.keys(allSpecialized).map((category) => (
          <Accordion key={category} sx={{
            backgroundColor: '#356596',
            color: '#fff',
            '& .MuiAccordionSummary-root': {
              backgroundColor: '#3e3e3e',
            }
          }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}>
              <Typography>{formatPropertyName(category)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List style={{ maxHeight: '70vh', overflow: 'auto' }}>
                {allSpecialized[category].map((specialized) => (
                  <ListItem key={specialized.id} sx={{
                    '&:hover': {
                      backgroundColor: '#3e3e3e',
                    }
                  }}>
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon sx={{ color: '#fff' }} />}
                      checkedIcon={<CheckCircleIcon sx={{ color: '#ffffff' }} />}
                      checked={selectedSpecialized.some((e) => e.id === specialized.id)}
                      onChange={() => handleSelect(specialized.id)}
                      sx={{
                        color: '#356596',
                        '&.Mui-checked': {
                          color: '#ffffff',
                        }
                      }}
                    />
                    <ListItemText primary={specialized.name} />
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
        <Box display="flex" justifyContent="space-between" p={1}>
          <Button onClick={selectAll} style={{
            transition: 'background-color 0.3s, color 0.3s',
            color: '#356596',
            borderColor: '#ffffff'
          }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#356596';
              e.target.style.color = '#fff';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = 'transparent';
              e.target.style.color = '#356596';
            }}>
            Select All
          </Button>
          <Button onClick={removeAll} style={{
            transition: 'background-color 0.3s, color 0.3s',
            color: '#f44336',
            borderColor: '#ffffff'
          }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#f44336';
              e.target.style.color = '#fff';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = 'transparent';
              e.target.style.color = '#f44336';
            }}>
            Remove All
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
