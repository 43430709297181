// // Custom PlayerList component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState, useEffect } from 'react';
import { Box, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Grid, Divider, TextField, Pagination, Button, Drawer } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Client, Databases, Query } from 'appwrite';
import conf from '../conf/conf';
import DetailedContactCard from '../components/DetailedContactCard';
import authService from '../appwrite/auth';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

export default function PlayerList() {
    const [players, setPlayers] = useState([]);
    const [open, setOpen] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isPinned, setIsPinned] = useState(false);
    const rowsPerPage = 9;

    useEffect(() => {
        const fetchPlayers = async () => {
            try {
                const currentUser = await authService.getCurrentUser();
                if (!currentUser) {
                    window.location.href = '/login';
                    return;
                }
                const userId = currentUser.$id;
                const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteCollectionId, [
                    Query.equal('userID', userId)
                ]);
                const data = response.documents.map((doc) => ({
                    id: doc.$id,
                    jerseyNumber: doc.jerseyNumber,
                    fullName: doc.fullName,
                    pPosition: doc.pposition,
                    email: doc.email,
                    phone: doc.phone,
                    dateOfBirth: doc.dateOfBirth,
                    position: doc.position,
                    nationality: doc.nationality,
                    previousClubs: doc.previousClubs,
                    addressLine1: doc.addressLine1,
                    addressLine2: doc.addressLine2,
                    emergencyName: doc.emergencyName,
                    emergencyRelationship: doc.emergencyRelationship,
                    emergencyPhone: doc.emergencyPhone,
                    image: doc.image ? `https://cloud.appwrite.io/v1/storage/buckets/${conf.appwriteBucketId}/files/${doc.image}/view?project=${conf.appwriteProjectId}` : null,
                    playingFoot: doc.playingFoot,
                    joinedClub: doc.joinedClub,
                    fifaPlayerID: doc.fifaplayerID,
                    weight: doc.weight,
                    height: doc.height,
                    userID: doc.userID,
                    userEmail: doc.userEmail
                }));
                setPlayers(data);
            } catch (error) {
                console.error('Error fetching players:', error);
            }
        };

        fetchPlayers();
    }, []);

    const handleOpen = (id) => {
        setOpen(prev => ({ ...prev, [id]: !prev[id] }));
    };

    const handleDetailsClick = (player) => {
        setSelectedPlayer(player);
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        if (!isPinned) { 
            setDrawerOpen(false);
            setSelectedPlayer(null);
        }
    };

    const togglePin = () => {
        setIsPinned(prev => !prev);
    };

    const indexOfLastPlayer = currentPage * rowsPerPage;
    const indexOfFirstPlayer = indexOfLastPlayer - rowsPerPage;
    const currentPlayers = players.slice(indexOfFirstPlayer, indexOfLastPlayer);

    return (
        <Box sx={{ padding: '20px' }}>
            <Typography variant="h4" sx={{ marginBottom: '20px' }}>Player List</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead style={{ backgroundColor: 'black' }}>
                        <TableRow>
                            <TableCell />
                            <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Jersey Number</TableCell>
                            <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Full Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Primary Position</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentPlayers.map((player) => (
                            <React.Fragment key={player.id}>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                    <TableCell>
                                        <IconButton size="small" onClick={() => handleOpen(player.id)}>
                                            {open[player.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{player.jerseyNumber}</TableCell>
                                    <TableCell>{player.fullName}</TableCell>
                                    <TableCell>{player.pPosition}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                        <Collapse in={open[player.id]} timeout="auto" unmountOnExit sx={{ backgroundColor: '#f2f2f2', padding: '10px' }}>
                                            <Box margin={1}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12} md={2}>
                                                        {player.image ? (
                                                            <img src={player.image} alt={player.fullName} style={{ width: '100%', maxWidth: '200px', marginRight: '10px' }} onError={(e) => console.error('Image Load Error:', e)} />
                                                        ) : (
                                                            <Typography variant="body2" color="textSecondary">No Image</Typography>
                                                        )}
                                                        <Box sx={{ mt: 3, mx: 5 }}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => handleDetailsClick(player)}
                                                                size="small"
                                                                sx={{
                                                                    backgroundColor: '#356596',
                                                                    color: '#ffffff',
                                                                    '&:hover': {
                                                                        backgroundColor: '#ffffff',
                                                                        color: '#356596'
                                                                    }
                                                                }}
                                                            >
                                                                Details
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={10}>
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Email"
                                                                    value={player.email}
                                                                    InputProps={{ readOnly: true }}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Phone"
                                                                    value={player.phone}
                                                                    InputProps={{ readOnly: true }}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Date of Birth"
                                                                    value={player.dateOfBirth}
                                                                    InputProps={{ readOnly: true }}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Divider style={{ margin: '10px 0' }} />

                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Secondary Position"
                                                                    value={player.position}
                                                                    InputProps={{ readOnly: true }}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Nationality"
                                                                    value={player.nationality}
                                                                    InputProps={{ readOnly: true }}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Previous Clubs"
                                                                    value={player.previousClubs}
                                                                    InputProps={{ readOnly: true }}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Divider style={{ margin: '10px 0' }} />

                                                        <TextField
                                                            label="Address"
                                                            value={`${player.addressLine1}, ${player.addressLine2}`}
                                                            InputProps={{ readOnly: true }}
                                                            margin="normal"
                                                            fullWidth
                                                            multiline
                                                            rows={2}
                                                            variant="outlined"
                                                        />
                                                        <Divider style={{ margin: '10px 0' }} />

                                                        <TextField
                                                            label="Emergency Contact"
                                                            value={`${player.emergencyName} (${player.emergencyRelationship}) - ${player.emergencyPhone}`}
                                                            InputProps={{ readOnly: true }}
                                                            margin="normal"
                                                            fullWidth
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination
                    count={Math.ceil(players.length / rowsPerPage)}
                    page={currentPage}
                    onChange={(event, page) => setCurrentPage(page)}
                />
            </Box>
            <Drawer 
                anchor="right" 
                open={drawerOpen} 
                onClose={handleDrawerClose}
                ModalProps={{ keepMounted: true }}
            >
                <DetailedContactCard 
                    player={selectedPlayer} 
                    onClose={handleDrawerClose} 
                    isPinned={isPinned} 
                    togglePin={togglePin} 
                />
            </Drawer>
        </Box>
    );
}
