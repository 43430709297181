// // Custom CardDetails component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState, useEffect } from 'react';
import { Box, Card, Typography, RadioGroup, FormControlLabel, Radio, TextField, Button, Chip } from '@mui/material';
import { Client, Databases, Query, ID } from 'appwrite';
import conf from '../conf/conf';
import authService from '../appwrite/auth';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const CardDetails = ({ time, period, matchID, details, setDetails, onClose }) => {
    const [teamPlayers, setTeamPlayers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredPlayers, setFilteredPlayers] = useState([]);

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            height: '30px'
        },
        '& .MuiSelect-select': {
            height: '30px',
            display: 'flex',
            alignItems: 'center'
        }
    };

    useEffect(() => {
        const fetchPlayers = async () => {
            try {
                const currentUser = await authService.getCurrentUser();
                if (!currentUser) {
                    throw new Error('No user is currently logged in.');
                }
                const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.createSquadCollectionId, [
                    Query.equal('userID', currentUser.$id),
                ]);
                const players = response.documents.flatMap(doc => {
                    const startingEleven = doc.startingEleven.map(player => JSON.parse(player));
                    const substitutes = doc.substitutes.map(player => JSON.parse(player));
                    return [...startingEleven, ...substitutes];
                });
                setTeamPlayers(players);
            } catch (error) {
                console.error('Error fetching players:', error);
            }
        };

        fetchPlayers();
    }, []);

    useEffect(() => {
        if (searchQuery.length >= 3) {
            setFilteredPlayers(
                teamPlayers.filter(player => player.fullName && player.fullName.toLowerCase().includes(searchQuery.toLowerCase()))
            );
        } else {
            setFilteredPlayers([]);
        }
    }, [searchQuery, teamPlayers]);

    const handlePlayerSelect = (player) => {
        setDetails(prevDetails => ({ ...prevDetails, selectedPlayer: player }));
        setSearchQuery('');
        setFilteredPlayers([]);
    };

    const handleSave = async () => {
        try {
            const currentUser = await authService.getCurrentUser();
            const cardData = {
                matchID,
                userID: currentUser.$id,
                userEmail: currentUser.email,
                cardType: details.cardType,
                teamPlayer: details.selectedPlayer?.fullName || '',
                oppositionPlayer: details.oppositionPlayer || '',
                reason: details.reason,
                period,
                minute: time,
                comment: details.comment
            };
            await databases.createDocument(conf.appwriteDatabaseId, conf.cardsCollectionId, ID.unique(), cardData);
            alert('Card data saved successfully');
            setDetails({ selectedPlayer: null, cardType: '', oppositionPlayer: '', reason: '', comment: '' });
            onClose();
        } catch (error) {
            console.error('Error saving card data:', error);
        }
    };

    return (
        <Card sx={{ padding: 2, width: '100%', boxSizing: 'border-box' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">Capture Card Details</Typography>
                <Typography variant="body1">| Period: <strong>{period}</strong> | Minute: <strong>{time}</strong></Typography>
            </Box>
            <RadioGroup row value={details.cardType} onChange={(e) => setDetails(prevDetails => ({ ...prevDetails, cardType: e.target.value }))}>
                <FormControlLabel value="yellow" control={<Radio />} label="Yellow" />
                <FormControlLabel value="red" control={<Radio />} label="Red" />
            </RadioGroup>
            <Typography variant="subtitle1">Team Player</Typography>
            <TextField
                fullWidth
                placeholder="Search Team Player"
                margin="normal"
                sx={textFieldStyle}
                InputProps={{ style: { height: '30px' } }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                autoComplete="off"
            />
            {filteredPlayers.length > 0 && (
                <Box sx={{ maxHeight: '150px', overflowY: 'auto', border: '1px solid #ccc', marginTop: '5px' }}>
                    {filteredPlayers.map((player, index) => (
                        <Box key={index} sx={{ padding: '5px', cursor: 'pointer' }} onClick={() => handlePlayerSelect(player)}>
                            {`${player.fullName} | No ${player.jerseyNumber} | ${player.pposition}`}
                        </Box>
                    ))}
                </Box>
            )}
            {details.selectedPlayer && (
                <Box sx={{ mt: 2 }}>
                    <Chip label={`${details.selectedPlayer.fullName} | No ${details.selectedPlayer.jerseyNumber} | ${details.selectedPlayer.pposition}`} />
                </Box>
            )}
            <Typography variant="subtitle1">Opposition Player</Typography>
            <TextField
                fullWidth
                placeholder="Enter Opposition Player"
                margin="normal"
                sx={textFieldStyle}
                InputProps={{ style: { height: '30px' } }}
                value={details.oppositionPlayer}
                onChange={(e) => setDetails(prevDetails => ({ ...prevDetails, oppositionPlayer: e.target.value }))}
            />
            <Typography variant="subtitle1">Reason</Typography>
            <RadioGroup row value={details.reason} onChange={(e) => setDetails(prevDetails => ({ ...prevDetails, reason: e.target.value }))}>
                <FormControlLabel value="dangerous_play" control={<Radio />} label="Dangerous Play" />
                <FormControlLabel value="violent_conduct" control={<Radio />} label="Violent Conduct" />
                <FormControlLabel value="dissent" control={<Radio />} label="Dissent" />
            </RadioGroup>
            <TextField
                fullWidth
                label="Comment"
                multiline
                rows={3}
                margin="normal"
                sx={{ '& .MuiInputBase-root': { height: 'auto' } }}
                value={details.comment}
                onChange={(e) => setDetails(prevDetails => ({ ...prevDetails, comment: e.target.value }))}
            />
            <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
        </Card>
    );
};

export default CardDetails;

