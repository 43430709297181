import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, ListSubheader, Tooltip } from '@mui/material';

export default function PPositionSelector({ selectedPPosition, setSelectedPPosition, error }) {
    const handlePPositionChange = (event) => {
        setSelectedPPosition(event.target.value); // Update parent component state
    };

    const positionOptions = [
        "Goalkeeper", "LB - Left-Back", "CB - Centre-Back", "RB - Right-Back", 
        "DM - Defensive Midfielder", "LM - Left Midfielder", "CM - Central Midfielder", 
        "RM - Right Midfielder", "AM - Attacking Midfielder", "LW - Left Winger", 
        "CF - Centre Forward", "RW - Right Winger"
    ];

    return (
        <Tooltip title={error || ''} open={!!error} placement="left" arrow>
            <FormControl
                fullWidth
                size="small"
                sx={{
                    marginBottom: '1px',
                    '& .MuiInputBase-input': {
                        fontSize: '0.65rem',
                        padding: '16px 12px',
                        fontFamily: 'Roboto, sans-serif'
                    },
                    '& .MuiInputBase-root': {
                        marginTop: '8px',
                        marginBottom: '8px'
                    }
                }}
            >
                <InputLabel id="pposition-select-label" sx={{ fontSize: '0.65rem', fontFamily: 'Roboto, sans-serif' }}>
                    Preferred Position
                </InputLabel>
                <Select
                    labelId="pposition-select-label"
                    id="pposition-select"
                    value={selectedPPosition} // Use value from parent component
                    label="PPosition"
                    onChange={handlePPositionChange}
                    sx={{ fontSize: '0.65rem', fontFamily: 'Roboto, sans-serif' }}
                >
                    <MenuItem value=""><em>None</em></MenuItem>
                    {positionOptions.map(option => (
                        <MenuItem key={option} value={option} sx={{ fontSize: '0.65rem', fontFamily: 'Roboto, sans-serif' }}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Tooltip>
    );
}
