
// // Custom SubscriptionActivate component
// // Created by Shamiel Speelman
// // Version: 0.1import React from 'react';
import { Button, Box, Typography, ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#356596',
            dark: '#FFFFFF',
        },
        action: {
            active: '#356596',
            hover: '#e9edf2',
            hoverOpacity: 0.8,
        },
    },
});

const SubscriptionActivate = ({ onBack }) => {
    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 3,
                    borderRadius: 2,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    transition: 'box-shadow 0.3s ease-in-out',
      '&:hover': {
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.9)'
      }
                }}
            >
                {['Football', 'Cricket', 'Rugby', 'Generic'].map((sport) => (
                    <Box
                        key={sport}
                        
                        sx={{
                            height: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            bgcolor: 'primary.main',
                            color: '#e9edf2',
                            borderRadius: 1,
                            transition: 'background-color 0.5s ease, color 0.5s ease',
                            '&:hover': {
                                bgcolor: 'primary.dark',
                                color: 'primary.main',
                                
                            },
                            marginBottom: '1px',
                '& .MuiInputBase-input': {
                  fontSize: '0.75rem',
                  padding: '6px 12px'
                },
                '& .MuiInputBase-root': {
                  marginTop: '8px',
                  marginBottom: '8px'
                }
             
                        }}
                    >
                        <Typography>{sport}</Typography>
                    </Box>
                ))}
                <Button
                    variant="contained"
                    size="small" sx={{

                        '& .MuiInputBase-input': {
                          fontSize: '0.75rem',
                          padding: '6px 12px'
                        },
                        '& .MuiInputBase-root': {
                          marginTop: '8px',
                          marginBottom: '8px'
                        },
                        backgroundColor: '#ffffff',
                    color: '#356596',
                    '&:hover': {
                      backgroundColor: '#356596',
                      color: '#ffffff'}
                      }}
                    onClick={onBack}
                >
                    Back
                </Button>
            </Box>
        </ThemeProvider>
    );
}

export default SubscriptionActivate;
