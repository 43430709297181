// // Custom Terms component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TermsOfUse = ({ onClose }) => {
  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Card sx={{
        width: '80%',
        maxWidth: '800px',
        backgroundColor: '#356596',
        color: '#ffffff',
        borderRadius: '10px',
        overflowY: 'auto',
        transition: 'box-shadow 0.7s ease-in-out',
        '&:hover': {
          boxShadow: '0 10px 16px rgba(0, 0, 0, 0.9)'
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        mt: 6
      }}>
        <CardContent>
          <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
            Terms of Use
          </Typography>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{ backgroundColor: '#ffffff' }}>
              <Typography sx={{ color: '#356596' }}>Introduction</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#356596', color: '#ffffff' }}>
              <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                Welcome to FitSport | RPE. By accessing or using our application, you agree to comply with and be bound by these Terms of Use. If you do not agree to these terms, please do not use our application.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header" sx={{ backgroundColor: '#ffffff' }}>
              <Typography sx={{ color: '#356596' }}>User Accounts</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#356596', color: '#ffffff' }}>
              <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                To access certain features of our application, you may need to create an account. You are responsible for maintaining the confidentiality of your account information, including your password, and for all activities that occur under your account.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header" sx={{ backgroundColor: '#ffffff' }}>
              <Typography sx={{ color: '#356596' }}>Prohibited Activities</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#356596', color: '#ffffff' }}>
              <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                You agree not to engage in any of the following prohibited activities:
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '0.9rem', ml: 2 }}>
                • Using the application for any illegal purpose.<br />
                • Attempting to gain unauthorized access to our application or user accounts.<br />
                • Interfering with the operation of the application or any user’s enjoyment of it.<br />
                • Uploading or transmitting viruses or other harmful code.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header" sx={{ backgroundColor: '#ffffff' }}>
              <Typography sx={{ color: '#356596' }}>Intellectual Property</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#356596', color: '#ffffff' }}>
              <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                All content, features, and functionality of the application, including but not limited to text, graphics, logos, and software, are the exclusive property of FitSport | RPE and are protected by copyright, trademark, and other intellectual property laws.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5a-content" id="panel5a-header" sx={{ backgroundColor: '#ffffff' }}>
              <Typography sx={{ color: '#356596' }}>Termination</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#356596', color: '#ffffff' }}>
              <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                We may terminate or suspend your account and access to the application at our sole discretion, without prior notice or liability, for any reason, including if you breach these Terms of Use.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6a-content" id="panel6a-header" sx={{ backgroundColor: '#ffffff' }}>
              <Typography sx={{ color: '#356596' }}>Limitation of Liability</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#356596', color: '#ffffff' }}>
              <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                FitSport | RPE will not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the application.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel7a-content" id="panel7a-header" sx={{ backgroundColor: '#ffffff' }}>
              <Typography sx={{ color: '#356596' }}>Governing Law</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#356596', color: '#ffffff' }}>
              <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                These Terms of Use shall be governed by and construed in accordance with the laws of the jurisdiction in which FitSport | RPE operates, without regard to its conflict of law provisions.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel8a-content" id="panel8a-header" sx={{ backgroundColor: '#ffffff' }}>
              <Typography sx={{ color: '#356596' }}>Contact Us</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#356596', color: '#ffffff' }}>
              <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                If you have any questions about these Terms of Use, please contact us at support@fitsportrpe.com.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="outlined" style={{ width: '100%' }} size="small" sx={{
              '& .MuiInputBase-input': {
                fontSize: '0.75rem',
                padding: '6px 12px'
              },
              '& .MuiInputBase-root': {
                marginTop: '8px',
                marginBottom: '8px'
              },
              backgroundColor: '#356596',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#ffffff',
                color: '#356596'
              }
            }} onClick={onClose}>
              Close
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TermsOfUse;
