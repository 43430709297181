// // Custom AcceptCookies component
// // Created by Shamiel Speelman
// // Version: 0.1

import React from 'react'; 
import { Card, CardContent, Typography, Button, Box } from '@mui/material';

const CookiesCard = ({ onAccept, onReject }) => {
  return (
    <Card sx={{
      width: '200px',
      minHeight: '300px',
      backgroundColor: '#356596',
      color: '#ffffff',
      borderRadius: '10px',
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      transition: 'transform 0.7s ease-in-out',
      transform: 'translateY(0)',
      '&:hover': {
        boxShadow: '0 10px 16px rgba(0, 0, 0, 0.9)'
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      zIndex: 1000,
    }}>
      <CardContent sx={{ overflowY: 'auto', px: 1 }}>
        <Typography variant="body2" sx={{ fontSize: '0.75rem', mb: 2 }}>
          "We use essential cookies to make our website work. We also set additional cookies that help us improve your experience, help keep you safe, perform analytics, and serve relevant ads. These additional cookies will be set only if you click ‘Accept’ below. For more information about the cookies we use, or to change your preferences, please visit our Cookies Policy."
        </Typography>
      </CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'space-around', p: 1 }}>
        <Button 
          onClick={onAccept}
          variant="contained" 
          style={{ width: '100%' }} 
          size="small" 
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '0.75rem',
              padding: '6px 12px'
            },
            '& .MuiInputBase-root': {
              marginTop: '8px',
              marginBottom: '8px'
            },
            backgroundColor: '#ffffff',
            color: '#356596',
            '&:hover': {
              backgroundColor: '#356596',
              color: '#ffffff'
            }
          }}
        >
          I Accept
        </Button>
        <Button 
          onClick={onReject}
          variant="outlined" 
          style={{ width: '100%' }} 
          size="small" 
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '0.75rem',
              padding: '6px 12px'
            },
            '& .MuiInputBase-root': {
              marginTop: '8px',
              marginBottom: '8px'
            },
            backgroundColor: '#356596',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#ffffff',
              color: '#356596'
            }
          }}
        >
          Reject
        </Button>
      </Box>
    </Card>
  );
};

export default CookiesCard;
