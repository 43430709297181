// // Custom ManageCommunications component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, Divider } from '@mui/material';
import conf from '../conf/conf';
import { Client, Databases, Query } from 'appwrite';

const client = new Client()
  .setEndpoint(conf.appwriteUrl)
  .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const deleteTelegramMessage = async (chatId, messageId) => {
  const url = `https://api.telegram.org/bot${conf.telegramBotToken}/deleteMessage`;
  const payload = {
    chat_id: chatId,
    message_id: messageId
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    const data = await response.json();
    if (!data.ok) {
      throw new Error(`Error deleting message: ${data.description}`);
    }
    console.log(`Deleted message ${messageId} from chat ${chatId}`);
    return data.result;
  } catch (error) {
    console.error(`Failed to delete Telegram message ${messageId} from chat ${chatId}:`, error);
    throw error;
  }
};

const ManageCommunication = () => {
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchTrackedMessages = async () => {
      setLoading(true);
      setStatus('Fetching tracked messages...');

      try {
        const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.messageTrackingCollectionId);
        const trackedMessages = response.documents.map(doc => ({
          chatId: doc.chatId,
          messageId: doc.messageId,
          text: doc.text,
        }));

        console.log('Fetched tracked messages:', trackedMessages);
        setMessages(trackedMessages);
        setStatus('Tracked messages fetched.');
      } catch (error) {
        setStatus(`Failed to fetch tracked messages: ${error.message}`);
      }

      setLoading(false);
    };

    fetchTrackedMessages();
  }, []);

  const handleClearHistory = async () => {
    setLoading(true);
    setStatus('Clearing message history...');

    try {
      for (const { chatId, messageId } of messages) {
        try {
          await deleteTelegramMessage(chatId, messageId);
          setStatus(prevStatus => `${prevStatus}\nDeleted message ${messageId} from chat ${chatId}`);
        } catch (error) {
          if (error.message.includes('message to delete not found')) {
            setStatus(prevStatus => `${prevStatus}\nMessage ${messageId} already deleted or not found.`);
          } else {
            setStatus(prevStatus => `${prevStatus}\nFailed to delete message ${messageId} from chat ${chatId}: ${error.message}`);
          }
        }
      }

      setStatus(prevStatus => `${prevStatus}\nMessage history cleared.`);
    } catch (error) {
      setStatus(`Failed to clear message history: ${error.message}`);
    }

    setLoading(false);
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>Manage Communication</Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClearHistory}
        disabled={loading}
      >
        {loading ? 'Clearing...' : 'Clear History'}
      </Button>
      <Typography variant="body1" sx={{ marginTop: '20px', whiteSpace: 'pre-wrap' }}>{status}</Typography>
      <Box sx={{ marginTop: '20px' }}>
        {messages.map((message, index) => (
          <Box key={index} sx={{ marginBottom: '10px' }}>
            <Typography variant="body2">
              <strong>Chat ID:</strong> {message.chatId}
            </Typography>
            <Typography variant="body2">
              <strong>Message ID:</strong> {message.messageId}
            </Typography>
            <Typography variant="body2">
              <strong>Text:</strong> {message.text}
            </Typography>
            <Divider sx={{ marginTop: '10px' }} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ManageCommunication;
