// import React, { useState, useEffect } from 'react';
// import { TableContainer, Table, TableHead, TableCell, TableBody, TableRow, Card, CardContent, Typography, IconButton, Grid, Divider, Tabs, Tab, Box, Avatar, Paper, Collapse, List, ListItem, ListItemText, Button, Menu, MenuItem } from '@mui/material';
// import { Close, PushPin, Phone, Email, Home, Edit, Info, ExpandLess, ExpandMore } from '@mui/icons-material';
// import { Client, Databases, Query } from 'appwrite';
// import conf from '../conf/conf';
// import authService from '../appwrite/auth';

// const client = new Client()
//     .setEndpoint(conf.appwriteUrl)
//     .setProject(conf.appwriteProjectId);

// const databases = new Databases(client);

// // Utility functions for conversions
// const convertHeightToFeetAndInches = (heightInMeters) => {
//   const totalInches = heightInMeters * 39.37;
//   const feet = Math.floor(totalInches / 12);
//   const inches = Math.round(totalInches % 12);
//   return `${feet}'${inches}"`;
// };

// const convertKgToLbs = (weightInKg) => {
//   const pounds = weightInKg * 2.20462;
//   return `${pounds.toFixed(2)} lbs`;
// };

// const calculateAge = (dateOfBirth) => {
//   const birthDate = new Date(dateOfBirth);
//   const difference = Date.now() - birthDate.getTime();
//   const ageDate = new Date(difference);
//   return Math.abs(ageDate.getUTCFullYear() - 1970);
// };

// const truncateName = (name, maxLength) => {
//   if (name.length > maxLength) {
//     return `${name.substring(0, maxLength)}...`;
//   }
//   return name;
// };

// const DetailedContactCard = ({ player, onClose, clubs }) => {
//   const [openDetails, setOpenDetails] = useState(true); // Personal Details open by default
//   const [openStatistics, setOpenStatistics] = useState(false);
//   const [openCareerStats, setOpenCareerStats] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [tabValue, setTabValue] = useState(0);
//   const [defaultClub, setDefaultClub] = useState('');
//   const [lastGame, setLastGame] = useState(null);
//   const [nextGame, setNextGame] = useState(null);
//   const [clubsData, setClubsData] = useState({});
//   const [playerStats, setPlayerStats] = useState({ goals: 0, assists: 0, distance: 0 });

//   useEffect(() => {
//     console.log('Config:', conf);

//     const fetchUserData = async () => {
//       try {
//         const currentUser = await authService.getCurrentUser();
//         console.log('Current user:', currentUser);
//         if (!currentUser) {
//           throw new Error('No user is currently logged in.');
//         }

//         const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteUsersId, [
//             Query.equal('userid', currentUser.$id)
//         ]);

//         if (response.total > 0) {
//           const userDoc = response.documents[0];
//           console.log('User document:', userDoc);
//           const defaultClubId = userDoc.defaultClub;

//           if (defaultClubId) {
//             try {
//               const clubDoc = await databases.getDocument(conf.appwriteDatabaseId, conf.createClubCollectionId, defaultClubId);
//               console.log('Club document:', clubDoc);
//               setDefaultClub(clubDoc.teamname);
//             } catch (error) {
//               console.error('Error fetching default club:', error);
//               setDefaultClub('Unknown Club');
//             }
//           } else {
//             setDefaultClub('No default club set');
//           }
//         } else {
//           throw new Error('User document not found.');
//         }
//       } catch (error) {
//         console.error('Error fetching user data or default club:', error);
//         setDefaultClub('Error fetching default club');
//       }
//     };

//     fetchUserData();
//   }, []);

//   useEffect(() => {
//     const fetchMatchData = async () => {
//       try {
//         if (!player || !player.fullName) {
//           return;
//         }
  
//         // Fetch goals and assists from the goals collection
//         const goalsResponse = await databases.listDocuments(
//           conf.appwriteDatabaseId,
//           conf.goalsCollectionId,
//           [
//             Query.equal('teamPlayer', player.fullName)
//           ]
//         );
  
//         const assistsResponse = await databases.listDocuments(
//           conf.appwriteDatabaseId,
//           conf.goalsCollectionId,
//           [
//             Query.equal('assistPlayer', player.fullName)
//           ]
//         );
  
//         // Fetch distance covered from the substitutes collection
//         const distanceResponse = await databases.listDocuments(
//           conf.appwriteDatabaseId,
//           conf.substitutesCollectionId,
//           [
//             Query.equal('selectedPlayerOut', player.fullName)
//           ]
//         );
  
//         // Calculate the stats
//         const goals = goalsResponse.documents.length;
//         const assists = assistsResponse.documents.length;
//         const distance = distanceResponse.documents.reduce((acc, doc) => acc + (doc.distanceCovered || 0), 0) / 1000; // Convert meters to kilometers
  
//         // Set the player stats
//         setPlayerStats({ goals, assists, distance });
  
//         console.log('Player Stats:', { goals, assists, distance });
  
//         // Fetch match data and related club data as before
//         const matchResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.createSquadCollectionId, [
//           Query.equal('userID', player.userID),
//         ]);
  
//         const finalResultsResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.finalResultsCollectionId);
//         const results = finalResultsResponse.documents.reduce((acc, result) => {
//           acc[result.matchID] = result;
//           return acc;
//         }, {});
  
//         const sortedMatches = matchResponse.documents.sort((a, b) => new Date(a.matchDate) - new Date(b.matchDate));
//         const lastGameMatch = sortedMatches.slice().reverse().find(match => results[match.$id]);
//         const nextGameMatch = sortedMatches.find(match => !results[match.$id] && new Date(match.matchDate) > new Date());
  
//         setLastGame(lastGameMatch ? { ...lastGameMatch, result: results[lastGameMatch.$id] } : null);
//         setNextGame(nextGameMatch || null);
  
//         const clubIds = matchResponse.documents.map(match => match.defaultClub);
//         const clubPromises = clubIds.map(clubId => databases.getDocument(conf.appwriteDatabaseId, conf.createClubCollectionId, clubId));
//         const clubResponses = await Promise.all(clubPromises);
//         const clubData = clubResponses.reduce((acc, club) => {
//           acc[club.$id] = club;
//           return acc;
//         }, {});
//         setClubsData(clubData);
//       } catch (error) {
//         console.error('Error fetching match data:', error);
//       }
//     };
  
//     fetchMatchData();
//   }, [player]);
  
  
  

//   const handleDetailsClick = () => {
//     setOpenDetails(!openDetails);
//   };

//   const handleStatisticsClick = () => {
//     setOpenStatistics(!openStatistics);
//   };

//   const handleCareerStatsClick = () => {
//     setOpenCareerStats(!openCareerStats);
//   };

//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//   };

//   const renderLastGame = () => {
//     if (!lastGame) {
//       return <Typography variant="body2">Oops, looks like we have no data to display</Typography>;
//     }

//     const club = clubsData[lastGame.defaultClub];
//     const team1Name = club ? club.teamname : 'Loading...';
//     const team1Logo = club ? club.emblem : 'path/to/placeholder-image.png';

    

//     return (
//       <Box sx={{ textAlign: 'center' }}>
//         <Typography variant="body2">{lastGame.matchName}</Typography>
//         <Grid container justifyContent="center" alignItems="center">
//           <Grid item>
//             <Avatar src={team1Logo} alt={team1Name} sx={{ width: 56, height: 56 }} />
//             <Typography variant="body2">{truncateName(team1Name, 13)}</Typography>
//           </Grid>
//           <Grid item sx={{ mx: 2 }}>
//             <Typography variant="h4">{lastGame.result.finalScoreHome} - {lastGame.result.finalScoreOpposition}</Typography>
//           </Grid>
//           <Grid item>
//             <Avatar sx={{ width: 56, height: 56, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//               <Typography variant="h6" sx={{ fontSize: '14px' }}>OT</Typography>
//             </Avatar>
//             <Typography variant="body2">{truncateName(lastGame.opposition, 13)}</Typography>
//           </Grid>
//         </Grid>
//         <Typography variant="body2">{new Date(lastGame.matchDate).toLocaleDateString()}</Typography>
//       </Box>
//     );
//   };

//   const renderNextGame = () => {
//     if (!nextGame) {
//       return <Typography variant="body2">Oops, looks like we have no data to display</Typography>;
//     }

//     const club = clubsData[nextGame.defaultClub];
//     const team1Name = club ? club.teamname : 'Loading...';
//     const team1Logo = club ? club.emblem : 'path/to/placeholder-image.png';

//     return (
//       <Box sx={{ textAlign: 'center' }}>
//         <Typography variant="body2">{nextGame.matchName}</Typography>
//         <Grid container justifyContent="center" alignItems="center">
//           <Grid item>
//             <Avatar src={team1Logo} alt={team1Name} sx={{ width: 56, height: 56 }} />
//             <Typography variant="body2">{truncateName(team1Name, 13)}</Typography>
//           </Grid>
//           <Grid item sx={{ mx: 2 }}>
//             <Typography variant="h4">00:00</Typography>
//           </Grid>
//           <Grid item>
//             <Avatar sx={{ width: 56, height: 56, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//               <Typography variant="h6" sx={{ fontSize: '14px' }}>OT</Typography>
//             </Avatar>
//             <Typography variant="body2">{truncateName(nextGame.opposition, 13)}</Typography>
//           </Grid>
//         </Grid>
//         <Typography variant="body2">{new Date(nextGame.matchDate).toLocaleDateString()}</Typography>
//       </Box>
//     );
//   };

//   if (!player) {
//     return null; // or a loading indicator
//   }

//   return (
//     <Card sx={{ width: 400, height: '100vh', overflowY: 'auto', m: 2, position: 'relative', bgcolor: '#fff', transition: 'box-shadow 0.3s ease-in-out', 
//     '&:hover': {
//       boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)' 
//     } }}>
//       <CardContent>
//         <IconButton aria-label="close" sx={{ position: 'absolute', right: 0, top: 0 }} onClick={onClose}>
//           <Close />
//         </IconButton>
//         <IconButton aria-label="pin" sx={{ position: 'absolute', right: 36, top: 0 }}>
//           <PushPin />
//         </IconButton>
//         <Grid container spacing={2} alignItems="center">
//           <Grid item>
//             <Avatar src={player.image} sx={{ width: 56, height: 56, borderRadius: '10%', border: '1px solid grey' }} />
//           </Grid>
//           <Grid item xs>
//             <Typography variant="h6">{player.fullName}</Typography>
//             <Typography variant="body2" sx={{ color: 'green', display: 'flex', alignItems: 'center' }}>
//               <Typography variant="caption" sx={{ bgcolor: 'green', borderRadius: '50%', width: 8, height: 8, mr: 0.5, display: 'inline-block' }}></Typography> Contracted
//             </Typography>
//             <Typography variant="body2">Club: {defaultClub}</Typography>
//             <Typography variant="body2">Position: {player.pposition}</Typography>
//             <Typography variant="body2">Manager: {player.emergencyName}</Typography>
//           </Grid>
//         </Grid>
//         <Divider sx={{ my: 1 }} />
//         <Grid container alignItems="center" sx={{ mb: 1 }}>
//           <Phone sx={{ mr: 1 }} />
//           <Typography variant="body2">{player.phone}</Typography>
//         </Grid>
//         <Grid container alignItems="center" sx={{ mb: 1 }}>
//           <Email sx={{ mr: 1 }} />
//           <Typography variant="body2">{player.email}</Typography>
//         </Grid>
//         <Grid container alignItems="center" sx={{ mb: 1 }}>
//           <Home sx={{ mr: 1 }} />
//           <Typography variant="body2">{player.addressLine1} {player.addressLine2}</Typography>
//         </Grid>
//         <Divider sx={{ my: 1 }} />
//         <Button sx={{ mr: 1, bgcolor: '#356596', color: '#fff', '&:hover': { bgcolor: '#fff', color: '#356596' } }} variant="contained" startIcon={<Edit />}>EDIT</Button>
//         <Button sx={{ bgcolor: '#fff', color: '#356596', '&:hover': { bgcolor: '#356596', color: '#fff' } }} variant="contained" startIcon={<Info />}>DETAILS</Button>
//         <Button aria-controls="action-menu" aria-haspopup="true" onClick={handleMenuClick} sx={{ ml: 1, bgcolor: '#fff', color: '#356596', '&:hover': { bgcolor: '#356596', color: '#fff' } }}>
//            See Actions
//         </Button>
//         <Menu
//           id="action-menu"
//           anchorEl={anchorEl}
//           keepMounted
//           open={Boolean(anchorEl)}
//           onClose={handleMenuClose}
//         >
//           <MenuItem onClick={handleMenuClose}>Call</MenuItem>
//           <MenuItem onClick={handleMenuClose}>Send Fax</MenuItem>
//           <MenuItem onClick={handleMenuClose}>Send Email</MenuItem>
//           <MenuItem onClick={handleMenuClose}>Make a meeting</MenuItem>
//         </Menu>
//         <Divider sx={{ my: 1 }} />
//         <ListItem button onClick={handleDetailsClick} sx={{ '&:hover': { bgcolor: 'action.hover' }, bgcolor: openDetails ? 'action.hover' : '#fff' }}>
//           <ListItemText primary="Personal Details" />
//           {openDetails ? <ExpandLess /> : <ExpandMore />}
//         </ListItem>
//         <Collapse in={openDetails} timeout="auto" unmountOnExit>
//           <List component="div" disablePadding>
//             <Paper variant="outlined" sx={{ m: 1, p: 1 }}>
//               <Grid container spacing={2} alignItems="center">
//                 <Grid item xs={5}>
//                   <Typography variant="caption" sx={{ color: 'textSecondary' }}>Height</Typography>
//                   <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{player.height}m</Typography>
//                   <Typography variant="caption" sx={{ color: 'textSecondary' }}>{convertHeightToFeetAndInches(player.height)}</Typography>
//                 </Grid>
//                 <Divider orientation="vertical" flexItem />
//                 <Grid item xs={6}>
//                   <Typography variant="caption" sx={{ color: 'textSecondary' }}>Weight</Typography>
//                   <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{player.weight}kg</Typography>
//                   <Typography variant="caption" sx={{ color: 'textSecondary' }}>{convertKgToLbs(player.weight)}</Typography>
//                 </Grid>
//               </Grid>
//             </Paper>
//             <Paper variant="outlined" sx={{ m: 1, p: 1 }}>
//               <Grid container spacing={2} alignItems="center">
//                 <Grid item xs={5}>
//                   <Typography variant="body2">Born: {player.dateOfBirth}</Typography>
//                   <Typography variant="body2">Age: {calculateAge(player.dateOfBirth)}</Typography>
//                   <Typography variant="body2">From: {player.nationality}</Typography>
//                 </Grid>
//                 <Divider orientation="vertical" flexItem />
//                 <Grid item xs={6}>
//                   <Typography variant="body2">Debut: {player.joinedClub}</Typography>
//                   <Typography variant="body2">Years in League: </Typography>
//                   <Typography variant="body2">Previously: {player.previousClubs}</Typography>
//                 </Grid>
//               </Grid>
//             </Paper>
//           </List>
//         </Collapse>
//         <ListItem button onClick={handleStatisticsClick} sx={{ '&:hover': { bgcolor: 'action.hover' }, bgcolor: openStatistics ? 'action.hover' : '#fff' }}>
//           <ListItemText primary="Player Statistics" />
//           {openStatistics ? <ExpandLess /> : <ExpandMore />}
//         </ListItem>
//         <Collapse in={openStatistics} timeout="auto" unmountOnExit>
//           <List component="div" disablePadding>
//             <Grid container spacing={2} sx={{ m: 1, p: 1 }}>
//               <Grid item xs={4}>
//                 <Paper variant="outlined" sx={{ p: 1, ml: -1, textAlign: 'center', bgcolor: '#356596', color: '#fff' }}>
//                   <Typography variant="body2">Assists per game</Typography>
//                   <Typography variant="h5">{playerStats.assists}</Typography>
//                 </Paper>
//               </Grid>
//               <Grid item xs={3}>
//                 <Paper variant="outlined" sx={{ p: 1, ml: -1, textAlign: 'center', bgcolor: '#283747', color: '#fff' }}>
//                   <Typography variant="body2">Goals per game</Typography>
//                   <Typography variant="h5">{playerStats.goals}</Typography>
//                 </Paper>
//               </Grid>
//               <Grid item xs={4}>
//                 <Paper variant="outlined" sx={{ p: 1, ml: -1, textAlign: 'center', bgcolor: '#356596', color: '#fff' }}>
//                   <Typography variant="body2">Distance per game</Typography>
//                   <Typography variant="h5">{playerStats.distance.toFixed(2)} km</Typography>
//                 </Paper>
//               </Grid>
//             </Grid>
//             <Paper variant="outlined" sx={{ m: 1, p: 1 }}>
//               <Tabs value={tabValue} onChange={handleTabChange}>
//                 <Tab label="Last Game" />
//                 <Tab label="Next Game" />
//               </Tabs>
//               <Divider sx={{ my: 1 }} />
//               {tabValue === 0 && (
//                 <Box sx={{ textAlign: 'center' }}>
//                   {renderLastGame()}
//                 </Box>
//               )}
//               {tabValue === 1 && (
//                 <Box sx={{ textAlign: 'center' }}>
//                   {renderNextGame()}
//                 </Box>
//               )}
//             </Paper>
//           </List>
//         </Collapse>
//         <ListItem button onClick={handleCareerStatsClick} sx={{ '&:hover': { bgcolor: 'action.hover' }, bgcolor: openCareerStats ? 'action.hover' : '#fff' }}>
//           <ListItemText primary="Career Statistics" />
//           {openCareerStats ? <ExpandLess /> : <ExpandMore />}
//         </ListItem>
//         <Collapse in={openCareerStats} timeout="auto" unmountOnExit>
//           <List component="div" disablePadding>
//             <Paper variant="outlined" sx={{ m: 1, p: 1 }}>
//               <Typography variant="h6" sx={{ bgcolor: '#d32f2f', color: '#fff', p: 1, textAlign: 'center' }}>Coming Soon</Typography>
//               {/* <TableContainer>
//                 <Table size="small">
//                   <TableHead>
//                     <TableRow>
//                       <TableCell>Season</TableCell>
//                       <TableCell>Team</TableCell>
//                       <TableCell>Goals</TableCell>
//                       <TableCell>Assists</TableCell>
//                       <TableCell>Yellow Cards</TableCell>
//                       <TableCell>Red Cards</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     <TableRow>
//                       <TableCell>Last Season</TableCell>
//                       <TableCell>Eagles</TableCell>
//                       <TableCell>0</TableCell>
//                       <TableCell>2</TableCell>
//                       <TableCell>1</TableCell>
//                       <TableCell>0</TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>Next Season</TableCell>
//                       <TableCell>Eagles</TableCell>
//                       <TableCell>0</TableCell>
//                       <TableCell>0</TableCell>
//                       <TableCell>0</TableCell>
//                       <TableCell>0</TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>This Season</TableCell>
//                       <TableCell>Eagles</TableCell>
//                       <TableCell>0</TableCell>
//                       <TableCell>1</TableCell>
//                       <TableCell>2</TableCell>
//                       <TableCell>0</TableCell>
//                     </TableRow>
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </Paper>
//             <Paper variant="outlined" sx={{ m: 1, p: 1 }}>
//               <Typography variant="h6" sx={{ bgcolor: '#d32f2f', color: '#fff', p: 1, textAlign: 'center' }}>Career Total</Typography>
//               <TableContainer>
//                 <Table size="small">
//                   <TableHead>
//                     <TableRow>
//                       <TableCell>Season</TableCell>
//                       <TableCell>Goals</TableCell>
//                       <TableCell>Assists</TableCell>
//                       <TableCell>Yellow Cards</TableCell>
//                       <TableCell>Red Cards</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     <TableRow>
//                       <TableCell>Last Season</TableCell>
//                       <TableCell>0</TableCell>
//                       <TableCell>2</TableCell>
//                       <TableCell>1</TableCell>
//                       <TableCell>0</TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>Next Season</TableCell>
//                       <TableCell>0</TableCell>
//                       <TableCell>0</TableCell>
//                       <TableCell>0</TableCell>
//                       <TableCell>0</TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>This Season</TableCell>
//                       <TableCell>0</TableCell>
//                       <TableCell>1</TableCell>
//                       <TableCell>2</TableCell>
//                       <TableCell>0</TableCell>
//                     </TableRow>
//                   </TableBody>
//                 </Table>
//               </TableContainer> */}
//             </Paper>
//           </List>
//         </Collapse>
//       </CardContent>
//     </Card>
//   );
// };

// export default DetailedContactCard;
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, IconButton, Grid, Divider, Box, Avatar, Paper, Collapse, List, ListItem, ListItemText, Button, Menu, MenuItem, Tabs, Tab } from '@mui/material';
import { Close, PushPin, Phone, Email, Home, Edit, Info, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Client, Databases, Query } from 'appwrite';
import conf from '../conf/conf';
import authService from '../appwrite/auth';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const convertHeightToFeetAndInches = (heightInMeters) => {
  const totalInches = heightInMeters * 39.37;
  const feet = Math.floor(totalInches / 12);
  const inches = Math.round(totalInches % 12);
  return `${feet}'${inches}"`;
};

const convertKgToLbs = (weightInKg) => {
  const pounds = weightInKg * 2.20462;
  return `${pounds.toFixed(2)} lbs`;
};

const calculateAge = (dateOfBirth) => {
  const birthDate = new Date(dateOfBirth);
  const difference = Date.now() - birthDate.getTime();
  const ageDate = new Date(difference);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const truncateName = (name, maxLength) => {
  if (name.length > maxLength) {
    return `${name.substring(0, maxLength)}...`;
  }
  return name;
};

const DetailedContactCard = ({ player, onClose, isPinned, togglePin }) => {
  const [openDetails, setOpenDetails] = useState(true);
  const [openStatistics, setOpenStatistics] = useState(false);
  const [openCareerStats, setOpenCareerStats] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [defaultClub, setDefaultClub] = useState('');
  const [lastGame, setLastGame] = useState(null);
  const [nextGame, setNextGame] = useState(null);
  const [clubsData, setClubsData] = useState({});
  const [playerStats, setPlayerStats] = useState({ goals: 0, assists: 0, distance: 0 });

  useEffect(() => {
    console.log('Config:', conf);

    const fetchUserData = async () => {
      try {
        const currentUser = await authService.getCurrentUser();
        console.log('Current user:', currentUser);
        if (!currentUser) {
          throw new Error('No user is currently logged in.');
        }

        const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteUsersId, [
            Query.equal('userid', currentUser.$id)
        ]);

        if (response.total > 0) {
          const userDoc = response.documents[0];
          console.log('User document:', userDoc);
          const defaultClubId = userDoc.defaultClub;

          if (defaultClubId) {
            try {
              const clubDoc = await databases.getDocument(conf.appwriteDatabaseId, conf.createClubCollectionId, defaultClubId);
              console.log('Club document:', clubDoc);
              setDefaultClub(clubDoc.teamname);
            } catch (error) {
              console.error('Error fetching default club:', error);
              setDefaultClub('Unknown Club');
            }
          } else {
            setDefaultClub('No default club set');
          }
        } else {
          throw new Error('User document not found.');
        }
      } catch (error) {
        console.error('Error fetching user data or default club:', error);
        setDefaultClub('Error fetching default club');
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchMatchData = async () => {
      try {
        if (!player || !player.fullName) {
          return;
        }
  
        const goalsResponse = await databases.listDocuments(
          conf.appwriteDatabaseId,
          conf.goalsCollectionId,
          [
            Query.equal('teamPlayer', player.fullName)
          ]
        );
  
        const assistsResponse = await databases.listDocuments(
          conf.appwriteDatabaseId,
          conf.goalsCollectionId,
          [
            Query.equal('assistPlayer', player.fullName)
          ]
        );
  
        const distanceResponse = await databases.listDocuments(
          conf.appwriteDatabaseId,
          conf.substitutesCollectionId,
          [
            Query.equal('selectedPlayerOut', player.fullName)
          ]
        );
  
        const goals = goalsResponse.documents.length;
        const assists = assistsResponse.documents.length;
        const distance = distanceResponse.documents.reduce((acc, doc) => acc + (doc.distanceCovered || 0), 0) / 1000; // Convert meters to kilometers
  
        setPlayerStats({ goals, assists, distance });
  
        console.log('Player Stats:', { goals, assists, distance });
  
        const matchResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.createSquadCollectionId, [
          Query.equal('userID', player.userID),
        ]);
  
        const finalResultsResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.finalResultsCollectionId);
        const results = finalResultsResponse.documents.reduce((acc, result) => {
          acc[result.matchID] = result;
          return acc;
        }, {});
  
        const sortedMatches = matchResponse.documents.sort((a, b) => new Date(a.matchDate) - new Date(b.matchDate));
        const lastGameMatch = sortedMatches.slice().reverse().find(match => results[match.$id]);
        const nextGameMatch = sortedMatches.find(match => !results[match.$id] && new Date(match.matchDate) > new Date());
  
        setLastGame(lastGameMatch ? { ...lastGameMatch, result: results[lastGameMatch.$id] } : null);
        setNextGame(nextGameMatch || null);
  
        const clubIds = matchResponse.documents.map(match => match.defaultClub);
        const clubPromises = clubIds.map(clubId => databases.getDocument(conf.appwriteDatabaseId, conf.createClubCollectionId, clubId));
        const clubResponses = await Promise.all(clubPromises);
        const clubData = clubResponses.reduce((acc, club) => {
          acc[club.$id] = club;
          return acc;
        }, {});
        setClubsData(clubData);
      } catch (error) {
        console.error('Error fetching match data:', error);
      }
    };
  
    fetchMatchData();
  }, [player]);
  
  const handleDetailsClick = () => {
    setOpenDetails(!openDetails);
  };

  const handleStatisticsClick = () => {
    setOpenStatistics(!openStatistics);
  };

  const handleCareerStatsClick = () => {
    setOpenCareerStats(!openCareerStats);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderLastGame = () => {
    if (!lastGame) {
      return <Typography variant="body2">Oops, looks like we have no data to display</Typography>;
    }

    const club = clubsData[lastGame.defaultClub];
    const team1Name = club ? club.teamname : 'Loading...';
    const team1Logo = club ? club.emblem : 'path/to/placeholder-image.png';

    return (
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="body2">{lastGame.matchName}</Typography>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Avatar src={team1Logo} alt={team1Name} sx={{ width: 56, height: 56 }} />
            <Typography variant="body2">{truncateName(team1Name, 13)}</Typography>
          </Grid>
          <Grid item sx={{ mx: 2 }}>
            <Typography variant="h4">{lastGame.result.finalScoreHome} - {lastGame.result.finalScoreOpposition}</Typography>
          </Grid>
          <Grid item>
            <Avatar sx={{ width: 56, height: 56, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="h6" sx={{ fontSize: '14px' }}>OT</Typography>
            </Avatar>
            <Typography variant="body2">{truncateName(lastGame.opposition, 13)}</Typography>
          </Grid>
        </Grid>
        <Typography variant="body2">{new Date(lastGame.matchDate).toLocaleDateString()}</Typography>
      </Box>
    );
  };

  const renderNextGame = () => {
    if (!nextGame) {
      return <Typography variant="body2">Oops, looks like we have no data to display</Typography>;
    }

    const club = clubsData[nextGame.defaultClub];
    const team1Name = club ? club.teamname : 'Loading...';
    const team1Logo = club ? club.emblem : 'path/to/placeholder-image.png';

    return (
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="body2">{nextGame.matchName}</Typography>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Avatar src={team1Logo} alt={team1Name} sx={{ width: 56, height: 56 }} />
            <Typography variant="body2">{truncateName(team1Name, 13)}</Typography>
          </Grid>
          <Grid item sx={{ mx: 2 }}>
            <Typography variant="h4">00:00</Typography>
          </Grid>
          <Grid item>
            <Avatar sx={{ width: 56, height: 56, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="h6" sx={{ fontSize: '14px' }}>OT</Typography>
            </Avatar>
            <Typography variant="body2">{truncateName(nextGame.opposition, 13)}</Typography>
          </Grid>
        </Grid>
        <Typography variant="body2">{new Date(nextGame.matchDate).toLocaleDateString()}</Typography>
      </Box>
    );
  };

  if (!player) {
    return null; // or a loading indicator
  }

  return (
    <Card sx={{ 
        width: 400, 
        height: '100vh', 
        overflowY: 'auto', 
        m: 2, 
        position: 'relative', 
        bgcolor: '#fff', 
        transition: 'box-shadow 0.3s ease-in-out', 
        '&:hover': {
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)'
        }
      }}>
      <CardContent>
        <IconButton aria-label="close" sx={{ position: 'absolute', right: 0, top: 0 }} onClick={onClose}>
          <Close />
        </IconButton>
        <IconButton aria-label="pin" sx={{ position: 'absolute', right: 36, top: 0 }} onClick={togglePin}>
          <PushPin color={isPinned ? 'primary' : 'inherit'} />
        </IconButton>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar src={player.image} sx={{ width: 56, height: 56, borderRadius: '10%', border: '1px solid grey' }} />
          </Grid>
          <Grid item xs>
            <Typography variant="h6">{player.fullName}</Typography>
            <Typography variant="body2" sx={{ color: 'green', display: 'flex', alignItems: 'center' }}>
              <Typography variant="caption" sx={{ bgcolor: 'green', borderRadius: '50%', width: 8, height: 8, mr: 0.5, display: 'inline-block' }}></Typography> Contracted
            </Typography>
            <Typography variant="body2">Club: {defaultClub}</Typography>
            <Typography variant="body2">Position: {player.pPosition}</Typography>
            <Typography variant="body2">Manager: {player.emergencyName}</Typography>
          </Grid>
        </Grid>
        <Divider sx={{ my: 1 }} />
        <Grid container alignItems="center" sx={{ mb: 1 }}>
          <Phone sx={{ mr: 1 }} />
          <Typography variant="body2">{player.phone}</Typography>
        </Grid>
        <Grid container alignItems="center" sx={{ mb: 1 }}>
          <Email sx={{ mr: 1 }} />
          <Typography variant="body2">{player.email}</Typography>
        </Grid>
        <Grid container alignItems="center" sx={{ mb: 1 }}>
          <Home sx={{ mr: 1 }} />
          <Typography variant="body2">{player.addressLine1} {player.addressLine2}</Typography>
        </Grid>
        <Divider sx={{ my: 1 }} />
        <Button sx={{ mr: 1, bgcolor: '#356596', color: '#fff', '&:hover': { bgcolor: '#fff', color: '#356596' } }} variant="contained" startIcon={<Edit />}>EDIT</Button>
        <Button sx={{ bgcolor: '#fff', color: '#356596', '&:hover': { bgcolor: '#356596', color: '#fff' } }} variant="contained" startIcon={<Info />}>DETAILS</Button>
        <Button aria-controls="action-menu" aria-haspopup="true" onClick={handleMenuClick} sx={{ ml: 1, bgcolor: '#fff', color: '#356596', '&:hover': { bgcolor: '#356596', color: '#fff' } }}>
           See Actions
        </Button>
        <Menu
          id="action-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose}>Call</MenuItem>
          <MenuItem onClick={handleMenuClose}>Send Fax</MenuItem>
          <MenuItem onClick={handleMenuClose}>Send Email</MenuItem>
          <MenuItem onClick={handleMenuClose}>Make a meeting</MenuItem>
        </Menu>
        <Divider sx={{ my: 1 }} />
        <ListItem button onClick={handleDetailsClick} sx={{ '&:hover': { bgcolor: 'action.hover' }, bgcolor: openDetails ? 'action.hover' : '#fff' }}>
          <ListItemText primary="Personal Details" />
          {openDetails ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openDetails} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Paper variant="outlined" sx={{ m: 1, p: 1 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={5}>
                  <Typography variant="caption" sx={{ color: 'textSecondary' }}>Height</Typography>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{player.height}m</Typography>
                  <Typography variant="caption" sx={{ color: 'textSecondary' }}>{convertHeightToFeetAndInches(player.height)}</Typography>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={6}>
                  <Typography variant="caption" sx={{ color: 'textSecondary' }}>Weight</Typography>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{player.weight}kg</Typography>
                  <Typography variant="caption" sx={{ color: 'textSecondary' }}>{convertKgToLbs(player.weight)}</Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper variant="outlined" sx={{ m: 1, p: 1 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={5}>
                  <Typography variant="body2">Born: {player.dateOfBirth}</Typography>
                  <Typography variant="body2">Age: {calculateAge(player.dateOfBirth)}</Typography>
                  <Typography variant="body2">From: {player.nationality}</Typography>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={6}>
                  <Typography variant="body2">Debut: {player.joinedClub}</Typography>
                  <Typography variant="body2">Years in League: </Typography>
                  <Typography variant="body2">Previously: {player.previousClubs}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </List>
        </Collapse>
        <ListItem button onClick={handleStatisticsClick} sx={{ '&:hover': { bgcolor: 'action.hover' }, bgcolor: openStatistics ? 'action.hover' : '#fff' }}>
          <ListItemText primary="Player Statistics" />
          {openStatistics ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openStatistics} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Grid container spacing={2} sx={{ m: 1, p: 1 }}>
              <Grid item xs={4}>
                <Paper variant="outlined" sx={{ p: 1, ml: -1, textAlign: 'center', bgcolor: '#356596', color: '#fff' }}>
                  <Typography variant="body2">Assists per game</Typography>
                  <Typography variant="h5">{playerStats.assists}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper variant="outlined" sx={{ p: 1, ml: -1, textAlign: 'center', bgcolor: '#283747', color: '#fff' }}>
                  <Typography variant="body2">Goals per game</Typography>
                  <Typography variant="h5">{playerStats.goals}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper variant="outlined" sx={{ p: 1, ml: -1, textAlign: 'center', bgcolor: '#356596', color: '#fff' }}>
                  <Typography variant="body2">Distance per game</Typography>
                  <Typography variant="h5">{playerStats.distance.toFixed(2)} km</Typography>
                </Paper>
              </Grid>
            </Grid>
            <Paper variant="outlined" sx={{ m: 1, p: 1 }}>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Last Game" />
                <Tab label="Next Game" />
              </Tabs>
              <Divider sx={{ my: 1 }} />
              {tabValue === 0 && (
                <Box sx={{ textAlign: 'center' }}>
                  {renderLastGame()}
                </Box>
              )}
              {tabValue === 1 && (
                <Box sx={{ textAlign: 'center' }}>
                  {renderNextGame()}
                </Box>
              )}
            </Paper>
          </List>
        </Collapse>
        <ListItem button onClick={handleCareerStatsClick} sx={{ '&:hover': { bgcolor: 'action.hover' }, bgcolor: openCareerStats ? 'action.hover' : '#fff' }}>
          <ListItemText primary="Career Statistics" />
          {openCareerStats ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openCareerStats} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Paper variant="outlined" sx={{ m: 1, p: 1 }}>
              <Typography variant="h6" sx={{ bgcolor: '#d32f2f', color: '#fff', p: 1, textAlign: 'center' }}>Coming Soon</Typography>
              {/* <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Season</TableCell>
                      <TableCell>Team</TableCell>
                      <TableCell>Goals</TableCell>
                      <TableCell>Assists</TableCell>
                      <TableCell>Yellow Cards</TableCell>
                      <TableCell>Red Cards</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Last Season</TableCell>
                      <TableCell>Eagles</TableCell>
                      <TableCell>0</TableCell>
                      <TableCell>2</TableCell>
                      <TableCell>1</TableCell>
                      <TableCell>0</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Next Season</TableCell>
                      <TableCell>Eagles</TableCell>
                      <TableCell>0</TableCell>
                      <TableCell>0</TableCell>
                      <TableCell>0</TableCell>
                      <TableCell>0</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>This Season</TableCell>
                      <TableCell>Eagles</TableCell>
                      <TableCell>0</TableCell>
                      <TableCell>1</TableCell>
                      <TableCell>2</TableCell>
                      <TableCell>0</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer> */}
            </Paper>
          </List>
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default DetailedContactCard;

