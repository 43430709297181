// // Custom PlanTwo component
// // Created by Shamiel Speelman
// // Version: 0.1

import React from 'react';
import { Card, CardContent, Typography, Button, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CommunityButton = styled(Button)({
  backgroundColor: '#ffffff',
  border: '1px solid #cccccc',
  borderRadius: '20px',
  color: '#356596',
  fontSize: '0.875rem',
  padding: '10px 20px',
  width: '200px',
  height: '34px',
  '&:hover': {
    boxShadow: '0 10px 16px rgba(0, 0, 0, 0.6)'
  },
  margin: '-16px',
});

const PlanTwo = () => {
  const navigate = useNavigate();

  const handleSubscribe = () => {
    navigate('/dashboard/subscribe', {
      state: {
        plan: 'Community Plan',
        cost: '€2.92/month',
        billingCycle: 'Annually'
      }
    });
  };

  return (
    <Card sx={{
      width: '240px',
      backgroundColor: '#ffffff',
      color: '#356596',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      transition: 'box-shadow 0.7s ease-in-out',
      '&:hover': {
        boxShadow: '0 10px 16px rgba(0, 0, 0, 0.9)'
      },
      p: 2,
      textAlign: 'center',
      overflow: 'hidden',
      mt: 6
    }}>
      <CardContent>
        <CommunityButton>
          Community Plan
        </CommunityButton>
        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
          Verified Community Club - 50% Off
        </Typography>
        <Typography variant="h5" sx={{ mb: 1 }}>
          €2.92/month
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Total €34.99 + tax<br/>
          Billed annually
        </Typography>
        <Button variant="contained" onClick={handleSubscribe} sx={{
          backgroundColor: 'white',
          color: '#356596',
          '&:hover': {
            backgroundColor: '#356596',
            color: 'white',
          }
        }}>
          Subscribe
        </Button>
      </CardContent>
    </Card>
  );
};

export default PlanTwo;
