import React, { useState, useEffect } from 'react';
import {
  Drawer,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  IconButton,
  Typography,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const allEquip = {
  footballs: [
    { id: 'size3Football', name: 'Size 3 Football (Under 8 years)', type: 'footballs' },
    { id: 'size4Football', name: 'Size 4 Football (8-12 years)', type: 'footballs' },
    { id: 'size5Football', name: 'Size 5 Football (12+ years)', type: 'footballs' },
  ],
  cones: [
    { id: 'discCones', name: 'Disc Cones', type: 'cones' },
    { id: 'tallCones', name: 'Tall Cones', type: 'cones' },
  ],
  agilityLadders: [{ id: 'agilityLadder', name: 'Agility Ladder', type: 'agilityLadders' }],
  hurdles: [{ id: 'variousHurdles', name: 'Various Heights Hurdles', type: 'hurdles' }],
  goalposts: [
    { id: 'fullSizeGoal', name: 'Full-Size Goal', type: 'goalposts' },
    { id: 'smallSidedGoal', name: 'Small-Sided Game Goal', type: 'goalposts' },
  ],
  bibsVests: [{ id: 'coloredBibs', name: 'Colored Bibs/Vests', type: 'bibsVests' }],
  whistleTimer: [
    { id: 'whistle', name: 'Whistle', type: 'whistleTimer' },
    { id: 'timer', name: 'Timer', type: 'whistleTimer' },
  ],
  trainingBibs: [{ id: 'trainingBibs', name: 'Training Bibs', type: 'trainingBibs' }],
  reboundBoardsNets: [
    { id: 'reboundBoard', name: 'Rebound Board', type: 'reboundBoardsNets' },
    { id: 'reboundNet', name: 'Rebound Net', type: 'reboundBoardsNets' },
  ],
  resistanceBands: [
    { id: 'variousResistanceBands', name: 'Various Resistance Bands', type: 'resistanceBands' },
  ],
  medicineBalls: [
    { id: 'variousMedicineBalls', name: 'Various Weights Medicine Balls', type: 'medicineBalls' },
  ],
  portableGoals: [{ id: 'portableGoal', name: 'Portable Goal/Mini Goal', type: 'portableGoals' }],
  markers: [
    { id: 'flatMarkers', name: 'Flat Markers', type: 'markers' },
    { id: 'poleMarkers', name: 'Pole Markers', type: 'markers' },
  ],
  safetyEquipment: [
    { id: 'firstAidKit', name: 'First Aid Kit', type: 'safetyEquipment' },
    { id: 'waterBottles', name: 'Water Bottles', type: 'safetyEquipment' },
    { id: 'icePacks', name: 'Ice Packs', type: 'safetyEquipment' },
    { id: 'hydrationStations', name: 'Hydration Stations', type: 'safetyEquipment' },
  ],
};

const formatPropertyName = (name) => {
  return name
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export default function EquipmentPanel({
  isOpen,
  selectedEquip,
  setSelectedEquip,
  setIsPanelOpen,
}) {
  const [localEquip, setLocalEquip] = useState([]);
  const theme = useTheme();
  
  useEffect(() => {
    setLocalEquip(Object.values(allEquip).flat());
  }, []);

  const handleSelect = (equipId) => {
    const currentIndex = selectedEquip.findIndex((item) => item.id === equipId);
    const newSelectedEquip = [...selectedEquip];

    if (currentIndex === -1) {
      const newEquip = localEquip.find((item) => item.id === equipId);
      newSelectedEquip.push(newEquip);
    } else {
      newSelectedEquip.splice(currentIndex, 1);
    }

    setSelectedEquip(newSelectedEquip);
  };

  const selectAll = () => {
    setSelectedEquip(localEquip);
  };

  const removeAll = () => {
    setSelectedEquip([]);
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => setIsPanelOpen(false)}
      variant="persistent"
      ModalProps={{ keepMounted: true }}
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: '#ffffff',
          color: '#fff',
          borderRadius: '8px 0 0 8px',
          boxShadow: theme.shadows[5],
          transition: 'width 0.3s',
        }
      }}
    >
      <Box sx={{ width: 400, padding: 2 }}>
        <IconButton
          onClick={() => setIsPanelOpen(false)}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            zIndex: 1300,
            backgroundColor: 'white',
            borderRadius: '50%',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" style={{ padding: '16px' }}>
          Equipment List
        </Typography>
        {Object.keys(allEquip).map((category) => (
          <Accordion key={category} sx={{
            backgroundColor: '#356596',
            color: '#fff',
            '& .MuiAccordionSummary-root': {
              backgroundColor: '#3e3e3e',
            }
          }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}>
              <Typography>{formatPropertyName(category)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List style={{ maxHeight: '70vh', overflow: 'auto' }}>
                {allEquip[category].map((equip) => (
                  <ListItem key={equip.id} sx={{
                    '&:hover': {
                      backgroundColor: '#3e3e3e',
                    }
                  }}>
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon sx={{ color: '#fff' }} />}
                      checkedIcon={<CheckCircleIcon sx={{ color: '#ffffff' }} />}
                      checked={selectedEquip.some((e) => e.id === equip.id)}
                      onChange={() => handleSelect(equip.id)}
                      sx={{
                        color: '#356596',
                        '&.Mui-checked': {
                          color: '#ffffff',
                        }
                      }}
                    />
                    <ListItemText primary={equip.name} />
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
        <Box display="flex" justifyContent="space-between" p={1}>
          <Button onClick={selectAll} style={{
            transition: 'background-color 0.3s, color 0.3s',
            color: '#356596',
            borderColor: '#ffffff'
          }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#356596';
              e.target.style.color = '#fff';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = 'transparent';
              e.target.style.color = '#356596';
            }}>
            Select All
          </Button>
          <Button onClick={removeAll} style={{
            transition: 'background-color 0.3s, color 0.3s',
            color: '#f44336',
            borderColor: '#ffffff'
          }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#f44336';
              e.target.style.color = '#fff';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = 'transparent';
              e.target.style.color = '#f44336';
            }}>
            Remove All
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
