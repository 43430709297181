// // // Custom Appjs component
// // // Created by Shamiel Speelman
// // // Version: 0.1
import { useEffect, useState } from 'react';
import { Box, Button, ThemeProvider, createTheme } from '@mui/material';
import Login from './components/Login';
import Register from './components/Register';
import PlayGround from './pages/PlayGround';
import CreatePlayer from './pages/CreatePlayer';
import PlayerList from './pages/PlayerList';
import ManageCommunication from './pages/ManageCommunication';
import CreateSquad from './pages/CreateSquad';
import CreateTraining from './pages/CreateTraining';
import Calendar from './pages/Calendar';
import CreateClub from './pages/CreateClub';
import Subscribe from './pages/Subscribe';
import SubscriptionActivate from './components/SubscriptionActivate';
import ClubList from './pages/ClubList';
import TrainingList from './pages/TrainingList';
import CreateRPE from './pages/CreateRPE';
import RPEStatistics from './pages/RPEStatistics';
import MatchStatistics from './pages/MatchStatistics';
import MatchDay from './pages/MatchDay';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import DashboardHome from './pages/DashboardHome'; // New component for dashboard home content
import { SnackbarProvider } from 'notistack';
import authService from './appwrite/auth';

const theme = createTheme({
  palette: {
    primary: {
      main: '#356596',
      dark: '#FFFFFF',
    },
    action: {
      active: '#356596',
      hover: '#e9edf2',
      hoverOpacity: 0.8,
    },
  },
});

export default function App() {
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showSubscriptionActivate, setShowSubscriptionActivate] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const handleAuthChange = (event) => {
      setIsAuthenticated(!!event.detail);
    };

    window.addEventListener('auth-change', handleAuthChange);
    authService.validateSession().then(jwt => {
      setIsAuthenticated(!!jwt);
    });

    return () => {
      window.removeEventListener('auth-change', handleAuthChange);
    };
  }, []);

  const handleBack = () => {
    setShowLogin(false);
    setShowRegister(false);
    setShowSubscriptionActivate(false);
  };

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <Routes>
            <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" /> : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: '100vw',
                  height: '100vh',
                  gap: 2,
                }}
              >
                {!showLogin && !showRegister && !showSubscriptionActivate ? (
                  <>
                    <Box sx={{ width: { xs: '90%', md: 400 }, height: 200, borderRadius: 1, bgcolor: 'primary.main', color: '#e9edf2', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 2, transition: 'background-color 0.2s ease', '&:hover': { bgcolor: 'primary.dark', color: 'primary.main' }, }}>
                      <h3>Activate Subscription</h3>
                      <p>Activate your subscription to access all features.</p>
                      <Button variant="contained" sx={{ bgcolor: 'primary.dark', color: 'primary.main', borderRadius: '16px', '&:hover': { bgcolor: 'primary.main', color: 'primary.dark' }, }} onClick={() => setShowSubscriptionActivate(true)}>
                        Activate Now
                      </Button>
                    </Box>
                    <Box sx={{ width: { xs: '90%', md: 400 }, height: 200, borderRadius: 1, bgcolor: 'primary.main', color: '#e9edf2', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 2, transition: 'transform 0.5s ease', '&:hover': { bgcolor: 'primary.dark', color: 'primary.main' }, }}>
                      <h3>Login</h3>
                      <p>Already a member? Log in to continue.</p>
                      <Button variant="contained" sx={{ bgcolor: 'primary.dark', color: 'primary.main', borderRadius: '16px', '&:hover': { bgcolor: 'primary.main', color: 'primary.dark' }, }} onClick={() => setShowLogin(true)}>
                        Login
                      </Button>
                    </Box>
                    <Box sx={{ width: { xs: '90%', md: 400 }, height: 200, borderRadius: 1, bgcolor: 'primary.main', color: '#e9edf2', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 2, transition: 'background-color 0.5s ease', '&:hover': { bgcolor: 'primary.dark', color: 'primary.main' }, }}>
                      <h3>Register</h3>
                      <p>New here? Register to start your journey with us.</p>
                      <Button variant="contained" sx={{ bgcolor: 'primary.dark', color: 'primary.main', borderRadius: '16px', '&:hover': { bgcolor: 'primary.main', color: 'primary.dark' }, }} onClick={() => setShowRegister(true)}>
                        Register
                      </Button>
                    </Box>
                  </>
                ) : showLogin ? (
                  <Login onBack={handleBack} />
                ) : showRegister ? (
                  <Register onBack={handleBack} />
                ) : showSubscriptionActivate ? (
                  <SubscriptionActivate onBack={handleBack} />
                ) : null}
              </Box>
            )} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}>
              <Route index element={<DashboardHome />} />
              <Route path="createplayer" element={<CreatePlayer />} />
              <Route path="playerlist" element={<PlayerList />} />
              <Route path="createclub" element={<CreateClub />} />
              <Route path="clublist" element={<ClubList />} />
              <Route path="createtraining" element={<CreateTraining />} />
              <Route path="traininglist" element={<TrainingList />} />
              <Route path="matchday" element={<MatchDay />} />  
              <Route path="matchstatistics" element={<MatchStatistics />} /> 
              <Route path="createsquad" element={<CreateSquad />} />
              <Route path="playground" element={<PlayGround />} />
              <Route path="createrpe" element={<CreateRPE />} />
              <Route path="rpestatistics" element={<RPEStatistics />} />
              <Route path="managecommunication" element={<ManageCommunication />} />
              <Route path="calendar" element={<Calendar />} />
              <Route path="subscribe" element={<Subscribe />} />

            </Route>
            <Route path="/activate-subscription" element={<SubscriptionActivate />} />
          </Routes>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  );
}
