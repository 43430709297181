

// export default PlayerSelection;
import React from 'react';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';

const PlayerCard = ({ player, onSelected, jerseyType }) => {
    const jerseyIcon = player.selected
        ? (jerseyType === 'striped' ? '/icons/football-jersey.png' : '/icons/football-plain.png')
        : (jerseyType === 'striped' ? '/icons/football-unselected.png' : '/icons/football-plain_unselected.png');

    return (
        <Card 
            sx={{ 
                flex: '1 1 calc(20% - 10px)', // Adjusting width to fit 5 cards in a row with flex
                margin: '5px',
                cursor: 'pointer',
                ':hover': {
                    transform: 'scale(1.05)', // Slightly enlarge on hover for visual effect
                },
                position: 'relative', // Ensure child elements can be positioned over the jersey
                aspectRatio: '4/5', // Ensures the card maintains a 4:5 aspect ratio
                height: '250px', // Adjust the height to be smaller
                minWidth: '150px', // Minimum width to ensure cards don't get too narrow
            }} 
            onClick={() => onSelected(player)}
        >
            <CardMedia
                component="img"
                sx={{ 
                    height: '100%', 
                    width: '100%', 
                    objectFit: 'contain', // Ensures the jersey image fits within the card without being cut off
                }}
                image={jerseyIcon}
                alt="Football Jersey"
            />
            <CardContent 
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography 
                    variant="h5" 
                    component="div" 
                    sx={{ 
                        fontSize: '3vw', 
                        fontWeight: 'bold',
                        color: '#356596'
                    }}
                >
                    {player.jerseyNumber}
                </Typography>
                <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    sx={{ 
                        fontSize: '0.75vw',
                        marginBottom: '2px',
                        fontWeight: 'bold',
                        color: 'error.main'
                    }}
                >
                    {player.pposition}
                </Typography>
                <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    sx={{ 
                        fontSize: '1vw',
                    }}
                >
                    {player.fullName}
                </Typography>
            </CardContent>
        </Card>
    );
};

const PlayerSelection = ({ players, currentJerseyType, handlePlayerSelected }) => {
    return (
        <Box sx={{ width: '100%', height: ['auto', 670], overflowY: 'auto', p: 2, display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {players.map(player => (
                <PlayerCard
                    key={player.$id}
                    player={player}
                    onSelected={handlePlayerSelected}
                    jerseyType={currentJerseyType}
                />
            ))}
        </Box>
    );
};

export default PlayerSelection;


