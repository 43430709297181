// // Custom CreateClub component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState, useEffect } from 'react';
import { Client, Databases, ID, Storage } from 'appwrite';
import { Grid, Stepper, Step, StepLabel, Button, Typography, Container, TextField, Box, Card } from '@mui/material';
import { useSnackbar } from 'notistack';
import { SketchPicker } from 'react-color';
import CountrySelector from '../components/CountrySelector';
import LeagueDropdown from '../components/LeagueDropdown';
import authService from '../appwrite/auth';
import conf from '../conf/conf';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);
const storage = new Storage(client);

const getSteps = () => ['Club Info', 'Additional Details', 'Confirmation'];

const TextFieldComponent = ({ label, value, onChange, required = false, type = 'text' }) => (
    <TextField
        label={label}
        value={value}
        onChange={onChange}
        fullWidth
        variant="outlined"
        required={required}
        type={type}
        sx={{ marginBottom: '10px' }}
    />
);

const ColorPickerComponent = ({ label, color, onChange }) => (
    <Grid item xs={4}>
        <Typography>{label}</Typography>
        <SketchPicker color={color} onChangeComplete={onChange} />
    </Grid>
);

const StepContent = ({ step, formState, updateFormState, handleImageUpload }) => {
    const handleColorChange = (colorType, color) => {
        updateFormState(colorType, color.hex);
    };

    switch (step) {
        case 0:
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextFieldComponent label="Team Name" value={formState.teamname} onChange={(e) => updateFormState('teamname', e.target.value)} required />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextFieldComponent label="Nickname" value={formState.nickname} onChange={(e) => updateFormState('nickname', e.target.value)} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextFieldComponent label="Home Stadium" value={formState.homestadium} onChange={(e) => updateFormState('homestadium', e.target.value)} required />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextFieldComponent label="Capacity" value={formState.capacity} onChange={(e) => updateFormState('capacity', parseInt(e.target.value) || '')} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CountrySelector selectedCountry={formState.country} setSelectedCountry={(newValue) => updateFormState('country', newValue)} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <LeagueDropdown selectedLeague={formState.league} setLeague={(newValue) => updateFormState('league', newValue)} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextFieldComponent label="Founded Year" type="date" value={formState.foundedyear} onChange={(e) => updateFormState('foundedyear', e.target.value)} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextFieldComponent label="Owner" value={formState.owner} onChange={(e) => updateFormState('owner', e.target.value)} required />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextFieldComponent label="Manager" value={formState.manager} onChange={(e) => updateFormState('manager', e.target.value)} required />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextFieldComponent label="Head Coach" value={formState.headcoach} onChange={(e) => updateFormState('headcoach', e.target.value)} required />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>Club Colors</Typography>
                        <Grid container spacing={2}>
                            <ColorPickerComponent label="Primary Color" color={formState.primaryColor} onChange={(color) => handleColorChange('primaryColor', color)} />
                            <ColorPickerComponent label="Secondary Color" color={formState.secondaryColor} onChange={(color) => handleColorChange('secondaryColor', color)} />
                            <ColorPickerComponent label="Tertiary Color" color={formState.tertiaryColor} onChange={(color) => handleColorChange('tertiaryColor', color)} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <input accept="image/*" style={{ display: 'none' }} id="raised-button-file" type="file" onChange={handleImageUpload} />
                        <label htmlFor="raised-button-file">
                            <Button variant="contained" component="span">Upload Club Logo</Button>
                        </label>
                        {formState.emblem && <img src={formState.emblem} alt="Uploaded" style={{ maxHeight: '100px', marginTop: '10px' }} />}
                    </Grid>
                </Grid>
            );
        case 1:
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextFieldComponent label="Address Line 1" value={formState.addressline1} onChange={(e) => updateFormState('addressline1', e.target.value)} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldComponent label="Address Line 2" value={formState.addressline2} onChange={(e) => updateFormState('addressline2', e.target.value)} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldComponent label="Phone" value={formState.phone} onChange={(e) => updateFormState('phone', e.target.value)} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldComponent label="Email" value={formState.email} onChange={(e) => updateFormState('email', e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldComponent label="Official Website" value={formState.officialweb} onChange={(e) => updateFormState('officialweb', e.target.value)} />
                    </Grid>
                </Grid>
            );
        case 2:
            return (
                <Box sx={{ marginTop: '30px', padding: '20px', textAlign: 'center' }}>
                    <Typography variant="h6" gutterBottom>Looks good, we have all we need to create your club profile.</Typography>
                    <Typography variant="body1">Additional information to build out the club profile can be added or amended in the club profile section. Click submit to save club data.</Typography>
                </Box>
            );
        default:
            return 'Unknown step';
    }
};

const CreateTeam = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [activeStep, setActiveStep] = useState(0);
    const [user, setUser] = useState(null);
    const [formState, setFormState] = useState({
        teamname: '', nickname: '', homestadium: '', capacity: '',
        country: '', league: '', foundedyear: '', owner: '',
        manager: '', headcoach: '', primaryColor: '#fff', secondaryColor: '#fff',
        tertiaryColor: '#fff', emblem: null, addressline1: '',
        addressline2: '', phone: '', email: '', officialweb: '',
        userID: '', userEmail: ''
    });

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const currentUser = await authService.getCurrentUser();
                if (currentUser) {
                    setUser(currentUser);
                    setFormState((prev) => ({
                        ...prev,
                        userID: currentUser.$id,
                        userEmail: currentUser.email,
                    }));
                } else {

                    window.location.href = '/login';
                }
            } catch (error) {
                console.error('Error fetching user:', error);

                window.location.href = '/login';
            }
        };

        fetchUser();
    }, []);

    const resetForm = () => {
        setFormState({
            teamname: '', nickname: '', homestadium: '', capacity: '',
            country: '', league: '', foundedyear: '', owner: '',
            manager: '', headcoach: '', primaryColor: '#fff', secondaryColor: '#fff',
            tertiaryColor: '#fff', emblem: null, addressline1: '',
            addressline2: '', phone: '', email: '', officialweb: '',
            userID: user ? user.$id : '', userEmail: user ? user.email : ''
        });
        setActiveStep(0);
    };

    const steps = getSteps();

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const response = await storage.createFile(conf.appwriteBucketId, ID.unique(), file);
                const fileUrl = `${client.config.endpoint}/storage/buckets/${conf.appwriteBucketId}/files/${response.$id}/view?project=${client.config.project}`;
                setFormState((prev) => ({ ...prev, emblem: fileUrl }));
            } catch (error) {
                console.error('Error uploading file:', error);
                enqueueSnackbar('Failed to upload file', { variant: 'error' });
            }
        }
    };

    const isStepComplete = () => {
        if (activeStep === 0) {
            return formState.teamname.trim() !== '' &&
                formState.homestadium.trim() !== '' &&
                formState.country.trim() !== '' &&
                formState.league.trim() !== '' &&
                formState.owner.trim() !== '' &&
                formState.manager.trim() !== '' &&
                formState.headcoach.trim() !== '';
        }
        return true;
    };

    const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

    const handleSubmit = async () => {
        try {
            console.log("Submitting form with state:", formState);
            console.log("Database ID:", conf.appwriteDatabaseId);
            console.log("Collection ID:", conf.createClubCollectionId);

            const payload = {
                teamname: formState.teamname,
                nickname: formState.nickname,
                homestadium: formState.homestadium,
                capacity: formState.capacity,
                country: formState.country,
                email: formState.email,
                emblem: formState.emblem,
                foundedyear: formState.foundedyear,
                headcoach: formState.headcoach,
                league: formState.league,
                manager: formState.manager,
                officialweb: formState.officialweb,
                owner: formState.owner,
                phone: formState.phone,
                primaryColor: formState.primaryColor,
                secondaryColor: formState.secondaryColor,
                tertiaryColor: formState.tertiaryColor,
                addressline1: formState.addressline1,
                addressline2: formState.addressline2,
                userID: formState.userID,
                userEmail: formState.userEmail
            };

            await databases.createDocument(
                conf.appwriteDatabaseId,
                conf.createClubCollectionId,
                ID.unique(),
                payload
            );
            enqueueSnackbar('Form submitted successfully!', { variant: 'success' });
            resetForm();
        } catch (error) {
            console.error('Error submitting form:', error);
            enqueueSnackbar('Failed to submit form', { variant: 'error' });
        }
    };

    const updateFormState = (field, value) => setFormState((prev) => ({ ...prev, [field]: value }));

    return (
        <Container>
            <Typography variant="h4" sx={{ margin: '20px 0' }}>Create Team</Typography>
            <Stepper activeStep={activeStep} sx={{ marginBottom: 3 }}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Card sx={{ padding: 2, marginBottom: 3, transition: 'box-shadow 0.3s ease-in-out', '&:hover': { boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)' } }}>
                <StepContent step={activeStep} formState={formState} updateFormState={updateFormState} handleImageUpload={handleImageUpload} />
            </Card>
            <Box display="flex" justifyContent="space-between" mt={2}>
                <Button variant="outlined" onClick={handleBack} disabled={activeStep === 0}>Back</Button>
                {activeStep < steps.length - 1 ? (
                    <Button variant="contained" color="primary" onClick={handleNext} disabled={!isStepComplete()}>Next</Button>
                ) : (
                    <>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                        <Button variant="contained" color="secondary" onClick={resetForm} sx={{ marginLeft: 1 }}>Create Next Club</Button>
                    </>
                )}
            </Box>
        </Container>
    );
};

export default CreateTeam;






