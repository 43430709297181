// // Custom Calendar component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../Calendar2.css';
import { Box, Modal, Tooltip, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Client, Databases, Query } from 'appwrite';
import conf from '../conf/conf';
import authService from '../appwrite/auth';
import { sendBirthdayMessage, sendMatchReminder, sendTrainingReminder } from '../utils/telegramEvents';
import CreateSquad from './CreateSquad';
import CreateTraining from './CreateTraining';
import CreateEvent from './CreateEvent'; 

const localizer = momentLocalizer(moment);

const client = new Client()
  .setEndpoint(conf.appwriteUrl)
  .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const MyCalendar = () => {
  const [events, setEvents] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchMatchesTrainingAndPlayers = async () => {
      try {
        const currentUser = await authService.getCurrentUser();
        if (!currentUser) {
          throw new Error('No user is currently logged in.');
        }

        const matchesResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.createSquadCollectionId, [
          Query.equal('userID', currentUser.$id),
        ]);

        const matchesData = matchesResponse.documents.map(doc => ({
          id: doc.$id,
          title: doc.matchName,
          start: new Date(doc.matchDate),
          end: new Date(doc.matchDate),
          matchName: doc.matchName,
          opposition: doc.opposition,
          stadium: doc.stadium,
          matchDate: doc.matchDate,
          type: 'match',
        }));

        const trainingResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.createTrainingCollectionId, [
          Query.equal('userID', currentUser.$id),
        ]);

        const trainingData = trainingResponse.documents.map(doc => ({
          id: doc.$id,
          title: doc.sessionTitle,
          start: new Date(doc.sessionDate),
          end: new Date(doc.sessionDate),
          sessionTitle: doc.sessionTitle,
          location: doc.location,
          coachName: doc.coachName,
          assistingCoach: doc.assistingCoach,
          teamGroup: doc.teamGroup,
          type: 'training',
        }));

        const playersResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteCollectionId, [
          Query.equal('userID', currentUser.$id),
        ]);

        const currentYear = new Date().getFullYear();
        const playerBirthdaysData = playersResponse.documents.flatMap(doc => {
          const birthDate = new Date(doc.dateOfBirth);
          const birthDay = birthDate.getDate();
          const birthMonth = birthDate.getMonth();
          return [
            {
              id: `${doc.$id}-birthday-${currentYear}`,
              title: `${doc.fullName}'s Birthday`,
              start: new Date(currentYear, birthMonth, birthDay),
              end: new Date(currentYear, birthMonth, birthDay),
              fullName: doc.fullName,
              type: 'birthday',
            },
            {
              id: `${doc.$id}-birthday-${currentYear + 1}`,
              title: `${doc.fullName}'s Birthday`,
              start: new Date(currentYear + 1, birthMonth, birthDay),
              end: new Date(currentYear + 1, birthMonth, birthDay),
              fullName: doc.fullName,
              type: 'birthday',
            }
          ];
        });

        const combinedEvents = [...matchesData, ...trainingData, ...playerBirthdaysData];
        setEvents(combinedEvents);
      } catch (error) {
        console.error('Error fetching matches, training, or player data:', error);
      }
    };

    fetchMatchesTrainingAndPlayers();
  }, []);

  const handleSelectSlot = (slotInfo) => {
    setSelectedDay(slotInfo.start);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleButtonClick = (option) => {
    setSelectedOption(option);
    handleClose();
  };

  const handleModalClose = () => {
    setSelectedOption(null);
  };

  const addEventToCalendar = (newEvent) => {
    setEvents((prevEvents) => [...prevEvents, newEvent]);
  };

  const EventComponent = ({ event }) => (
    <Tooltip
      title={
        <div>
          <strong>{event.title}</strong><br />
          {event.type === 'match' ? (
            <>
              Opposition: {event.opposition}<br />
              Stadium: {event.stadium}
            </>
          ) : event.type === 'training' ? (
            <>
              Location: {event.location}<br />
              Coach: {event.coachName}<br />
              Assisting Coach: {event.assistingCoach}<br />
              Team: {event.teamGroup}
            </>
          ) : (
            <>
              Player Birthday
            </>
          )}
        </div>
      }
    >
      <span
        className="calendar-event"
        onClick={() => {
          if (event.type === 'birthday') {
            sendBirthdayMessage(event.fullName, event.start, event.id);
          } else if (event.type === 'match') {
            sendMatchReminder(event, true);
          } else if (event.type === 'training') {
            sendTrainingReminder(event, true);
          }
        }}
      >
        {event.title}
      </span>
    </Tooltip>
  );

  const eventStyleGetter = (event) => {
    let backgroundColor = '#3174ad'; 
    if (event.type === 'training') {
      backgroundColor = '#ff9800'; 
    } else if (event.type === 'birthday') {
      backgroundColor = '#4caf50'; 
    }

    return {
      style: {
        backgroundColor,
        borderRadius: '5px',
        opacity: 0.8,
        color: 'white',
        border: '0px',
        display: 'block',
        fontSize: '12px', 
        lineHeight: '1.2em' 
      }
    };
  };

  return (
    <div onClick={(e) => {
      const calendar = document.querySelector('.rbc-calendar');
      if (!calendar.contains(e.target)) {
        handleClose();
      }
    }}>
      <h1>My Calendar</h1>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        selectable
        views={['month', 'week', 'day']}
        onSelectSlot={handleSelectSlot}
        components={{ event: EventComponent }}
        eventPropGetter={eventStyleGetter}
        dayPropGetter={(date) => {
          const currentDate = new Date();
          if (moment(date).isSame(currentDate, 'day')) {
            return { className: 'rbc-day-today' };
          }
          if (date < new Date()) {
            return { style: { backgroundColor: '#f5f5f5' } };
          }
          return null;
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            size="small"
            sx={{
              backgroundColor: '#d32f2f',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#ffffff',
                color: '#d32f2f'
              },
              marginBottom: '10px',
              width: '100px',
              marginRight: '10px'
            }}
            onClick={() => handleButtonClick('match')}
          >
            Match
          </Button>
          <Button
            variant="contained"
            color="success"
            size="small"
            sx={{
              backgroundColor: '#388e3c',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#ffffff',
                color: '#388e3c'
              },
              width: '100px',
              marginBottom: '10px',
              marginRight: '10px'
            }}
            onClick={() => handleButtonClick('training')}
          >
            Training
          </Button>
          <Button
            variant="contained"
            color="info"
            size="small"
            sx={{
              backgroundColor: '#0088cc',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#ffffff',
                color: '#0088cc',
              },
              width: '100px',
              marginBottom: '10px',
              marginRight: '10px'
            }}
            onClick={() => handleButtonClick('event')}
          >
            Event
          </Button>
        </Box>
      </Modal>
      <Modal
        open={Boolean(selectedOption)}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '97%',
            height: '97%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflow: 'auto'
          }}
        >
          {selectedOption === 'match' && <CreateSquad selectedDay={selectedDay} />}
          {selectedOption === 'training' && <CreateTraining selectedDay={selectedDay} />}
          {selectedOption === 'event' && <CreateEvent selectedDay={selectedDay} onClose={handleModalClose} addEventToCalendar={addEventToCalendar} />}
        </Box>
      </Modal>
    </div>
  );
};

export default MyCalendar;
