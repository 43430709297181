// // Custom MatchDay component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState, useEffect, useRef } from 'react';
import {
    Box, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Typography, Grid, Divider, TextField, Pagination, Button, Drawer
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Client, Databases, Query } from 'appwrite';
import conf from '../conf/conf';
import authService from '../appwrite/auth';
import matchbanner from './matchbanner.jpg';
import StartMatch from './StartMatch';
import { useNavigate } from 'react-router-dom';
import { sendTelegramMessage, deleteTelegramMessage } from '../utils/telegramUtils'; 
//import { sendEmail } from '../utils/emailUtils';
//import { sendSMS } from '../utils/smsUtils';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

export default function MatchList() {
    const [matches, setMatches] = useState([]);
    const [open, setOpen] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedMatch, setSelectedMatch] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerLocked, setDrawerLocked] = useState(false);
    const [teamName, setTeamName] = useState('');
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const [finalResults, setFinalResults] = useState({});
    const intervalRef = useRef(null);
    const navigate = useNavigate();

    const rowsPerPage = 9;

    useEffect(() => {
        const fetchMatchesAndTeamName = async () => {
            try {
                const currentUser = await authService.getCurrentUser();
                if (!currentUser) {
                    throw new Error('No user is currently logged in.');
                }
                const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.createSquadCollectionId, [
                    Query.equal('userID', currentUser.$id),
                ]);
                setMatches(response.documents);

                const userDocument = await databases.getDocument(conf.appwriteDatabaseId, conf.appwriteUsersId, currentUser.$id);
                const clubDocument = await databases.getDocument(conf.appwriteDatabaseId, conf.createClubCollectionId, userDocument.defaultClub);
                setTeamName(clubDocument.teamname);

                const finalResultsResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.finalResultsCollectionId);
                const results = finalResultsResponse.documents.reduce((acc, result) => {
                    acc[result.matchID] = result;
                    return acc;
                }, {});
                setFinalResults(results);
            } catch (error) {
                console.error('Error fetching matches or user:', error);
            }
        };

        fetchMatchesAndTeamName();
    }, []);

    const handleOpen = (id) => {
        setOpen(prev => ({ ...prev, [id]: !prev[id] }));
    };

    const handleDetailsClick = (match) => {
        setSelectedMatch(match);
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        if (!drawerLocked) {
            setDrawerOpen(false);
        }
    };

    const handleLockToggle = () => {
        setDrawerLocked(!drawerLocked);
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}'${secs < 10 ? '0' : ''}${secs}`;
    };

    const handleStartPause = () => {
        if (isTimerRunning) {
            clearInterval(intervalRef.current);
        } else {
            intervalRef.current = setInterval(() => {
                setSeconds((prev) => prev + 1);
            }, 1000);
        }
        setIsTimerRunning(!isTimerRunning);
    };

    const handleMatchEnd = (matchID, finalScoreHome, finalScoreOpposition) => {
        setFinalResults(prevResults => ({
            ...prevResults,
            [matchID]: {
                finalScoreHome,
                finalScoreOpposition
            }
        }));
        setDrawerOpen(false);
        navigate('/matchday');
    };


    const handleNotify = async (match) => {
        try {
            const chatId = '-1002230926017';
            const matchDocument = await databases.getDocument(conf.appwriteDatabaseId, conf.createSquadCollectionId, match.$id);
            const players = [...matchDocument.startingEleven, ...matchDocument.substitutes];
            const playerDetails = players.map(player => {
                const playerData = JSON.parse(player);
                return `${playerData.fullName} - ${playerData.pposition}`;
            }).join('\n');
    
            const message = `Upcoming Match Details:\nName: ${match.matchName}\nDate: ${new Date(match.matchDate).toLocaleDateString()}\nOpposition: ${match.opposition}\nPlayers:\n${playerDetails}`;
            const response = await sendTelegramMessage(chatId, message);
    
            console.log(`Notification sent for match: ${match.matchName}`);
            setTimeout(async () => {
                try {
                    await deleteTelegramMessage(chatId, response.message_id);
                    console.log(`Message deleted for match: ${match.matchName}`);
                } catch (error) {
                    console.error('Error deleting message:', error);
                }
            }, 600000);
        } catch (error) {
            console.error('Error notifying players:', error);
        }
    };
    
    

    const indexOfLastMatch = currentPage * rowsPerPage;
    const indexOfFirstMatch = indexOfLastMatch - rowsPerPage;
    const currentMatches = matches.slice(indexOfFirstMatch, indexOfLastMatch);

    return (
        <Box sx={{ padding: '40px', margin: 2  }}>
            <Typography variant="h4" sx={{ marginBottom: '20px' }}>Match List</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead style={{ backgroundColor: 'black' }}>
                        <TableRow>
                            <TableCell />
                            <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Match Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Opposition</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentMatches.map((match) => (
                            <React.Fragment key={match.$id}>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                    <TableCell>
                                        <IconButton size="small" onClick={() => handleOpen(match.$id)}>
                                            {open[match.$id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{match.matchName}</TableCell>
                                    <TableCell>{new Date(match.matchDate).toLocaleDateString()}</TableCell>
                                    <TableCell>{match.opposition}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                        <Collapse in={open[match.$id]} timeout="auto" unmountOnExit sx={{ backgroundColor: '#f2f2f2', padding: '10px' }}>
                                            <Box margin={1}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12} md={2}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => handleDetailsClick(match)}
                                                            size="small"
                                                            sx={{
                                                                backgroundColor: finalResults[match.$id] ? 'grey' : '#356596',
                                                                color: '#ffffff',
                                                                '&:hover': {
                                                                    backgroundColor: finalResults[match.$id] ? 'grey' : '#ffffff',
                                                                    color: finalResults[match.$id] ? '#ffffff' : '#356596'
                                                                },
                                                                marginBottom: '10px',
                                                                width: '100%'
                                                            }}
                                                            disabled={finalResults[match.$id] ? true : false}
                                                        >
                                                            {finalResults[match.$id] 
                                                                ? `${finalResults[match.$id].finalScoreHome} - ${finalResults[match.$id].finalScoreOpposition}`
                                                                : isTimerRunning ? formatTime(seconds) : 'Start Match'}
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            size="small"
                                                            sx={{
                                                                backgroundColor: '#d32f2f',
                                                                color: '#ffffff',
                                                                '&:hover': {
                                                                    backgroundColor: '#ffffff',
                                                                    color: '#d32f2f'
                                                                },
                                                                marginBottom: '10px',
                                                                width: '100%'
                                                            }}
                                                        >
                                                            Call up
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="success"
                                                            size="small"
                                                            sx={{
                                                                backgroundColor: '#388e3c',
                                                                color: '#ffffff',
                                                                '&:hover': {
                                                                    backgroundColor: '#ffffff',
                                                                    color: '#388e3c'
                                                                },
                                                                width: '100%',
                                                                marginBottom: '10px',
                                                            }}
                                                        >
                                                            Line-up
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="info"
                                                            size="small"
                                                            startIcon={<TelegramIcon />}
                                                            sx={{
                                                                backgroundColor: '#0088cc',
                                                                color: '#ffffff',
                                                                '&:hover': {
                                                                    backgroundColor: '#ffffff',
                                                                    color: '#0088cc',
                                                                    
                                                                },
                                                                width: '100%'
                                                            }}
                                                            onClick={() => handleNotify(match)}
                                                        >
                                                            Notify
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} md={10}>
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Stadium"
                                                                    value={match.stadium}
                                                                    InputProps={{ readOnly: true }}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Duration"
                                                                    value={match.duration}
                                                                    InputProps={{ readOnly: true }}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Notes"
                                                                    value={match.notes}
                                                                    InputProps={{ readOnly: true }}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Divider style={{ margin: '10px 0' }} />

                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Session"
                                                                    value={match.session}
                                                                    InputProps={{ readOnly: true }}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Lineup"
                                                                    value={match.lineup}
                                                                    InputProps={{ readOnly: true }}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination
                    count={Math.ceil(matches.length / rowsPerPage)}
                    page={currentPage}
                    onChange={(event, page) => setCurrentPage(page)}
                />
            </Box>
            <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose} sx={{ width: '100vw' }}>
                {selectedMatch && (
                    <Box sx={{ width: '100vw' }}>
                        <Box sx={{ position: 'relative', height: '150px', marginTop: '30px' }}>
                            <img src={matchbanner} alt="Match Banner" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            <Box sx={{
                                position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '30px 30px 10px 30px'
                            }}>
                                <Typography variant="body1" sx={{ color: '#fff' }}>
                                {new Date(selectedMatch.matchDate).toLocaleDateString()} at {new Date(selectedMatch.matchDate).toLocaleTimeString()} - {selectedMatch.duration} min
                                </Typography>
                                <Typography variant="h6" sx={{ color: '#fff', fontWeight: 'bold', fontSize: '1.5em' }}>
                                    {teamName} 0 - 0 {selectedMatch.opposition}
                                </Typography>
                                <Typography variant="body1" sx={{ color: '#fff' }}>
                                    {selectedMatch.matchName} - {selectedMatch.stadium}
                                </Typography>
                            </Box>
                            <IconButton onClick={handleDrawerClose} sx={{ position: 'absolute', top: 10, right: 10, color: '#fff' }} disabled={drawerLocked}>
                                <CloseIcon />
                            </IconButton>
                            <IconButton onClick={handleLockToggle} sx={{ position: 'absolute', top: 10, right: 50, color: '#fff' }}>
                                {drawerLocked ? <LockOpenIcon sx={{ color: 'green' }} /> : <LockIcon sx={{ color: 'red' }} />}
                            </IconButton>
                        </Box>
                        
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <StartMatch 
                                teamName={teamName} 
                                opposition={selectedMatch.opposition} 
                                matchID={selectedMatch.$id} 
                                isRunning={isTimerRunning} 
                                setIsRunning={setIsTimerRunning} 
                                seconds={seconds} 
                                setSeconds={setSeconds} 
                                handleStartPause={handleStartPause}
                                handleMatchEnd={handleMatchEnd}
                            />
                        </Box>
                    </Box>
                )}
            </Drawer>
        </Box>
    );
}

