const conf = {
    appwriteUrl: process.env.REACT_APP_APPWRITE_URL,
    appwriteProjectId: process.env.REACT_APP_APPWRITE_PROJECT_ID,
    appwriteDatabaseId: process.env.REACT_APP_APPWRITE_DATABASE_ID,
    appwriteCollectionId: process.env.REACT_APP_APPWRITE_COLLECTION_ID,
    appwriteBucketId: process.env.REACT_APP_APPWRITE_BUCKET_ID,
    createTrainingCollectionId: process.env.REACT_APP_APPWRITE_CREATE_TRAINING_COLLECTION_ID,
    createClubCollectionId: process.env.REACT_APP_APPWRITE_CREATE_CLUB_COLLECTION_ID, 
    appwriteSessionsNotesId: process.env.REACT_APP_APPWRITE_SESSIONSNOTES_COLLECTION_ID,
    appwriteUsersId: process.env.REACT_APP_APPWRITE_USERS_ID,
    createSquadCollectionId: process.env.REACT_APP_APPWRITE_CREATE_SQUAD_COLLECTION_ID
}

console.log("Config:", conf);

export default conf;
