const conf = {
    appwriteUrl: process.env.REACT_APP_APPWRITE_URL,
    appwriteProjectId: process.env.REACT_APP_APPWRITE_PROJECT_ID,
    appwriteDatabaseId: process.env.REACT_APP_APPWRITE_DATABASE_ID,
    appwriteCollectionId: process.env.REACT_APP_APPWRITE_COLLECTION_ID,
    appwriteBucketId: process.env.REACT_APP_APPWRITE_BUCKET_ID,
    createTrainingCollectionId: process.env.REACT_APP_APPWRITE_CREATE_TRAINING_COLLECTION_ID,
    createClubCollectionId: process.env.REACT_APP_APPWRITE_CREATE_CLUB_COLLECTION_ID, 
    appwriteSessionsNotesId: process.env.REACT_APP_APPWRITE_SESSIONSNOTES_COLLECTION_ID,
    appwriteUsersId: process.env.REACT_APP_APPWRITE_USERS_ID,
    createSquadCollectionId: process.env.REACT_APP_APPWRITE_CREATE_SQUAD_COLLECTION_ID,
    goalsCollectionId: process.env.REACT_APP_APPWRITE_GOALS_COLLECTION_ID,
    cardsCollectionId: process.env.REACT_APP_APPWRITE_CARDS_COLLECTION_ID,
    substitutesCollectionId: process.env.REACT_APP_APPWRITE_SUBSTITUTES_COLLECTION_ID,
    matchEventsCollectionId: process.env.REACT_APP_APPWRITE_MATCH_EVENTS_COLLECTION_ID,
    finalResultsCollectionId: process.env.REACT_APP_APPWRITE_FINAL_RESULTS_COLLECTION_ID,
    rpeCaptureCollectionId: process.env.REACT_APP_APPWRITE_RPE_CAPTURE_COLLECTION_ID,
    rpeReadinessCollectionId: process.env.REACT_APP_APPWRITE_READINESS_RPE_COLLECTION_ID,
    appwriteMessageTrackingCollectionId: process.env.REACT_APP_APPWRITE_MESSAGE_TRACKING_COLLECTION_ID,
    appwriteGroupChatCollectionId: process.env.REACT_APP_APPWRITE_GROUP_CHAT_COLLECTION_ID,
    appwriteClubEventsCollectionId: process.env.REACT_APP_APPWRITE_CLUB_EVENTS_COLLECTION_ID,
    telegramBotToken: process.env.REACT_APP_TELEGRAM_BOT_TOKEN,
    sendGridApiKey: process.env.REACT_APP_SENDGRID_API_KEY,
    appwriteProviderId: process.env.REACT_APP_APPWRITE_PROVIDER_COLLECTION_ID,
    SendEmailAppwrite: process.env.REACT_APP_SENDEMAILAPPWRITE,
    Send_EmailAppwriteId: process.env.REACT_APP_SEND_EMAIL_APPWRITE_ID,
    deploymentAppwriteId: process.env.REACT_APP_DEPLOYMENT_APPWRITE_ID,
    stripe_Integration_API: process.env.STRIPE_INTEGRATION_API,
    appwriteAdminApiKey: process.env.REACT_APP_APPWRITE_ADMIN_API_KEY,
    appwrite_user_audit: process.env.REACT_APP_APPWRITE_USER_AUDIT_COLLECTION_ID,

    smtpHost: process.env.SMTP_HOST,
    smtpPort: process.env.SMTP_PORT,
    smtpUser: process.env.SMTP_USER,
    smtpPass: process.env.SMTP_PASS,

}

console.log("Config:", conf);

export default conf;
