// // Custom PlanOne component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ToggleContainer = styled('div')({
  backgroundColor: '#fff',
  borderRadius: '20px',
  display: 'flex',
  justifyContent: 'center',
  padding: '4px',
  width: '200px',
  height: '34px',
  margin: '-16px',
});

const ToggleOption = styled(Button)(({ theme, active }) => ({
  backgroundColor: active ? '#356596' : 'transparent',
  borderRadius: '20px',
  color: active ? '#fff' : '#356596',
  fontSize: '0.875rem',
  padding: '10px 20px',
}));

const PlanOne = ({ onClosePlans }) => {
  const [plan, setPlan] = useState('monthly');
  const navigate = useNavigate();

  const handlePlanChange = (newPlan) => {
    setPlan(newPlan);
  };

  const handleSubscribe = () => {
    onClosePlans();
    navigate('/dashboard/subscribe', {
      state: {
        plan: 'Plan One',
        cost: plan === 'monthly' ? '€104.00/annually' : '€84.00/annually',
        billingCycle: plan === 'monthly' ? 'Monthly' : 'Annually',
        billedToday: plan === 'monthly' ? '€8.66' : '€84.00',
        totalBilled: plan === 'monthly' ? '€104.00' : '€84.00',
        billingDate: plan === 'monthly' ? 'Monthly subscription 2024' : `Total billed in Jun ${new Date().getFullYear()}`
      }
    });
  };

  return (
    <Card sx={{
      width: '240px',
      backgroundColor: '#356596',
      color: '#ffffff',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      transition: 'box-shadow 0.7s ease-in-out',
      '&:hover': {
        boxShadow: '0 10px 16px rgba(0, 0, 0, 0.9)'
      },
      p: 2,
      textAlign: 'center',
      overflow: 'hidden',
      mt: 6
    }}>
      <CardContent>
        <ToggleContainer>
          <ToggleOption onClick={() => handlePlanChange('annual')} active={plan === 'annual'}>
            Annual
          </ToggleOption>
          <ToggleOption onClick={() => handlePlanChange('monthly')} active={plan === 'monthly'}>
            Monthly
          </ToggleOption>
        </ToggleContainer>
        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
          Most Popular
        </Typography>
        <Typography variant="h5" sx={{ mb: 1 }}>
          €{plan === 'monthly' ? "8.66" : "84.00"} /month
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Total €{plan === 'monthly' ? "104.00" : "84.00"} + tax
          <br />
          Billed {plan === 'monthly' ? "monthly" : "Now"}
        </Typography>
        <Button variant="contained" onClick={handleSubscribe} sx={{
          backgroundColor: 'white',
          mt: 6,
          color: '#356596',
          '&:hover': {
            backgroundColor: '#356596',
            color: 'white',
          }
        }}>
          Subscribe
        </Button>
      </CardContent>
    </Card>
  );
};

export default PlanOne;
