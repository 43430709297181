// // Custom RPECapture component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState } from 'react';
import { Box, Typography, Slider, IconButton, TextField, Button, Card, CardContent } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const descriptors = {
    Fatigue: [
        { value: 0, label: 'At Rest' },
        { value: 1, label: 'Slightly Tired' },
        { value: 2, label: 'Tired' },
        { value: 3, label: 'Very Tired' },
        { value: 4, label: 'Exhausted' },
        { value: 5, label: 'Played Out' }
    ],
    SleepQuality: [
        { value: 0, label: 'Rested' },
        { value: 1, label: 'Great Sleep' },
        { value: 2, label: 'Good Sleep' },
        { value: 3, label: 'Restless Sleep' },
        { value: 4, label: 'Not Much' },
        { value: 5, label: 'No Sleep' }
    ],
    MuscleSoreness: [
        { value: 0, label: 'No Pain' },
        { value: 1, label: 'Slight Discomfort' },
        { value: 2, label: 'Discomfort' },
        { value: 3, label: 'Painful' },
        { value: 4, label: 'Really Painful' },
        { value: 5, label: 'Excruciating Pain' }
    ],
    StressLevel: [
        { value: 0, label: 'Carefree' },
        { value: 1, label: 'Good' },
        { value: 2, label: 'Very Good' },
        { value: 3, label: 'Not too Bad' },
        { value: 4, label: 'Just Ok' },
        { value: 5, label: 'No Interest' }
    ],
    Mood: [
        { value: 0, label: 'Optimistic' },
        { value: 1, label: 'Bold' },
        { value: 2, label: 'Cheerful' },
        { value: 3, label: 'Dejected' },
        { value: 4, label: 'Aggravated' },
        { value: 5, label: 'Pessimistic' }
    ]
};

const categories = Object.keys(descriptors);

const CardContainer = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    justifyContent: 'space-around',
});

const ReadinessCard = styled(Card)(({ theme }) => ({
    width: 300,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    transition: 'box-shadow 0.7s ease-in-out',
    '&:hover': {
        boxShadow: '0 10px 16px rgba(0, 0, 0, 0.9)'
    },
    overflow: 'hidden',
    bgcolor: '#ffffff',
    marginTop: theme.spacing(2),
}));

const RPECapture = ({ readinessValues, setReadinessValues }) => {
    return (
        <CardContainer>
            {categories.map((category) => (
                <ReadinessCard key={category}>
                    <CardContent>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            {descriptors[category][readinessValues[category]].label}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton onClick={() => setReadinessValues({
                                ...readinessValues,
                                [category]: Math.max(readinessValues[category] - 1, 0)
                            })}>
                                <RemoveCircleIcon />
                            </IconButton>
                            <Slider
                                value={readinessValues[category]}
                                onChange={(e, value) => setReadinessValues({
                                    ...readinessValues,
                                    [category]: value
                                })}
                                step={1}
                                marks={descriptors[category].map(descriptor => ({ value: descriptor.value, label: '' }))}
                                min={0}
                                max={5}
                                valueLabelDisplay="auto"
                                sx={{ flexGrow: 1, mx: 2 }}
                            />
                            <IconButton onClick={() => setReadinessValues({
                                ...readinessValues,
                                [category]: Math.min(readinessValues[category] + 1, 5)
                            })}>
                                <AddCircleIcon />
                            </IconButton>
                        </Box>
                    </CardContent>
                </ReadinessCard>
            ))}
        </CardContainer>
    );
};

export default RPECapture;
