// import { enqueueSnackbar } from 'notistack';
// import { Client, Databases, Query, Messaging } from 'appwrite';
// import { sendTelegramMessage } from '../utils/telegramUtils';
// import conf from '../conf/conf';

// const client = new Client()
//   .setEndpoint(conf.appwriteUrl)
//   .setProject(conf.appwriteProjectId);

// const databases = new Databases(client);


// const chatId = '-1002230926017'; // Ensure this is the correct channel ID
// const groupChatId = '-1002201023900'; // Group chat ID

// // Function to store message details in Appwrite
// const storeMessageDetails = async (chatId, messageId, eventType, eventId) => {
//   try {
//     await databases.createDocument(
//       conf.appwriteDatabaseId,
//       conf.appwriteMessageTrackingCollectionId,
//       'unique()', // Unique ID
//       {
//         chatId,
//         messageId: String(messageId),
//         eventType,
//         eventId,
//         timestamp: new Date().toISOString(),
//       }
//     );
//   } catch (error) {
//     console.error('Error storing message details:', error);
//   }
// };

// // Function to send RPE message
// export const sendRPEMessage = async () => {
//   const message = `Hello, please submit your RPE data for today's session using the following format:
//   \n\n/fatigue | between 0 - 5 \n/sleepQuality | between 0 - 5\n/muscleSoreness | between 0 - 5\n/stressLevel | between 0 - 5\n/mood | between 0 - 5\n\nThank you!`;

//   try {
//     const response = await sendTelegramMessage(chatId, message);
//     console.log('RPE request sent to player');

//     // Store message details
//     await storeMessageDetails(chatId, response.message_id, 'RPE', null);

//     // Show snack message
//     enqueueSnackbar('RPE Message sent', { variant: 'success' });
//   } catch (error) {
//     console.error('Error sending RPE message:', error);
//     enqueueSnackbar('Failed to send RPE Message', { variant: 'error' });
//   }
// };

// export const sendBirthdayMessage = async (playerName, playerBirthday, playerId) => {
//   const message = `🎉🎂 It's a big day for you!! Happy Birthday ${playerName}. From all of us at FitSport, have the best day ever! 🎂🎉`;

//   const now = new Date();
//   const birthday = new Date(playerBirthday);
//   birthday.setFullYear(now.getFullYear());
//   birthday.setHours(0, 0, 0, 0);

//   if (birthday < now) {
//     birthday.setFullYear(now.getFullYear() + 1);
//   }

//   const delay = birthday.getTime() - now.getTime();

//   setTimeout(async () => {
//     try {
//       const response = await sendTelegramMessage(chatId, message);
//       console.log(`Birthday message sent for ${playerName}`);

//       // Store message details
//       await storeMessageDetails(chatId, response.message_id, 'birthday', playerId);

//       // Show snack message
//       enqueueSnackbar('Birthday Message sent', { variant: 'success' });

//     } catch (error) {
//       console.error('Error sending birthday message:', error);
//       enqueueSnackbar('Failed to send Birthday Message', { variant: 'error' });
//     }
//   }, delay);
// };

// export const sendMatchReminder = async (match, manual = false) => {
//   const matchDate = new Date(match.matchDate);
//   const formattedDate = matchDate.toLocaleDateString('en-GB', {
//     day: 'numeric', month: 'long', year: 'numeric'
//   });
//   const formattedTime = matchDate.toLocaleTimeString('en-GB', {
//     hour: '2-digit', minute: '2-digit'
//   });

//   const message = `⏰ REMINDER:\nOur next match is on\n${formattedDate}\nat ${formattedTime}\n${match.stadium},\n${match.opposition}`;

//   try {
//     const response = await sendTelegramMessage(chatId, message);
//     console.log(`Match reminder sent for ${match.matchName}`);

//     // Store message details
//     await storeMessageDetails(chatId, response.message_id, 'match', match.id);

//     if (manual) {
//       enqueueSnackbar('Match reminder delivered', { variant: 'success' });
//     }

//   } catch (error) {
//     console.error('Error sending match reminder:', error);
//     if (manual) {
//       enqueueSnackbar('Failed to send Match reminder', { variant: 'error' });
//     }
//   }
// };



// // Function to store group chat message details in Appwrite
// const storeGroupChatMessage = async (chatId, fromApp, toApp, fromMessage, toMessage, messageId) => {
//   try {
//     await databases.createDocument(
//       conf.appwriteDatabaseId,
//       conf.appwriteGroupChatCollectionId,
//       'unique()', // Unique ID
//       {
//         chatId,
//         fromApp,
//         toApp,
//         fromMessage,
//         toMessage,
//         dateSent: new Date().toISOString(),
//         messageId
//       }
//     );
//   } catch (error) {
//     console.error('Error storing message details:', error);
//   }
// };

// // Function to send group chat message
// export const sendGroupChatMessage = async (message) => {
//   try {
//     const response = await sendTelegramMessage(groupChatId, message);
//     console.log('Group chat message sent');

//     // Store message details
//     await storeGroupChatMessage(groupChatId, 'app', 'group', message, '', response.message_id);

//     return response;
//   } catch (error) {
//     console.error('Failed to send group chat message:', error);
//     throw error;
//   }
// };

// // Function to retrieve group chat messages
// export const getGroupChatMessages = async () => {
//   try {
//     const response = await databases.listDocuments(
//       conf.appwriteDatabaseId,
//       conf.appwriteGroupChatCollectionId,
//       [
//         Query.equal('chatId', groupChatId),
//         Query.orderAsc('$createdAt'),
//       ]
//     );
//     return response.documents;
//   } catch (error) {
//     console.error('Failed to retrieve group chat messages:', error);
//     throw error;
//   }
// };

// // Function to listen for new group chat messages
// export const subscribeToGroupChatMessages = (callback) => {
//   const unsubscribe = Messaging.subscribe(
//     [`databases.${conf.appwriteDatabaseId}.collections.${conf.appwriteGroupChatCollectionId}.documents`],
//     (response) => {
//       if (response.events.includes('databases.*.collections.*.documents.*.create')) {
//         const newMessage = response.payload;
//         if (newMessage.chatId === groupChatId) {
//           callback(newMessage);
//         }
//       }
//     }
//   );
//   return unsubscribe;
// };

// export const sendTrainingReminder = async (training, manual = false) => {
//   const trainingDate = new Date(training.start);
//   const formattedDate = trainingDate.toLocaleDateString('en-GB', {
//     day: 'numeric', month: 'long', year: 'numeric'
//   });
//   const formattedTime = trainingDate.toLocaleTimeString('en-GB', {
//     hour: '2-digit', minute: '2-digit'
//   });

//   const message = `⚽️ Upcoming Training ⚽️\n${training.sessionTitle}\n${formattedDate} at ${formattedTime}\n${training.location}\nCoach: ${training.coachName}\nAssisting Coach: ${training.assistingCoach}\nTeam: ${training.teamGroup}`;

//   try {
//     const response = await sendTelegramMessage(chatId, message);
//     console.log(`Training reminder sent for ${training.sessionTitle}`);

//     // Store message details
//     await storeMessageDetails(chatId, response.message_id, 'training', training.id);

//     if (manual) {
//       enqueueSnackbar('Training reminder delivered', { variant: 'success' });
//     }

//   } catch (error) {
//     console.error('Error sending training reminder:', error);
//     if (manual) {
//       enqueueSnackbar('Failed to send Training reminder', { variant: 'error' });
//     }
//   }
// };

// export const scheduleAutomaticReminders = (match) => {
//   const matchDate = new Date(match.matchDate);

//   const reminders = [
//     { time: matchDate.getTime() - (3 * 24 * 60 * 60 * 1000), message: '3 days before' },
//     { time: matchDate.getTime() - (24 * 60 * 60 * 1000), message: '1 day before' },
//     { time: matchDate.getTime() - (2 * 60 * 60 * 1000), message: '2 hours before' },
//   ];

//   reminders.forEach(reminder => {
//     const delay = reminder.time - new Date().getTime();
//     if (delay > 0) {
//       setTimeout(() => sendMatchReminder(match), delay);
//     }
//   });
// };

// // No reminders are sent on page load.
// // The functions `sendBirthdayMessage`, `sendMatchReminder`, `sendTrainingReminder`, and `scheduleAutomaticReminders` should be called explicitly when required.

// // Ensure no function calls on page load
// // For example, in your component or app initialization, do not call any of the above functions.
// import { enqueueSnackbar } from 'notistack';
// import { Client, Databases, Query, Messaging } from 'appwrite';
// import { sendTelegramMessage } from '../utils/telegramUtils';
// import conf from '../conf/conf';

// const client = new Client()
//   .setEndpoint(conf.appwriteUrl)
//   .setProject(conf.appwriteProjectId);

// const databases = new Databases(client);

// const chatId = '-1002230926017'; // Ensure this is the correct channel ID
// const groupChatId = '-1002201023900'; // Group chat ID

// // Helper function to validate and stringify messageId
// const validateMessageId = (messageId) => {
//   if (typeof messageId !== 'string') {
//     messageId = String(messageId);
//   }
//   if (messageId.length > 1000) {
//     throw new Error('messageId exceeds 1000 characters limit');
//   }
//   return messageId;
// };

// // Function to store message details in Appwrite
// const storeMessageDetails = async (chatId, messageId, eventType, eventId) => {
//   try {
//     messageId = validateMessageId(messageId);
//     await databases.createDocument(
//       conf.appwriteDatabaseId,
//       conf.appwriteMessageTrackingCollectionId,
//       'unique()', // Unique ID
//       {
//         chatId,
//         messageId,
//         eventType,
//         eventId,
//         timestamp: new Date().toISOString(),
//       }
//     );
//   } catch (error) {
//     console.error('Error storing message details:', error);
//   }
// };

// // Function to send RPE message
// export const sendRPEMessage = async () => {
//   const message = `Hello, please submit your RPE data for today's session using the following format:
//   \n\n/fatigue | between 0 - 5 \n/sleepQuality | between 0 - 5\n/muscleSoreness | between 0 - 5\n/stressLevel | between 0 - 5\n/mood | between 0 - 5\n\nThank you!`;

//   try {
//     const response = await sendTelegramMessage(chatId, message);
//     console.log('RPE request sent to player');

//     // Store message details
//     await storeMessageDetails(chatId, response.message_id, 'RPE', null);

//     // Show snack message
//     enqueueSnackbar('RPE Message sent', { variant: 'success' });
//   } catch (error) {
//     console.error('Error sending RPE message:', error);
//     enqueueSnackbar('Failed to send RPE Message', { variant: 'error' });
//   }
// };

// export const sendBirthdayMessage = async (playerName, playerBirthday, playerId) => {
//   const message = `🎉🎂 It's a big day for you!! Happy Birthday ${playerName}. From all of us at FitSport, have the best day ever! 🎂🎉`;

//   const now = new Date();
//   const birthday = new Date(playerBirthday);
//   birthday.setFullYear(now.getFullYear());
//   birthday.setHours(0, 0, 0, 0);

//   if (birthday < now) {
//     birthday.setFullYear(now.getFullYear() + 1);
//   }

//   const delay = birthday.getTime() - now.getTime();

//   setTimeout(async () => {
//     try {
//       const response = await sendTelegramMessage(chatId, message);
//       console.log(`Birthday message sent for ${playerName}`);

//       // Store message details
//       await storeMessageDetails(chatId, response.message_id, 'birthday', playerId);

//       // Show snack message
//       enqueueSnackbar('Birthday Message sent', { variant: 'success' });

//     } catch (error) {
//       console.error('Error sending birthday message:', error);
//       enqueueSnackbar('Failed to send Birthday Message', { variant: 'error' });
//     }
//   }, delay);
// };

// export const sendMatchReminder = async (match, manual = false) => {
//   const matchDate = new Date(match.matchDate);
//   const formattedDate = matchDate.toLocaleDateString('en-GB', {
//     day: 'numeric', month: 'long', year: 'numeric'
//   });
//   const formattedTime = matchDate.toLocaleTimeString('en-GB', {
//     hour: '2-digit', minute: '2-digit'
//   });

//   const message = `⏰ REMINDER:\nOur next match is on\n${formattedDate}\nat ${formattedTime}\n${match.stadium},\n${match.opposition}`;

//   try {
//     const response = await sendTelegramMessage(chatId, message);
//     console.log(`Match reminder sent for ${match.matchName}`);

//     // Store message details
//     await storeMessageDetails(chatId, response.message_id, 'match', match.id);

//     if (manual) {
//       enqueueSnackbar('Match reminder delivered', { variant: 'success' });
//     }

//   } catch (error) {
//     console.error('Error sending match reminder:', error);
//     if (manual) {
//       enqueueSnackbar('Failed to send Match reminder', { variant: 'error' });
//     }
//   }
// };

// // Function to store group chat message details in Appwrite
// const storeGroupChatMessage = async (chatId, fromApp, toApp, fromMessage, toMessage, messageId) => {
//   try {
//     messageId = validateMessageId(messageId);
//     await databases.createDocument(
//       conf.appwriteDatabaseId,
//       conf.appwriteGroupChatCollectionId,
//       'unique()', // Unique ID
//       {
//         chatId,
//         fromApp,
//         toApp,
//         fromMessage,
//         toMessage,
//         dateSent: new Date().toISOString(),
//         messageId
//       }
//     );
//   } catch (error) {
//     console.error('Error storing message details:', error);
//   }
// };

// // Function to send group chat message
// export const sendGroupChatMessage = async (message) => {
//   try {
//     const response = await sendTelegramMessage(groupChatId, message);
//     console.log('Group chat message sent');

//     // Store message details
//     await storeGroupChatMessage(groupChatId, 'app', 'group', message, '', response.message_id);

//     return response;
//   } catch (error) {
//     console.error('Failed to send group chat message:', error);
//     throw error;
//   }
// };

// // Function to retrieve group chat messages
// export const getGroupChatMessages = async () => {
//   try {
//     const response = await databases.listDocuments(
//       conf.appwriteDatabaseId,
//       conf.appwriteGroupChatCollectionId,
//       [
//         Query.equal('chatId', groupChatId),
//         Query.orderAsc('$createdAt'),
//       ]
//     );
//     return response.documents;
//   } catch (error) {
//     console.error('Failed to retrieve group chat messages:', error);
//     throw error;
//   }
// };

// // Function to listen for new group chat messages
// export const subscribeToGroupChatMessages = (callback) => {
//   const unsubscribe = Messaging.subscribe(
//     [`databases.${conf.appwriteDatabaseId}.collections.${conf.appwriteGroupChatCollectionId}.documents`],
//     (response) => {
//       if (response.events.includes('databases.*.collections.*.documents.*.create')) {
//         const newMessage = response.payload;
//         if (newMessage.chatId === groupChatId) {
//           callback(newMessage);
//         }
//       }
//     }
//   );
//   return unsubscribe;
// };

// export const sendTrainingReminder = async (training, manual = false) => {
//   const trainingDate = new Date(training.start);
//   const formattedDate = trainingDate.toLocaleDateString('en-GB', {
//     day: 'numeric', month: 'long', year: 'numeric'
//   });
//   const formattedTime = trainingDate.toLocaleTimeString('en-GB', {
//     hour: '2-digit', minute: '2-digit'
//   });

//   const message = `⚽️ Upcoming Training ⚽️\n${training.sessionTitle}\n${formattedDate} at ${formattedTime}\n${training.location}\nCoach: ${training.coachName}\nAssisting Coach: ${training.assistingCoach}\nTeam: ${training.teamGroup}`;

//   try {
//     const response = await sendTelegramMessage(chatId, message);
//     console.log(`Training reminder sent for ${training.sessionTitle}`);

//     // Store message details
//     await storeMessageDetails(chatId, response.message_id, 'training', training.id);

//     if (manual) {
//       enqueueSnackbar('Training reminder delivered', { variant: 'success' });
//     }

//   } catch (error) {
//     console.error('Error sending training reminder:', error);
//     if (manual) {
//       enqueueSnackbar('Failed to send Training reminder', { variant: 'error' });
//     }
//   }
// };

// export const scheduleAutomaticReminders = (match) => {
//   const matchDate = new Date(match.matchDate);

//   const reminders = [
//     { time: matchDate.getTime() - (3 * 24 * 60 * 60 * 1000), message: '3 days before' },
//     { time: matchDate.getTime() - (24 * 60 * 60 * 1000), message: '1 day before' },
//     { time: matchDate.getTime() - (2 * 60 * 60 * 1000), message: '2 hours before' },
//   ];

//   reminders.forEach(reminder => {
//     const delay = reminder.time - new Date().getTime();
//     if (delay > 0) {
//       setTimeout(() => sendMatchReminder(match), delay);
//     }
//   });
// };

// // No reminders are sent on page load.
// // The functions `sendBirthdayMessage`, `sendMatchReminder`, `sendTrainingReminder`, and `scheduleAutomaticReminders` should be called explicitly when required.

// // Ensure no function calls on page load
// // For example, in your component or app initialization, do not call any of the above functions.
import { enqueueSnackbar } from 'notistack';
import { Client, Databases, Query, Messaging } from 'appwrite';
import { sendTelegramMessage } from '../utils/telegramUtils';
import conf from '../conf/conf';
import authService from '../appwrite/auth';

const client = new Client()
  .setEndpoint(conf.appwriteUrl)
  .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const chatId = '-1002230926017'; // Ensure this is the correct channel ID
const groupChatId = '-1002201023900'; // Group chat ID

// Helper function to validate and stringify messageId
const validateMessageId = (messageId) => {
  if (typeof messageId !== 'string') {
    messageId = String(messageId);
  }
  if (messageId.length > 1000) {
    throw new Error('messageId exceeds 1000 characters limit');
  }
  return messageId;
};

// Function to store message details in Appwrite
const storeMessageDetails = async (chatId, messageId, eventType, eventId) => {
  try {
    messageId = validateMessageId(messageId);
    await databases.createDocument(
      conf.appwriteDatabaseId,
      conf.appwriteMessageTrackingCollectionId,
      'unique()', // Unique ID
      {
        chatId,
        messageId,
        eventType,
        eventId,
        timestamp: new Date().toISOString(),
      }
    );
  } catch (error) {
    console.error('Error storing message details:', error);
  }
};

// Function to validate the logged-in user
const validateUser = async () => {
  const currentUser = await authService.getCurrentUser();
  if (!currentUser) {
    throw new Error('No user is currently logged in.');
  }
  return currentUser;
};

// Function to send RPE message
export const sendRPEMessage = async () => {
  try {
    const currentUser = await validateUser();

    const message = `Hello, please submit your RPE data for today's session using the following format:
    \n\n/fatigue | between 0 - 5 \n/sleepQuality | between 0 - 5\n/muscleSoreness | between 0 - 5\n/stressLevel | between 0 - 5\n/mood | between 0 - 5\n\nThank you!`;

    const response = await sendTelegramMessage(chatId, message);
    console.log('RPE request sent to player');

    // Store message details
    await storeMessageDetails(chatId, response.message_id, 'RPE', null);

    // Show snack message
    enqueueSnackbar('RPE Message sent', { variant: 'success' });
  } catch (error) {
    console.error('Error sending RPE message:', error);
    enqueueSnackbar('Failed to send RPE Message', { variant: 'error' });
  }
};

export const sendBirthdayMessage = async (playerName, playerBirthday, playerId) => {
  try {
    const currentUser = await validateUser();

    const message = `🎉🎂 It's a big day for you!! Happy Birthday ${playerName}. From all of us at FitSport, have the best day ever! 🎂🎉`;

    const now = new Date();
    const birthday = new Date(playerBirthday);
    birthday.setFullYear(now.getFullYear());
    birthday.setHours(0, 0, 0, 0);

    if (birthday < now) {
      birthday.setFullYear(now.getFullYear() + 1);
    }

    const delay = birthday.getTime() - now.getTime();

    setTimeout(async () => {
      try {
        const response = await sendTelegramMessage(chatId, message);
        console.log(`Birthday message sent for ${playerName}`);

        // Store message details
        await storeMessageDetails(chatId, response.message_id, 'birthday', playerId);

        // Show snack message
        enqueueSnackbar('Birthday Message sent', { variant: 'success' });

      } catch (error) {
        console.error('Error sending birthday message:', error);
        enqueueSnackbar('Failed to send Birthday Message', { variant: 'error' });
      }
    }, delay);
  } catch (error) {
    console.error('Error validating user or scheduling birthday message:', error);
    enqueueSnackbar('Failed to validate user or schedule Birthday Message', { variant: 'error' });
  }
};

export const sendMatchReminder = async (match, manual = false) => {
  try {
    const currentUser = await validateUser();

    const matchDate = new Date(match.matchDate);
    const formattedDate = matchDate.toLocaleDateString('en-GB', {
      day: 'numeric', month: 'long', year: 'numeric'
    });
    const formattedTime = matchDate.toLocaleTimeString('en-GB', {
      hour: '2-digit', minute: '2-digit'
    });

    const message = `⏰ REMINDER:\nOur next match is on\n${formattedDate}\nat ${formattedTime}\n${match.stadium},\n${match.opposition}`;

    const response = await sendTelegramMessage(chatId, message);
    console.log(`Match reminder sent for ${match.matchName}`);

    // Store message details
    await storeMessageDetails(chatId, response.message_id, 'match', match.id);

    if (manual) {
      enqueueSnackbar('Match reminder delivered', { variant: 'success' });
    }
  } catch (error) {
    console.error('Error sending match reminder:', error);
    if (manual) {
      enqueueSnackbar('Failed to send Match reminder', { variant: 'error' });
    }
  }
};

export const sendTrainingReminder = async (training, manual = false) => {
  try {
    const currentUser = await validateUser();

    const trainingDate = new Date(training.start);
    const formattedDate = trainingDate.toLocaleDateString('en-GB', {
      day: 'numeric', month: 'long', year: 'numeric'
    });
    const formattedTime = trainingDate.toLocaleTimeString('en-GB', {
      hour: '2-digit', minute: '2-digit'
    });

    const message = `⚽️ Upcoming Training ⚽️\n${training.sessionTitle}\n${formattedDate} at ${formattedTime}\n${training.location}\nCoach: ${training.coachName}\nAssisting Coach: ${training.assistingCoach}\nTeam: ${training.teamGroup}`;

    const response = await sendTelegramMessage(chatId, message);
    console.log(`Training reminder sent for ${training.sessionTitle}`);

    // Store message details
    await storeMessageDetails(chatId, response.message_id, 'training', training.id);

    if (manual) {
      enqueueSnackbar('Training reminder delivered', { variant: 'success' });
    }
  } catch (error) {
    console.error('Error sending training reminder:', error);
    if (manual) {
      enqueueSnackbar('Failed to send Training reminder', { variant: 'error' });
    }
  }
};

export const scheduleAutomaticReminders = (match) => {
  try {
    validateUser().then(() => {
      const matchDate = new Date(match.matchDate);

      const reminders = [
        { time: matchDate.getTime() - (3 * 24 * 60 * 60 * 1000), message: '3 days before' },
        { time: matchDate.getTime() - (24 * 60 * 60 * 1000), message: '1 day before' },
        { time: matchDate.getTime() - (2 * 60 * 60 * 1000), message: '2 hours before' },
      ];

      reminders.forEach(reminder => {
        const delay = reminder.time - new Date().getTime();
        if (delay > 0) {
          setTimeout(() => sendMatchReminder(match), delay);
        }
      });
    });
  } catch (error) {
    console.error('Error scheduling automatic reminders:', error);
  }
};

// Function to store group chat message details in Appwrite
const storeGroupChatMessage = async (chatId, fromApp, toApp, fromMessage, toMessage, messageId) => {
  try {
    messageId = validateMessageId(messageId);
    await databases.createDocument(
      conf.appwriteDatabaseId,
      conf.appwriteGroupChatCollectionId,
      'unique()', // Unique ID
      {
        chatId,
        fromApp,
        toApp,
        fromMessage,
        toMessage,
        dateSent: new Date().toISOString(),
        messageId
      }
    );
  } catch (error) {
    console.error('Error storing message details:', error);
  }
};

// Function to send group chat message
export const sendGroupChatMessage = async (message) => {
  try {
    const currentUser = await validateUser();

    const response = await sendTelegramMessage(groupChatId, message);
    console.log('Group chat message sent');

    // Store message details
    await storeGroupChatMessage(groupChatId, 'app', 'group', message, '', response.message_id);

    return response;
  } catch (error) {
    console.error('Failed to send group chat message:', error);
    throw error;
  }
};

// Function to retrieve group chat messages
export const getGroupChatMessages = async () => {
  try {
    await validateUser();

    const response = await databases.listDocuments(
      conf.appwriteDatabaseId,
      conf.appwriteGroupChatCollectionId,
      [
        Query.equal('chatId', groupChatId),
        Query.orderAsc('$createdAt'),
      ]
    );
    return response.documents;
  } catch (error) {
    console.error('Failed to retrieve group chat messages:', error);
    throw error;
  }
};

// Function to listen for new group chat messages
export const subscribeToGroupChatMessages = (callback) => {
  const unsubscribe = Messaging.subscribe(
    [`databases.${conf.appwriteDatabaseId}.collections.${conf.appwriteGroupChatCollectionId}.documents`],
    (response) => {
      if (response.events.includes('databases.*.collections.*.documents.*.create')) {
        const newMessage = response.payload;
        if (newMessage.chatId === groupChatId) {
          callback(newMessage);
        }
      }
    }
  );
  return unsubscribe;
};
