// // // // Custom TopNav component
// // // // Created by Shamiel Speelman
// // // // Version: 0.1
// import React, { useState, useRef, useEffect } from 'react';
// import { Drawer, Box, IconButton, Typography, Menu, TextField, List, ListItem, ListItemText, Avatar } from '@mui/material';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import ChatIcon from '@mui/icons-material/Chat';
// import SendIcon from '@mui/icons-material/Send';
// import EmailIcon from '@mui/icons-material/Email';
// import CloseIcon from '@mui/icons-material/Close';
// import UserProfileMenu from './UserProfileMenu';
// import { sendGroupChatMessage } from '../utils/telegramEvents';
// import ClubDetailCard from '../components/ClubDetailCard';
// import EmailDrawer from './EmailDrawer';
// import { Client, Databases, Query } from 'appwrite';
// import conf from '../conf/conf';
// import authService from '../appwrite/auth';

// const client = new Client()
//     .setEndpoint(conf.appwriteUrl)
//     .setProject(conf.appwriteProjectId);

// const databases = new Databases(client);

// const TopNav = ({ handleLogout, user, clubs }) => {
//     const [showProfileMenu, setShowProfileMenu] = useState(false);
//     const [chatAnchorEl, setChatAnchorEl] = useState(null);
//     const [chatMessage, setChatMessage] = useState('');
//     const [chatMessages, setChatMessages] = useState([]);
//     const [showEmailDrawer, setShowEmailDrawer] = useState(false);
//     const [emblemUrl, setEmblemUrl] = useState('');
//     const [teamName, setTeamName] = useState('');
//     const messageListRef = useRef(null);
//     const profileMenuRef = useRef(null);
//     const clubDetailCardRef = useRef(null);
//     const passwordDialogRef = useRef(null);
//     const [drawerOpen, setDrawerOpen] = useState(false);

//     useEffect(() => {
//         const fetchUserData = async () => {
//             try {
//                 const currentUser = await authService.getCurrentUser();
//                 if (!currentUser) {
//                     throw new Error('No user is currently logged in.');
//                 }

//                 const userResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteUsersId, [
//                     Query.equal('userid', currentUser.$id)
//                 ]);

//                 if (userResponse.total > 0) {
//                     const userDoc = userResponse.documents[0];
//                     const defaultClubId = userDoc.defaultClub;

//                     if (defaultClubId) {
//                         try {
//                             const clubDoc = await databases.getDocument(conf.appwriteDatabaseId, conf.createClubCollectionId, defaultClubId);
//                             setEmblemUrl(clubDoc.emblem);
//                             setTeamName(clubDoc.teamname);
//                         } catch (error) {
//                             console.error('Error fetching default club:', error);
//                         }
//                     }
//                 } else {
//                     console.error('User document not found.');
//                 }
//             } catch (error) {
//                 console.error('Error fetching user data or default club:', error);
//             }
//         };

//         fetchUserData();
//     }, []);

//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (
//                 profileMenuRef.current && !profileMenuRef.current.contains(event.target) &&
//                 clubDetailCardRef.current && !clubDetailCardRef.current.contains(event.target) &&
//                 passwordDialogRef.current && !passwordDialogRef.current.contains(event.target)
//             ) {
//                 setShowProfileMenu(false);
//                 setDrawerOpen(false);
//             }
//         };

//         document.addEventListener('mousedown', handleClickOutside);
//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, []);

//     const toggleProfileMenu = () => {
//         setShowProfileMenu(prev => !prev);
//     };

//     const handleCloseMenu = () => {
//         setShowProfileMenu(false);
//     };

//     const handleChatClick = (event) => {
//         setChatAnchorEl(event.currentTarget);
//     };

//     const handleChatClose = () => {
//         setChatAnchorEl(null);
//     };

//     const handleSendMessage = async () => {
//         if (chatMessage.trim() === '') return;

//         try {
//             const response = await sendGroupChatMessage(chatMessage);
//             setChatMessages([...chatMessages, { from: 'You', text: chatMessage }]);
//             setChatMessage('');
//             if (messageListRef.current) {
//                 messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
//             }
//         } catch (error) {
//             console.error('Error sending message:', error);
//         }
//     };

//     const handleEmailClick = () => {
//         setShowEmailDrawer(true);
//     };

//     const handleEmailDrawerClose = () => {
//         setShowEmailDrawer(false);
//     };

//     const handleDrawerOpen = () => {
//         setDrawerOpen(true);
//     };

//     const handleDrawerClose = () => {
//         setDrawerOpen(false);
//     };

//     const userData = JSON.parse(localStorage.getItem('userData')) || {};
//     const userName = userData.name || '';
//     const userEmail = userData.email || '';

//     return (
//         <Box sx={{
//             width: '100%',
//             height: '52px',
//             backgroundColor: '#FFFFFF',
//             display: 'flex',
//             justifyContent: 'space-between',
//             alignItems: 'center',
//             px: 2,
//         }}>
//             <Box display="flex" alignItems="center">
//                 {emblemUrl && (
//                     <Avatar src={emblemUrl} sx={{ width: 52, height: 52, marginRight: 2 }} />
//                 )}
//                 <Typography sx={{ color: '#356596', fontSize: '1rem', fontWeight: 'bold' }}>
//                     FitSport RPE | {teamName}
//                 </Typography>
//                 <Typography sx={{ color: '#356596', fontSize: '0.8rem', marginLeft: 30 }}>
//                     Welcome {userName}!
//                 </Typography>
//             </Box>
//             <Box>
//                 <IconButton sx={{ color: '#356596', marginRight: 1 }} onClick={handleChatClick}>
//                     <ChatIcon />
//                 </IconButton>
//                 <IconButton sx={{ color: '#356596', marginRight: 1 }} onClick={handleEmailClick}>
//                     <EmailIcon />
//                 </IconButton>
//                 <IconButton sx={{ color: '#356596', marginRight: 1 }}>
//                     <NotificationsIcon />
//                 </IconButton>
//                 <IconButton
//                     sx={{ color: '#356596', marginRight: 2 }}
//                     onClick={toggleProfileMenu}
//                 >
//                     <AccountCircleIcon />
//                 </IconButton>
//                 {showProfileMenu && <UserProfileMenu ref={profileMenuRef} userEmail={userEmail} user={user} clubs={clubs} handleLogout={handleLogout} handleCloseMenu={handleCloseMenu} handleDrawerOpen={handleDrawerOpen} />}
//             </Box>
//             <Menu
//                 anchorEl={chatAnchorEl}
//                 open={Boolean(chatAnchorEl)}
//                 onClose={handleChatClose}
//                 PaperProps={{
//                     style: {
//                         maxHeight: '300px',
//                         width: '300px',
//                         overflow: 'auto',
//                     },
//                 }}
//             >
//                 <Typography variant="h6" sx={{ padding: '8px' }}>Team Group Chat</Typography>
//                 <Box ref={messageListRef} sx={{ maxHeight: '200px', overflowY: 'auto', padding: '8px' }}>
//                     <List>
//                         {chatMessages.map((msg, index) => (
//                             <ListItem key={index}>
//                                 <ListItemText primary={`${msg.from}: ${msg.text}`} />
//                             </ListItem>
//                         ))}
//                     </List>
//                 </Box>
//                 <Box sx={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
//                     <TextField
//                         fullWidth
//                         variant="outlined"
//                         value={chatMessage}
//                         onChange={(e) => setChatMessage(e.target.value)}
//                         placeholder="Type a message"
//                     />
//                     <IconButton color="primary" onClick={handleSendMessage}>
//                         <SendIcon />
//                     </IconButton>
//                 </Box>
//             </Menu>
//             <EmailDrawer open={showEmailDrawer} onClose={handleEmailDrawerClose} />
//             <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
//                 <Box ref={clubDetailCardRef} sx={{ width: 450, padding: 2 }}>
//                     {user && <ClubDetailCard user={user} clubs={clubs} passwordDialogRef={passwordDialogRef} />}
//                     <IconButton onClick={handleDrawerClose} sx={{ position: 'absolute', top: 10, right: 10 }}>
//                        <CloseIcon />
//                     </IconButton>
//                 </Box>
//             </Drawer>
//         </Box>
//     );
// };

// export default TopNav;
// // // Custom TopNav component
// // // Created by Shamiel Speelman
// // // Version: 0.1
import React, { useState, useRef, useEffect } from 'react';
import { Drawer, Box, IconButton, Typography, Menu, TextField, List, ListItem, ListItemText, Avatar } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChatIcon from '@mui/icons-material/Chat';
import SendIcon from '@mui/icons-material/Send';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import UserProfileMenu from './UserProfileMenu';
import { sendGroupChatMessage } from '../utils/telegramEvents';
import ClubDetailCard from '../components/ClubDetailCard';
import EmailDrawer from './EmailDrawer';
import { Client, Databases, Query } from 'appwrite';
import conf from '../conf/conf';
import authService from '../appwrite/auth';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const TopNav = ({ handleLogout, user, clubs }) => {
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [chatAnchorEl, setChatAnchorEl] = useState(null);
    const [chatMessage, setChatMessage] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const [showEmailDrawer, setShowEmailDrawer] = useState(false);
    const [emblemUrl, setEmblemUrl] = useState('');
    const [teamName, setTeamName] = useState('');
    const [userImage, setUserImage] = useState('');
    const messageListRef = useRef(null);
    const profileMenuRef = useRef(null);
    const clubDetailCardRef = useRef(null);
    const passwordDialogRef = useRef(null);
    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const currentUser = await authService.getCurrentUser();
                if (!currentUser) {
                    throw new Error('No user is currently logged in.');
                }

                const userResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteUsersId, [
                    Query.equal('userid', currentUser.$id)
                ]);

                if (userResponse.total > 0) {
                    const userDoc = userResponse.documents[0];
                    const defaultClubId = userDoc.defaultClub;

                    if (userDoc.clubImageId) {
                        const imageUrl = `${client.config.endpoint}/storage/buckets/${conf.appwriteBucketId}/files/${userDoc.clubImageId}/view?project=${client.config.project}`;
                        setUserImage(imageUrl);
                    }

                    if (defaultClubId) {
                        try {
                            const clubDoc = await databases.getDocument(conf.appwriteDatabaseId, conf.createClubCollectionId, defaultClubId);
                            setEmblemUrl(clubDoc.emblem);
                            setTeamName(clubDoc.teamname);
                        } catch (error) {
                            console.error('Error fetching default club:', error);
                        }
                    }
                } else {
                    console.error('User document not found.');
                }
            } catch (error) {
                console.error('Error fetching user data or default club:', error);
            }
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                profileMenuRef.current && !profileMenuRef.current.contains(event.target) &&
                clubDetailCardRef.current && !clubDetailCardRef.current.contains(event.target) &&
                passwordDialogRef.current && !passwordDialogRef.current.contains(event.target)
            ) {
                setShowProfileMenu(false);
                setDrawerOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleProfileMenu = () => {
        setShowProfileMenu(prev => !prev);
    };

    const handleCloseMenu = () => {
        setShowProfileMenu(false);
    };

    const handleChatClick = (event) => {
        setChatAnchorEl(event.currentTarget);
    };

    const handleChatClose = () => {
        setChatAnchorEl(null);
    };

    const handleSendMessage = async () => {
        if (chatMessage.trim() === '') return;

        try {
            const response = await sendGroupChatMessage(chatMessage);
            setChatMessages([...chatMessages, { from: 'You', text: chatMessage }]);
            setChatMessage('');
            if (messageListRef.current) {
                messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
            }
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const handleEmailClick = () => {
        setShowEmailDrawer(true);
    };

    const handleEmailDrawerClose = () => {
        setShowEmailDrawer(false);
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    const userName = userData.name || '';
    const userEmail = userData.email || '';

    return (
        <Box sx={{
            width: '100%',
            height: '52px',
            backgroundColor: '#FFFFFF',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 2,
        }}>
            <Box display="flex" alignItems="center">
                {emblemUrl && (
                    <Avatar src={emblemUrl} sx={{ width: 52, height: 52, marginRight: 2 }} />
                )}
                <Typography sx={{ color: '#356596', fontSize: '1rem', fontWeight: 'bold' }}>
                    FitSport RPE | {teamName}
                </Typography>
                <Typography sx={{ color: '#356596', fontSize: '0.8rem', marginLeft: 30 }}>
                    Welcome {userName}!
                </Typography>
            </Box>
            <Box>
                <IconButton sx={{ color: '#356596', marginRight: 1 }} onClick={handleChatClick}>
                    <ChatIcon />
                </IconButton>
                <IconButton sx={{ color: '#356596', marginRight: 1 }} onClick={handleEmailClick}>
                    <EmailIcon />
                </IconButton>
                <IconButton sx={{ color: '#356596', marginRight: 1 }}>
                    <NotificationsIcon />
                </IconButton>
                <IconButton
                    sx={{ color: '#356596', marginRight: 2 }}
                    onClick={toggleProfileMenu}
                >
                    {userImage ? (
                        <Avatar src={userImage} sx={{ width: 32, height: 32 }} />
                    ) : (
                        <AccountCircleIcon />
                    )}
                </IconButton>
                {showProfileMenu && <UserProfileMenu ref={profileMenuRef} userEmail={userEmail} user={user} clubs={clubs} handleLogout={handleLogout} handleCloseMenu={handleCloseMenu} handleDrawerOpen={handleDrawerOpen} />}
            </Box>
            <Menu
                anchorEl={chatAnchorEl}
                open={Boolean(chatAnchorEl)}
                onClose={handleChatClose}
                PaperProps={{
                    style: {
                        maxHeight: '300px',
                        width: '300px',
                        overflow: 'auto',
                    },
                }}
            >
                <Typography variant="h6" sx={{ padding: '8px' }}>Team Group Chat</Typography>
                <Box ref={messageListRef} sx={{ maxHeight: '200px', overflowY: 'auto', padding: '8px' }}>
                    <List>
                        {chatMessages.map((msg, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={`${msg.from}: ${msg.text}`} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={chatMessage}
                        onChange={(e) => setChatMessage(e.target.value)}
                        placeholder="Type a message"
                    />
                    <IconButton color="primary" onClick={handleSendMessage}>
                        <SendIcon />
                    </IconButton>
                </Box>
            </Menu>
            <EmailDrawer open={showEmailDrawer} onClose={handleEmailDrawerClose} />
            <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
                <Box ref={clubDetailCardRef} sx={{ width: 450, padding: 2 }}>
                    {user && <ClubDetailCard user={user} clubs={clubs} passwordDialogRef={passwordDialogRef} />}
                    <IconButton onClick={handleDrawerClose} sx={{ position: 'absolute', top: 10, right: 10 }}>
                       <CloseIcon />
                    </IconButton>
                </Box>
            </Drawer>
        </Box>
    );
};

export default TopNav;



