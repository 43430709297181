import React from 'react';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

function RpeSlider({ value, onRpeChange }) {
    const handleChange = (event, newValue) => {
        onRpeChange(newValue); // Pass the new value to parent
    };

    const valueLabelFormat = (value) => {
        switch (value) {
            case 0: return 'Rest';
            case 1: return 'Very, Very Easy';
            case 2: return 'Easy';
            case 3: return 'Moderate';
            case 4: return 'Somewhat Hard';
            case 5: return 'Hard';
            case 7: return 'Very Hard';
            case 10: return 'Maximal';
            default: return '';
        }
    };

    const marks = [
        { value: 0, label: '0' },
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' }
    ];

    return (
        <div>
            <Typography id="rpe-slider" gutterBottom>
                Rate of Perceived Exertion (RPE)
            </Typography>
            <Slider
                value={value}
                onChange={handleChange}
                aria-labelledby="rpe-slider"
                valueLabelDisplay="auto"
                valueLabelFormat={valueLabelFormat}
                min={0}
                max={10}
                marks={marks}
            />
        </div>
    );
}

export default RpeSlider;
