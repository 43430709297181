// // Custom SubstituteDeatils component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState, useEffect } from 'react';
import { Box, Card, Typography, TextField, Button, MenuItem, Chip } from '@mui/material';
import { Client, Databases, Query, ID } from 'appwrite';
import conf from '../conf/conf';
import authService from '../appwrite/auth';
import { useSnackbar } from 'notistack';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const SubstituteDetails = ({ time, period, details, setDetails, matchID, onClose }) => {
    const [startingEleven, setStartingEleven] = useState([]);
    const [substitutes, setSubstitutes] = useState([]);
    const [searchQueryOut, setSearchQueryOut] = useState('');
    const [filteredPlayersOut, setFilteredPlayersOut] = useState([]);
    const [searchQueryIn, setSearchQueryIn] = useState('');
    const [filteredPlayersIn, setFilteredPlayersIn] = useState([]);

    const reasonOptions = ["Tactical", "Injury"];

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            height: '30px'
        },
        '& .MuiSelect-select': {
            height: '30px',
            display: 'flex',
            alignItems: 'center'
        }
    };

    useEffect(() => {
        const fetchPlayers = async () => {
            try {
                const currentUser = await authService.getCurrentUser();
                if (!currentUser) {
                    throw new Error('No user is currently logged in.');
                }
                const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.createSquadCollectionId, [
                    Query.equal('userID', currentUser.$id),
                ]);
                const startingElevenPlayers = response.documents.flatMap(doc => doc.startingEleven.map(player => JSON.parse(player)));
                const substitutePlayers = response.documents.flatMap(doc => doc.substitutes.map(player => JSON.parse(player)));
                setStartingEleven(startingElevenPlayers);
                setSubstitutes(substitutePlayers);
            } catch (error) {
                console.error('Error fetching players:', error);
            }
        };

        fetchPlayers();
    }, []);

    useEffect(() => {
        if (searchQueryOut.length >= 3) {
            setFilteredPlayersOut(
                startingEleven.filter(player => player.fullName && player.fullName.toLowerCase().includes(searchQueryOut.toLowerCase()))
            );
        } else {
            setFilteredPlayersOut([]);
        }
    }, [searchQueryOut, startingEleven]);

    useEffect(() => {
        if (searchQueryIn.length >= 3) {
            setFilteredPlayersIn(
                substitutes.filter(player => player.fullName && player.fullName.toLowerCase().includes(searchQueryIn.toLowerCase()))
            );
        } else {
            setFilteredPlayersIn([]);
        }
    }, [searchQueryIn, substitutes]);

    const handlePlayerSelectOut = (player) => {
        setDetails(prevDetails => ({ ...prevDetails, selectedPlayerOut: player }));
        setSearchQueryOut('');
        setFilteredPlayersOut([]);
    };

    const handlePlayerSelectIn = (player) => {
        setDetails(prevDetails => ({ ...prevDetails, selectedPlayerIn: player }));
        setSearchQueryIn('');
        setFilteredPlayersIn([]);
    };

    const { enqueueSnackbar } = useSnackbar();
    const handleSave = async () => {
        try {
            const currentUser = await authService.getCurrentUser();
            const substituteData = {
                matchID,
                userID: currentUser.$id,
                userEmail: currentUser.email,
                selectedPlayerOut: details.selectedPlayerOut?.fullName || '',
                selectedPlayerIn: details.selectedPlayerIn?.fullName || '',
                reason: details.reason,
                period,
                minute: time,
                comment: details.comment,
                distanceCovered: details.distanceCovered || 0,
                minutesPlayed: details.minutesPlayed || 0
            };
            await databases.createDocument(conf.appwriteDatabaseId, conf.substitutesCollectionId, ID.unique(), substituteData);
            enqueueSnackbar('Substitute Data saved successfully', { variant: 'success' });
            setDetails({ selectedPlayerOut: null, selectedPlayerIn: null, reason: '', comment: '', distanceCovered: '', minutesPlayed: '' });
            onClose();
        } catch (error) {
            console.error('Error saving substitute data:', error);
        }
    };

    return (
        <Card sx={{ padding: 2, width: '100%', boxSizing: 'border-box' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">Capture Substitute Details</Typography>
                <Typography variant="body1">| Period: <strong>{period}</strong> | Minute: <strong>{time}</strong></Typography>
            </Box>
            <Typography variant="subtitle1">Player Out</Typography>
            <TextField
                fullWidth
                placeholder="Search Player Out"
                margin="normal"
                sx={textFieldStyle}
                InputProps={{ style: { height: '30px' } }}
                value={searchQueryOut}
                onChange={(e) => setSearchQueryOut(e.target.value)}
                autoComplete="off"
            />
            {filteredPlayersOut.length > 0 && (
                <Box sx={{ maxHeight: '150px', overflowY: 'auto', border: '1px solid #ccc', marginTop: '5px' }}>
                    {filteredPlayersOut.map((player, index) => (
                        <Box key={index} sx={{ padding: '5px', cursor: 'pointer' }} onClick={() => handlePlayerSelectOut(player)}>
                            {`${player.fullName} | No ${player.jerseyNumber} | ${player.pposition}`}
                        </Box>
                    ))}
                </Box>
            )}
            
            {details.selectedPlayerOut && (
                <Box sx={{ mt: 2 }}>
                    <Chip label={`${details.selectedPlayerOut.fullName} | No ${details.selectedPlayerOut.jerseyNumber} | ${details.selectedPlayerOut.pposition}`} />
                </Box>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <TextField
                    fullWidth
                    label=""
                    placeholder="Enter Minutes Played"
                    margin="normal"
                    sx={{ ...textFieldStyle, mr: 1 }}
                    InputProps={{ style: { height: '30px' } }}
                    value={details.minutesPlayed || ''}
                    onChange={(e) => setDetails(prevDetails => ({ ...prevDetails, minutesPlayed: parseInt(e.target.value) || 0 }))}
                />
                <TextField
                    fullWidth
                    label=""
                    placeholder="Enter Distance Covered in metres "
                    margin="normal"
                    sx={{ ...textFieldStyle, ml: 1 }}
                    InputProps={{ style: { height: '30px' } }}
                    value={details.distanceCovered || ''}
                    onChange={(e) => setDetails(prevDetails => ({ ...prevDetails, distanceCovered: parseInt(e.target.value) || 0 }))}
                />
            </Box>
            <Typography variant="subtitle1">Player In</Typography>
            <TextField
                fullWidth
                placeholder="Search Player In"
                margin="normal"
                sx={textFieldStyle}
                InputProps={{ style: { height: '30px' } }}
                value={searchQueryIn}
                onChange={(e) => setSearchQueryIn(e.target.value)}
                autoComplete="off"
            />
            {filteredPlayersIn.length > 0 && (
                <Box sx={{ maxHeight: '150px', overflowY: 'auto', border: '1px solid #ccc', marginTop: '5px' }}>
                    {filteredPlayersIn.map((player, index) => (
                        <Box key={index} sx={{ padding: '5px', cursor: 'pointer' }} onClick={() => handlePlayerSelectIn(player)}>
                            {`${player.fullName} | No ${player.jerseyNumber} | ${player.pposition}`}
                        </Box>
                    ))}
                </Box>
            )}
            {details.selectedPlayerIn && (
                <Box sx={{ mt: 2 }}>
                    <Chip label={`${details.selectedPlayerIn.fullName} | No ${details.selectedPlayerIn.jerseyNumber} | ${details.selectedPlayerIn.pposition}`} />
                </Box>
            )}
            
            <Typography variant="subtitle1">Reason</Typography>
            <TextField
                select
                fullWidth
                margin="normal"
                sx={textFieldStyle}
                SelectProps={{ style: { height: '30px' } }}
                value={details.reason}
                onChange={(e) => setDetails(prevDetails => ({ ...prevDetails, reason: e.target.value }))}
            >
                {reasonOptions.map(option => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                fullWidth
                label="Comment"
                multiline
                rows={3}
                margin="normal"
                sx={{ '& .MuiInputBase-root': { height: 'auto' } }}
                value={details.comment}
                onChange={(e) => setDetails(prevDetails => ({ ...prevDetails, comment: e.target.value }))}
            />
            <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
        </Card>
    );
};

export default SubstituteDetails;



