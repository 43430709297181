import React from 'react';
import { Box } from '@mui/material';

const SoccerPitch = ({ players, onPlayerDrop, onPlayerRemove, onPlayerDragStart }) => {
    const handleDrop = (event) => {
        event.preventDefault();
        const playerData = event.dataTransfer.getData('text/plain');
        if (!playerData) return;
        const player = JSON.parse(playerData);
        const rect = event.currentTarget.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        onPlayerDrop({ ...player, x, y });
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    return (
        <Box className="soccer-pitch" onDrop={handleDrop} onDragOver={handleDragOver}>
            {players.map((player, index) => (
                <Box
                    key={index}
                    draggable
                    onDragStart={(event) => onPlayerDragStart(event, player)}
                    onDoubleClick={() => onPlayerRemove(player)}
                    className="player"
                    style={{
                        top: player.y,
                        left: player.x,
                        backgroundColor: player.color,
                        backgroundImage: player.image ? `url(${player.image})` : 'none',
                        backgroundSize: 'cover',
                    }}
                >
                    {player.type === 'player' && player.number}
                </Box>
            ))}
            <Box className="halfway-line" />
            <Box className="center-circle" />
            <Box className="center-spot" />
            <Box className="penalty-box left" />
            <Box className="penalty-box right" />
            <Box className="goal-box left" />
            <Box className="goal-box right" />
            <Box className="penalty-spot left" />
            <Box className="penalty-spot right" />
            <Box className="penalty-arc left" />
            <Box className="penalty-arc right" />
        </Box>
    );
};

export default SoccerPitch;
