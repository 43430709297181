
// // Custom SideMenu component
// // Created by Shamiel Speelman
// // Version: 0.1
// export default SidebarMenu;
import React from 'react';
import { Box, Button } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';
//import MessageIcon from '@mui/icons-material/Message';
//import NotificationsIcon from '@mui/icons-material/Notifications';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AnalyticsIcon from '@mui/icons-material/Analytics';
//import PeopleIcon from '@mui/icons-material/People';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import OnDeviceTrainingIcon from '@mui/icons-material/OnDeviceTraining';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import MailLockIcon from '@mui/icons-material/MailLock';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { Link } from 'react-router-dom';

const SidebarMenu = ({ isLocked, setIsLocked, isHovered, handleLogout }) => {
  const toggleLock = () => {
    setIsLocked(!isLocked);
  };

  const icons = [
    isLocked ? <LockIcon sx={{ color: 'red' }} /> : <LockOpenIcon sx={{ color: 'green' }} />,
    <HomeIcon />,<AccountBoxIcon />, <Diversity2Icon/> ,<SettingsIcon />, <FactCheckIcon />,
    <OnDeviceTrainingIcon />, <DashboardIcon />, <AnalyticsIcon />, <ScoreboardIcon />, <LeaderboardIcon />, <RoomPreferencesIcon />, <QueryStatsIcon />,
    <HelpCenterIcon />,<MailLockIcon/>, <CalendarMonthIcon/>,<ExitToAppIcon />
  ];

  const menuNames = [
    { name: "Lock", path: "#" }, { name: "Dashboard", path: "/dashboard" },
    { name: "Create Player", path: "/dashboard/createplayer" }, { name: "Players List", path: "/dashboard/playerlist" },
    { name: "Create Team", path: "/dashboard/createclub" }, { name: "Team List", path: "/dashboard/clublist" }, 
    { name: "Create Training", path: "/dashboard/createtraining" }, { name: "Training List", path: "/dashboard/traininglist" },
    { name: "Create Squad", path: "/dashboard/createsquad" }, { name: "Match Day", path: "/dashboard/matchday" }, { name: "Fixtures & Results", path: "/dashboard/matchstatistics" },
    { name: "RPE", path: "/dashboard/createrpe" }, { name: "RPE Statistics", path: "/dashboard/rpestatistics" },
    { name: "Help", path: "/dashboard/playground" },
    { name: "Communication", path: "/dashboard/managecommunication" },{ name: "Calendar", path: "/dashboard/calendar" },
     { name: "Logout", path: "#" },
  ];

  const buttonStyle = (iconPosition) => ({
    width: '100%',
    height: '20px',
    my: 1,
    py: 1,
    bgcolor: '#FFFFFF',
    borderRadius: '1px',
    color: '#356596',
    textTransform: 'none',
    fontSize: '0.675rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      bgcolor: '#356596',
      color: '#FFFFFF',
    },
    position: 'relative',
    paddingLeft: iconPosition === 'left' ? '24px' : '0',
  });

  return (
    <Box
      onMouseEnter={() => setIsLocked(isLocked ? true : true)}
      onMouseLeave={() => setIsLocked(isLocked ? true : false)}
      sx={{
        width: '160px',
        height: 'calc(90% - 4px)',
        position: 'absolute',
        left: isLocked || isHovered ? '0' : '-130px',
        top: '70px',
        bgcolor: '#FFFFFF',
        border: `1px solid ${isHovered ? '#f5f8fa' : '#FFFFFF'}`,
        borderRadius: '10px',
        transition: 'left 0.7s',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {menuNames.map((item, index) => (
        <Link to={item.path} key={index} style={{ textDecoration: 'none' }}>
          <Button sx={buttonStyle(isLocked || isHovered ? 'left' : 'right')}
            onClick={item.name === "Lock" ? toggleLock : (item.name === "Logout" ? handleLogout : undefined)}
            startIcon={isLocked || isHovered ? icons[index] : null}
            endIcon={!isLocked && !isHovered ? icons[index] : null}
          >
            {item.name}
          </Button>
        </Link>
      ))}
    </Box>
  );
};

export default SidebarMenu;

