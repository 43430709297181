// import React from 'react';
// import { TextField } from '@mui/material';

// const CustomTextField = ({ label, value, onChange, type = 'text', multiline = false, rows, required = false }) => (
//     <TextField
//         label={label}
//         value={value}
//         onChange={onChange}
//         fullWidth
//         variant="outlined"
//         size="small"
//         required={required}
//         type={type}
//         multiline={multiline}
//         rows={rows}
//         sx={{
//             marginBottom: '1px',
//             '& .MuiInputBase-input': {
//                 fontSize: '0.65rem',
//                 padding: '3px 10px',
//                 fontFamily: 'Roboto, sans-serif'
//             },
//             '& .MuiInputBase-root': {
//                 marginTop: '8px',
//                 marginBottom: '8px',
//             }
//         }}
//     />
// );

// export default CustomTextField;
import React, { forwardRef } from 'react';
import { TextField } from '@mui/material';

const CustomTextField = forwardRef(({ label, value, onChange, type = 'text', multiline = false, rows, required = false, error, helperText }, ref) => (
    <TextField
        label={label}
        value={value}
        onChange={onChange}
        fullWidth
        variant="outlined"
        
        required={required}
        type={type}
        multiline={multiline}
        rows={rows}
        
        
        inputRef={ref}
        sx={{
            marginBottom: '1px',
            '& .MuiInputBase-input': {
                fontSize: '0.65rem',
                padding: '16px 12px',
                fontFamily: 'Roboto, sans-serif'
            },
            '& .MuiInputBase-root': {
                marginTop: '8px',
                marginBottom: '8px',
            }
        }}
    />
));

export default CustomTextField;
