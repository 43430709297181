
// // export default SelectedPlayerRow;
// import React from 'react';
// import Check from '@mui/icons-material/Check';
// import { styled } from '@mui/material/styles';
// import { Select, MenuItem } from '@mui/material';
// import LockIcon from '@mui/icons-material/Lock';
// import IconButton from '@mui/material/IconButton';

// const PlayerRow = styled('div')(({ theme }) => ({
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     backgroundColor: '#f4f4f4',
//     padding: '10px',
//     marginTop: '10px',
//     [theme.breakpoints.down('sm')]: {
//         flexDirection: 'column',
//         alignItems: 'flex-start',
//     },
// }));

// const PlayerName = styled('span')(({ theme }) => ({
//     fontSize: '0.7rem',
//     [theme.breakpoints.down('sm')]: {
//         fontSize: '1rem',
//     },
// }));

// const PlayerDetails = styled('span')(({ theme }) => ({
//     fontSize: '0.7rem',
//     [theme.breakpoints.down('sm')]: {
//         display: 'none',
//     },
// }));

// const TrainingLoad = styled('span')({
//     fontSize: '0.7rem',
// });

// function SelectedPlayerRow({ player, rpe, duration, trainingLoad, onDurationChange, isLocked, onLockData }) {
//     if (!player) {
//         return null; 
//     }
//     const durationOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 65, 70, 75, 80, 85, 90];

//     return (
//         <PlayerRow>
//             <PlayerName>{player.fullName}</PlayerName>
//             <PlayerDetails>{`#${player.jerseyNumber} - ${player.pPosition}`}</PlayerDetails>
//             <span>{`RPE: ${rpe}`}</span>
//             <Select
//                 value={duration}
//                 onChange={(event) => onDurationChange(event.target.value)}
//                 style={{ marginLeft: '10px', marginRight: '10px' }}
//             >
//                 {durationOptions.map((option) => (
//                     <MenuItem key={option} value={option}>{option}</MenuItem>
//                 ))}
//             </Select>
//             <TrainingLoad>{`Training Load: ${trainingLoad}`}</TrainingLoad>
//             <IconButton onClick={onLockData} disabled={isLocked}>
//                 {isLocked ? <LockIcon style={{ color: 'red' }} /> : <Check style={{ color: 'green' }} />}
//             </IconButton>
//         </PlayerRow>
//     );
// }

// export default SelectedPlayerRow;
// import React from 'react';
// import Check from '@mui/icons-material/Check';
// import { styled } from '@mui/material/styles';
// import { Select, MenuItem } from '@mui/material';
// import LockIcon from '@mui/icons-material/Lock';
// import IconButton from '@mui/material/IconButton';

// const PlayerRow = styled('div')(({ theme }) => ({
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     backgroundColor: '#f4f4f4',
//     padding: '10px',
//     marginTop: '10px',
//     [theme.breakpoints.down('sm')]: {
//         flexDirection: 'column',
//         alignItems: 'flex-start',
//     },
// }));

// const PlayerName = styled('span')(({ theme }) => ({
//     fontSize: '0.7rem',
//     [theme.breakpoints.down('sm')]: {
//         fontSize: '1rem',
//     },
// }));

// const PlayerDetails = styled('span')(({ theme }) => ({
//     fontSize: '0.7rem',
//     [theme.breakpoints.down('sm')]: {
//         display: 'none',
//     },
// }));

// const TrainingLoad = styled('span')({
//     fontSize: '0.7rem',
// });

// const Average = styled('span')({
//     fontSize: '0.7rem',
//     marginLeft: '10px',
//     marginRight: '10px',
// });

// function SelectedPlayerRow({ player, rpe, duration, trainingLoad, onDurationChange, isLocked, onLockData, mode, readinessValues }) {
//     if (!player) {
//         return null; 
//     }
    
//     const durationOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 65, 70, 75, 80, 85, 90];
    
//     const unweightedAverage = (
//         Object.values(readinessValues).reduce((acc, val) => acc + val, 0) / Object.keys(readinessValues).length
//     ).toFixed(2);

//     return (
//         <PlayerRow>
//             <PlayerName>{player.fullName}</PlayerName>
//             {mode === 'rpe' ? (
//                 <>
//                     <PlayerDetails>{`#${player.jerseyNumber} - ${player.pPosition}`}</PlayerDetails>
//                     <span>{`RPE: ${rpe}`}</span>
//                     <Select
//                         value={duration}
//                         onChange={(event) => onDurationChange(event.target.value)}
//                         style={{ marginLeft: '10px', marginRight: '10px' }}
//                     >
//                         {durationOptions.map((option) => (
//                             <MenuItem key={option} value={option}>{option}</MenuItem>
//                         ))}
//                     </Select>
//                     <TrainingLoad>{`Training Load: ${trainingLoad}`}</TrainingLoad>
//                 </>
//             ) : (
//                 <>
//                     <span>{`Fatigue: ${readinessValues.Fatigue}`}</span>
//                     <span>{`Sleep Quality: ${readinessValues.SleepQuality}`}</span>
//                     <span>{`Muscle Soreness: ${readinessValues.MuscleSoreness}`}</span>
//                     <span>{`Stress Level: ${readinessValues.StressLevel}`}</span>
//                     <span>{`Mood: ${readinessValues.Mood}`}</span>
//                     <Average>{`Average: ${unweightedAverage}`}</Average>
//                 </>
//             )}
//             <IconButton onClick={onLockData} disabled={isLocked}>
//                 {isLocked ? <LockIcon style={{ color: 'red' }} /> : <Check style={{ color: 'green' }} />}
//             </IconButton>
//         </PlayerRow>
//     );
// }

// export default SelectedPlayerRow;
// import React from 'react';
// import Check from '@mui/icons-material/Check';
// import { styled } from '@mui/material/styles';
// import { Select, MenuItem } from '@mui/material';
// import LockIcon from '@mui/icons-material/Lock';
// import IconButton from '@mui/material/IconButton';

// const PlayerRow = styled('div')(({ theme }) => ({
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     backgroundColor: '#f4f4f4',
//     padding: '10px',
//     marginTop: '10px',
//     [theme.breakpoints.down('sm')]: {
//         flexDirection: 'column',
//         alignItems: 'flex-start',
//     },
// }));

// const PlayerName = styled('span')(({ theme }) => ({
//     fontSize: '0.7rem',
//     [theme.breakpoints.down('sm')]: {
//         fontSize: '1rem',
//     },
// }));

// const PlayerDetails = styled('span')(({ theme }) => ({
//     fontSize: '0.7rem',
//     [theme.breakpoints.down('sm')]: {
//         display: 'none',
//     },
// }));

// const TrainingLoad = styled('span')({
//     fontSize: '0.7rem',
// });

// function SelectedPlayerRow({ player, rpe, duration, trainingLoad, onDurationChange, isLocked, onLockData, mode, readinessValues, unweightedAverage }) {
//     if (!player) {
//         return null; 
//     }
    
//     const durationOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 65, 70, 75, 80, 85, 90];

//     return (
//         <PlayerRow>
//             <PlayerName>{player.fullName}</PlayerName>
//             {mode === 'rpe' ? (
//                 <>
//                     <span>{`RPE: ${rpe}`}</span>
//                     <Select
//                         value={duration}
//                         onChange={(event) => onDurationChange(event.target.value)}
//                         style={{ marginLeft: '10px', marginRight: '10px' }}
//                     >
//                         {durationOptions.map((option) => (
//                             <MenuItem key={option} value={option}>{option}</MenuItem>
//                         ))}
//                     </Select>
//                     <TrainingLoad>{`Training Load: ${trainingLoad}`}</TrainingLoad>
//                 </>
//             ) : (
//                 <>
//                     <span>{`Fatigue: ${readinessValues.Fatigue}`}</span>
//                     <span>{`Sleep Quality: ${readinessValues.SleepQuality}`}</span>
//                     <span>{`Muscle Soreness: ${readinessValues.MuscleSoreness}`}</span>
//                     <span>{`Stress Level: ${readinessValues.StressLevel}`}</span>
//                     <span>{`Mood: ${readinessValues.Mood}`}</span>
//                     <span>{`Unweighted Average: ${unweightedAverage}`}</span>
//                 </>
//             )}
//             <IconButton onClick={onLockData} disabled={isLocked}>
//                 {isLocked ? <LockIcon style={{ color: 'red' }} /> : <Check style={{ color: 'green' }} />}
//             </IconButton>
//         </PlayerRow>
//     );
// }

// export default SelectedPlayerRow;

import React from 'react';
import Check from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';
import { Select, MenuItem, TextField } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import IconButton from '@mui/material/IconButton';

const PlayerRow = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f4f4f4',
    padding: '10px',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
}));

const PlayerName = styled('span')(({ theme }) => ({
    fontSize: '0.7rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
    },
}));

const PlayerDetails = styled('span')(({ theme }) => ({
    fontSize: '0.7rem',
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const TrainingLoad = styled('span')({
    fontSize: '0.7rem',
});

function SelectedPlayerRow({ player, rpe, duration, trainingLoad, onDurationChange, isLocked, onLockData, mode, readinessValues, unweightedAverage, onBpmChange, bpm }) {
    if (!player) {
        return null; 
    }

    const durationOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 65, 70, 75, 80, 85, 90];

    return (
        <PlayerRow>
            <PlayerName>{player.fullName}</PlayerName>
            {mode === 'rpe' ? (
                <>
                    <span>{`RPE: ${rpe}`}</span>
                    <Select
                        value={duration}
                        onChange={(event) => onDurationChange(event.target.value)}
                        style={{ marginLeft: '10px', marginRight: '10px' }}
                    >
                        {durationOptions.map((option) => (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                    <TrainingLoad>{`Training Load: ${trainingLoad}`}</TrainingLoad>
                </>
            ) : (
                <>
                    <span>{`Fatigue: ${readinessValues.Fatigue}`}</span>
                    <span>{`Sleep Quality: ${readinessValues.SleepQuality}`}</span>
                    <span>{`Muscle Soreness: ${readinessValues.MuscleSoreness}`}</span>
                    <span>{`Stress Level: ${readinessValues.StressLevel}`}</span>
                    <span>{`Mood: ${readinessValues.Mood}`}</span>
                    <span>{`Unweighted Average: ${unweightedAverage}`}</span>
                </>
            )}
            <TextField
                label="BPM"
                type="number"
                value={bpm}
                onChange={(event) => onBpmChange(parseInt(event.target.value, 10))}
                style={{ width: '60px', marginLeft: '10px' }}
            />
            <IconButton onClick={onLockData} disabled={isLocked}>
                {isLocked ? <LockIcon style={{ color: 'red' }} /> : <Check style={{ color: 'green' }} />}
            </IconButton>
        </PlayerRow>
    );
}

export default SelectedPlayerRow;

