import React from 'react';
import { Box, Typography } from '@mui/material';

const StepThree = () => (
    <Box sx={{ marginTop: '30px', padding: '20px', textAlign: 'center' }}>
        <Typography variant="h6" gutterBottom>
            We have all we need to create this player profile.
        </Typography>
        <Typography variant="body1">
            Additional information to build out the player profile can be added or amended in the player profile section. Click submit to save the current player.
        </Typography>
    </Box>
);

export default StepThree;
