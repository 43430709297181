import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

// Array of leagues
const leagues = [
    { value: 'psl', label: 'Premier Soccer League (South Africa)' },
    { value: 'nfd', label: 'National First Division (South Africa)' },
    { value: 'mtn8', label: 'MTN 8 (South Africa)' },
    { value: 'nedbank', label: 'Nedbank Cup (South Africa)' },
    { value: 'telkom', label: 'Telkom Knockout (South Africa)' },
    { value: 'epl', label: 'English Premier League (EPL)' },
    { value: 'laliga', label: 'La Liga (Spain)' },
    { value: 'seriea', label: 'Serie A (Italy)' },
    { value: 'bundesliga', label: 'Bundesliga (Germany)' },
    { value: 'ligue1', label: 'Ligue 1 (France)' },
    { value: 'mls', label: 'Major League Soccer (MLS)' },
    { value: 'brasileirao', label: 'Campeonato Brasileiro Serie A (Brazil)' },
    { value: 'eredivisie', label: 'Eredivisie (Netherlands)' },
    { value: 'primera', label: 'Argentine Primera Division' },
    { value: 'ligaMX', label: 'Liga MX (Mexico)' },
    { value: 'jleague', label: 'J1 League (Japan)' },
    { value: 'kleague', label: 'K League (South Korea)' },
    { value: 'aLeague', label: 'A-League (Australia)' },
    { value: 'superliga', label: 'Chinese Super League' },
    { value: 'rpl', label: 'Russian Premier League' },
    { value: 'spl', label: 'Scottish Premiership' },
    { value: 'turkish', label: 'Super Lig (Turkey)' },
    { value: 'portuguese', label: 'Primeira Liga (Portugal)' },
    { value: 'belgian', label: 'Belgian Pro League' },
    { value: 'superleague', label: 'Greek Super League' },
    { value: 'saudi', label: 'Saudi Professional League' },
    { value: 'uae', label: 'UAE Pro League' },
    // ... other leagues
];


function LeagueDropdown({ league, setLeague }) {
    const handleChange = (event) => {
        setLeague(event.target.value);
    };


    return (
        <FormControl fullWidth>
            <InputLabel id="league-select-label">League</InputLabel>
            <Select
                labelId="league-select-label"
                id="league-select"
                value={league}
                label="League"
                onChange={handleChange}
                fullWidth
                variant="outlined"
             
                size="small"
                sx={{
                    marginBottom: '10px',
                    '& .MuiInputBase-input': {
                        fontSize: '0.65rem',
                        padding: '16px 12px',
                        fontFamily: 'Roboto, sans-serif'
                    },
                    '& .MuiInputBase-root': {
                        marginTop: '8px',
                        marginBottom: '8px'
                    }
                }}
            >
                {leagues.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default LeagueDropdown;