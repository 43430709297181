import conf from '../conf/conf';

export const sendTelegramMessage = async (chatId, message) => {
    const url = `https://api.telegram.org/bot${conf.telegramBotToken}/sendMessage`;
    const payload = {
        chat_id: chatId,
        text: message
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });

        const data = await response.json();
        if (!data.ok) {
            throw new Error(`Error sending message: ${data.description}`);
        }

        return data.result;
    } catch (error) {
        console.error('Failed to send Telegram message:', error); 
        throw error;
    }
};

export const deleteTelegramMessage = async (chatId, messageId) => {
    const url = `https://api.telegram.org/bot${conf.telegramBotToken}/deleteMessage`;
    const payload = {
        chat_id: chatId,
        message_id: messageId
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });

        const data = await response.json();
        if (!data.ok) {
            throw new Error(`Error deleting message: ${data.description}`);
        }

        return data.result;
    } catch (error) {
        console.error('Failed to delete Telegram message:', error); 
        throw error;
    }
};

