// // Custom CreateRPE component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Drawer, List, ListItem, ListItemText, Snackbar } from '@mui/material';
import PlayerCard from '../components/PlayerCard';
import RPESlider from '../components/RPESlider';
import SelectedPlayerRow from '../components/SelectedPlayerRow';
import { styled } from '@mui/material/styles';
import { Client, Databases, Query } from 'appwrite';
import conf from '../conf/conf';
import { useSnackbar } from 'notistack';
import authService from '../appwrite/auth';
import RPECapture from './RPECapture'; 
import { sendRPEMessage } from '../utils/telegramEvents';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const CardContainer = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '1rem',
});

const ToggleContainer = styled('div')({
    backgroundColor: '#fff',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    padding: '4px',
    width: '250px',
    height: '34px',
    margin: '-16px',
});

const ToggleOption = styled(Button)(({ theme, active }) => ({
    backgroundColor: active ? '#356596' : 'transparent',
    borderRadius: '20px',
    color: active ? '#fff' : '#356596',
    fontSize: '0.875rem',
    padding: '10px 20px',
}));

const CreateRPE = () => {
    const [players, setPlayers] = useState([]);
    const [selectedPlayerId, setSelectedPlayerId] = useState(null);
    const [rpeValue, setRpeValue] = useState(0);
    const [duration, setDuration] = useState(5);
    const [trainingLoad, setTrainingLoad] = useState(0);
    const [isLocked, setIsLocked] = useState(false);
    const [lockedData, setLockedData] = useState({});
    const [capturedPlayerCount, setCapturedPlayerCount] = useState(0);
    const [plan, setPlan] = useState('match');
    const [mode, setMode] = useState('rpe');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [matches, setMatches] = useState([]);
    const [trainings, setTrainings] = useState([]);
    const [activeSelection, setActiveSelection] = useState(null);
    const [readinessValues, setReadinessValues] = useState({
        Fatigue: 0,
        SleepQuality: 0,
        MuscleSoreness: 0,
        StressLevel: 0,
        Mood: 0,
    });
    const [unweightedAverage, setUnweightedAverage] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [bpm, setBpm] = useState(0);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchPlayers = async () => {
            try {
                const currentUser = await authService.getCurrentUser();
                const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteCollectionId, [
                    Query.equal('userID', currentUser.$id)
                ]);
                const playersData = response.documents.map(doc => ({
                    id: doc.$id,
                    fullName: doc.fullName,
                    jerseyNumber: doc.jerseyNumber,
                    pPosition: doc.pposition,
                    image: doc.image ? `https://cloud.appwrite.io/v1/storage/buckets/${conf.appwriteBucketId}/files/${doc.image}/view?project=${conf.appwriteProjectId}` : null,
                }));
                setPlayers(playersData);
            } catch (error) {
                console.error('Error fetching players:', error);
            }
        };

        fetchPlayers();
    }, []);

    useEffect(() => {
        const fetchMatches = async () => {
            try {
                const currentUser = await authService.getCurrentUser();
                const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.createSquadCollectionId, [
                    Query.equal('userID', currentUser.$id),
                    Query.orderDesc('$createdAt'),
                    Query.limit(4)
                ]);
                setMatches(response.documents);
            } catch (error) {
                console.error('Error fetching matches:', error);
            }
        };

        const fetchTrainings = async () => {
            try {
                const currentUser = await authService.getCurrentUser();
                const response = await databases.listDocuments(conf.appwriteDatabaseId, conf.createTrainingCollectionId, [
                    Query.equal('userID', currentUser.$id),
                    Query.orderDesc('$createdAt'),
                    Query.limit(4)
                ]);
                setTrainings(response.documents);
            } catch (error) {
                console.error('Error fetching trainings:', error);
            }
        };

        fetchMatches();
        fetchTrainings();
    }, []);

    useEffect(() => {
        const average = (
            (readinessValues.Fatigue +
            readinessValues.SleepQuality +
            readinessValues.MuscleSoreness +
            readinessValues.StressLevel +
            readinessValues.Mood) / 5
        ).toFixed(2);
        setUnweightedAverage(average);
    }, [readinessValues]);

    const handleLockData = async () => {
        setIsLocked(!isLocked);
        if (!isLocked) {
            const sessionData = {
                PlayerId: selectedPlayerId,
                RPE: rpeValue,
                Duration: duration,
                TrainingLoad: trainingLoad,
                hrBPM: bpm
            };
            setCapturedPlayerCount(capturedPlayerCount + 1);
            setLockedData(prev => ({
                ...prev,
                [selectedPlayerId]: {
                    ...sessionData,
                    isSaved: true
                }
            }));
            try {
                const currentUser = await authService.getCurrentUser();
                const userID = currentUser.$id;
                const userEmail = currentUser.email;
                const commonData = {
                    userID,
                    userEmail,
                    rpeValue,
                    duration,
                    trainingLoad,
                    hrBPM: bpm,
                    playerID: selectedPlayerId,
                    playerName: selectedPlayer.fullName,
                    jerseyNumber: parseInt(selectedPlayer.jerseyNumber, 10),
                    pposition: selectedPlayer.pPosition,
                };
                const dataToSave = activeSelection.type === 'match' ? {
                    ...commonData,
                    matchID: activeSelection.data.$id,
                    matchName: activeSelection.data.matchName,
                    matchDate: activeSelection.data.matchDate,
                    opposition: activeSelection.data.opposition,
                } : {
                    ...commonData,
                    trainingID: activeSelection.data.$id,
                    sessionTitle: activeSelection.data.sessionTitle,
                    sessionDate: activeSelection.data.sessionDate,
                    location: activeSelection.data.location,
                };
                await databases.createDocument(conf.appwriteDatabaseId, conf.rpeCaptureCollectionId, 'unique()', dataToSave);
                enqueueSnackbar('Player data saved successfully', { variant: 'success' });
            } catch (error) {
                console.error('Error saving RPE data:', error);
            }
        } else {
            setIsLocked(false);
        }
    };

    const handleRpeChange = (newValue) => {
        setRpeValue(newValue);
    };

    const handleDurationChange = (newDuration) => {
        setDuration(newDuration);
    };

    const handleBpmChange = (newBpm) => {
        setBpm(parseInt(newBpm, 10));
    };

    useEffect(() => {
        if (selectedPlayerId in lockedData) {
            const data = lockedData[selectedPlayerId];
            setRpeValue(data.RPE);
            setDuration(data.Duration);
            setTrainingLoad(data.TrainingLoad);
            setBpm(data.hrBPM || 0);
            setIsLocked(true);
        } else {
            setRpeValue(0);
            setDuration(0);
            setTrainingLoad(0);
            setBpm(0);
            setIsLocked(false);
        }
    }, [selectedPlayerId, lockedData]);

    const calculateTrainingLoad = (rpe, duration) => {
        return rpe * duration;
    };

    useEffect(() => {
        setTrainingLoad(calculateTrainingLoad(rpeValue, duration));
    }, [rpeValue, duration]);

    const handleSelectPlayer = (playerId) => {
        if (!activeSelection && mode !== 'readiness') {
            setSnackbarOpen(true);
            return;
        }
        setSelectedPlayerId(prevId => prevId === playerId ? null : playerId);
    };

    const selectedPlayer = players.find(player => player.id === selectedPlayerId);

    const handlePlanChange = (newPlan) => {
        setPlan(newPlan);
        setDrawerOpen(true);
    };

    const handleModeChange = (newMode) => {
        setMode(newMode);
        if (newMode === 'readiness') {
            setDrawerOpen(true);
        }
    };

    const handleSelectMatch = (match) => {
        setActiveSelection({ type: 'match', data: match });
        setDrawerOpen(false);
    };

    const handleSelectTraining = (training) => {
        setActiveSelection({ type: 'training', data: training });
        setDrawerOpen(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleReset = () => {
        setSelectedPlayerId(null);
        setRpeValue(0);
        setDuration(5);
        setTrainingLoad(0);
        setBpm(0);
        setIsLocked(false);
        setLockedData({});
        setCapturedPlayerCount(0);
        setActiveSelection(null);
        setSnackbarOpen(false);
        setReadinessValues({
            Fatigue: 0,
            SleepQuality: 0,
            MuscleSoreness: 0,
            StressLevel: 0,
            Mood: 0,
        });
        setUnweightedAverage(0);
    };

    const handleLockReadinessData = async () => {
        setIsLocked(!isLocked);
        if (!isLocked) {
            const sessionData = {
                PlayerId: selectedPlayerId,
                Fatigue: readinessValues.Fatigue,
                SleepQuality: readinessValues.SleepQuality,
                MuscleSoreness: readinessValues.MuscleSoreness,
                StressLevel: readinessValues.StressLevel,
                Mood: readinessValues.Mood,
                UnweightedAverage: unweightedAverage,
                hrBPM: bpm
            };
            setCapturedPlayerCount(capturedPlayerCount + 1);
            setLockedData(prev => ({
                ...prev,
                [selectedPlayerId]: {
                    ...sessionData,
                    isSaved: true
                }
            }));
            try {
                const currentUser = await authService.getCurrentUser();
                const userID = currentUser.$id;
                const userEmail = currentUser.email;
                const commonData = {
                    userID,
                    userEmail,
                    playerID: selectedPlayerId,
                    playerName: selectedPlayer.fullName,
                    jerseyNumber: parseInt(selectedPlayer.jerseyNumber, 10),
                    pposition: selectedPlayer.pPosition,
                    fatigue: readinessValues.Fatigue,
                    sleepQuality: readinessValues.SleepQuality,
                    muscleSoreness: readinessValues.MuscleSoreness,
                    stressLevel: readinessValues.StressLevel,
                    mood: readinessValues.Mood,
                    unweightedAverage: unweightedAverage,
                    hrBPM: bpm
                };
                const dataToSave = activeSelection.type === 'match' ? {
                    ...commonData,
                    matchID: activeSelection.data.$id,
                    matchName: activeSelection.data.matchName,
                    matchDate: activeSelection.data.matchDate,
                    opposition: activeSelection.data.opposition,
                } : {
                    ...commonData,
                    trainingID: activeSelection.data.$id,
                    sessionTitle: activeSelection.data.sessionTitle,
                    sessionDate: activeSelection.data.sessionDate,
                    location: activeSelection.data.location,
                };
                await databases.createDocument(conf.appwriteDatabaseId, conf.rpeReadinessCollectionId, 'unique()', dataToSave);
                enqueueSnackbar('Readiness data saved successfully', { variant: 'success' });
            } catch (error) {
                console.error('Error saving readiness data:', error);
            }
        } else {
            setIsLocked(false);
        }
    };

    return (
        <Box
            onClick={() => {
                if (!activeSelection && mode !== 'readiness') {
                    setSnackbarOpen(true);
                }
            }}
            sx={{ padding: '40px', margin: 2 }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h4">RPE</Typography>
                <Box display="flex" alignItems="center">
                    <ToggleContainer>
                        <ToggleOption onClick={() => handleModeChange('readiness')} active={mode === 'readiness'}>
                            Readiness
                        </ToggleOption>
                        <ToggleOption onClick={() => handlePlanChange('match')} active={plan === 'match'}>
                            Match
                        </ToggleOption>
                        <ToggleOption onClick={() => handlePlanChange('training')} active={plan === 'training'}>
                            Training
                        </ToggleOption>
                    </ToggleContainer>
                    <Button onClick={handleReset} sx={{ marginLeft: '10px' }}>Reset</Button>
                </Box>
            </Box>
            <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                <List>
                    {plan === 'match' && matches.map(match => (
                        <ListItem button key={match.$id} onClick={() => handleSelectMatch(match)}>
                            <ListItemText primary={match.matchName} secondary={`${new Date(match.matchDate).toLocaleDateString()} - ${match.opposition}`} />
                        </ListItem>
                    ))}
                    {plan === 'training' && trainings.map(training => (
                        <ListItem button key={training.$id} onClick={() => handleSelectTraining(training)}>
                            <ListItemText primary={training.sessionTitle} secondary={`${new Date(training.sessionDate).toLocaleDateString()} - ${training.location}`} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message="Select a Match or Training session to continue."
            />
            <Box
                onClick={() => {
                    if (!activeSelection && mode !== 'readiness') {
                        setSnackbarOpen(true);
                    }
                }}
                sx={{
                    pointerEvents: activeSelection || mode === 'readiness' ? 'auto' : 'none',
                    ...(activeSelection || mode === 'readiness' ? {} : { cursor: 'not-allowed' }),
                }}
            >
                {mode === 'rpe' ? (
                    <RPESlider value={rpeValue} onRpeChange={handleRpeChange} />
                ) : (
                    <RPECapture readinessValues={readinessValues} setReadinessValues={setReadinessValues} />
                )}
                <CardContainer>
                    {players.map(player => (
                        <PlayerCard
                            key={player.id}
                            player={player}
                            pPosition={player.pPosition}
                            isSelected={selectedPlayerId === player.id}
                            isLocked={lockedData[player.id]?.isSaved}
                            onSelect={() => handleSelectPlayer(player.id)}
                            clickable={true}
                        />
                    ))}
                </CardContainer>
                {selectedPlayer && (
                    <SelectedPlayerRow
                        player={selectedPlayer}
                        rpe={rpeValue}
                        duration={duration}
                        trainingLoad={trainingLoad}
                        onDurationChange={handleDurationChange}
                        isLocked={isLocked}
                        onLockData={mode === 'rpe' ? handleLockData : handleLockReadinessData}
                        mode={mode}
                        readinessValues={readinessValues}
                        unweightedAverage={unweightedAverage}
                        bpm={bpm}
                        onBpmChange={handleBpmChange}
                    />
                )}
            </Box>
            <Button variant="contained" color="primary" size="small" sx={{
                '& .MuiInputBase-input': {
                    fontSize: '0.75rem',
                    padding: '6px 12px'
                },
                '& .MuiInputBase-root': {
                    marginTop: '8px',
                    marginBottom: '8px'
                },
                backgroundColor: '#356596',
                color: '#ffffff',
                '&:hover': {
                    backgroundColor: '#ffffff',
                    color: '#356596'
                }
            }} onClick={sendRPEMessage}>
                Send RPE Survey
            </Button>
        </Box>
    );
};

export default CreateRPE;

