// // Custom MatchEventDetails component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import MatchCard from './MatchCard';
import ResultsCard from './ResultsCard';
import { Client, Databases, Query } from 'appwrite';
import conf from '../conf/conf';
import authService from '../appwrite/auth';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const SwitchContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#356596',
    borderRadius: '25px',
    padding: '5px',
    marginBottom: '20px',
});

const SwitchButton = styled(Button)(({ active }) => ({
    backgroundColor: active ? '#ffffff' : '#356596',
    color: active ? '#356596' : '#ffffff',
    borderRadius: '20px',
    margin: '0 10px',
    '&:hover': {
        backgroundColor: '#ffffff',
        color: '#356596',
    },
}));

const ChipStyled = styled(Chip)(({ active }) => ({
    backgroundColor: active ? '#ffffff' : '#356596',
    color: active ? '#356596' : '#ffffff',
    '&:hover': {
        backgroundColor: '#ffffff',
        color: '#356596',
    },
}));

const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

const MatchStatistics = () => {
    const [activeTab, setActiveTab] = useState('Fixtures');
    const [matches, setMatches] = useState({ upcoming: [], completed: [] });
    const [finalResults, setFinalResults] = useState({});
    const [clubs, setClubs] = useState({});
    const [activeMonth, setActiveMonth] = useState(null);

    useEffect(() => {
        const fetchMatchData = async () => {
            try {
                const currentUser = await authService.getCurrentUser();
                const matchResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.createSquadCollectionId, [
                    Query.equal('userID', currentUser.$id),
                ]);

                const finalResultsResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.finalResultsCollectionId);
                const results = finalResultsResponse.documents.reduce((acc, result) => {
                    acc[result.matchID] = result;
                    return acc;
                }, {});
                const clubIds = matchResponse.documents.map(match => match.defaultClub);
                const clubPromises = clubIds.map(clubId => databases.getDocument(conf.appwriteDatabaseId, conf.createClubCollectionId, clubId));
                const clubResponses = await Promise.all(clubPromises);
                const clubData = clubResponses.reduce((acc, club) => {
                    acc[club.$id] = club;
                    return acc;
                }, {});
                const upcomingMatches = matchResponse.documents.filter(match => !results[match.$id]);
                const completedMatches = matchResponse.documents.filter(match => results[match.$id]);
                const sortedUpcomingMatches = upcomingMatches.sort((a, b) => new Date(b.matchDate) - new Date(a.matchDate));
                const sortedCompletedMatches = completedMatches.sort((a, b) => new Date(b.matchDate) - new Date(a.matchDate));

                setMatches({ upcoming: sortedUpcomingMatches, completed: sortedCompletedMatches });
                setFinalResults(results);
                setClubs(clubData);
            } catch (error) {
                console.error('Error fetching match data:', error);
            }
        };

        fetchMatchData();
    }, []);

    const handleMonthClick = (month) => {
        setActiveMonth(month);
    };

    const filteredMatches = activeMonth
        ? matches.upcoming.filter(match => new Date(match.matchDate).toLocaleString('default', { month: 'long' }) === activeMonth)
        : matches.upcoming;

    const filteredResults = activeMonth
        ? matches.completed.filter(match => new Date(match.matchDate).toLocaleString('default', { month: 'long' }) === activeMonth)
        : matches.completed;

    const currentDate = new Date();

    return (
        <Box sx={{ padding: '20px' }}>
            <SwitchContainer>
                <SwitchButton active={activeTab === 'Fixtures'} onClick={() => setActiveTab('Fixtures')}>Fixtures</SwitchButton>
                <SwitchButton active={activeTab === 'Results'} onClick={() => setActiveTab('Results')}>Results</SwitchButton>
                <SwitchButton active={activeTab === 'Tables'} onClick={() => setActiveTab('Tables')}>Tables</SwitchButton>
            </SwitchContainer>

            {activeTab === 'Fixtures' && (
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px', mb: 2 }}>
                        {months.map(month => (
                            <ChipStyled
                                key={month}
                                label={month}
                                active={activeMonth === month}
                                onClick={() => handleMonthClick(month)}
                            />
                        ))}
                    </Box>
                    {filteredMatches.length > 0 ? (
                        filteredMatches.map((match) => {
                            const matchDate = new Date(match.matchDate);
                            const club = clubs[match.defaultClub];
                            const team1Name = club ? club.teamname : 'Loading...';
                            const team1Logo = club ? club.emblem : 'path/to/placeholder-image.png';
                            const isPastDate = matchDate < currentDate;

                            return (
                                <Box key={match.$id} sx={{ marginBottom: '50px' }}>
                                    <MatchCard
                                        team1={team1Name}
                                        team2={match.opposition}
                                        date={matchDate.toLocaleDateString()}
                                        time={matchDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                        matchType={match.matchName}
                                        team1Logo={team1Logo}
                                        team2Logo="OT"
                                        disabled={isPastDate}
                                        
                                    />
                                </Box>
                            );
                        })
                    ) : (
                        activeMonth && (
                            <Box sx={{ textAlign: 'center', color: 'grey', marginTop: '20px' }}>
                                <Typography variant="h6">Oops, looks like there is nothing to display for this month</Typography>
                                {clubs && Object.keys(clubs).length > 0 && (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                        <img src={clubs[Object.keys(clubs)[0]].emblem} alt="Club Emblem" style={{ width: '60px', height: '60px' }} />
                                    </Box>
                                )}
                            </Box>
                        )
                    )}
                </Box>
            )}

            {activeTab === 'Results' && (
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px', mb: 2 }}>
                        {months.map(month => (
                            <ChipStyled
                                key={month}
                                label={month}
                                active={activeMonth === month}
                                onClick={() => handleMonthClick(month)}
                            />
                        ))}
                    </Box>
                    {filteredResults.length > 0 ? (
                        filteredResults.map((match) => {
                            const club = clubs[match.defaultClub];
                            const team1Name = club ? club.teamname : 'Loading...';
                            const team1Logo = club ? club.emblem : 'path/to/placeholder-image.png';
                            const result = finalResults[match.$id];

                            return (
                                <Box key={match.$id} sx={{ marginBottom: '20px' }}>
                                    <ResultsCard
                                        team1={team1Name}
                                        team2={match.opposition}
                                        date={new Date(match.matchDate).toLocaleDateString()}
                                        matchType={match.matchName}
                                        team1Logo={team1Logo}
                                        team2Logo="OT"
                                        scoreHome={result ? result.finalScoreHome : '00'}
                                        scoreAway={result ? result.finalScoreOpposition : '00'}
                                    />
                                </Box>
                            );
                        })
                    ) : (
                        activeMonth && (
                            <Box sx={{ textAlign: 'center', color: 'grey', marginTop: '20px' }}>
                                <Typography variant="h6">Oops, looks like there is nothing to display for this month</Typography>
                                {clubs && Object.keys(clubs).length > 0 && (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                        <img src={clubs[Object.keys(clubs)[0]].emblem} alt="Club Emblem" style={{ width: '60px', height: '60px' }} />
                                    </Box>
                                )}
                            </Box>
                        )
                    )}
                </Box>
            )}

            {activeTab === 'Tables' && (
                <Box>
                    <Typography variant="h5">Coming soon</Typography>
                    {/* I still need to define this content */}
                </Box>
            )}
        </Box>
    );
};

export default MatchStatistics;
