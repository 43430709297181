// // Custom SubscribeStart component
// // Created by Shamiel Speelman
// // Version: 0.1

import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Box, List, ListItem, ListItemIcon, ListItemText, Modal } from '@mui/material';
import { AccessTime, ArrowForward } from '@mui/icons-material';
import PlanOne from './PlanOne';
import PlanTwo from './PlanTwo';

const SubscribeStart = ({ handleClose }) => {
  const [openPlans, setOpenPlans] = useState(false);

  const handleOpenPlans = () => {
    setOpenPlans(true);
  };

  const handleClosePlans = () => setOpenPlans(false);

  return (
    <>
      <Card sx={{
        width: '600px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        transition: 'box-shadow 0.7s ease-in-out',
        '&:hover': {
          boxShadow: '0 10px 16px rgba(0, 0, 0, 0.3)'
        },
        overflow: 'hidden',
        mt: 6
      }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
            <AccessTime sx={{ mr: 1 }} />
            <Typography variant="h6" component="div">
              Subscription
            </Typography>
          </Box>
          <Typography variant="h7" sx={{ textAlign: 'center', mt: 4, mb: 4 }}>
            Looks like you are ready to join the FitSport | RPE family. Since you are opted for a paid subscription, the first month's on us.
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <ArrowForward />
              </ListItemIcon>
              <ListItemText primary="Today" secondary="Your existing data is secured, you just continue doing what you do. We unlock subscription features including routes, segment leaderboards, advanced training analysis and more." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ArrowForward />
              </ListItemIcon>
              <ListItemText primary="In 28 days" secondary="We'll send a reminder about when your free subscription clock window will end." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ArrowForward />
              </ListItemIcon>
              <ListItemText primary="In 30 days" secondary="You’ll be charged the subscription amount. Because we believe you have a right to change your mind, you can Cancel anytime before this." />
            </ListItem>
          </List>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 4 }}>
            <Button variant="contained" onClick={handleOpenPlans} sx={{
              backgroundColor: '#356596',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#ffffff',
                color: '#356596',
              }
            }}>
              Start Free 30-Day Subscription Perk
            </Button>
            <Button variant="outlined" onClick={handleClose} sx={{
              borderColor: '#356596',
              color: '#356596',
              '&:hover': {
                backgroundColor: '#356596',
                color: '#ffffff',
              }
            }}>
              Not Ready
            </Button>
          </Box>
        </CardContent>
      </Card>

      <Modal open={openPlans} onClose={handleClosePlans}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <PlanOne onClosePlans={handleClosePlans} />
            <PlanTwo />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SubscribeStart;


