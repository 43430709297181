import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, ListSubheader } from '@mui/material';

export default function PositionSelector({ selectedPosition, setSelectedPosition }) {
    const handlePositionChange = (event) => {
        setSelectedPosition(event.target.value); // Update parent component state
    };

    const positionOptions = [
        "Goalkeeper", "LB - Left-Back", "CB - Centre-Back", "RB - Right-Back", 
        "DM - Defensive Midfielder", "LM - Left Midfielder", "CM - Central Midfielder", 
        "RM - Right Midfielder", "AM - Attacking Midfielder", "LW - Left Winger", 
        "CF - Centre Forward", "RW - Right Winger"
    ];

    return (
        <FormControl
            fullWidth
            size="small"
            sx={{
                marginBottom: '1px',
                '& .MuiInputBase-input': {
                    fontSize: '0.65rem',
                    padding: '16px 12px',
                    fontFamily: 'Roboto, sans-serif'
                },
                '& .MuiInputBase-root': {
                    marginTop: '8px',
                    marginBottom: '8px'
                }
            }}
        >
            <InputLabel id="position-select-label" sx={{ fontSize: '0.65rem', fontFamily: 'Roboto, sans-serif' }}>
                Position
            </InputLabel>
            <Select
                labelId="position-select-label"
                id="position-select"
                value={selectedPosition} // Use value from parent component
                label="Position"
                onChange={handlePositionChange}
                sx={{ fontSize: '0.65rem', fontFamily: 'Roboto, sans-serif' }}
            >
                <MenuItem value=""><em>None</em></MenuItem>
                {positionOptions.map(option => (
                    <MenuItem key={option} value={option} sx={{ fontSize: '0.65rem', fontFamily: 'Roboto, sans-serif' }}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
