// // Custom CreateTraining component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState, useEffect, useRef } from 'react';
import { Drawer, Container, Typography, Box, Button, Card, TextField, Chip } from '@mui/material';
import ExerciseSelectionPanel from '../components/custome_components/ExerciseSelection';
import EquipmentPanel from '../components/custome_components/EquipmentDetails';
import PhysicalConditioningPanel from '../components/custome_components/PhysicalConditioning';
import SpecializedTrainingPanel from '../components/custome_components/SpecialisedTraining';
import SkillsSelectionPanel from '../components/custome_components/SkillsSelection';
import ViewTraining from '../components/custome_components/ViewTraining';
import { format } from 'date-fns';
import { Client, Databases, ID } from 'appwrite'; 
import conf from '../conf/conf';
import authService from '../appwrite/auth';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const CreateTraining = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [showTopButtons, setShowTopButtons] = useState(false);
    const cardRef = useRef(null);

    const [sessionTitle, setSessionTitle] = useState('');
    const [sessionDate, setSessionDate] = useState(new Date());
    const [location, setLocation] = useState('');
    const [coachName, setCoachName] = useState('');
    const [assistingCoach, setAssistingCoach] = useState('');
    const [teamGroup, setTeamGroup] = useState('');
    const [sessionObjectives, setSessionObjectives] = useState('');

    const [selectedExercises, setSelectedExercises] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [selectedConditioning, setSelectedConditioning] = useState([]);
    const [selectedSpecialized, setSelectedSpecialized] = useState([]);
    const [selectedEquip, setSelectedEquip] = useState([]);

    const [activePanel, setActivePanel] = useState('');

    useEffect(() => {
        if (!authService.isUserLoggedIn()) {
        }
    }, []);

    useEffect(() => {
        checkCardHeight();
        window.addEventListener('resize', checkCardHeight);
        window.addEventListener('scroll', checkCardHeight);
        return () => {
            window.removeEventListener('resize', checkCardHeight);
            window.removeEventListener('scroll', checkCardHeight);
        };
    }, [selectedExercises, selectedSkills, selectedConditioning, selectedSpecialized, selectedEquip]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handlePreview = () => {
        setIsPreviewOpen(!isPreviewOpen);
    };

    const gatherPreviewData = () => {
        return {
            sessionTitle,
            sessionDate,
            location,
            coachName,
            assistingCoach,
            teamGroup,
            sessionObjectives,
            selectedExercises,
            selectedSkills,
            selectedConditioning,
            selectedSpecialized,
            selectedEquip
        };
    };

    const handleSubmit = async () => {
        if (!authService.isUserLoggedIn()) {
            alert('Please log in to submit the training session.');
            return;
        }

        try {
            const user = await authService.getCurrentUser();

            const truncateString = (str, maxLength) => {
                return str.length > maxLength ? str.substring(0, maxLength - 3) + '...' : str;
            };

            const stringifyArray = (array) => {
                const jsonString = JSON.stringify(array);
                return truncateString(jsonString, 3000);
            };

            const data = {
                sessionTitle,
                sessionDate,
                location,
                coachName,
                assistingCoach,
                teamGroup,
                sessionObjectives,
                selectedExercises: stringifyArray(selectedExercises),
                selectedSkills: stringifyArray(selectedSkills),
                selectedConditioning: stringifyArray(selectedConditioning),
                selectedSpecialized: stringifyArray(selectedSpecialized),
                selectedEquip: stringifyArray(selectedEquip),
                userID: user.$id,
                userEmail: user.email,
            };

            await databases.createDocument(conf.appwriteDatabaseId, conf.createTrainingCollectionId, ID.unique(), data);
            alert('Training session created successfully!');
        } catch (error) {
            console.error('Error creating training session', error);
            alert('Failed to create training session. Please try again.');
        }
    };

    const openPanel = (panel) => {
        if (activePanel === panel) {
            setActivePanel('');
        } else {
            setActivePanel(panel);
        }
    };

    const checkCardHeight = () => {
        if (cardRef.current) {
            const cardHeight = cardRef.current.offsetHeight;
            const windowHeight = window.innerHeight;
            setShowTopButtons(cardHeight > windowHeight);
        }
    };

    return (
        <Container>
            <Typography variant="h4" sx={{ margin: '20px 0' }}>Create Training Session</Typography>
            {showTopButtons && (
                <Box display="flex" justifyContent="space-between" mb={2}>
                    <Button variant="outlined" onClick={handleBack} size="small" sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '0.75rem',
                            padding: '6px 12px'
                        },
                        '& .MuiInputBase-root': {
                            marginTop: '8px',
                            marginBottom: '8px'
                        },
                        transition: 'background-color 0.3s, color 0.3s',
                        '&:hover': {
                            backgroundColor: '#f0f0f0',
                            color: '#000',
                        },
                    }}>Back</Button>
                    <Button variant="outlined" onClick={handlePreview} size="small" sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '0.75rem',
                            padding: '6px 12px'
                        },
                        '& .MuiInputBase-root': {
                            marginTop: '8px',
                            marginBottom: '8px'
                        },
                        backgroundColor: '#ffffff',
                        color: '#356596',
                        '&:hover': {
                            backgroundColor: '#356596',
                            color: '#ffffff',
                        }
                    }}>Preview</Button>
                    <Button variant="contained" color="primary" size="small" sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '0.75rem',
                            padding: '6px 12px'
                        },
                        '& .MuiInputBase-root': {
                            marginTop: '8px',
                            marginBottom: '8px'
                        },
                        backgroundColor: '#356596',
                        color: '#ffffff',
                        '&:hover': {
                            backgroundColor: '#ffffff',
                            color: '#356596',
                        }
                    }} onClick={handleSubmit}>Submit</Button>
                    <Button variant="contained" color="secondary" size="small" sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '0.75rem',
                            padding: '6px 12px'
                        },
                        '& .MuiInputBase-root': {
                            marginTop: '8px',
                            marginBottom: '8px'
                        }
                    }}>Create Next Session</Button>
                </Box>
            )}
            <Card ref={cardRef} sx={{
                width: 600,
                margin: '0 auto',
                padding: 2,
                transition: 'box-shadow 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.9)'
                }
            }}>
                {activeStep === 0 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>General Information</Typography>
                        <Box display="flex" justifyContent="space-between" mb={2}>
                            <TextField
                                id="outlined-size-small"
                                size="small"
                                label="Training Session Title"
                                value={sessionTitle}
                                onChange={(e) => setSessionTitle(e.target.value)}
                                fullWidth
                                margin="normal"
                                sx={{
                                    marginRight: 2,
                                    '& .MuiInputBase-root': { height: 36 },
                                    marginTop: '8px',
                                    marginBottom: '8px',
                                    '& .MuiInputBase-input': {
                                        fontSize: '0.75rem',
                                        padding: '6px 12px',
                                        fontFamily: 'Roboto'
                                    }
                                }}
                            />
                            <TextField
                                id="outlined-size-small"
                                size="small"
                                label="Location/Training Ground"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                fullWidth
                                margin="normal"
                                sx={{
                                    marginRight: 2,
                                    '& .MuiInputBase-root': { height: 36 },
                                    marginTop: '8px',
                                    marginBottom: '8px',
                                    '& .MuiInputBase-input': {
                                        fontSize: '0.75rem',
                                        padding: '6px 12px',
                                        fontFamily: 'Roboto'
                                    }
                                }}
                            />
                            <TextField
                                id="outlined-size-small"
                                size="small"
                                label="Date and Time"
                                type="datetime-local"
                                value={format(sessionDate, "yyyy-MM-dd'T'HH:mm")}
                                onChange={(e) => setSessionDate(new Date(e.target.value))}
                                fullWidth
                                margin="normal"
                                sx={{
                                    marginRight: 2,
                                    '& .MuiInputBase-root': { height: 36 },
                                    marginTop: '8px',
                                    marginBottom: '8px',
                                    '& .MuiInputBase-input': {
                                        fontSize: '0.75rem',
                                        padding: '6px 12px',
                                        fontFamily: 'Roboto'
                                    }
                                }}
                            />
                        </Box>
                        <Box display="flex" justifyContent="space-between" mb={2}>
                            <TextField
                                id="outlined-size-small"
                                size="small"
                                label="Coach's Name"
                                value={coachName}
                                onChange={(e) => setCoachName(e.target.value)}
                                fullWidth
                                margin="normal"
                                sx={{
                                    marginRight: 2,
                                    '& .MuiInputBase-root': { height: 36 },
                                    marginTop: '8px',
                                    marginBottom: '8px',
                                    '& .MuiInputBase-input': {
                                        fontSize: '0.75rem',
                                        padding: '6px 12px',
                                        fontFamily: 'Roboto'
                                    }
                                }}
                            />
                            <TextField
                                id="outlined-size-small"
                                size="small"
                                label="Assistant Coach"
                                value={assistingCoach}
                                onChange={(e) => setAssistingCoach(e.target.value)}
                                fullWidth
                                margin="normal"
                                sx={{
                                    marginRight: 2,
                                    '& .MuiInputBase-root': { height: 36 },
                                    marginTop: '8px',
                                    marginBottom: '8px',
                                    '& .MuiInputBase-input': {
                                        fontSize: '0.75rem',
                                        padding: '6px 12px',
                                        fontFamily: 'Roboto'
                                    }
                                }}
                            />
                            <TextField
                                id="outlined-size-small"
                                size="small"
                                label="Team/Group"
                                value={teamGroup}
                                onChange={(e) => setTeamGroup(e.target.value)}
                                fullWidth
                                margin="normal"
                                sx={{
                                    marginRight: 2,
                                    '& .MuiInputBase-root': { height: 36 },
                                    marginTop: '8px',
                                    marginBottom: '8px',
                                    '& .MuiInputBase-input': {
                                        fontSize: '0.75rem',
                                        padding: '6px 12px',
                                        fontFamily: 'Roboto'
                                    }
                                }}
                            />
                        </Box>
                        <TextField
                            id="outlined-size-small"
                            size="small"
                            label="Session Objectives"
                            value={sessionObjectives}
                            onChange={(e) => setSessionObjectives(e.target.value)}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            sx={{
                                '& .MuiInputBase-input': {
                                    fontSize: '0.75rem',
                                    padding: '6px 12px',
                                    fontFamily: 'Roboto'
                                }
                            }}
                        />
                        <Box display="flex" justifyContent="flex-end" mt={2}>
                            <Button variant="contained" color="primary" size="small" sx={{
                                '& .MuiInputBase-input': {
                                    fontSize: '0.75rem',
                                    padding: '6px 12px'
                                },
                                '& .MuiInputBase-root': {
                                    marginTop: '8px',
                                    marginBottom: '8px'
                                },
                                backgroundColor: '#356596',
                                color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#ffffff',
                                    color: '#356596'
                                }
                            }} onClick={handleNext}>Next</Button>
                        </Box>
                    </Box>
                )}
                {activeStep === 1 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>Training Focus</Typography>
                        <Box display="flex" flexDirection="column" gap={2}>
                            <Button variant="outlined" onClick={() => openPanel('exercise')}>Select Exercise</Button>
                            <Box display="flex" flexWrap="wrap" mt={1}>
                                {selectedExercises.map((exercise, index) => (
                                    <Chip key={exercise.id} label={exercise.name} style={{ marginRight: '5px', marginBottom: '5px' }} />
                                ))}
                            </Box>
                            <Button variant="outlined" onClick={() => openPanel('skills')}>Select Skills</Button>
                            <Box display="flex" flexWrap="wrap" mt={1}>
                                {selectedSkills.map((skill, index) => (
                                    <Chip key={skill.id} label={skill.name} style={{ marginRight: '5px', marginBottom: '5px' }} />
                                ))}
                            </Box>
                            <Button variant="outlined" onClick={() => openPanel('conditioning')}>Select Conditioning</Button>
                            <Box display="flex" flexWrap="wrap" mt={1}>
                                {selectedConditioning.map((conditioning, index) => (
                                    <Chip key={conditioning.id} label={conditioning.name} style={{ marginRight: '5px', marginBottom: '5px' }} />
                                ))}
                            </Box>
                            <Button variant="outlined" onClick={() => openPanel('specialized')}>Select Specialized Training</Button>
                            <Box display="flex" flexWrap="wrap" mt={1}>
                                {selectedSpecialized.map((specialized, index) => (
                                    <Chip key={specialized.id} label={specialized.name} style={{ marginRight: '5px', marginBottom: '5px' }} />
                                ))}
                            </Box>
                            <Button variant="outlined" onClick={() => openPanel('equipment')}>Select Equipment</Button>
                            <Box display="flex" flexWrap="wrap" mt={1}>
                                {selectedEquip.map((equip, index) => (
                                    <Chip key={equip.id} label={equip.name} style={{ marginRight: '5px', marginBottom: '5px' }} />
                                ))}
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mt={2}>
                            <Button variant="outlined" onClick={handleBack} size="small" sx={{
                                '& .MuiInputBase-input': {
                                    fontSize: '0.75rem',
                                    padding: '6px 12px'
                                },
                                '& .MuiInputBase-root': {
                                    marginTop: '8px',
                                    marginBottom: '8px'
                                },
                                transition: 'background-color 0.3s, color 0.3s',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                    color: '#000',
                                },
                            }}>Back</Button>
                            <Button variant="outlined" onClick={handlePreview} size="small" sx={{
                                '& .MuiInputBase-input': {
                                    fontSize: '0.75rem',
                                    padding: '6px 12px'
                                },
                                '& .MuiInputBase-root': {
                                    marginTop: '8px',
                                    marginBottom: '8px'
                                },
                                backgroundColor: '#ffffff',
                                color: '#356596',
                                '&:hover': {
                                    backgroundColor: '#356596',
                                    color: '#ffffff',
                                }
                            }}>Preview</Button>
                            <Button variant="contained" color="primary" size="small" sx={{
                                '& .MuiInputBase-input': {
                                    fontSize: '0.75rem',
                                    padding: '6px 12px'
                                },
                                '& .MuiInputBase-root': {
                                    marginTop: '8px',
                                    marginBottom: '8px'
                                },
                                backgroundColor: '#356596',
                                color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#ffffff',
                                    color: '#356596',
                                }
                            }} onClick={handleSubmit}>Submit</Button>
                            <Button variant="contained" color="secondary" size="small" sx={{
                                '& .MuiInputBase-input': {
                                    fontSize: '0.75rem',
                                    padding: '6px 12px'
                                },
                                '& .MuiInputBase-root': {
                                    marginTop: '8px',
                                    marginBottom: '8px'
                                }
                            }}>Create Next Session</Button>
                        </Box>
                    </Box>
                )}
            </Card>
            <Drawer anchor="right" open={activePanel === 'exercise'} onClose={() => setActivePanel('')}>
                <ExerciseSelectionPanel
                    isOpen={activePanel === 'exercise'}
                    type="exercise"
                    selectedExercises={selectedExercises}
                    setSelectedExercises={setSelectedExercises}
                    setIsPanelOpen={() => setActivePanel('')}
                />
            </Drawer>
            <Drawer anchor="right" open={activePanel === 'skills'} onClose={() => setActivePanel('')}>
                <SkillsSelectionPanel
                    isOpen={activePanel === 'skills'}
                    type="skills"
                    selectedSkills={selectedSkills}
                    setSelectedSkills={setSelectedSkills}
                    setIsPanelOpen={() => setActivePanel('')}
                />
            </Drawer>
            <Drawer anchor="right" open={activePanel === 'conditioning'} onClose={() => setActivePanel('')}>
                <PhysicalConditioningPanel
                    isOpen={activePanel === 'conditioning'}
                    type="conditioning"
                    selectedConditioning={selectedConditioning}
                    setSelectedConditioning={setSelectedConditioning}
                    setIsPanelOpen={() => setActivePanel('')}
                />
            </Drawer>
            <Drawer anchor="right" open={activePanel === 'specialized'} onClose={() => setActivePanel('')}>
                <SpecializedTrainingPanel
                    isOpen={activePanel === 'specialized'}
                    type="specialized"
                    selectedSpecialized={selectedSpecialized}
                    setSelectedSpecialized={setSelectedSpecialized}
                    setIsPanelOpen={() => setActivePanel('')}
                />
            </Drawer>
            <Drawer anchor="right" open={activePanel === 'equipment'} onClose={() => setActivePanel('')}>
                <EquipmentPanel
                    isOpen={activePanel === 'equipment'}
                    type="equipment"
                    selectedEquip={selectedEquip}
                    setSelectedEquip={setSelectedEquip}
                    setIsPanelOpen={() => setActivePanel('')}
                />
            </Drawer>
            <Drawer anchor="right" open={isPreviewOpen} onClose={handlePreview}>
                <ViewTraining data={gatherPreviewData()} onClose={handlePreview} />
            </Drawer>
        </Container>
    );
};

export default CreateTraining;
