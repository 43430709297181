// // // // // Custom UserProfile component
// // // // // Created by Shamiel Speelman
// // // // // Version: 0.1
// import React, { useState } from 'react';
// import { Box, Button, Divider, Typography, ListItemIcon, Modal, IconButton } from '@mui/material';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import PersonIcon from '@mui/icons-material/Person';
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import PolicyIcon from '@mui/icons-material/Policy';
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
// import FileUploadIcon from '@mui/icons-material/FileUpload';
// import CloseIcon from '@mui/icons-material/Close';
// import Terms from './Terms';

// const UserProfileMenu = React.forwardRef(({ userEmail, handleLogout, handleCloseMenu, handleDrawerOpen }, ref) => {
//     const [userImage, setUserImage] = useState(null);
//     const [isTermsOpen, setIsTermsOpen] = useState(false);

//     const handleImageUpload = event => {
//         const file = event.target.files[0];
//         const reader = new FileReader();
//         reader.onloadend = () => {
//             setUserImage(reader.result);
//         };
//         reader.readAsDataURL(file);
//     };

//     const handleOpenTerms = () => {
//         setIsTermsOpen(true);
//     };

//     const handleCloseTerms = () => {
//         setIsTermsOpen(false);
//     };

//     const menuItems = [
//         { text: 'My Account', icon: <PersonIcon />, action: handleDrawerOpen },
//         { text: 'FAQs', icon: <HelpOutlineIcon /> },
//         { text: 'Terms of Use', icon: <PolicyIcon />, action: handleOpenTerms },
//         { text: 'Exit', icon: <CloseIcon sx={{ color: 'red' }} />, action: handleCloseMenu, style: { color: 'red', backgroundColor: 'grey' } },
//         { text: 'Logout', icon: <ExitToAppIcon />, action: handleLogout }
//     ];

//     return (
//         <>
//             <Box ref={ref} sx={{
//                 width: '150px',
//                 bgcolor: '#FFFFFF',
//                 boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
//                 borderRadius: '5px',
//                 overflow: 'hidden',
//                 position: 'absolute',
//                 top: '60px',
//                 right: '20px',
//                 zIndex: 1000,
//             }}>
//                 <Box sx={{
//                     padding: '10px',
//                     textAlign: 'center'
//                 }}>
//                     {userImage ? (
//                         <img src={userImage} alt="User profile" style={{ width: 50, height: 50, borderRadius: '50%' }} />
//                     ) : (
//                         <Box>
//                             <AccountCircleIcon sx={{ fontSize: 40 }} />
//                             <input
//                                 type="file"
//                                 onChange={handleImageUpload}
//                                 style={{ display: 'none' }}
//                                 id="file-input"
//                             />
//                             <label htmlFor="file-input">
//                                 <FileUploadIcon sx={{ cursor: 'pointer', color: '#356596', fontSize: 40 }} />
//                             </label>
//                         </Box>
//                     )}
//                     <Typography sx={{ fontSize: '0.675rem', color: '#000', mt: 1, mb: 1 }}>{userEmail}</Typography>
//                 </Box>
//                 <Divider sx={{ my: 1 }} />
//                 {menuItems.map((item, index) => (
//                     <React.Fragment key={index}>
//                         <Button onClick={item.action} sx={{
//                             width: '100%',
//                             justifyContent: 'flex-start',
//                             bgcolor: 'transparent',
//                             color: '#356596',
//                             fontSize: '0.675rem',
//                             '&:hover': {
//                                 bgcolor: '#356596',
//                                 color: '#FFFFFF',
//                             },
//                             pl: 2,
//                         }}>
//                             <ListItemIcon sx={{ minWidth: 'auto', mr: 1, color: 'inherit' }}>{item.icon}</ListItemIcon>
//                             {item.text}
//                         </Button>
//                         {index < menuItems.length - 1 && <Divider />}
//                     </React.Fragment>
//                 ))}

//                 <Modal
//                     open={isTermsOpen}
//                     onClose={handleCloseTerms}
//                     aria-labelledby="terms-modal-title"
//                     aria-describedby="terms-modal-description"
//                 >
//                     <Box sx={{
//                         position: 'absolute',
//                         top: '10%', 
//                         left: '50%',
//                         transform: 'translate(-50%, 0)',
//                         width: 600,
//                         maxHeight: '80vh', 
//                         overflowY: 'flex', 
//                         bgcolor: 'background.paper',
//                         border: '2px solid #000',
//                         boxShadow: 24,
//                         p: 1,
//                     }}>
//                         <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                             <Typography id="terms-modal-title" variant="h6" component="h2">
//                                 Terms of Use
//                             </Typography>
//                             <IconButton onClick={handleCloseTerms}>
//                                 <CloseIcon />
//                             </IconButton>
//                         </Box>
//                         <Box id="terms-modal-description" sx={{ mt: -25 }}>
//                             <Terms onClose={handleCloseTerms} />
//                         </Box>
//                     </Box>
//                 </Modal>
//             </Box>
//         </>
//     );
// });

// export default UserProfileMenu;


// // // // Custom UserProfile component
// // // // Created by Shamiel Speelman
// // // // Version: 0.1
import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography, ListItemIcon, Modal, IconButton, Avatar } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PolicyIcon from '@mui/icons-material/Policy';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import Terms from './Terms';
import { Client, Databases, Query } from 'appwrite';
import conf from '../conf/conf';
import authService from '../appwrite/auth';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const UserProfileMenu = React.forwardRef(({ userEmail, handleLogout, handleCloseMenu, handleDrawerOpen }, ref) => {
    const [userImage, setUserImage] = useState(null);
    const [isTermsOpen, setIsTermsOpen] = useState(false);

    useEffect(() => {
        const fetchUserImage = async () => {
            try {
                const currentUser = await authService.getCurrentUser();
                if (!currentUser) {
                    throw new Error('No user is currently logged in.');
                }

                const userResponse = await databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteUsersId, [
                    Query.equal('userid', currentUser.$id)
                ]);

                if (userResponse.total > 0) {
                    const userDoc = userResponse.documents[0];
                    if (userDoc.clubImageId) {
                        const imageUrl = `${client.config.endpoint}/storage/buckets/${conf.appwriteBucketId}/files/${userDoc.clubImageId}/view?project=${client.config.project}`;
                        setUserImage(imageUrl);
                    }
                } else {
                    console.error('User document not found.');
                }
            } catch (error) {
                console.error('Error fetching user image:', error);
            }
        };

        fetchUserImage();
    }, []);

    const handleImageUpload = event => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setUserImage(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleOpenTerms = () => {
        setIsTermsOpen(true);
    };

    const handleCloseTerms = () => {
        setIsTermsOpen(false);
    };

    const menuItems = [
        { text: 'My Account', icon: <PersonIcon />, action: handleDrawerOpen },
        { text: 'FAQs', icon: <HelpOutlineIcon /> },
        { text: 'Terms of Use', icon: <PolicyIcon />, action: handleOpenTerms },
        { text: 'Exit', icon: <CloseIcon sx={{ color: 'red' }} />, action: handleCloseMenu, style: { color: 'red', backgroundColor: 'grey' } },
        { text: 'Logout', icon: <ExitToAppIcon />, action: handleLogout }
    ];

    return (
        <>
            <Box ref={ref} sx={{
                width: '150px',
                bgcolor: '#FFFFFF',
                boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                borderRadius: '5px',
                overflow: 'hidden',
                position: 'absolute',
                top: '60px',
                right: '20px',
                zIndex: 1000,
            }}>
                <Box sx={{
                    padding: '10px',
                    textAlign: 'center'
                }}>
                    {userImage ? (
                        <Avatar src={userImage} alt="User profile" sx={{ width: 50, height: 50, borderRadius: '50%', margin: '0 auto'  }} />
                    ) : (
                        <Box>
                            <Avatar sx={{ width: 50, height: 50, margin: '0 auto' }}>
                                <AccountCircleIcon sx={{ fontSize: 40 }} />
                            </Avatar>
                            <input
                                type="file"
                                onChange={handleImageUpload}
                                style={{ display: 'none' }}
                                id="file-input"
                            />
                            <label htmlFor="file-input">
                                <FileUploadIcon sx={{ cursor: 'pointer', color: '#356596', fontSize: 40 }} />
                            </label>
                        </Box>
                    )}
                    <Typography sx={{ fontSize: '0.675rem', color: '#000', mt: 1, mb: 1 }}>{userEmail}</Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                {menuItems.map((item, index) => (
                    <React.Fragment key={index}>
                        <Button onClick={item.action} sx={{
                            width: '100%',
                            justifyContent: 'flex-start',
                            bgcolor: 'transparent',
                            color: '#356596',
                            fontSize: '0.675rem',
                            '&:hover': {
                                bgcolor: '#356596',
                                color: '#FFFFFF',
                            },
                            pl: 2,
                        }}>
                            <ListItemIcon sx={{ minWidth: 'auto', mr: 1, color: 'inherit' }}>{item.icon}</ListItemIcon>
                            {item.text}
                        </Button>
                        {index < menuItems.length - 1 && <Divider />}
                    </React.Fragment>
                ))}

                <Modal
                    open={isTermsOpen}
                    onClose={handleCloseTerms}
                    aria-labelledby="terms-modal-title"
                    aria-describedby="terms-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '10%', 
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                        width: 600,
                        maxHeight: '80vh', 
                        overflowY: 'flex', 
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 1,
                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography id="terms-modal-title" variant="h6" component="h2">
                                Terms of Use
                            </Typography>
                            <IconButton onClick={handleCloseTerms}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box id="terms-modal-description" sx={{ mt: -25 }}>
                            <Terms onClose={handleCloseTerms} />
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </>
    );
});

export default UserProfileMenu;
