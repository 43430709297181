// // import React, { Component } from 'react';
// // import { Drawer, IconButton, Box, Typography, TextField, Button, Divider } from '@mui/material';
// // import { useSnackbar } from 'notistack';

// // export class EmailDrawer extends Component {
// //     state = {
// //         isOpen: false,
// //         emailFormData: {
// //             recipientName: '',
// //             toEmail: '',
// //             ccEmail: '',
// //             subject: '',
// //             compose: ''
// //         },
// //         emailFormErrors: {
// //             recipientName: '',
// //             toEmail: '',
// //             ccEmail: '',
// //             subject: '',
// //             compose: ''
// //         },
// //         emailFormDirty: false
// //     };

// //     toggleDrawer = () => {
// //         this.setState(prevState => ({ isOpen: !prevState.isOpen }));
// //     };

// //     handleEmailFormChange = (field) => (event) => {
// //         this.setState({
// //             emailFormData: { ...this.state.emailFormData, [field]: event.target.value },
// //             emailFormDirty: true
// //         });
// //     };

// //     validateEmailField = (email) => {
// //         const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
// //         return emailRegex.test(email);
// //     };

// //     validateForm = () => {
// //         let errors = {};
// //         let formIsValid = true;

// //         // Validate recipient name
// //         if (!this.state.emailFormData.recipientName) {
// //             errors.recipientName = 'Recipient name is required';
// //             formIsValid = false;
// //         }

// //         // Validate toEmail
// //         if (!this.state.emailFormData.toEmail) {
// //             errors.toEmail = 'Email address is required';
// //             formIsValid = false;
// //         } else if (!this.validateEmailField(this.state.emailFormData.toEmail)) {
// //             errors.toEmail = 'Invalid email address';
// //             formIsValid = false;
// //         }

// //         // Validate ccEmail (if provided)
// //         if (this.state.emailFormData.ccEmail && !this.validateEmailField(this.state.emailFormData.ccEmail)) {
// //             errors.ccEmail = 'Invalid email address';
// //             formIsValid = false;
// //         }

// //         // Validate subject
// //         if (!this.state.emailFormData.subject) {
// //             errors.subject = 'Subject is required';
// //             formIsValid = false;
// //         }

// //         // Validate compose
// //         if (!this.state.emailFormData.compose) {
// //             errors.compose = 'Message is required';
// //             formIsValid = false;
// //         }

// //         this.setState({ emailFormErrors: errors });
// //         return formIsValid;
// //     };

// //     handleSendEmail = () => {
// //         if (this.validateForm()) {
// //             console.log('Sending email:', this.state.emailFormData);
// //             this.props.enqueueSnackbar('Email sent successfully', { variant: 'success' });
// //             // Clear form fields after sending email
// //             this.setState({
// //                 emailFormData: {
// //                     recipientName: '',
// //                     toEmail: '',
// //                     ccEmail: '',
// //                     subject: '',
// //                     compose: ''
// //                 },
// //                 emailFormDirty: false
// //             });
// //             this.toggleDrawer();
// //         }
// //     };

// //     render() {
// //         return (
// //             <Drawer
// //                 anchor="right"
// //                 open={this.props.open}
// //                 onClose={this.props.onClose}
// //                 PaperProps={{ style: { width: '400px' } }}
// //             >
// //                 <Box p={2}>
// //                     <Box display="flex" justifyContent="space-between" alignItems="center">
// //                         <Typography variant="subtitle1">Send Email</Typography>
// //                         <IconButton
// //                             size="small"
// //                             onClick={this.props.onClose}
// //                             sx={{
// //                                 '&:hover': {
// //                                     backgroundColor: 'rgba(0, 0, 0, 0.1)',
// //                                 }
// //                             }}
// //                         >
// //                             <Typography>&times;</Typography>
// //                         </IconButton>
// //                     </Box>
// //                     <Divider />
// //                     <TextField
// //                         label="Recipient Name"
// //                         error={!!this.state.emailFormErrors.recipientName}
// //                         helperText={this.state.emailFormErrors.recipientName}
// //                         fullWidth
// //                         margin="normal"
// //                         value={this.state.emailFormData.recipientName}
// //                         onChange={this.handleEmailFormChange('recipientName')}
// //                         sx={{ width: '95%', mx: 'auto' }}
// //                     />
// //                     <TextField
// //                         label="To Email"
// //                         error={!!this.state.emailFormErrors.toEmail}
// //                         helperText={this.state.emailFormErrors.toEmail}
// //                         fullWidth
// //                         margin="normal"
// //                         value={this.state.emailFormData.toEmail}
// //                         onChange={this.handleEmailFormChange('toEmail')}
// //                         sx={{ width: '95%', mx: 'auto' }}
// //                     />
// //                     <TextField
// //                         label="CC Email"
// //                         error={!!this.state.emailFormErrors.ccEmail}
// //                         helperText={this.state.emailFormErrors.ccEmail}
// //                         fullWidth
// //                         margin="normal"
// //                         value={this.state.emailFormData.ccEmail}
// //                         onChange={this.handleEmailFormChange('ccEmail')}
// //                         sx={{ width: '95%', mx: 'auto' }}
// //                     />
// //                     <TextField
// //                         label="Subject"
// //                         error={!!this.state.emailFormErrors.subject}
// //                         helperText={this.state.emailFormErrors.subject}
// //                         fullWidth
// //                         margin="normal"
// //                         value={this.state.emailFormData.subject}
// //                         onChange={this.handleEmailFormChange('subject')}
// //                         sx={{ width: '95%', mx: 'auto' }}
// //                     />
// //                     <TextField
// //                         label="Compose"
// //                         error={!!this.state.emailFormErrors.compose}
// //                         helperText={this.state.emailFormErrors.compose}
// //                         fullWidth
// //                         margin="normal"
// //                         multiline
// //                         rows={3}
// //                         value={this.state.emailFormData.compose}
// //                         onChange={this.handleEmailFormChange('compose')}
// //                         sx={{ width: '95%', mx: 'auto' }}
// //                     />
// //                     <Button
// //                         variant="contained"
// //                         color="primary"
// //                         onClick={this.handleSendEmail}
// //                         sx={{
// //                             width: '95%',
// //                             mx: 'auto',
// //                             '&:hover': {
// //                                 backgroundColor: '#ffffff',
// //                                 color: '#3f51b5',
// //                             }
// //                         }}
// //                     >
// //                         Send
// //                     </Button>
// //                 </Box>
// //             </Drawer>
// //         );
// //     }
// // }

// // const WithSnackbar = (props) => {
// //     const { enqueueSnackbar } = useSnackbar();
// //     return <EmailDrawer {...props} enqueueSnackbar={enqueueSnackbar} />;
// // };

// // export default WithSnackbar;
// import React, { Component } from 'react';
// import { Drawer, IconButton, Box, Typography, TextField, Button, Divider, List, ListItem, ListItemText } from '@mui/material';
// import { useSnackbar } from 'notistack';
// import { Client, Account, Databases } from 'appwrite';
// import conf from '../conf/conf';

// export class EmailDrawer extends Component {
//     state = {
//         isOpen: false,
//         emailFormData: {
//             recipientName: '',
//             toEmail: '',
//             ccEmail: '',
//             subject: '',
//             compose: ''
//         },
//         emailFormErrors: {
//             recipientName: '',
//             toEmail: '',
//             ccEmail: '',
//             subject: '',
//             compose: ''
//         },
//         emailFormDirty: false,
//         users: [],
//         players: [],
//         suggestions: []
//     };

//     client = new Client();
//     account = new Account(this.client);
//     databases = new Databases(this.client);

//     componentDidMount() {
//         this.client
//             .setEndpoint(conf.appwriteUrl)
//             .setProject(conf.appwriteProjectId);

//         this.fetchUsers();
//         this.fetchPlayers();
//     }

//     fetchUsers = async () => {
//         try {
//             const response = await this.account.list();
//             const users = response.users.map(user => ({
//                 name: user.name,
//                 email: user.email,
//                 id: user.$id
//             }));
//             this.setState({ users });
//         } catch (error) {
//             console.error('Error fetching users:', error);
//         }
//     };

//     fetchPlayers = async () => {
//         try {
//             const response = await this.databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteCollectionId);
//             const players = response.documents.map(player => ({
//                 name: player.fullName,
//                 email: player.email,
//                 id: player.$id
//             }));
//             this.setState({ players });
//         } catch (error) {
//             console.error('Error fetching players:', error);
//         }
//     };

//     handleEmailFormChange = (field) => (event) => {
//         this.setState({
//             emailFormData: { ...this.state.emailFormData, [field]: event.target.value },
//             emailFormDirty: true
//         });

//         if (field === 'recipientName' && event.target.value.length >= 3) {
//             this.updateSuggestions(event.target.value);
//         }
//     };

//     updateSuggestions = (query) => {
//         const { users, players } = this.state;
//         const suggestions = [...users, ...players].filter(item =>
//             item.name.toLowerCase().includes(query.toLowerCase())
//         );
//         this.setState({ suggestions });
//     };

//     handleSuggestionClick = (suggestion) => {
//         this.setState({
//             emailFormData: {
//                 ...this.state.emailFormData,
//                 recipientName: suggestion.name,
//                 toEmail: suggestion.email
//             },
//             suggestions: []
//         });
//     };

//     validateEmailField = (email) => {
//         const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
//         return emailRegex.test(email);
//     };

//     validateForm = () => {
//         let errors = {};
//         let formIsValid = true;

//         if (!this.state.emailFormData.recipientName) {
//             errors.recipientName = 'Recipient name is required';
//             formIsValid = false;
//         }

//         if (!this.state.emailFormData.toEmail) {
//             errors.toEmail = 'Email address is required';
//             formIsValid = false;
//         } else if (!this.validateEmailField(this.state.emailFormData.toEmail)) {
//             errors.toEmail = 'Invalid email address';
//             formIsValid = false;
//         }

//         if (this.state.emailFormData.ccEmail && !this.validateEmailField(this.state.emailFormData.ccEmail)) {
//             errors.ccEmail = 'Invalid email address';
//             formIsValid = false;
//         }

//         if (!this.state.emailFormData.subject) {
//             errors.subject = 'Subject is required';
//             formIsValid = false;
//         }

//         if (!this.state.emailFormData.compose) {
//             errors.compose = 'Message is required';
//             formIsValid = false;
//         }

//         this.setState({ emailFormErrors: errors });
//         return formIsValid;
//     };

//     handleSendEmail = () => {
//         if (this.validateForm()) {
//             console.log('Sending email:', this.state.emailFormData);
//             this.props.enqueueSnackbar('Email sent successfully', { variant: 'success' });

//             this.setState({
//                 emailFormData: {
//                     recipientName: '',
//                     toEmail: '',
//                     ccEmail: '',
//                     subject: '',
//                     compose: ''
//                 },
//                 emailFormDirty: false
//             });
//             this.toggleDrawer();
//         }
//     };

//     render() {
//         return (
//             <Drawer
//                 anchor="right"
//                 open={this.props.open}
//                 onClose={this.props.onClose}
//                 PaperProps={{ style: { width: '400px' } }}
//             >
//                 <Box p={2}>
//                     <Box display="flex" justifyContent="space-between" alignItems="center">
//                         <Typography variant="subtitle1">Send Email</Typography>
//                         <IconButton
//                             size="small"
//                             onClick={this.props.onClose}
//                             sx={{
//                                 '&:hover': {
//                                     backgroundColor: 'rgba(0, 0, 0, 0.1)',
//                                 }
//                             }}
//                         >
//                             <Typography>&times;</Typography>
//                         </IconButton>
//                     </Box>
//                     <Divider />
//                     <TextField
//                         label="Recipient Name"
//                         error={!!this.state.emailFormErrors.recipientName}
//                         helperText={this.state.emailFormErrors.recipientName}
//                         fullWidth
//                         margin="normal"
//                         value={this.state.emailFormData.recipientName}
//                         onChange={this.handleEmailFormChange('recipientName')}
//                         sx={{ width: '95%', mx: 'auto' }}
//                     />
//                     {this.state.suggestions.length > 0 && (
//                         <List sx={{ maxHeight: 200, overflow: 'auto' }}>
//                             {this.state.suggestions.map((suggestion, index) => (
//                                 <ListItem button key={index} onClick={() => this.handleSuggestionClick(suggestion)}>
//                                     <ListItemText primary={suggestion.name} secondary={suggestion.email} />
//                                 </ListItem>
//                             ))}
//                         </List>
//                     )}
//                     <TextField
//                         label="To Email"
//                         error={!!this.state.emailFormErrors.toEmail}
//                         helperText={this.state.emailFormErrors.toEmail}
//                         fullWidth
//                         margin="normal"
//                         value={this.state.emailFormData.toEmail}
//                         onChange={this.handleEmailFormChange('toEmail')}
//                         sx={{ width: '95%', mx: 'auto' }}
//                     />
//                     <TextField
//                         label="CC Email"
//                         error={!!this.state.emailFormErrors.ccEmail}
//                         helperText={this.state.emailFormErrors.ccEmail}
//                         fullWidth
//                         margin="normal"
//                         value={this.state.emailFormData.ccEmail}
//                         onChange={this.handleEmailFormChange('ccEmail')}
//                         sx={{ width: '95%', mx: 'auto' }}
//                     />
//                     <TextField
//                         label="Subject"
//                         error={!!this.state.emailFormErrors.subject}
//                         helperText={this.state.emailFormErrors.subject}
//                         fullWidth
//                         margin="normal"
//                         value={this.state.emailFormData.subject}
//                         onChange={this.handleEmailFormChange('subject')}
//                         sx={{ width: '95%', mx: 'auto' }}
//                     />
//                     <TextField
//                         label="Compose"
//                         error={!!this.state.emailFormErrors.compose}
//                         helperText={this.state.emailFormErrors.compose}
//                         fullWidth
//                         margin="normal"
//                         multiline
//                         rows={3}
//                         value={this.state.emailFormData.compose}
//                         onChange={this.handleEmailFormChange('compose')}
//                         sx={{ width: '95%', mx: 'auto' }}
//                     />
//                     <Button
//                         variant="contained"
//                         color="primary"
//                         onClick={this.handleSendEmail}
//                         sx={{
//                             width: '95%',
//                             mx: 'auto',
//                             '&:hover': {
//                                 backgroundColor: '#ffffff',
//                                 color: '#3f51b5',
//                             }
//                         }}
//                     >
//                         Send
//                     </Button>
//                 </Box>
//             </Drawer>
//         );
//     }
// }

// const WithSnackbar = (props) => {
//     const { enqueueSnackbar } = useSnackbar();
//     return <EmailDrawer {...props} enqueueSnackbar={enqueueSnackbar} />;
// };

// export default WithSnackbar;
// import React, { Component } from 'react';
// import { Drawer, IconButton, Box, Typography, TextField, Button, Divider, List, ListItem, ListItemText } from '@mui/material';
// import { useSnackbar } from 'notistack';
// import { Client, Account, Databases, Query } from 'appwrite';
// import conf from '../conf/conf';
// import authService from '../appwrite/auth';

// export class EmailDrawer extends Component {
//     state = {
//         isOpen: false,
//         emailFormData: {
//             recipientName: '',
//             toEmail: '',
//             ccEmail: '',
//             subject: '',
//             compose: ''
//         },
//         emailFormErrors: {
//             recipientName: '',
//             toEmail: '',
//             ccEmail: '',
//             subject: '',
//             compose: ''
//         },
//         emailFormDirty: false,
//         users: [],
//         players: [],
//         suggestions: [],
//         errorMessage: ''
//     };

//     client = new Client();
//     account = new Account(this.client);
//     databases = new Databases(this.client);

//     componentDidMount() {
//         this.client
//             .setEndpoint(conf.appwriteUrl)
//             .setProject(conf.appwriteProjectId);

//         this.validateUserAndFetchData();
//     }

//     validateUserAndFetchData = async () => {
//         try {
//             const currentUser = await authService.getCurrentUser();
//             if (!currentUser) {
//                 throw new Error('User not authenticated');
//             }

//             await this.fetchUsers(currentUser.$id);
//             await this.fetchPlayers(currentUser.$id);
//         } catch (error) {
//             console.error('Error validating user or fetching data:', error);
//             this.setState({ errorMessage: 'No data matching your search' });
//         }
//     };

//     fetchUsers = async (userId) => {
//         try {
//             const response = await this.databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteUsersId, [
//                 Query.equal('userID', userId)
//             ]);
//             const users = response.documents.map(user => ({
//                 name: user.name,
//                 email: user.email,
//                 id: user.$id
//             }));
//             this.setState({ users });
//         } catch (error) {
//             console.error('Error fetching users:', error);
//             this.setState({ errorMessage: 'No data matching your search' });
//         }
//     };

//     fetchPlayers = async (userId) => {
//         try {
//             const response = await this.databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteCollectionId, [
//                 Query.equal('userID', userId)
//             ]);
//             const players = response.documents.map(player => ({
//                 name: player.fullName,
//                 email: player.email,
//                 id: player.$id
//             }));
//             this.setState({ players });
//         } catch (error) {
//             console.error('Error fetching players:', error);
//             this.setState({ errorMessage: 'No data matching your search' });
//         }
//     };

//     handleEmailFormChange = (field) => (event) => {
//         this.setState({
//             emailFormData: { ...this.state.emailFormData, [field]: event.target.value },
//             emailFormDirty: true
//         });

//         if (field === 'recipientName' && event.target.value.length >= 3) {
//             this.updateSuggestions(event.target.value);
//         }
//     };

//     updateSuggestions = (query) => {
//         const { users, players } = this.state;
//         const suggestions = [...users, ...players].filter(item =>
//             item.name.toLowerCase().includes(query.toLowerCase())
//         );
//         this.setState({ suggestions, errorMessage: suggestions.length === 0 ? 'No data matching your search' : '' });
//     };

//     handleSuggestionClick = (suggestion) => {
//         this.setState({
//             emailFormData: {
//                 ...this.state.emailFormData,
//                 recipientName: suggestion.name,
//                 toEmail: suggestion.email
//             },
//             suggestions: []
//         });
//     };

//     validateEmailField = (email) => {
//         const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
//         return emailRegex.test(email);
//     };

//     validateForm = () => {
//         let errors = {};
//         let formIsValid = true;

//         if (!this.state.emailFormData.recipientName) {
//             errors.recipientName = 'Recipient name is required';
//             formIsValid = false;
//         }

//         if (!this.state.emailFormData.toEmail) {
//             errors.toEmail = 'Email address is required';
//             formIsValid = false;
//         } else if (!this.validateEmailField(this.state.emailFormData.toEmail)) {
//             errors.toEmail = 'Invalid email address';
//             formIsValid = false;
//         }

//         if (this.state.emailFormData.ccEmail && !this.validateEmailField(this.state.emailFormData.ccEmail)) {
//             errors.ccEmail = 'Invalid email address';
//             formIsValid = false;
//         }

//         if (!this.state.emailFormData.subject) {
//             errors.subject = 'Subject is required';
//             formIsValid = false;
//         }

//         if (!this.state.emailFormData.compose) {
//             errors.compose = 'Message is required';
//             formIsValid = false;
//         }

//         this.setState({ emailFormErrors: errors });
//         return formIsValid;
//     };

//     handleSendEmail = () => {
//         if (this.validateForm()) {
//             console.log('Sending email:', this.state.emailFormData);
//             this.props.enqueueSnackbar('Email sent successfully', { variant: 'success' });

//             this.setState({
//                 emailFormData: {
//                     recipientName: '',
//                     toEmail: '',
//                     ccEmail: '',
//                     subject: '',
//                     compose: ''
//                 },
//                 emailFormDirty: false
//             });
//             this.toggleDrawer();
//         }
//     };

//     render() {
//         return (
//             <Drawer
//                 anchor="right"
//                 open={this.props.open}
//                 onClose={this.props.onClose}
//                 PaperProps={{ style: { width: '400px' } }}
//             >
//                 <Box p={2}>
//                     <Box display="flex" justifyContent="space-between" alignItems="center">
//                         <Typography variant="subtitle1">Send Email</Typography>
//                         <IconButton
//                             size="small"
//                             onClick={this.props.onClose}
//                             sx={{
//                                 '&:hover': {
//                                     backgroundColor: 'rgba(0, 0, 0, 0.1)',
//                                 }
//                             }}
//                         >
//                             <Typography>&times;</Typography>
//                         </IconButton>
//                     </Box>
//                     <Divider />
//                     <TextField
//                         label="Recipient Name"
//                         error={!!this.state.emailFormErrors.recipientName}
//                         helperText={this.state.emailFormErrors.recipientName}
//                         fullWidth
//                         margin="normal"
//                         value={this.state.emailFormData.recipientName}
//                         onChange={this.handleEmailFormChange('recipientName')}
//                         sx={{ width: '95%', mx: 'auto' }}
//                     />
//                     {this.state.suggestions.length > 0 && (
//                         <List sx={{ maxHeight: 200, overflow: 'auto' }}>
//                             {this.state.suggestions.map((suggestion, index) => (
//                                 <ListItem button key={index} onClick={() => this.handleSuggestionClick(suggestion)}>
//                                     <ListItemText primary={suggestion.name} secondary={suggestion.email} />
//                                 </ListItem>
//                             ))}
//                         </List>
//                     )}
//                     {this.state.errorMessage && (
//                         <Typography color="error" sx={{ textAlign: 'center', mt: 2 }}>
//                             {this.state.errorMessage}
//                         </Typography>
//                     )}
//                     <TextField
//                         label="To Email"
//                         error={!!this.state.emailFormErrors.toEmail}
//                         helperText={this.state.emailFormErrors.toEmail}
//                         fullWidth
//                         margin="normal"
//                         value={this.state.emailFormData.toEmail}
//                         onChange={this.handleEmailFormChange('toEmail')}
//                         sx={{ width: '95%', mx: 'auto' }}
//                     />
//                     <TextField
//                         label="CC Email"
//                         error={!!this.state.emailFormErrors.ccEmail}
//                         helperText={this.state.emailFormErrors.ccEmail}
//                         fullWidth
//                         margin="normal"
//                         value={this.state.emailFormData.ccEmail}
//                         onChange={this.handleEmailFormChange('ccEmail')}
//                         sx={{ width: '95%', mx: 'auto' }}
//                     />
//                     <TextField
//                         label="Subject"
//                         error={!!this.state.emailFormErrors.subject}
//                         helperText={this.state.emailFormErrors.subject}
//                         fullWidth
//                         margin="normal"
//                         value={this.state.emailFormData.subject}
//                         onChange={this.handleEmailFormChange('subject')}
//                         sx={{ width: '95%', mx: 'auto' }}
//                     />
//                     <TextField
//                         label="Compose"
//                         error={!!this.state.emailFormErrors.compose}
//                         helperText={this.state.emailFormErrors.compose}
//                         fullWidth
//                         margin="normal"
//                         multiline
//                         rows={3}
//                         value={this.state.emailFormData.compose}
//                         onChange={this.handleEmailFormChange('compose')}
//                         sx={{ width: '95%', mx: 'auto' }}
//                     />
//                     <Button
//                         variant="contained"
//                         color="primary"
//                         onClick={this.handleSendEmail}
//                         sx={{
//                             width: '95%',
//                             mx: 'auto',
//                             '&:hover': {
//                                 backgroundColor: '#ffffff',
//                                 color: '#3f51b5',
//                             }
//                         }}
//                     >
//                         Send
//                     </Button>
//                 </Box>
//             </Drawer>
//         );
//     }
// }

// const WithSnackbar = (props) => {
//     const { enqueueSnackbar } = useSnackbar();
//     return <EmailDrawer {...props} enqueueSnackbar={enqueueSnackbar} />;
// };

// export default WithSnackbar;
// import React, { Component } from 'react';
// import { Drawer, IconButton, Box, Typography, TextField, Button, Divider, List, ListItem, ListItemText } from '@mui/material';
// import { useSnackbar } from 'notistack';
// import { Client, Account, Databases, Functions, Query } from 'appwrite';
// import conf from '../conf/conf';
// import authService from '../appwrite/auth';

// export class EmailDrawer extends Component {
//     state = {
//         isOpen: false,
//         emailFormData: {
//             recipientName: '',
//             toEmail: '',
//             ccEmail: '',
//             subject: '',
//             compose: ''
//         },
//         emailFormErrors: {
//             recipientName: '',
//             toEmail: '',
//             ccEmail: '',
//             subject: '',
//             compose: ''
//         },
//         emailFormDirty: false,
//         users: [],
//         players: [],
//         suggestions: [],
//         errorMessage: ''
//     };

//     client = new Client();
//     account = new Account(this.client);
//     databases = new Databases(this.client);
//     functions = new Functions(this.client);

//     componentDidMount() {
//         this.client
//             .setEndpoint(conf.appwriteUrl)
//             .setProject(conf.appwriteProjectId);

//         this.validateUserAndFetchData();
//     }

//     validateUserAndFetchData = async () => {
//         try {
//             const currentUser = await authService.getCurrentUser();
//             if (!currentUser) {
//                 throw new Error('User not authenticated');
//             }

//             await this.fetchUsers(currentUser.$id);
//             await this.fetchPlayers(currentUser.$id);
//         } catch (error) {
//             console.error('Error validating user or fetching data:', error);
//             this.setState({ errorMessage: 'No data matching your search' });
//         }
//     };

//     fetchUsers = async (userId) => {
//         try {
//             const response = await this.databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteUsersId, [
//                 Query.equal('userID', userId)
//             ]);
//             const users = response.documents.map(user => ({
//                 name: user.name,
//                 email: user.email,
//                 id: user.$id
//             }));
//             this.setState({ users });
//         } catch (error) {
//             console.error('Error fetching users:', error);
//             this.setState({ errorMessage: 'No data matching your search' });
//         }
//     };

//     fetchPlayers = async (userId) => {
//         try {
//             const response = await this.databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteCollectionId, [
//                 Query.equal('userID', userId)
//             ]);
//             const players = response.documents.map(player => ({
//                 name: player.fullName,
//                 email: player.email,
//                 id: player.$id
//             }));
//             this.setState({ players });
//         } catch (error) {
//             console.error('Error fetching players:', error);
//             this.setState({ errorMessage: 'No data matching your search' });
//         }
//     };

//     handleEmailFormChange = (field) => (event) => {
//         this.setState({
//             emailFormData: { ...this.state.emailFormData, [field]: event.target.value },
//             emailFormDirty: true
//         });

//         if (field === 'recipientName' && event.target.value.length >= 3) {
//             this.updateSuggestions(event.target.value);
//         }
//     };

//     updateSuggestions = (query) => {
//         const { users, players } = this.state;
//         const suggestions = [...users, ...players].filter(item =>
//             item.name.toLowerCase().includes(query.toLowerCase())
//         );
//         this.setState({ suggestions, errorMessage: suggestions.length === 0 ? 'No data matching your search' : '' });
//     };

//     handleSuggestionClick = (suggestion) => {
//         this.setState({
//             emailFormData: {
//                 ...this.state.emailFormData,
//                 recipientName: suggestion.name,
//                 toEmail: suggestion.email
//             },
//             suggestions: []
//         });
//     };

//     validateEmailField = (email) => {
//         const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
//         return emailRegex.test(email);
//     };

//     validateForm = () => {
//         let errors = {};
//         let formIsValid = true;

//         if (!this.state.emailFormData.recipientName) {
//             errors.recipientName = 'Recipient name is required';
//             formIsValid = false;
//         }

//         if (!this.state.emailFormData.toEmail) {
//             errors.toEmail = 'Email address is required';
//             formIsValid = false;
//         } else if (!this.validateEmailField(this.state.emailFormData.toEmail)) {
//             errors.toEmail = 'Invalid email address';
//             formIsValid = false;
//         }

//         if (this.state.emailFormData.ccEmail && !this.validateEmailField(this.state.emailFormData.ccEmail)) {
//             errors.ccEmail = 'Invalid email address';
//             formIsValid = false;
//         }

//         if (!this.state.emailFormData.subject) {
//             errors.subject = 'Subject is required';
//             formIsValid = false;
//         }

//         if (!this.state.emailFormData.compose) {
//             errors.compose = 'Message is required';
//             formIsValid = false;
//         }

//         this.setState({ emailFormErrors: errors });
//         return formIsValid;
//     };

//     handleSendEmail = async () => {
//         if (this.validateForm()) {
//             try {
//                 const { toEmail, ccEmail, subject, compose } = this.state.emailFormData;

//                 // Call the Appwrite function
//                 await this.functions.createExecution(
//                     conf.Send_EmailAppwriteId, // Function ID from conf.js
//                     JSON.stringify({ toEmail, ccEmail, subject, compose })
//                 );

//                 this.props.enqueueSnackbar('Email sent successfully', { variant: 'success' });

//                 this.setState({
//                     emailFormData: {
//                         recipientName: '',
//                         toEmail: '',
//                         ccEmail: '',
//                         subject: '',
//                         compose: ''
//                     },
//                     emailFormDirty: false
//                 });

//                 this.props.onClose();
//             } catch (error) {
//                 console.error('Error sending email:', error);
//                 this.props.enqueueSnackbar('Failed to send email', { variant: 'error' });
//             }
//         }
//     };

//     render() {
//         return (
//             <Drawer
//                 anchor="right"
//                 open={this.props.open}
//                 onClose={this.props.onClose}
//                 PaperProps={{ style: { width: '400px' } }}
//             >
//                 <Box p={2}>
//                     <Box display="flex" justifyContent="space-between" alignItems="center">
//                         <Typography variant="subtitle1">Send Email</Typography>
//                         <IconButton
//                             size="small"
//                             onClick={this.props.onClose}
//                             sx={{
//                                 '&:hover': {
//                                     backgroundColor: 'rgba(0, 0, 0, 0.1)',
//                                 }
//                             }}
//                         >
//                             <Typography>&times;</Typography>
//                         </IconButton>
//                     </Box>
//                     <Divider />
//                     <TextField
//                         label="Recipient Name"
//                         error={!!this.state.emailFormErrors.recipientName}
//                         helperText={this.state.emailFormErrors.recipientName}
//                         fullWidth
//                         margin="normal"
//                         value={this.state.emailFormData.recipientName}
//                         onChange={this.handleEmailFormChange('recipientName')}
//                         sx={{ width: '95%', mx: 'auto' }}
//                     />
//                     {this.state.suggestions.length > 0 && (
//                         <List sx={{ maxHeight: 200, overflow: 'auto' }}>
//                             {this.state.suggestions.map((suggestion, index) => (
//                                 <ListItem button key={index} onClick={() => this.handleSuggestionClick(suggestion)}>
//                                     <ListItemText primary={suggestion.name} secondary={suggestion.email} />
//                                 </ListItem>
//                             ))}
//                         </List>
//                     )}
//                     {this.state.errorMessage && (
//                         <Typography color="error" sx={{ textAlign: 'center', mt: 2 }}>
//                             {this.state.errorMessage}
//                         </Typography>
//                     )}
//                     <TextField
//                         label="To Email"
//                         error={!!this.state.emailFormErrors.toEmail}
//                         helperText={this.state.emailFormErrors.toEmail}
//                         fullWidth
//                         margin="normal"
//                         value={this.state.emailFormData.toEmail}
//                         onChange={this.handleEmailFormChange('toEmail')}
//                         sx={{ width: '95%', mx: 'auto' }}
//                     />
//                     <TextField
//                         label="CC Email"
//                         error={!!this.state.emailFormErrors.ccEmail}
//                         helperText={this.state.emailFormErrors.ccEmail}
//                         fullWidth
//                         margin="normal"
//                         value={this.state.emailFormData.ccEmail}
//                         onChange={this.handleEmailFormChange('ccEmail')}
//                         sx={{ width: '95%', mx: 'auto' }}
//                     />
//                     <TextField
//                         label="Subject"
//                         error={!!this.state.emailFormErrors.subject}
//                         helperText={this.state.emailFormErrors.subject}
//                         fullWidth
//                         margin="normal"
//                         value={this.state.emailFormData.subject}
//                         onChange={this.handleEmailFormChange('subject')}
//                         sx={{ width: '95%', mx: 'auto' }}
//                     />
//                     <TextField
//                         label="Compose"
//                         error={!!this.state.emailFormErrors.compose}
//                         helperText={this.state.emailFormErrors.compose}
//                         fullWidth
//                         margin="normal"
//                         multiline
//                         rows={3}
//                         value={this.state.emailFormData.compose}
//                         onChange={this.handleEmailFormChange('compose')}
//                         sx={{ width: '95%', mx: 'auto' }}
//                     />
//                     <Button
//                         variant="contained"
//                         color="primary"
//                         onClick={this.handleSendEmail}
//                         sx={{
//                             width: '95%',
//                             mx: 'auto',
//                             '&:hover': {
//                                 backgroundColor: '#ffffff',
//                                 color: '#3f51b5',
//                             }
//                         }}
//                     >
//                         Send
//                     </Button>
//                 </Box>
//             </Drawer>
//         );
//     }
// }

// const WithSnackbar = (props) => {
//     const { enqueueSnackbar } = useSnackbar();
//     return <EmailDrawer {...props} enqueueSnackbar={enqueueSnackbar} />;
// };

// export default WithSnackbar;

import React, { Component } from 'react';
import { Drawer, IconButton, Box, Typography, TextField, Button, Divider, List, ListItem, ListItemText } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Client, Account, Databases, Functions, Query } from 'appwrite';
import conf from '../conf/conf';
import authService from '../appwrite/auth';

export class EmailDrawer extends Component {
    state = {
        isOpen: false,
        emailFormData: {
            recipientName: '',
            toEmail: '',
            ccEmail: '',
            subject: '',
            compose: ''
        },
        emailFormErrors: {
            recipientName: '',
            toEmail: '',
            ccEmail: '',
            subject: '',
            compose: ''
        },
        emailFormDirty: false,
        users: [],
        players: [],
        suggestions: [],
        errorMessage: ''
    };

    client = new Client();
    account = new Account(this.client);
    databases = new Databases(this.client);
    functions = new Functions(this.client);

    componentDidMount() {
        this.client
            .setEndpoint(conf.appwriteUrl)
            .setProject(conf.appwriteProjectId);

        this.validateUserAndFetchData();
    }

    validateUserAndFetchData = async () => {
        try {
            const currentUser = await authService.getCurrentUser();
            if (!currentUser) {
                throw new Error('User not authenticated');
            }

            await this.fetchUsers(currentUser.$id);
            await this.fetchPlayers(currentUser.$id);
        } catch (error) {
            console.error('Error validating user or fetching data:', error);
            this.setState({ errorMessage: 'No data matching your search' });
        }
    };

    fetchUsers = async (userId) => {
        try {
            const response = await this.databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteUsersId, [
                Query.equal('userID', userId)
            ]);
            const users = response.documents.map(user => ({
                name: user.name,
                email: user.email,
                id: user.$id
            }));
            this.setState({ users });
        } catch (error) {
            console.error('Error fetching users:', error);
            this.setState({ errorMessage: 'No data matching your search' });
        }
    };

    fetchPlayers = async (userId) => {
        try {
            const response = await this.databases.listDocuments(conf.appwriteDatabaseId, conf.appwriteCollectionId, [
                Query.equal('userID', userId)
            ]);
            const players = response.documents.map(player => ({
                name: player.fullName,
                email: player.email,
                id: player.$id
            }));
            this.setState({ players });
        } catch (error) {
            console.error('Error fetching players:', error);
            this.setState({ errorMessage: 'No data matching your search' });
        }
    };

    handleEmailFormChange = (field) => (event) => {
        this.setState({
            emailFormData: { ...this.state.emailFormData, [field]: event.target.value },
            emailFormDirty: true
        });

        if (field === 'recipientName' && event.target.value.length >= 3) {
            this.updateSuggestions(event.target.value);
        }
    };

    updateSuggestions = (query) => {
        const { users, players } = this.state;
        const suggestions = [...users, ...players].filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
        );
        this.setState({ suggestions, errorMessage: suggestions.length === 0 ? 'No data matching your search' : '' });
    };

    handleSuggestionClick = (suggestion) => {
        this.setState({
            emailFormData: {
                ...this.state.emailFormData,
                recipientName: suggestion.name,
                toEmail: suggestion.email
            },
            suggestions: []
        });
    };

    validateEmailField = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    };

    validateForm = () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.emailFormData.recipientName) {
            errors.recipientName = 'Recipient name is required';
            formIsValid = false;
        }

        if (!this.state.emailFormData.toEmail) {
            errors.toEmail = 'Email address is required';
            formIsValid = false;
        } else if (!this.validateEmailField(this.state.emailFormData.toEmail)) {
            errors.toEmail = 'Invalid email address';
            formIsValid = false;
        }

        if (this.state.emailFormData.ccEmail && !this.validateEmailField(this.state.emailFormData.ccEmail)) {
            errors.ccEmail = 'Invalid email address';
            formIsValid = false;
        }

        if (!this.state.emailFormData.subject) {
            errors.subject = 'Subject is required';
            formIsValid = false;
        }

        if (!this.state.emailFormData.compose) {
            errors.compose = 'Message is required';
            formIsValid = false;
        }

        this.setState({ emailFormErrors: errors });
        return formIsValid;
    };

    handleSendEmail = async () => {
        if (this.validateForm()) {
            try {
                const { toEmail, ccEmail, subject, compose } = this.state.emailFormData;
    
                const payload = {
                    toEmail,
                    ccEmail,
                    subject,
                    compose
                };
    
                // Log the payload before sending
                console.log('Sending payload:', JSON.stringify(payload));
    
                // Call the Appwrite function
                const response = await this.functions.createExecution(
                    conf.Send_EmailAppwriteId, // Use the configured function ID from conf.js
                    JSON.stringify(payload)
                );
    
                console.log('Function response:', response);
    
                this.props.enqueueSnackbar('Email sent successfully', { variant: 'success' });
    
                this.setState({
                    emailFormData: {
                        recipientName: '',
                        toEmail: '',
                        ccEmail: '',
                        subject: '',
                        compose: ''
                    },
                    emailFormDirty: false
                });
    
                this.props.onClose();
            } catch (error) {
                console.error('Error sending email:', error);
                this.props.enqueueSnackbar('Failed to send email', { variant: 'error' });
            }
        }
    };

    render() {
        return (
            <Drawer
                anchor="right"
                open={this.props.open}
                onClose={this.props.onClose}
                PaperProps={{ style: { width: '400px' } }}
            >
                <Box p={2}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1">Send Email</Typography>
                        <IconButton
                            size="small"
                            onClick={this.props.onClose}
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                }
                            }}
                        >
                            <Typography>&times;</Typography>
                        </IconButton>
                    </Box>
                    <Divider />
                    <TextField
                        label="Recipient Name"
                        error={!!this.state.emailFormErrors.recipientName}
                        helperText={this.state.emailFormErrors.recipientName}
                        fullWidth
                        margin="normal"
                        value={this.state.emailFormData.recipientName}
                        onChange={this.handleEmailFormChange('recipientName')}
                        sx={{ width: '95%', mx: 'auto' }}
                    />
                    {this.state.suggestions.length > 0 && (
                        <List sx={{ maxHeight: 200, overflow: 'auto' }}>
                            {this.state.suggestions.map((suggestion, index) => (
                                <ListItem button key={index} onClick={() => this.handleSuggestionClick(suggestion)}>
                                    <ListItemText primary={suggestion.name} secondary={suggestion.email} />
                                </ListItem>
                            ))}
                        </List>
                    )}
                    {this.state.errorMessage && (
                        <Typography color="error" sx={{ textAlign: 'center', mt: 2 }}>
                            {this.state.errorMessage}
                        </Typography>
                    )}
                    <TextField
                        label="To Email"
                        error={!!this.state.emailFormErrors.toEmail}
                        helperText={this.state.emailFormErrors.toEmail}
                        fullWidth
                        margin="normal"
                        value={this.state.emailFormData.toEmail}
                        onChange={this.handleEmailFormChange('toEmail')}
                        sx={{ width: '95%', mx: 'auto' }}
                    />
                    <TextField
                        label="CC Email"
                        error={!!this.state.emailFormErrors.ccEmail}
                        helperText={this.state.emailFormErrors.ccEmail}
                        fullWidth
                        margin="normal"
                        value={this.state.emailFormData.ccEmail}
                        onChange={this.handleEmailFormChange('ccEmail')}
                        sx={{ width: '95%', mx: 'auto' }}
                    />
                    <TextField
                        label="Subject"
                        error={!!this.state.emailFormErrors.subject}
                        helperText={this.state.emailFormErrors.subject}
                        fullWidth
                        margin="normal"
                        value={this.state.emailFormData.subject}
                        onChange={this.handleEmailFormChange('subject')}
                        sx={{ width: '95%', mx: 'auto' }}
                    />
                    <TextField
                        label="Compose"
                        error={!!this.state.emailFormErrors.compose}
                        helperText={this.state.emailFormErrors.compose}
                        fullWidth
                        margin="normal"
                        multiline
                        rows={3}
                        value={this.state.emailFormData.compose}
                        onChange={this.handleEmailFormChange('compose')}
                        sx={{ width: '95%', mx: 'auto' }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSendEmail}
                        sx={{
                            width: '95%',
                            mx: 'auto',
                            '&:hover': {
                                backgroundColor: '#ffffff',
                                color: '#3f51b5',
                            }
                        }}
                    >
                        Send
                    </Button>
                </Box>
            </Drawer>
        );
    }
}

const WithSnackbar = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    return <EmailDrawer {...props} enqueueSnackbar={enqueueSnackbar} />;
};

export default WithSnackbar;
