// // Custom RPEAnalytics component
// // Created by Shamiel Speelman
// // Version: 0.1
import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    BarChart,
    Bar,
    AreaChart,
    Area,
    ResponsiveContainer,
} from 'recharts';

const getWeekNumber = (date) => {
    const tempDate = new Date(date.getTime());
    tempDate.setHours(0, 0, 0, 0);
    tempDate.setDate(tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7));
    const week1 = new Date(tempDate.getFullYear(), 0, 4);
    return (
        1 +
        Math.round(
            ((tempDate.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7
        )
    );
};

const prepareWeeklyRpeTrends = (data) => {
    const weeklyData = {};
    data.forEach((entry) => {
        const date = new Date(entry.matchDate || entry.sessionDate);
        const week = getWeekNumber(date);
        if (!weeklyData[week]) {
            weeklyData[week] = { week };
        }
        weeklyData[week][entry.playerName] = entry.rpeValue;
    });
    return Object.values(weeklyData);
};

const prepareTrainingLoadOverTime = (data) => {
    return data.map((entry) => ({
        date: entry.matchDate || entry.sessionDate,
        trainingLoad: entry.rpeValue * entry.duration,
    }));
};

const preparePlayerPerformanceOverTime = (data) => {
    return data.map((entry) => ({
        date: entry.matchDate || entry.sessionDate,
        playerName: entry.playerName,
        rpeValue: entry.rpeValue,
        hrBPM: entry.hrBPM,
    }));
};

const prepareComparativeAnalysis = (data) => {
    const playerData = {};
    data.forEach((entry) => {
        if (!playerData[entry.playerName]) {
            playerData[entry.playerName] = { player: entry.playerName, totalRpe: 0, totalBPM: 0, count: 0 };
        }
        playerData[entry.playerName].totalRpe += entry.rpeValue;
        playerData[entry.playerName].totalBPM += entry.hrBPM || 0;
        playerData[entry.playerName].count += 1;
    });
    return Object.values(playerData).map((player) => ({
        player: player.player,
        averageRpe: player.totalRpe / player.count,
        averageBPM: player.totalBPM / player.count,
    }));
};

const RPEAnalytics = ({ onClose, rpeData }) => {
    const weeklyRpeTrends = prepareWeeklyRpeTrends(rpeData);
    const trainingLoadOverTime = prepareTrainingLoadOverTime(rpeData);
    const playerPerformanceOverTime = preparePlayerPerformanceOverTime(rpeData);
    const comparativeAnalysis = prepareComparativeAnalysis(rpeData);

    return (
        <Box sx={{ width: '100%', height: '100%', padding: '20px', backgroundColor: '#f4f4f4' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h4">RPE Analytics</Typography>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '400px', marginTop: '20px' }}>
                <Box sx={{ width: '48%' }}>
                    <Typography variant="h6">Weekly RPE Trends</Typography>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={weeklyRpeTrends}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="week" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {Object.keys(weeklyRpeTrends[0] || {}).filter(key => key !== 'week').map(playerName => (
                                <Line key={playerName} type="monotone" dataKey={playerName} stroke="#8884d8" />
                            ))}
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
                <Box sx={{ width: '48%' }}>
                    <Typography variant="h6">Training Load Over Time</Typography>
                    <ResponsiveContainer width="100%" height="100%">
                        <AreaChart data={trainingLoadOverTime}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Area type="monotone" dataKey="trainingLoad" stroke="#8884d8" fill="#8884d8" />
                        </AreaChart>
                    </ResponsiveContainer>
                </Box>
            </Box>
            <Box sx={{ height: '400px', marginTop: '40px' }}>
                <Typography variant="h6">Player Performance Over Time</Typography>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={playerPerformanceOverTime}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="rpeValue" stroke="#8884d8" />
                        {playerPerformanceOverTime.some(entry => entry.hrBPM) && (
                            <Line type="monotone" dataKey="hrBPM" stroke="#82ca9d" />
                        )}
                    </LineChart>
                </ResponsiveContainer>
            </Box>
            <Box sx={{ height: '400px', marginTop: '20px' }}>
                <Typography variant="h6">Comparative Analysis of Players</Typography>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={comparativeAnalysis}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="player" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="averageRpe" fill="#8884d8" />
                        {comparativeAnalysis.some(entry => entry.averageBPM) && (
                            <Bar dataKey="averageBPM" fill="#82ca9d" />
                        )}
                    </BarChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    );
};

export default RPEAnalytics;

