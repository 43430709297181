// // Custom PlayGround component (needs to be fleshed out a bit more)
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import formationImage from '../components/custome_components/formation-4-1-2-1-2.jpg';

const FormationCard_4_1_2_1_2 = ({ onClose }) => {
  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Card sx={{
        width: '80%',
        maxWidth: '800px',
        backgroundColor: '#356596',
        color: '#ffffff',
        borderRadius: '10px',
        overflowY: 'auto',
        transition: 'box-shadow 0.7s ease-in-out',
        '&:hover': {
          boxShadow: '0 10px 16px rgba(0, 0, 0, 0.9)'
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        mt: 6
      }}>
        <CardContent>
          <Typography variant="h5" sx={{ mb: 2, textAlign: 'left' }}>
            4-1-2-1-2 FORMATION
          </Typography>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{ backgroundColor: '#ffffff' }}>
              <Typography sx={{ color: '#356596' }}>FORMATION OVERVIEW</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#356596', color: '#ffffff' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', mb: 2 }}>
                  <Box sx={{ flex: '1 1 auto', pr: 2 }}>
                    <Box sx={{ float: 'right', ml: 2, mb: 1, maxWidth: '50%' }}>
                      <img src={formationImage} alt="4-1-2-1-2 Formation" style={{ width: '100%', borderRadius: '10px' }} />
                    </Box>
                    <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                      The 4-1-2-1-2 formation, also known as the diamond formation or Christmas tree formation, is a tactical formation commonly used by many famous football clubs. It is a variation of the 4-4-2 formation and is designed to provide a strong midfield presence while maintaining a compact defensive shape. This formation is characterized by four defenders, one defensive midfielder, two central midfielders, one attacking midfielder, and two strikers.
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                      The following places make up the 4-1-2-1-2 formation:
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                      • Goalkeeper: The goalie is the last line of defence and is in charge of organising the defence and stopping shots on goal.
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                      • Defenders: Typically, there are two centre backs and two full backs among the four defenders. While the full-backs offer width in attack and defensive protection on the flanks, the center-backs are in charge of marking the opposition's strikers and covering the goalkeeper.
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                      • Defensive midfielder: The defensive midfielder sits in front of the back four and is responsible for breaking up opposition attacks and shielding the defense. This player also provides support to the central midfielders and may drop back to form a back five when the team is defending.
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                      • Central midfielders: The two central midfielders are responsible for controlling the midfield and linking defense with attack. They provide support to the strikers and attacking midfielder while also tracking back to help defend.
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                      • Attacking midfielder: The attacking midfielder is in charge of setting up scoring chances for the two strikers as they play behind each other. This player has outstanding passing and dribbling abilities and frequently creates plays.
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                      • Strikers: The two strikers are responsible for scoring goals and putting pressure on the opposition defense. They may play as a partnership or with one striker dropping deeper to create space for the other.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header" sx={{ backgroundColor: '#ffffff' }}>
              <Typography sx={{ color: '#356596' }}>PROS</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#356596', color: '#ffffff' }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>Strong midfield presence</Typography>
              <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                The diamond formation provides a strong central midfield presence with two central midfielders and a defensive midfielder. This can help the team control the midfield and dominate possession, allowing them to create scoring opportunities and dictate the tempo of the game.
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>Compact Defensive Shape</Typography>
              <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                The diamond formation provides a compact defensive shape, with the defensive midfielder sitting in front of the back four to provide cover and break up opposition attacks. This can make it difficult for the opposition to break through the defense and create scoring opportunities.
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>Flexibility</Typography>
              <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                The diamond formation is flexible and can be adapted to suit different styles of play. For example, the attacking midfielder can be instructed to drop back and help defend when the team is under pressure, or the full-backs can be instructed to push forward to provide width in attack.
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>Effective in the Final Third</Typography>
              <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                The diamond formation can be effective in the final third of the pitch, with the attacking midfielder playing a key role in creating scoring opportunities for the strikers. This can help the team score goals and win games.
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>Partnership Between Strikers</Typography>
              <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                The diamond formation often employs two strikers playing as a partnership, which can create a strong attacking threat and make it difficult for the opposition defense to mark both players effectively.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header" sx={{ backgroundColor: '#ffffff' }}>
              <Typography sx={{ color: '#356596' }}>CONS</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#356596', color: '#ffffff' }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>Vulnerability on the Flanks</Typography>
              <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                The diamond formation can be vulnerable on the flanks as the full-backs may push too far forward, leaving gaps in behind. This can make it easy for the opposition to attack down the wings and create scoring opportunities.
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>Lack of Width</Typography>
              <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                The diamond formation is narrow by design, which can limit the team's ability to create scoring opportunities from wide areas. This can make the team's attacking play predictable and easier to defend against.
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>Dependence on the Attacking Midfielder</Typography>
              <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                The CAM plays a crucial role in the formation, and if they are marked out of the game, the team may struggle to create scoring opportunities.
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>Limited Defensive Cover in Wide Areas</Typography>
              <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                The diamond formation may struggle to provide defensive cover in wide areas as the full-backs may be pushed too far forward. This can make it difficult for the team to defend against opposition attacks down the wings.
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>Lack of Flexibility in Midfield</Typography>
              <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                The diamond formation relies heavily on the central midfielders to control the midfield, which can limit the team's ability to adapt to changes in the game. If the opposition starts dominating the midfield, the team may struggle to regain control.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header" sx={{ backgroundColor: '#ffffff' }}>
              <Typography sx={{ color: '#356596' }}>HOW TO COUNTER 4-1-2-1-2 FORMATION?</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#356596', color: '#ffffff' }}>
              <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                Exploit the Wide Areas: Since the 4-1-2-1-2 formation is narrow, there can be space to exploit on the wings. Look to utilize fast and skillful wingers who can create chances by delivering crosses or cutting inside to create chances.
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                Counterattack: Since the formation is so compact, there may be opportunities to counterattack when the opposition pushes forward. Use quick forwards and midfielders to launch counterattacks and catch the opposition out of position.
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                Press High Up The Pitch: Apply pressure on the opponent’s defense, especially on the full-backs, to force them to make mistakes. With a high press, you can force the opposition to play long balls or commit turnovers in dangerous areas.
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                Overload the Midfield: Since the formation has a diamond-shaped midfield, there can be space to exploit by overloading the midfield. Use your midfielders to press high and create numerical superiority in midfield, which can help you to control the game.
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                Play Wide: To stretch the opposition and create space, try to play the ball wide to your full-backs, who can then deliver crosses into the box or create chances by overlapping the wingers. Play with a Defensive Midfielder: Playing with a defensive midfielder can help to shield your defense and prevent the opposition from creating chances through the middle. The defensive midfielder can also act as a pivot to transition from defense to attack quickly.
              </Typography>
              <Typography variant="subtitle1" sx={{ fontSize: '0.65rem', mb: 1 }}>Suggested Counter Formations</Typography>
              <Typography variant="body2" sx={{ fontSize: '0.65rem', mb: 1 }}>
                4-3-3<br />
                4-2-2-2<br />
                4-2-3-1<br />
                4-5-1<br />
                3-5-2
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="outlined" style={{ width: '100%' }} size="small" sx={{
              '& .MuiInputBase-input': {
                fontSize: '0.75rem',
                padding: '6px 12px'
              },
              '& .MuiInputBase-root': {
                marginTop: '8px',
                marginBottom: '8px'
              },
              backgroundColor: '#356596',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#ffffff',
                color: '#356596'
              }
            }} onClick={onClose}>
              Close
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default FormationCard_4_1_2_1_2;
