// // Custom StartMatch component
// // Created by Shamiel Speelman
// // Version: 0.1
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Box, Typography, Grid, Link, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsIcon from '@mui/icons-material/Sports';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import EventIcon from '@mui/icons-material/Event';
import CardDetails from './CardDetails';
import GoalDetails from './GoalDetails';
import SubstituteDetails from './SubstituteDetails';
import MatchEventDetails from './MatchEventDetails';
import { Client, Databases, Query } from 'appwrite';
import conf from '../conf/conf';
import authService from '../appwrite/auth';

const client = new Client()
    .setEndpoint(conf.appwriteUrl)
    .setProject(conf.appwriteProjectId);

const databases = new Databases(client);

const StartMatch = ({ teamName, opposition, matchID, isRunning, setIsRunning, seconds, setSeconds, handleStartPause, handleMatchEnd }) => {
    const [openDialog, setOpenDialog] = useState(null);
    const [goalDetails, setGoalDetails] = useState({ selectedPlayer: null, action: '', bodyPart: '', groundArea: '', oppositionPlayer: '', ownGoal: false, comment: '' });
    const [cardDetails, setCardDetails] = useState({ selectedPlayer: null, cardType: '', oppositionPlayer: '', reason: '', comment: '' });
    const [substituteDetails, setSubstituteDetails] = useState({ selectedPlayerOut: null, selectedPlayerIn: null, reason: '', comment: '' });
    const [matchEventDetails, setMatchEventDetails] = useState({ selectedPlayer: null, eventType: 'decisive_save', oppositionPlayer: '', reason: '', comment: '' });
    const [currentTime, setCurrentTime] = useState({ time: '0\'00', period: 1 });
    const [matchResultsDialogOpen, setMatchResultsDialogOpen] = useState(false);
    const [finalScoreHome, setFinalScoreHome] = useState(0);
    const [finalScoreOpposition, setFinalScoreOpposition] = useState(0);
    const [staticTime, setStaticTime] = useState('');

    const navigate = useNavigate();

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}'${secs < 10 ? '0' : ''}${secs}`;
    };

    const getPeriod = (seconds) => {
        return seconds < 45 * 60 ? 1 : 2;
    };

    const intervalRef = useRef(null);

    const handleOpenDialog = (dialogType) => {
        const currentSeconds = seconds;
        const period = getPeriod(currentSeconds);
        const time = formatTime(currentSeconds);
        setCurrentTime({ time, period });
        setOpenDialog(dialogType);
    };

    const handleCloseDialog = () => {
        setOpenDialog(null);
    };

    const handleEndMatch = () => {
        clearInterval(intervalRef.current);
        setIsRunning(false);
        const time = formatTime(seconds);
        setStaticTime(time);
        setCurrentTime({ time, period: getPeriod(seconds) });
        setMatchResultsDialogOpen(true);
    };

    const fetchGoals = async (matchID) => {
        try {
            const goals = await databases.listDocuments(conf.appwriteDatabaseId, conf.goalsCollectionId, [
                Query.equal('matchID', matchID)
            ]);
            let homeGoals = 0;
            let oppositionGoals = 0;

            goals.documents.forEach(goal => {
                if (goal.teamPlayer) homeGoals += 1;
                if (goal.oppositionPlayer) oppositionGoals += 1;
            });

            setFinalScoreHome(homeGoals);
            setFinalScoreOpposition(oppositionGoals);
            return { homeGoals, oppositionGoals };
        } catch (error) {
            console.error('Error fetching goals:', error);
            return { homeGoals: 0, oppositionGoals: 0 };
        }
    };

    const handleSaveMatchResults = async () => {
        try {
            const { homeGoals, oppositionGoals } = await fetchGoals(matchID);

            const currentUser = await authService.getCurrentUser();
            const fullTime = staticTime;
            const extraTime = seconds > 90 * 60 ? formatTime(seconds - 90 * 60) : '0:00';
            const data = {
                matchID,
                userID: currentUser.$id,
                userEmail: currentUser.email,
                fullTime,
                extraTime,
                finalScoreHome: homeGoals,
                finalScoreOpposition: oppositionGoals
            };
            await databases.createDocument(conf.appwriteDatabaseId, conf.finalResultsCollectionId, 'unique()', data);
            setMatchResultsDialogOpen(false);
            handleMatchEnd(matchID, homeGoals, oppositionGoals);
            navigate('/dashboard/matchday'); // Redirect to MatchDay component
        } catch (error) {
            console.error('Error saving match results:', error);
        }
    };

    useEffect(() => {
        const fetchInitialGoals = async () => {
            await fetchGoals(matchID);
        };
        fetchInitialGoals();
    }, [matchID]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (isRunning) {
                fetchGoals(matchID);
            }
        }, 5000);

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [isRunning, matchID]);

    useEffect(() => {
        const interval = intervalRef.current;
        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    const cardStyle = {
        height: '100px',
        backgroundColor: '#ffffff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        border: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'box-shadow 0.3s ease-in-out',
        '&:hover': {
            boxShadow: '0 10px 16px rgba(0, 0, 0, 0.9)'
        },
        cursor: 'pointer' // Add this line to make the card clickable
    };

    return (
        <Card sx={{
            width: 700,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            transition: 'box-shadow 0.7s ease-in-out',
            '&:hover': {
                boxShadow: '0 10px 16px rgba(0, 0, 0, 0.9)'
            },
            overflow: 'hidden',
            bgcolor: '#ffffff',
            textAlign: 'center',
            margin: 'auto'
        }}>
            <Box sx={{ bgcolor: '#000', color: '#fff', padding: '10px 0' }}>
                <Typography variant="h6">{teamName} - {opposition}</Typography>
                <Typography variant="h6">{finalScoreHome} - {finalScoreOpposition}</Typography>
            </Box>
            <Box sx={{ padding: '20px', textAlign: 'center' }}>
                <Link
                    href="#"
                    onClick={handleEndMatch}
                    sx={{
                        display: isRunning ? 'block' : 'none',
                        marginBottom: '20px',
                        color: '#d32f2f',
                        textDecoration: 'none',
                        fontFamily: 'Roboto',
                        fontSize: '1rem',
                        '&:hover': {
                            textDecoration: 'underline'
                        }
                    }}
                >
                    End Match
                </Link>
                <Typography variant="h1" sx={{ my: 3 }}>{formatTime(seconds)}</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box sx={cardStyle} onClick={() => handleOpenDialog('goal')}>
                            <SportsSoccerIcon sx={{ fontSize: 70, color: 'lightgrey' }} />
                            <Typography sx={{ marginLeft: '10px', fontSize: '1.2rem', color: '#356596' }}>GOAL</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={cardStyle} onClick={() => handleOpenDialog('card')}>
                            <SportsIcon sx={{ fontSize: 70, color: 'lightgrey' }} />
                            <Typography sx={{ marginLeft: '10px', fontSize: '1.2rem', color: '#356596' }}>CARD</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={6}>
                        <Box sx={cardStyle} onClick={() => handleOpenDialog('substitute')}>
                            <SwapHorizIcon sx={{ fontSize: 70, color: 'lightgrey' }} />
                            <Typography sx={{ marginLeft: '10px', fontSize: '1.2rem', color: '#356596' }}>SUBSTITUTE</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={cardStyle} onClick={() => handleOpenDialog('matchEvent')}>
                            <EventIcon sx={{ fontSize: 70, color: 'lightgrey' }} />
                            <Typography sx={{ marginLeft: '10px', fontSize: '1.2rem', color: '#356596' }}>MATCH EVENT</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Button 
                    variant="contained" 
                    onClick={handleStartPause}
                    sx={{
                        backgroundColor: '#356596',
                        color: '#ffffff',
                        '&:hover': {
                            backgroundColor: '#ffffff',
                            color: '#356596'
                        },
                        marginBottom: '10px',
                        width: '100%',
                        mt: 3
                    }}
                >
                    {isRunning ? 'Pause Period' : 'Start Period'}
                </Button>
            </Box>
            <Dialog
                open={Boolean(openDialog)}
                onClose={handleCloseDialog}
                aria-labelledby="dialog-title"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="dialog-title">
                    {openDialog === 'goal' && 'Capture Goal Details'}
                    {openDialog === 'card' && 'Capture Card Details'}
                    {openDialog === 'substitute' && 'Capture Substitute Details'}
                    {openDialog === 'matchEvent' && 'Capture Match Event Details'}
                </DialogTitle>
                <DialogContent dividers sx={{ padding: '10px 10px' }}>
                    {openDialog === 'goal' && <GoalDetails time={currentTime.time} period={currentTime.period} matchID={matchID} details={goalDetails} setDetails={setGoalDetails} onClose={handleCloseDialog} />}
                    {openDialog === 'card' && <CardDetails time={currentTime.time} period={currentTime.period} matchID={matchID} details={cardDetails} setDetails={setCardDetails} onClose={handleCloseDialog} />}
                    {openDialog === 'substitute' && <SubstituteDetails time={currentTime.time} period={currentTime.period} matchID={matchID} details={substituteDetails} setDetails={setSubstituteDetails} onClose={handleCloseDialog} />}
                    {openDialog === 'matchEvent' && <MatchEventDetails time={currentTime.time} period={currentTime.period} matchID={matchID} details={matchEventDetails} setDetails={setMatchEventDetails} onClose={handleCloseDialog} />}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDialog}
                        variant="contained"
                        color="secondary"
                        size="small"
                        sx={{
                            backgroundColor: '#d32f2f',
                            color: '#ffffff',
                            '&:hover': {
                                backgroundColor: '#ffffff',
                                color: '#d32f2f'
                            },
                            marginBottom: '10px',
                            width: '100%'
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={matchResultsDialogOpen}
                onClose={() => setMatchResultsDialogOpen(false)}
                aria-labelledby="match-results-dialog-title"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="match-results-dialog-title">Match Results</DialogTitle>
                <DialogContent dividers sx={{ padding: '10px 10px' }}>
                    <Typography variant="h6">{teamName} - {opposition}</Typography>
                    <Typography variant="h4" sx={{ my: 2 }}>{finalScoreHome} - {finalScoreOpposition}</Typography>
                    <Typography variant="body1">Full Time: {staticTime}</Typography>
                    <Typography variant="body1">Extra Time: {seconds > 90 * 60 ? formatTime(seconds - 90 * 60) : '0:00'}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleSaveMatchResults}
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{
                            marginBottom: '10px',
                            width: '100%'
                        }}
                    >
                        Save and Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

export default StartMatch;
